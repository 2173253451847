import React, { ReactNode, useEffect, useRef, useState } from "react";

import { CircularProgress } from "@mui/material";
import
  {
    DocumentReference,
  } from "firebase/firestore";
import { EliteCompany } from "../types";
import { useNavigate } from "react-router-dom";
import { WarningAmber } from "@mui/icons-material";

type DeactivateModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  children: ReactNode;
  eliteCompanyRef: DocumentReference;
  callback: () => Promise<void>;
};

const DeactivateModal: React.FC<DeactivateModalProps> = ({
  isOpen,
  onClose,
  children,
  eliteCompanyRef,
  onConfirm,
  callback,
}) =>
{
  const navigate = useNavigate();
  const [deactivating, setDeactivating] = useState(false);
  const [loading, setLoading] = useState(true);
  const [activating, setActivating] = useState(false);
  const [confirmChange, setConfirmChange] = useState(false);
  const [user, setUser] = useState<DocumentReference>();
  const modalRef = useRef<HTMLDivElement>(null);
  const [cardsOptions, setCardsOptions] = useState<string[]>();
  const [eliteCompany, setEliteCompany] = useState<EliteCompany>();
  const [cardWithIndex, setCardWithIndex] = useState<string[]>();
  const [selectedCard, setSelectedCard] = useState<string>();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() =>
  {
    async function fetchData()
    {
      setLoading(false);
    }
    fetchData();
  }, [loading]);

  const handleDeactivate = async () =>
  {
    setDeactivating(true);
    await callback().then((res) =>
    {
      onConfirm();
    });
  };

  useEffect(() =>
  {
    const handleEscape = (event: KeyboardEvent) =>
    {
      if (event.key === "Escape" && !loading && !deactivating)
      {
        onClose();
      }
    };

    const handleOutsideClick = (event: MouseEvent) =>
    {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node) &&
        !loading &&
        !deactivating
      )
      {
        onClose();
      }
    };

    if (isOpen)
    {
      document.addEventListener("keydown", handleEscape);
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () =>
    {
      document.removeEventListener("keydown", handleEscape);
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, onClose, loading, deactivating]);

  if (!isOpen)
  {
    return null;
  }
  if (deactivating)
  {
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50 ">
        <div className="modal-container" ref={modalRef}>
          <div className="flex flex-col bg-white rounded-lg shadow-lg w-[446px] h-[522px] justify-center items-center p-[52px]">
            <CircularProgress color="inherit" />
            <div className="font-custom text-[24px] font-medium mt-[67px]">
              Deactivating your card...
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (loading)
  {
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50 ">
        <div className="modal-container" ref={modalRef}>
          <div className="flex flex-col bg-white rounded-lg shadow-lg w-[446px] h-[422px] justify-center items-center p-[52px]">
            <CircularProgress color="inherit" />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 ">
      <div className="modal-container" ref={modalRef}>
        <div className="flex flex-col bg-white rounded-lg shadow-lg w-[446px] min-h-[422px] justify-center items-start p-[52px]">
          <h2 className="font-custom text-button text-[39px]/[43px] mb-[22px]">
            Deactivate Card
          </h2>
          <div className="flex flex-row bg-deletePageBox text-deleteText font-custom text-[20px] p-[18px] mb-[17px] items-center">
            <WarningAmber className="mr-[20px]" />
            <p>
              Deactivating this account will remove the profile and email address entirely, and you will need to create a new account for the card.
            </p>
          </div>
          <div className="flex flex-row p-4 bg-white ">
            <button
              className=" font-custom font-medium text-white shadow-custom text-[20px] tracking-[0.05em] text-center bg-poppy rounded-[50px] w-[146px] h-[46px] mr-[13px]"
              onClick={async () =>
              {
                await handleDeactivate();
              }}
            >
              Continue
            </button>
            <button
              className=" font-custom font-medium text-white shadow-custom text-[20px] tracking-[0.05em] text-center bg-button rounded-[50px] w-[146px] h-[46px] "
              onClick={() =>
              {
                onClose();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeactivateModal;
