import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import { useLocation, useNavigate } from 'react-router-dom';
import { sendEmail } from '../Checkout';
import { Email } from '../../types';

export const ConfirmOrder = () =>
{
  const navigate = useNavigate();
  const location = useLocation();
  async function resendEmail()
  {
    if (location.state && location.state.email)
    {
      await sendEmail({ dest: location.state.dest, fullName: 'businesscardX', message: location.state.email, subject: location.state.subject, sender: '' } as Email)
    }
  }
  const time = 15
  const [num, setNum] = useState(time);
  const [pause, setPause] = useState(false);
  let intervalRef = useRef<NodeJS.Timer>();
  const decreaseNum = () => setNum((prev) => prev - 1);
  useEffect(() =>
  {
    intervalRef.current = setInterval(decreaseNum, 1000);
    return () => clearInterval(intervalRef.current);
  }, []);

  useEffect(() =>
  {

    if (pause)
    {
      clearInterval(intervalRef.current);
    }
  }, [pause])

  useEffect(() =>
  {

    if (num === 0)
    {
      setPause(true)
    }
  }, [num])

  const handleClick = () =>
  {
    if (num === 0)
    {
      setPause(false)
      setNum(time)
      intervalRef.current = setInterval(decreaseNum, 1000);
      resendEmail()
    }
  };






  return (
    <div className='overflow-hidden'>
      <Navbar />
      <div className='lg:mx-[80px] mx-[25px] mb-[100px] lg:mt-[110px] mt-[74px]'>
        <h1 className=' inline-block font-custom font-black lg:text-[64px]/[86px] text-[30px]/[41px] lg:mb-[34px] lg:w-auto w-[340px]'>
          Congrats! Your Order Has Been Placed
        </h1>
        <div className='bg-primary relative lg:w-[150%] w-[125%]  h-[37px] z-[-1]   lg:mt-[-70px] mt-[-18px] lg:mb-[37px] mb-[24px]' />
        <div>
          <h3 className=' inline-block font-custom font-light lg:text-[20px]/[32px] text-[14px]/[25px] lg:tracking-normal tracking-[0.05em] lg:mb-[28px] mb-[32px] w-[338px]'>
            Please check your email on order information. In the mean time, why don't you create your profile? <a className={((location.state && location.state.email) ? 'visible' : 'invisible') + ' ' + (pause ? 'opacity-100 cursor-pointer' : 'opacity-50 cursor-default') + ' text-blue-500 flex'} onClick={handleClick}> Didn't get the email? Send it again. <div className={pause ? 'hidden' : 'block'}>{num}</div></a>
          </h3>
          <div className='flex lg:justify-start justify-start items-center z-10'>
            <button className=' font-custom font-medium text-white shadow-custom lg:text-[19px]/[32px] text-[16px]/[25px] text-center tracking-[0.05em] hover:bg-inset bg-button rounded-[33px] lg:w-auto w-[343px] lg:px-[71px] px-0 lg:pt-[10px] pt-[12px] lg:pb-[12px] pb-[14px]' onClick={() => { navigate('/signup'); }}>Go to Sign Up</button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
