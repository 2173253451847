import React, { useEffect, useState } from 'react';
import { GoogleMap, GoogleMapProps, LoadScript, Marker } from '@react-google-maps/api';
import Geocode from "react-geocode";

interface MapContainerProps
{
  classname?: string
  w?: string
  h?: string
  location?: string
  onClick?: VoidFunction
}


const MapContainer = ({ ...props }: MapContainerProps) =>
{
  const [location, setLocation] = useState({ lat: 37.7749, lng: -122.4194 })

  const mapStyles: React.CSSProperties = {
    height: props.h ?? '160px',
    width: props.w ?? '160px',
    position: 'relative',
  };

  async function getLatLong()
  {
    let loc = {
      lat: 37.7749,
      lng: -122.4194
    };
    if (props.location)
    {
      await Geocode.fromAddress(props.location).then((response) =>
      {
        const { lat, lng } = response.results[0].geometry.location;
        loc = { lat: lat, lng: lng }
      })
    }
    return loc
  }



  return <div className={props.classname ?? ''}
    onMouseDown={props.onClick}
    onKeyDown={props.onClick}
  >
    <GoogleMap
      mapContainerStyle={mapStyles}
      zoom={13}
      center={location}
      onLoad={async () =>
      {
        const l = await getLatLong();
        setLocation(l);
      }}
    >
      <Marker position={location} />
    </GoogleMap>

  </div>
};

export default MapContainer;
