import { useState } from "react";
import { Language, lang } from "../languages"
import { ContactDoc, EliteCompany } from "../types"
import { formatPhoneNumberIntl } from "react-phone-number-input"
import { phoneTypel18n, stringToPhoneType } from "../utils/features"
import trash from "../assets/images/trash-can.svg"
import { cn, getTintedColor } from "../utils/utils";
interface ContactCardProps
{
    contact: ContactDoc
    language: Language
    customColor: boolean
    onDelete: (arg: any) => void
    onSave: () => Promise<void>
    elite?: EliteCompany

}
export const ContactCard = ({ ...props }: ContactCardProps) =>
{
    const [open, setOpen] = useState(false);

    async function handleSave()
    {
        await props.onSave()
    }

    return <>

        <div className="relative rounded-[8px] border-2 border-button px-[22px] pt-[18px] pb-[12px] text-button w-[360px] mb-[5px]">
            <button onClick={() => { props.onDelete({ firstName: props.contact.firstName, lastName: props.contact.lastName, ref: props.contact.ref }) }} className="absolute top-0 right-0 mt-[15px] mr-[15px]">
                <img alt="delete contact" src={trash} />
            </button>
            <div className="flex flex-col text-[20px] font-custom font-medium gap-[5px]">
                <div className="break-words pr-[5px] flex-row flex justify-start items-center gap-[5px]">
                    {lang.name[props.language]}:
                    <div className="text-[18px]">
                        {`${props.contact.firstName} ${props.contact.lastName}`}
                    </div>
                </div>
                <div className="mb-[5px] flex-row flex justify-start items-center gap-[5px]">
                    {lang.dateAdded[props.language]}:
                    <div className="text-[18px]">
                        {`${new Date(props.contact.date.toMillis()).toLocaleDateString()} - ${new Date(props.contact.date.toMillis()).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}`}
                    </div>
                </div>
                <div className={(open ? 'block' : 'hidden') + " px-[18px] py-[20px] mb-[10px] border-2 border-button, rounded-[6px]"}>
                    <div className="flex flex-col gap-[22px]">
                        {props.contact.phone && <a
                            href={"tel:" + props.contact.phone.number + (props.contact.phone.ext ? "," + props.contact.phone.ext : "")}
                            className={
                                cn("font-custom font-medium shadow-custom text-[17px]/[23px] text-center hover:bg-secondary bg-primary rounded-[26px] pt-[7px] pb-[7px]",
                                    { "block": props.contact.phone.number.length > 0 },
                                    { "hidden": props.contact.phone.number.length <= 0 })}
                            style={{
                                background: props.elite && props.elite.primaryColor,
                                color: (props.elite && props.elite.primaryWhite) ? '#ffffff' : '#000000'
                            }}
                        >
                            <span className={"text-[13px]/[32px] tracking-[0.05em] mr-[8px] "}>
                                {
                                    phoneTypel18n(stringToPhoneType(props.contact.phone.type), props.language)
                                }
                            </span>
                            {formatPhoneNumberIntl(props.contact.phone.number)}
                            {props.contact.phone.ext && (
                                <span className={"text-[13px]/[32px] tracking-[0.05em] ml-[8px] "}>
                                    ext. {props.contact.phone.ext}
                                </span>
                            )}
                        </a>}

                        {props.contact.email &&
                            <a href={"mailto:" + props.contact.email}
                                className={
                                    cn("truncate font-custom font-medium shadow-custom text-[17px]/[23px] text-center hover:bg-secondary bg-primary rounded-[26px] pt-[12px] pb-[11px] px-[20px]",
                                        { "block": props.contact.email.length > 0 },
                                        { "hidden": props.contact.email.length <= 0 })
                                }
                                style={{
                                    background: props.elite && props.elite.primaryColor,
                                    color: (props.elite && props.elite.primaryWhite) ? '#ffffff' : '#000000'
                                }}
                            >
                                {props.contact.email}
                            </a>
                        }

                        <div className="flex flex-col text-wrap break-words">
                            Notes
                            <div className="font-normal text-[16px]">
                                {props.contact.notes ?? 'No Notes...'}
                            </div>
                        </div>
                    </div>
                </div>

                <button onClick={() => { setOpen(!open) }}
                    className={
                        cn("self-center w-full font-custom text-[20px]/[32px] shadow-custom font-medium rounded-[23px] tracking-[0.05em] pt-[10px] pb-[10px] mb-[7px]",
                            { "hover:bg-secondary hover:text-button text-button bg-primary": !open && !props.elite },
                            { "bg-inset text-button hover:bg-slate-500 hover:text-white": open && !props.elite },
                        )}
                    style={{
                        background: props.elite ? (open ? getTintedColor(props.elite.primaryColor, -50) : props.elite.primaryColor) : '',

                        color: (props.elite && props.elite.primaryWhite) ? '#ffffff' : '#000000'
                    }}
                >
                    {!open ? lang.viewContactInfo[props.language] : lang.hideContactInfo[props.language]}
                </button>
                <button
                    onClick={() => { handleSave() }}
                    className={
                        cn("self-center shadow-custom w-full font-custom text-[20px]/[32px] font-medium rounded-[23px] tracking-[0.05em] pt-[10px] pb-[10px]",
                            { "hover:bg-inset hover:text-white bg-slate-500 text-button": props.contact.saved },
                            { "hover:bg-inset text-white bg-button": !props.contact.saved },
                        )}
                    style={{
                        background: props.elite && props.elite.secondaryColor,
                        color: props.elite ? (props.elite.secondaryWhite ? '#ffffff' : '#000000') : ''
                    }}
                >
                    {!props.contact.saved ? lang.saveContact[props.language] : lang.contactSaved[props.language]}
                </button>
            </div>
        </div >
    </>
}
