import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
import { CheckBox as ChkIcon } from "@mui/icons-material";
import { ChangeEvent } from "react";

interface CChecBoxProps
{
    label: string,
    classname?: string,
    checked?: boolean,
    textcolor?: string,
    fontsize?: string,
    fontheight?: string,
    fontweight?: string,
    onCheck?: CallableFunction
    id?: string
}
const Icon = styled(ChkIcon)`
color: #252529;
`;


const Label = styled(FormControlLabel, { shouldForwardProp: (prop) => prop !== 'props' })<{ props: CChecBoxProps }>
    (({ props }) => `
& .MuiTouchRipple-child{
    background-color: #252529;
}
& .MuiFormControlLabel-label{
    color: ${props.textcolor ?? '#252529'};
    font-family: 'Satoshi-Variable';
    font-size: ${props.fontsize ?? '20px'};
    line-height: ${props.fontheight ?? '32px'};
    font-weight: ${props.fontweight ?? '300px'};
    letter-spacing: 0.05em;
}
& .MuiSvgIcon-root
    {
    color: #000;
    }
`);

const CCheckBox = ({ ...props }: CChecBoxProps) =>
{
    const oncheck = (event: ChangeEvent<HTMLInputElement>) =>
    {
        if (props.onCheck)
        {
            props.onCheck(event.target.checked);
        }
    }
    return <div className={props.classname}>
        <Label props={props} control={<Checkbox id={props.id} onChange={oncheck} checked={props.checked} checkedIcon={<Icon />} />} label={props.label} />
    </div>
}
export default CCheckBox;
