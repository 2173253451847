import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import menu from "../../assets/images/hamburger.svg";
import bcx_icon from "../../assets/images/bcx-icon.svg";
import Crayon from "../../assets/images/Crayon.svg";
import { v4 as uuidv4 } from 'uuid';
import { AdminCard } from "../../components/AdminCard";
import Footer from "../../components/Footer";
import { ToggleCard } from "../../components/ToggleCard";
import ig from '../../assets/images/instagram-logo.svg'
import fb from '../../assets/images/facebook-logo.svg'
import wp from '../../assets/images/whatsapp-logo.svg'
import tk from '../../assets/images/tiktok.svg'
import tw from '../../assets/images/twitter-x-logo.svg'
import li from '../../assets/images/linkedin-logo.svg'
import sc from '../../assets/images/snapchat.svg'
import
{
  DocumentReference,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { auth1, db, useStorage } from "../../services/firebase";
import { Card, EliteCompany, Metric, User } from "../../types";
import { useNavigate } from "react-router-dom";
import ActivateCardModal from "../../components/ActivateCardModal";
import ReassignCardModal from "../../components/ReassignCardModal";
import styled from "@emotion/styled";
import { CircularProgress, Switch, Tooltip } from "@mui/material";
import ColorPickerModal from "../../components/ColorPickerModal";
import DeactivateModal from "../../components/DeactivateModal";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import axios from "axios";
import CTextField from "../../components/CTextField";
import { CTooltip } from "../../components/CTooltip";
import { LogoURLModal } from "../../components/LogoURLModal";

interface PropsType
{
  children: React.ReactNode;
}

const ListView = (props: PropsType) =>
{
  return (
    <div className="flex lg:flex-row flex-col flex-wrap">
      {
        React.Children.map(props.children, (child, index) =>
        {
          if (child)
          {
            return (
              <div key={index} className="flex flex-col ">
                {child}
              </div>
            );
          }
        }
        )
      }
    </div>
  );
};

const CardListView = (props: PropsType) =>
{
  return (
    <div>
      {React.Children.map(props.children, (child, index) =>
      {
        return <div key={`Inactive_${index}`}>{child}</div>;
      })}
    </div>
  );
};



const CustomSwitch = styled(Switch)`
    & .MuiSwitch-track {
      background-color: #ea4335;
      opacity: 1;
    }
    & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
      background-color: #34a853;
      opacity: 1;
    }

    & .MuiSwitch-thumb {
      background-color: #fff;
    }
  `;

export const AdminView = () =>
{
  const navigate = useNavigate();
  const [eliteId, setEliteId] = useState('');
  const [menuToggle, setMenuToggle] = useState(true);
  const [eliteUser, setEliteUser] = useState<JSX.Element[]>([]);
  const [inactiveCards, setInactiveCards] = useState(<></>);
  const [modalOpen, setModalOpen] = useState(false);
  const [reassignModalOpen, setReassignModalOpen] = useState(false);
  const [colorModalOpen, setColorModalOpen] = useState(false);
  const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
  const [logoURLModalOpen, setLogoURLModalOpen] = useState(false);
  const [orderNumberChosen, setOrderNumberChosen] = useState("none");
  const [userRefChosen, setUserRefChosen] = useState<DocumentReference>();
  const [userChosen, setUserChosen] = useState<User>();
  const [loading, setLoading] = useState(true);
  const [loadingLogo, setLoadingLogo] = useState(true);
  const [eliteDoc, setEliteDoc] = useState<EliteCompany>();
  const [useCustomColors, setUseCustomColors] = useState(false);
  const [primaryWhite, setPrimaryWhite] = useState(false);
  const [secondaryWhite, setSecondaryWhite] = useState(true);
  const [whiteArrows, setWhiteArrows] = useState(true);
  const [useLogoURL, setUseLogoURL] = useState(false);
  const [logoURL, setlogoURL] = useState('');

  const [refresh, setRefresh] = useState(true);
  const eliteRef = eliteId !== "" ? doc(db, "eliteCompanies", eliteId!) : "";
  const modalRef = useRef<HTMLDivElement>(null);
  const logoURLModalRef = useRef<HTMLDivElement>(null);
  const reassignModalRef = useRef<HTMLDivElement>(null);
  const colorModalRef = useRef<HTMLDivElement>(null);
  const deactivateModalRef = useRef<HTMLDivElement>(null);
  const [aggregate, setAggregate] = useState<Metric>({} as Metric);

  const getUser = async (): Promise<string> =>
  {
    let eliteRefId = '';
    let user = auth1.currentUser;
    if (user)
    {
      const authUserRef = doc(db, "users", user.uid);
      const userDoc = (await getDoc(authUserRef)).data();
      if (userDoc)
      {
        const userTyped = userDoc as User;
        if (userTyped.eliteAdmin)
        {
          setEliteId(userTyped.eliteCompany.id);
          eliteRefId = userTyped.eliteCompany.id;
        } else
        {
          setEliteId('NotAdmin');
        }
      }
    }

    return eliteRefId;
  };


  const getEliteUsers = async (
    eliteCompanyId: string,
    callbackReassign: Function,
    callbackDeactivate: Function
  ) =>
  {
    const updateAdmin = async (
      admin: boolean,
      userRef: DocumentReference,
      orderNumber: string,
      adminCards: string[],
      userDoc: User,
      eliteCompanyRef: DocumentReference,
      eliteCompanyDoc: EliteCompany,
      setAdmin: Function
    ) =>
    {
      if (admin === true)
      {
        if (adminAmount! > 1)
        {
          await updateDoc(userRef!, {
            ...userDoc,
            eliteAdmin: false,
          });
          await updateDoc(eliteCompanyRef!, {
            ...eliteCompanyDoc,
            adminCards: adminCards,
          });
          adminAmount! -= 1;
          setEliteDoc({
            ...eliteCompanyDoc,
            adminCards: adminCards,
          });
          setAdmin(false);
        } else
        {
          alert("You must have at least one admin user in the elite company");
        }
      } else
      {
        if (adminCards.indexOf(orderNumber) === -1)
        {
          adminCards!.push(orderNumber!);
        }

        const newAdminCards = adminCards;
        await updateDoc(userRef!, {
          ...userDoc,
          eliteAdmin: true,
        });
        await updateDoc(eliteCompanyRef!, {
          ...eliteCompanyDoc,
          adminCards: newAdminCards,
        });
        adminAmount! += 1;
        setAdmin(true);
      }
    };

    const users = [];
    const eliteCompanyRef = doc(db, "eliteCompanies", eliteCompanyId!);
    const eliteCompanyDoc = (await getDoc(eliteCompanyRef)).data();
    const eliteCompany = eliteCompanyDoc as EliteCompany;
    let metricAcum = { contactsSaved: 0, getACard: 0, leadsCreated: 0, phoneCalls: 0, visits: 0, websiteVisits: 0, addressLookups: 0, emailTaps: 0, mediaLookups: 0, fb: 0, ig: 0, li: 0, sc: 0, tk: 0, tw: 0, wp: 0 } as Metric
    let adminAmount = eliteCompany.adminCards.length;
    const metricPromises = [];
    const queryPromises = [];

    for (let i = 0; i < eliteCompany.activeCards.length; i++)
    {
      metricPromises.push(getMetrics(eliteCompany.activeCards[i]));
      const q = query(
        collection(db, "users"),
        where("orderNumber", "==", eliteCompany.activeCards[i])
      );
      queryPromises.push(getDocs(q));
    }
    const metricResults = await Promise.all(metricPromises);
    const queryResults = await Promise.all(queryPromises);

    for (let i = 0; i < eliteCompany.activeCards.length; i++)
    {
      metricAcum = addMetrics(metricResults[i], metricAcum)
      const querySnapshot = queryResults[i];
      const data = querySnapshot.docs[0]?.data() as User;
      const userRef = querySnapshot.docs[0]?.ref;
      const cardIndex =
        eliteCompany.cards.indexOf(eliteCompany.activeCards[i]) + 1;

      if (data)
      {
        const userHtml = (
          <AdminCard
            key={eliteCompany.activeCards[i]} // Adding a key to avoid react warnings
            userRef={userRef}
            deactivateUser={() =>
            {
              callbackDeactivate(userRef, data, eliteCompany.activeCards[i]);
            }}
            eliteCompanyRef={eliteCompanyRef}
            userDoc={data}
            eliteCompanyDoc={eliteCompanyDoc as EliteCompany}
            cardnumber={cardIndex}
            callback={updateAdmin}
            callbackReassign={() =>
            {
              callbackReassign(eliteCompany.activeCards[i]);
            }}
          />
        );
        users.push(userHtml);
      }
    }
    setAggregate(metricAcum)
    setEliteUser(users.sort((a, b) => { return a.props.cardnumber - b.props.cardnumber }))
  };

  const deactivateUser = async (
    userRef: DocumentReference,
    userDoc: User,
    orderNumber: string,
    adminCards: string[]
  ) =>
  {
    if (adminCards.indexOf(orderNumber) === -1)
    {
      if (eliteDoc !== undefined && eliteDoc.activeCards.length > 1)
      {
        try
        {
          const url = process.env.REACT_APP_DELETE_USER_URL;
          const response = await axios.post(url!, { userId: userRef.id });
        } catch (error)
        {
          console.error("Error deleting user:", error);
        }

        await deleteDoc(userRef!);

        const activeCards = eliteDoc.activeCards;
        if (eliteRef !== "")
        {
          await updateDoc(eliteRef!, {
            ...eliteDoc,
            activeCards: activeCards,
          });
        }
        setRefresh(true)
      } else
      {
        alert("You should have at least one member in this company");
      }
    } else
    {
      alert("Remove it from admins before deactivating the card");
    }
  };

  async function getMetrics(orderNumber: string)
  {
    const q = query(collection(db, 'metrics'), where('orderNumber', '==', orderNumber))
    const querySnapshot = await getDocs(q)
    if (querySnapshot.docs.length > 0)
    {
      const metric = querySnapshot.docs[0].data() as Metric
      return metric
    } else
    {
      return {} as Metric
    }
  }

  function addMetrics(metric: Metric, aggregate: Metric)
  {
    return {
      contactsSaved: aggregate.contactsSaved! + (metric.contactsSaved ?? 0),
      leadsCreated: aggregate.leadsCreated! + (metric.leadsCreated ?? 0),
      phoneCalls: aggregate.phoneCalls! + (metric.phoneCalls ?? 0),
      visits: aggregate.visits! + (metric.visits ?? 0),
      websiteVisits: aggregate.websiteVisits! + (metric.websiteVisits ?? 0),
      emailTaps: aggregate.emailTaps! + (metric.emailTaps ?? 0),
      addressLookups: aggregate.addressLookups! + (metric.addressLookups ?? 0),
      mediaLookups: aggregate.mediaLookups! + (metric.mediaLookups ?? 0),
      getACard: aggregate.getACard! + (metric.getACard ?? 0),
      fb: aggregate.fb! + (metric.fb ?? 0),
      ig: aggregate.ig! + (metric.ig ?? 0),
      tw: aggregate.tw! + (metric.tw ?? 0),
      tk: aggregate.tk! + (metric.tk ?? 0),
      sc: aggregate.sc! + (metric.sc ?? 0),
      wp: aggregate.wp! + (metric.wp ?? 0),
      li: aggregate.li! + (metric.li ?? 0),
    } as Metric
  }

  const openModal = (orderNumber: string) =>
  {
    setOrderNumberChosen(orderNumber);
    setModalOpen(true);
  };

  const closeModal = () =>
  {
    setModalOpen(false);
  };

  const reassignOpenModal = (orderNumber: string) =>
  {
    setOrderNumberChosen(orderNumber);
    setReassignModalOpen(true);
  };

  const reassignCloseModal = () =>
  {
    setReassignModalOpen(false);
  };

  const confirmCloseModal = () =>
  {
    setModalOpen(false);
    setReassignModalOpen(false);
    setColorModalOpen(false);
    setDeactivateModalOpen(false);
    setLogoURLModalOpen(false);
    setRefresh(true);
  };
  const closeColorModal = () =>
  {
    setColorModalOpen(false);
  };

  const openDeactivateModal = (
    userRef: DocumentReference,
    userDoc: User,
    orderNumber: string
  ) =>
  {
    setOrderNumberChosen(orderNumber);
    setUserRefChosen(userRef);
    setUserChosen(userDoc);
    setDeactivateModalOpen(true);
  };

  const closeDeactivateModal = () =>
  {
    setDeactivateModalOpen(false);
  };

  const getInactiveCards = async (eliteCompanyId: string) =>
  {
    const inactiveCards = [];
    const eliteCompanyRef = doc(db, "eliteCompanies", eliteCompanyId!);
    const eliteCompanyDoc = (await getDoc(eliteCompanyRef)).data();
    const eliteCompany = eliteCompanyDoc as EliteCompany;

    setEliteDoc(eliteCompany);
    setLoadingLogo(false);
    setUseCustomColors(eliteCompany.useCustomColors);
    setPrimaryWhite(eliteCompany.primaryWhite);
    setSecondaryWhite(eliteCompany.secondaryWhite);
    setWhiteArrows(eliteCompany.whiteArrows);
    setUseLogoURL(eliteCompany.useLogoURL ?? false);
    setlogoURL(eliteCompany.logoURL ?? '')

    let filteredCards = eliteCompany.cards.filter(
      (card) => !eliteCompany.activeCards.includes(card)
    );

    for (let j = 0; j < filteredCards.length; j++)
    {
      const cardQuery = query(
        collection(db, "cards"),
        where("orderNumber", "==", filteredCards[j])
      );
      const querySnapshot = await getDocs(cardQuery);
      let data = querySnapshot.docs[0]?.data() as Card;
      let cardName = data.personalized ? data.name : "";
      let cardIndex = eliteCompany.cards.indexOf(filteredCards[j]) + 1;
      const cardHtml = (
        <div className="mb-5">
          <ToggleCard
            key={`Toggle_${filteredCards[j]}`}
            orderNumber={filteredCards[j]}
            cardnumber={cardIndex}
            cardName={cardName}
            eliteCompanyDoc={eliteCompany}
            callback={() =>
            {
              openModal(filteredCards[j]);
            }}
          />
        </div>
      );
      inactiveCards.push(cardHtml);
    }

    return <CardListView key={uuidv4()}>{inactiveCards}</CardListView>;
  };

  useEffect(() =>
  {
    if (refresh)
    {
      const getData = async () =>
      {
        await getUser().then(async (eliteRefId) =>
        {
          if (eliteRefId !== "")
          {
            await getEliteUsers(
              eliteRefId,
              reassignOpenModal,
              openDeactivateModal
            )
            await getInactiveCards(eliteRefId).then((res) =>
            {
              setInactiveCards(res);
            });
          }
        }).then(() =>
        {
          setLoading(false);
        });
      };
      getData()
      setRefresh(false)
    }
  }, [refresh]);

  const storage = useStorage();
  async function uploadLogo(event: ChangeEvent<HTMLInputElement>)
  {
    const file = event.target.files?.[0];
    let upUrl = "";
    if (
      file &&
      (file.type.toLocaleLowerCase() === "image/png" ||
        file.type.toLocaleLowerCase() === "image/jpeg" ||
        file.type.toLocaleLowerCase() === "image/jpg" ||
        file.type.toLocaleLowerCase() === "image/svg+xml")
    )
    {

      const storageRef = ref(storage, `/files/${uuidv4()}.${file.name.split('.').pop()}`);

      await uploadBytes(storageRef, file, { contentType: file.type }).then(
        async (snapshot) =>
        {
          await getDownloadURL(snapshot.ref).then((url) =>
          {
            upUrl = url;
          });
        }
      );

      event.target.value = "";
    }
    return upUrl;
  }

  if (eliteId === "")
  {
    return (
      <div className="lg:mx-[80px] mx-[25px] mb-[100px] lg:mt-[110px] mt-[74px] overflow-hidden">
        <CircularProgress
          color="inherit"
          className="absolute lg:left-1/2 left-1/3 lg:top-[55px] top-[25px] transform -translate-x-1/2 -translate-y-1/2 lg:h-[91px] h-[50px]"
        />
      </div>
    );
  }
  if (eliteId === "NotAdmin")
  {
    return (
      <div className="lg:mx-[80px] mx-[25px] mb-[100px] lg:mt-[110px] mt-[74px] overflow-hidden">
        <h1 className=" inline-block font-custom font-black lg:text-[64px]/[86px] text-[30px]/[41px] lg:mb-[34px] lg:w-auto w-[340px]">
          You are not an Elite Admin
        </h1>
        <div className="bg-primary relative lg:w-[150%] w-[125%]  h-[37px] z-[-1]   lg:mt-[-70px] mt-[-18px] lg:mb-[37px] mb-[24px]" />
        <div>
          <div className="flex lg:justify-start justify-start items-center z-10">
            <button
              className=" font-custom font-medium text-white shadow-custom lg:text-[19px]/[32px] text-[16px]/[25px] text-center tracking-[0.05em] bg-button rounded-[33px] lg:w-auto w-[343px] lg:px-[71px] px-0 lg:pt-[10px] pt-[12px] lg:pb-[12px] pb-[14px]"
              onClick={() =>
              {
                navigate("/");
              }}
            >
              Go to Home Page
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="relative">
      <div className=" lg:mb-[60px] mb-[16px] lg:px-[80px] px-[13px] h-[93px] pt-[15px] ">
        <div className="flex flex-row justify-between h-full bg-text rounded-xl overflow-hidden">
          <img
            className=" lg:ml-[30px] ml-[30px] w-[72px] cursor-pointer"
            src={bcx_icon}
            onClick={() =>
            {
              navigate("/");
            }}
          />
          {modalOpen && (
            <div className="fixed inset-0 z-10 flex items-center justify-center">
              <div
                ref={modalRef}
                className="fixed inset-0 bg-black opacity-75"
                style={{ pointerEvents: "none" }}
              ></div>
              <div className="modal-container">
                <ActivateCardModal
                  isOpen={modalOpen}
                  onClose={closeModal}
                  onConfirm={confirmCloseModal}
                  eliteCompanyRef={eliteRef as DocumentReference}
                  orderNumber={orderNumberChosen}
                >
                  <h2 className="font-custom text-button text-[39px]/[43px] mb-[22px]">
                    Set Up Account
                  </h2>
                  <p className="font-custom text-button text-[18px]">
                    Who Is This Account Going To Be For?
                  </p>

                </ActivateCardModal>
              </div>
            </div>
          )}
          {reassignModalOpen && (
            <div className="fixed inset-0 z-10 flex items-center justify-center">
              <div
                ref={reassignModalRef}
                className="fixed inset-0 bg-black opacity-75"
                style={{ pointerEvents: "none" }}
              ></div>
              <div className="modal-container">
                <ReassignCardModal
                  isOpen={reassignModalOpen}
                  onClose={reassignCloseModal}
                  onConfirm={confirmCloseModal}
                  eliteCompanyRef={eliteRef as DocumentReference}
                  orderNumber={orderNumberChosen}
                >
                  <h2 className="font-custom text-button text-[39px]/[43px] mb-[22px]">
                    Reassign Card
                  </h2>
                  <p className="font-custom text-button text-[18px] font-light mb-[16px]">
                    What Card Do You Want To Reassign
                  </p>
                </ReassignCardModal>
              </div>
            </div>
          )}
          {colorModalOpen && (
            <div className="fixed inset-0 z-10 flex items-center justify-center">
              <div
                ref={colorModalRef}
                className="fixed inset-0 bg-black opacity-75"
                style={{ pointerEvents: "none" }}
              ></div>
              <div className="modal-container">
                <ColorPickerModal
                  isOpen={colorModalOpen}
                  onClose={closeColorModal}
                  onConfirm={async (a, b) =>
                  {

                    await updateDoc(eliteRef as DocumentReference, {
                      primaryColor: a,
                      secondaryColor: b,
                      useCustomColors: true,
                    })
                    confirmCloseModal()
                  }}
                  eliteCompanyRef={eliteRef as DocumentReference}
                  primaryColor={eliteDoc?.primaryColor || "#fbbc05"}
                  secondaryColor={eliteDoc?.secondaryColor || "#252529"}
                >
                  <h2 className="font-custom text-button text-[39px]/[43px] mb-[22px]">
                    Choose your app's colors
                  </h2>
                  <p className="font-custom text-button text-[18px] font-light mb-[16px]">
                    What do you want to be your primary and secondary colors?
                  </p>
                </ColorPickerModal>
              </div>
            </div>
          )}
          {deactivateModalOpen && (
            <div className="fixed inset-0 z-10 flex items-center justify-center">
              <div
                ref={deactivateModalRef}
                className="fixed inset-0 bg-black opacity-75"
                style={{ pointerEvents: "none" }}
              ></div>
              <div className="modal-container">
                <DeactivateModal
                  isOpen={deactivateModalOpen}
                  onClose={closeDeactivateModal}
                  onConfirm={confirmCloseModal}
                  eliteCompanyRef={eliteRef as DocumentReference}
                  callback={async () =>
                  {
                    await deactivateUser(
                      userRefChosen!,
                      userChosen!,
                      orderNumberChosen,
                      eliteDoc?.adminCards!
                    );
                  }}
                >
                  <h2 className="font-custom text-button text-[39px]/[43px] mb-[22px]">
                    Choose your app's colors
                  </h2>
                  <p className="font-custom text-button text-[18px] font-light mb-[16px]">
                    What do you want to be your primary and secondary colors?
                  </p>
                </DeactivateModal>
              </div>
            </div>
          )}
          {logoURLModalOpen &&
            (
              <div className="fixed inset-0 z-10 flex items-center justify-center">
                <div
                  ref={logoURLModalRef}
                  className="fixed inset-0 bg-black opacity-75"
                  style={{ pointerEvents: "none" }}
                ></div>
                <div className="modal-container">
                  <LogoURLModal
                    isOpen={logoURLModalOpen}
                    value={eliteDoc?.logoURL ?? ''}
                    onClose={() => { setLogoURLModalOpen(false) }}
                    onSubmit={async (value) =>
                    {
                      setlogoURL(value);
                      if (eliteRef !== "")
                      {
                        await updateDoc(eliteRef, {
                          logoURL: value,
                        });
                      }
                      confirmCloseModal()
                    }}
                  />
                </div>
              </div>
            )
          }
          <button
            className=" self-center  lg:mr-[35px] mr-[29px] "
            onClick={() =>
            {
              setMenuToggle(!menuToggle);
            }}
          >
            <img src={menu} />
          </button>
        </div>
      </div>

      <div className="flex flex-col lg:px-[80px] px-[13px]">
        <div className="flex lg:flex-row flex-col lg:gap-0 gap-[36px]  lg:mb-[60px] mb-[22px] ">
          <div className="flex flex-row justify-between mr-[20px] shadow-custom rounded-[8px] overflow-clip">
            <div className="relative bg-white w-[410px] h-[91px] px-[10px] ">
              {" "}
              {loadingLogo && (
                <CircularProgress
                  color="inherit"
                  className="absolute lg:left-1/2 left-1/3 lg:top-[55px] top-[25px] transform -translate-x-1/2 -translate-y-1/2 lg:h-[91px] h-[50px]"
                />
              )}
              {!loadingLogo && (
                <img
                  className="absolute left-1/2 lg:top-[85px] top-[45px] transform -translate-x-1/2 -translate-y-1/2 lg:h-[91px] h-[50px]"
                  src={eliteDoc?.logo[0]}
                />
              )}
              <label className="cursor-pointer " htmlFor={"logoInput_"}>
                <img
                  className="mt-[7px] ml-auto h-[36px] w-[36px] cursor-pointer"
                  src={Crayon}
                />
                <input
                  disabled={loadingLogo}
                  id={"logoInput_"}
                  key={"logoInput_"}
                  type="file"
                  accept="image/png, image/jpeg, image/jpg, .svg"
                  className="hidden"
                  onChange={async (a) =>
                  {
                    setLoadingLogo(true);
                    await uploadLogo(a).then(async (a) =>
                    {
                      if (eliteRef !== "")
                      {
                        await updateDoc(eliteRef, {
                          logo: [a],
                        });
                        setLoadingLogo(false);
                        setRefresh(true)
                      }
                    });
                  }}
                />
              </label>
            </div>
            <div
              className="flex flex-col justify-center gap-[3px] px-[38px] bg-button items-center"
              style={{
                backgroundColor: useCustomColors
                  ? eliteDoc?.secondaryColor
                  : "#252529",
              }}
            >
              <div
                className={
                  "font-custom font-black lg:text-[40px]/[27px] text-[26px]/[22px] tracking-[0.05em] " +
                  (eliteDoc?.secondaryWhite || !eliteDoc?.useCustomColors
                    ? "text-white"
                    : "text-black")
                }
              >
                ELITE
              </div>
              <div
                className={
                  "font-custom font-light lg:text-[30px]/[27px] text-[19px]/[22px] tracking-[0.05em] " +
                  (eliteDoc?.secondaryWhite || !eliteDoc?.useCustomColors
                    ? "text-white"
                    : "text-black")
                }
              >
                PORTAL
              </div>
            </div>
          </div>
          <div className="flex flex-col shadow-custom rounded-[11px] overflow-clip font-custom text-[20px] md:w-[408px] w-[393px]">
            <div className="bg-inset text-center py-[5px]">
              Company Style
            </div>

            <div className="flex flex-col mx-[26px] mb-[15px] lg:items-between">
              <div className="flex flex-row text-[14px]/[16px] items-center lg:justify-between my-[10px]">
                <div className="flex items-center">
                  <p>Custom Colors</p>
                  <div>
                    <CustomSwitch
                      size="small"
                      checked={useCustomColors}
                      onChange={async () =>
                      {
                        setUseCustomColors(!useCustomColors);
                        setRefresh(true)
                        if (eliteRef !== "")
                        {
                          await updateDoc(eliteRef, {
                            useCustomColors: !useCustomColors,
                          });
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-row items-center">
                  <CTooltip title={eliteDoc?.useLogoURL ? (eliteDoc?.logoURL ?? 'https://businesscardx.com') : 'https://businesscardx.com'} placement="bottom">
                    <div className="flex items-center">
                      <p>Custom Logo URL</p>
                      <div>
                        <CustomSwitch
                          size="small"
                          checked={useLogoURL}
                          onChange={async () =>
                          {
                            setUseLogoURL(!useLogoURL);
                            setRefresh(true)
                            if (eliteRef !== "")
                            {
                              await updateDoc(eliteRef, {
                                useLogoURL: !useLogoURL,
                              });
                            }
                          }}
                        />
                      </div>

                    </div>
                  </CTooltip>
                  <div className={"cursor-pointer text-[14px]/[16px] hover:bg-secondary bg-primary rounded py-[4px] px-[4px] " + ((eliteDoc?.primaryWhite && eliteDoc?.useCustomColors) ? "text-white" : "text-black hover:text-white")}
                    style={{
                      backgroundColor: eliteDoc?.useCustomColors
                        ? eliteDoc?.primaryColor
                        : "",
                    }}
                    onClick={async () =>
                    {
                      setLogoURLModalOpen(true);
                    }}
                  >
                    Change
                  </div>

                </div>
              </div>

              <div className="flex flex-row items-start justify-between">
                <div className="flex basis-10/12 flex-col gap-[5px]">

                  <div className="flex flex-row items-center justify-between">
                    <div className="flex flex-row justify-between">
                      <p className="text-[18px]/[20px] w-[100px]">Primary</p>
                      <button
                        onClick={() =>
                        {
                          setColorModalOpen(true);
                        }}
                        className="h-[22px] w-[22px] bg-primary rounded cursor:pointer"
                        style={{
                          backgroundColor: useCustomColors
                            ? eliteDoc?.primaryColor
                            : "#fbbc05",
                        }}
                      ></button>
                    </div>

                    <p className="text-[14px]/[16px] whitespace-nowrap">White Text</p>
                    <CustomSwitch
                      size="small"
                      checked={primaryWhite}
                      onChange={async () =>
                      {
                        setPrimaryWhite(!primaryWhite);
                        setRefresh(true)
                        if (eliteRef !== "")
                        {
                          await updateDoc(eliteRef, {
                            primaryWhite: !primaryWhite,
                          })
                        }
                      }}
                    />
                  </div>

                  <div className="flex flex-row items-center justify-between">
                    <div className="flex flex-row justify-between">
                      <p className="text-[18px]/[20px] w-[100px]">Secondary</p>
                      <button
                        onClick={() =>
                        {
                          setColorModalOpen(true);
                        }}
                        className="h-[22px] w-[22px] bg-primary rounded cursor:pointer"
                        style={{
                          backgroundColor: useCustomColors
                            ? eliteDoc?.secondaryColor
                            : "#252529",
                        }}
                      ></button>
                    </div>
                    <p className="text-[14px]/[16px] whitespace-nowrap">White Text</p>
                    <CustomSwitch
                      size="small"
                      checked={secondaryWhite}
                      onChange={async () =>
                      {
                        setSecondaryWhite(!secondaryWhite);
                        setRefresh(true)
                        if (eliteRef !== "")
                        {
                          await updateDoc(eliteRef, {
                            secondaryWhite: !secondaryWhite,
                          })
                        }
                      }}
                    />
                  </div>

                </div>

                <div className="flex basis-1/12 flex-col -mt-[3px]">
                  <p className="text-[14px]/[16px]">White Arrows</p>
                  <CustomSwitch
                    size="small"
                    checked={whiteArrows}
                    onChange={async () =>
                    {
                      setWhiteArrows(!whiteArrows);
                      setRefresh(true)
                      if (eliteRef !== "")
                      {
                        await updateDoc(eliteRef, {
                          whiteArrows: !whiteArrows,
                        })
                      }
                    }}
                  />
                </div>

              </div>

            </div>

          </div>
        </div>

        {loading && (
          <div className="flex justify-center align-center">
            <CircularProgress color="inherit" />
          </div>
        )}

        {!loading && (
          <>
            <div className="mb-[45px] lg:block hidden">
              <h2 className="font-custom text-[39px]/[53px] font-medium text-button">
                Team Analytics
              </h2>
            </div>
            <div className="flex md:flex-row flex-col mb-[25px] gap-[30px]">
              <div className="flex md:flex-row flex-col md:gap-[35px] gap-[16px] justify-between text-[16px]/[18px] md:w-[40%] w-[393px] border rounded-xl shadow-custom bg-white px-[18px] py-[36px]">
                <div className="flex flex-col gap-[16px] md:w-1/2">
                  <div className="flex flex-row justify-between items-center ">
                    <div>Number Of Profile Visits:</div>
                    <div className="font-bold text-[20px]/[22px]">{aggregate.visits}</div>
                  </div>
                  <div className="bg-slate-300 h-[1px] w-[13%] -mt-[8px] -mb-[8px]" />
                  <div className="flex flex-row justify-between items-center">
                    <div>Number Of Contacts Saved:</div>
                    <div className="font-bold text-[20px]/[22px]">{aggregate.contactsSaved}</div>
                  </div>
                  <div className="bg-slate-300 h-[1px] w-[13%] -mt-[8px] -mb-[8px]" />
                  <div className="flex flex-row justify-between items-center">
                    <div>Leads Created:</div>
                    <div className="font-bold text-[20px]/[22px]">{aggregate.leadsCreated}</div>
                  </div>
                  <div className="bg-slate-300 h-[1px] w-[13%] -mt-[8px] -mb-[8px]" />
                  <div className="flex flex-row justify-between items-center">
                    <div>Email Taps:</div>
                    <div className="font-bold text-[20px]/[22px]">{aggregate.emailTaps}</div>
                  </div>
                </div>
                <div className="bg-slate-300 h-[1px] w-[13%] -mt-[8px] -mb-[8px] block md:hidden" />
                <div className="flex flex-col gap-[16px] md:w-1/2">
                  <div className="flex flex-row justify-between items-center">
                    <div>Phone Calls:</div>
                    <div className="font-bold text-[20px]/[22px]">{aggregate.phoneCalls}</div>
                  </div>
                  <div className="bg-slate-300 h-[1px] w-[13%] -mt-[8px] -mb-[8px]" />
                  <div className="flex flex-row justify-between items-center">
                    <div>Website Visits:</div>
                    <div className="font-bold text-[20px]/[22px]">{aggregate.websiteVisits}</div>
                  </div>

                  <div className="bg-slate-300 h-[1px] w-[13%] -mt-[8px] -mb-[8px]" />
                  <div className="flex flex-row justify-between items-center">
                    <div>Address Lookups:</div>
                    <div className="font-bold text-[20px]/[22px]">{aggregate.addressLookups}</div>
                  </div>
                  <div className="bg-slate-300 h-[1px] w-[13%] -mt-[8px] -mb-[8px]" />
                  <div className="flex flex-row justify-between items-center">
                    <div>Media Showcase Lookups:</div>
                    <div className="font-bold text-[20px]/[22px]">{aggregate.mediaLookups}</div>
                  </div>
                </div>

              </div>

              <div className="flex flex-col justify-center w-[393px] border rounded-xl shadow-custom bg-white py-[23px] px-[18px]">
                <div className="mb-[25px] text-[18px]/[20px]">Social Taps:</div>
                <div className="flex flex-row justify-between">
                  <div className="flex flex-col items-center gap-[12px]">
                    <img className="w-[45px] h-[45px]" src={ig} alt="Instagram Logo"></img>
                    <div className="font-medium text-[20px]/[22px]">{aggregate.ig ?? 0}</div>
                  </div>
                  <div className="flex flex-col items-center gap-[12px]">
                    <img className="w-[45px] h-[45px]" src={fb} alt="Facebook Logo"></img>
                    <div className="font-medium text-[20px]/[22px]">{aggregate.fb ?? 0}</div>
                  </div>
                  <div className="flex flex-col items-center gap-[12px]">
                    <img className="w-[45px] h-[45px]" src={tw} alt="X Logo"></img>
                    <div className="font-medium text-[20px]/[22px]">{aggregate.tw ?? 0}</div>
                  </div>
                  <div className="flex flex-col items-center gap-[12px]">
                    <img className="w-[45px] h-[45px]" src={li} alt="LinkedIn Logo"></img>
                    <div className="font-medium text-[20px]/[22px]">{aggregate.li ?? 0}</div>
                  </div>
                  <div className="flex flex-col items-center gap-[12px]">
                    <img className="w-[45px] h-[45px]" src={tk} alt="TikTok Logo"></img>
                    <div className="font-medium text-[20px]/[22px]">{aggregate.tk ?? 0}</div>
                  </div>
                  <div className="flex flex-col items-center gap-[12px]">
                    <img className="w-[45px] h-[45px]" src={sc} alt="Snapchat Logo"></img>
                    <div className="font-medium text-[20px]/[22px]">{aggregate.sc ?? 0}</div>
                  </div>
                  <div className="flex flex-col items-center gap-[12px]">
                    <img className="w-[45px] h-[45px]" src={wp} alt="WhatsApp Logo"></img>
                    <div className="font-medium text-[20px]/[22px]">{aggregate.wp ?? 0}</div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
        }

        {!loading && (
          <>
            <div className="mb-[45px] lg:block hidden">
              <h2 className="font-custom text-[39px]/[53px] font-medium text-button">
                Active Team List
              </h2>
            </div>
            <div className="flex flex-col">
              <div className="flex flex-col lg:flex-row lg:flex-wrap mx-[-10px] lg:mb-[45px] mb-[70px]">
                <ListView>{eliteUser}</ListView>
              </div>

            </div>

            <div className="flex lg:flex-row flex-col lg:justify-between mb-[77px] lg:items-end">
              <div className="lg:basis-1/4 flex flex-col gap-[11px] justify-start lg:mb-0 mb-[91px]">
                <div className="border-b-[2px] lg:border-secondary border-gray-400 lg:mb-[3px] mb-[8px]">
                  <h3 className="font-custom font-black lg:text-button text-gray-400 text-[18px]/[25px] text-center tracking-[0.05em] mb-[5px]">
                    Deactivated Cards
                  </h3>
                </div>
                {inactiveCards}
              </div>
              <div className="basis-1/4 flex flex-col lg:self-start lg:justify-start ">
                <div className="border-b-[2px] lg:border-secondary border-gray-400 mb-[16px]">
                  <h3 className={"lg:flex hidden justify-center font-custom font-black text-button  text-[18px]/[25px] text-center tracking-[0.05em]"}>
                    Need More?
                  </h3>
                </div>
                <button
                  onClick={() =>
                  {
                    navigate("/new-member");
                  }}
                  className={" font-custom font-medium text-black shadow-custom text-[15px]/[25px] tracking-[0.05em] text-center bg-primary rounded-[50px]  lg:pt-[10px] pt-[12px] lg:pb-[10px] pb-[10px] " +
                    (eliteDoc?.primaryWhite && eliteDoc?.useCustomColors
                      ? "text-white"
                      : "text-black")}
                  style={{
                    backgroundColor: useCustomColors
                      ? eliteDoc?.primaryColor
                      : "#fbbc05",
                  }}
                >
                  + Add More Members
                </button>
              </div>
            </div>
          </>
        )}

      </div>

      <div
        className={
          (menuToggle ? "hidden" : " animated-slide-left ") +
          " z-50 opacity-100 absolute right-0 top-0 lg:w-1/2 w-3/4 h-full bg-button"
        }
      >
        <div className="fixed lg:w-1/2 w-3/4">
          <div className=" flex flex-col lg:mt-[52px] mt-[47px] lg:ml-[80px] ml-[25px] lg:mr-[80px] mr-[25px]">
            <div className="flex flex-row justify-between mb-[61px]">
              <h2 className="font-custom font-medium text-white text-[30px]/[41px]">
                Settings
              </h2>
              <button
                className=""
                onClick={() =>
                {
                  setMenuToggle(!menuToggle);
                }}
              >
                <img src={menu} />
              </button>
            </div>
            <div className="flex flex-col gap-[20px] items-end">
              <button
                className=" font-custom text-white font-light lg:text-[20px]/[32px] text-[14px]/[25px] tracking-[0.05em]"
                onClick={() =>
                {
                  navigate("/profile");
                }}
              >
                Profile
              </button>
              <button
                onClick={() => navigate("/profile-change-password")}
                className=" font-custom text-white font-light lg:text-[20px]/[32px] text-[14px]/[25px] tracking-[0.05em]"
              >
                Change Password
              </button>

              <button
                onClick={() => navigate("/about")}
                className=" font-custom text-white font-light lg:text-[20px]/[32px] text-[14px]/[25px] tracking-[0.05em]"
              >
                About
              </button>
              <button
                onClick={() => navigate("/contact")}
                className=" font-custom text-white font-light lg:text-[20px]/[32px] text-[14px]/[25px] tracking-[0.05em]"
              >
                Contact
              </button>
              <button
                onClick={() => navigate("/shop")}
                className=" font-custom text-white font-light lg:text-[20px]/[32px] text-[14px]/[25px] tracking-[0.05em] mb-[102px]"
              >
                Shop
              </button>
              <button
                className=" font-custom text-white font-light lg:text-[20px]/[32px] text-[14px]/[25px] tracking-[0.05em]"
                onClick={async () =>
                {
                  await auth1.signOut().then(() =>
                  {
                    navigate("/login");
                  });
                }}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="lg:block hidden">
        <Footer />
      </div>
    </div>
  );
};
