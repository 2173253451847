import { combineReducers } from 'redux';
import CartReducer from "./feature/cartSlice";
import CurrencyReducer from "./feature/currencySlice";
import LanguageReducer from './feature/languageSlice';


const rootReducer = combineReducers({
    cart: CartReducer,
    currency: CurrencyReducer,
    language: LanguageReducer,
});

export default rootReducer;
