import React from 'react'
import Navbar from '../../components/Navbar'
import { FacebookLogin, GoogleSignIn, Apple } from '../../auth/auth'
import square_left from "../../assets/images/signupsucc-left-squares.svg";
import square_right from "../../assets/images/signupsucc-right-squares.svg";
import CTextField from '../../components/CTextField'
import { Navigate, useNavigate } from 'react-router-dom';

export const SignUpSuccessfully = () =>
{
  const navigate = useNavigate();
  return (
    <div className="">
      <Navbar classname="lg:hidden block mb-[167px]" hidecurr alternate />
      <div className="lg:relative flex lg:flex-row flex-col justify-between">
        <div className="lg:block hidden h-full justify-start">
          <img className=" max-h-[95%] h-auto" src={square_left} />
        </div>
        <div className="flex flex-col lg:absolute lg:left-[50%] lg:translate-x-[-50%]  pb-[66px]">
          <Navbar classname="lg:block hidden self-start mb-[245px] " alternate />
          <div className="self-center flex flex-col justify-center ">
            <h2 className=" font-custom font-medium self-center text-center lg:text-[57px]/[77px] text-[30px]/[41px] lg:mb-[11px] mb-[18px] ">
              Sign Up Was Successful!
            </h2>
            <h4 className=" font-custom font-light self-center text-center lg:text-[20px]/[32px] text-[14px]/[25px] tracking-[0.05em] lg:mb-[49px] mb-[53px] ">
              Let's Get Networking!
            </h4>
            <button className='w-[333px] self-center font-custom font-medium text-button shadow-custom text-[20px]/[27px] text-center tracking-[0.05em] bg-primary hover:bg-secondary rounded-[26px] lg:mb-0 mb-[62px] lg:pt-[10px] pt-[12px] lg:pb-[14px] pb-[12px]' onClick={() => { navigate('/onboarding'); }}>
              Let's Go!
            </button>
          </div>
        </div>
        <div className=" lg:block hidden h-full justify-end">
          <img className=" max-h-[100%] h-auto" src={square_right} />
        </div>

      </div>

    </div>
  )
}
