import { useContext } from 'react'
import Navbar from '../../components/Navbar'
import square_left from "../../assets/images/onboarding5-left-squares.svg";
import square_right from "../../assets/images/onboarding5-right-squares.svg";
import fb from "../../assets/images/onboarding5-facebook.svg";
import ig from "../../assets/images/onboarding5-instagram.svg";
import lin from "../../assets/images/onboarding5-linkedin.svg";
import CTextField from '../../components/CTextField'
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../components/contexts/UserContext';


export const Onboarding5 = () =>
{
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  return (
    <div className="">
      <Navbar classname="lg:hidden block mb-[24px]" hidecurr alternate />
      <div className="lg:relative flex lg:flex-row flex-col justify-between">
        <div className="lg:block hidden h-full justify-start z-50 pointer-events-none">
          <img className=" max-h-[95%] h-auto" src={square_left} />
        </div>
        <div className="flex flex-col lg:absolute lg:left-[50%] lg:translate-x-[-50%] pb-[66px]">
          <Navbar classname="lg:block hidden self-start mb-[40px] " alternate />
          <div className="self-center flex flex-col justify-center lg:mx-0 mx-[25px]">
            <h2 className=" font-custom font-medium self-center text-center lg:text-[57px]/[77px] text-[30px]/[41px] lg:mb-[46px] mb-[15px] ">
              Link Your Socials?
            </h2>

            <h4 className="lg:block hidden font-custom font-light self-center text-left text-[20px]/[32px]  tracking-[0.05em] mb-[46px]  ">
              Add your URLs for your socials.
            </h4>
            <h4 className="lg:hidden block font-custom font-light  text-[14px]/[25px] tracking-[0.05em] mb-[37px] ">
              Please add your URLs for your socials.
            </h4>
            <div className='self-center lg:mb-[142px] mb-[189px]'>
              <div className=' flex flex-row gap-[24px] items-center mb-[36px]'>
                <img className='self-center' src={lin} />
                <CTextField value={user.linkedin}
                  onChange={(a) => { setUser({ ...user, linkedin: a }) }}
                  label='LinkedIn URL'
                  hint='LinkedIn URL'
                  w='lg:w-[344px] w-[290px]'
                  classname=' self-center' />
              </div>
              <div className=' flex flex-row gap-[24px] mb-[36px]'>
                <img src={fb} />
                <CTextField value={user.facebook}
                  onChange={(a) => { setUser({ ...user, facebook: a }) }}
                  label='Facebook URL'
                  hint='Facebook URL'
                  w='lg:w-[344px] w-[290px]'
                  classname=' self-center' />
              </div>
              <div className=' flex flex-row gap-[24px] '>
                <img className='' src={ig} />
                <CTextField value={user.instagram}
                  mask={['@', /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/]}
                  onChange={(a) => { setUser({ ...user, instagram: a }) }}
                  label='Instagram Handle'
                  hint='Instagram Handle'
                  w='lg:w-[344px] w-[290px]'
                  classname=' self-center' />
              </div>
            </div>


            <div className='flex flex-row gap-[29px] self-center items-center'>

              <button className='w-[98px] font-custom font-medium text-white shadow-custom text-[20px]/[27px] text-center tracking-[0.05em] bg-black hover:bg-inset rounded-[26px]  lg:pt-[10px] pt-[12px] lg:pb-[14px] pb-[12px]' onClick={() =>
              {
                navigate('/onboarding-4')
              }}>
                Back
              </button>
              <div className='flex flex-row gap-[10px] self-end mt-[102px] '>
                <div className='w-[9px] h-[9px] bg-slate-400 rounded-full' />
                <div className='w-[9px] h-[9px] bg-slate-400 rounded-full' />
                <div className='w-[9px] h-[9px] bg-slate-400 rounded-full' />
                <div className='w-[9px] h-[9px] bg-slate-400 rounded-full' />
                <div className='w-[9px] h-[9px] bg-primary rounded-full' />

                <div className='w-[9px] h-[9px] bg-slate-400 rounded-full' />
              </div>
              <button className='w-[98px] font-custom font-medium hover:bg-secondary hover:text-white text-button shadow-custom text-[20px]/[27px] text-center tracking-[0.05em] bg-primary rounded-[26px]   mr-0  lg:pt-[10px] pt-[12px] lg:pb-[14px] pb-[12px]' onClick={() =>
              {
                navigate('/onboarding-6')
              }}>
                Next
              </button>
            </div>
          </div>
        </div>

        <div className=" lg:block hidden h-full justify-end z-50 pointer-events-none">
          <img className=" max-h-[100%] h-auto" src={square_right} />
        </div>
      </div>
    </div>


  )
}
