import { ChangeEvent, useEffect, useRef, useState } from "react";
import square_left from "../../assets/images/edit-profile-left-squares.svg";
import square_right from "../../assets/images/edit-profile-right-squares.svg";
import bcx_icon from "../../assets/images/bcx-icon.svg";
import Footer from "../../components/Footer";
import menu from "../../assets/images/hamburger.svg";
import add_img from "../../assets/images/add-image.svg";
import CTextField from "../../components/CTextField";
import CSelect from "../../components/CSelect";
import { Address, EliteCompany, Media, PhoneTypes, User, Website } from "../../types";
import { auth1, db } from "../../services/firebase";
import
{
  DocumentData,
  DocumentReference,
  and,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useLocation, useNavigate } from "react-router-dom";
import { Alert, CircularProgress } from "@mui/material";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useStorage } from "../../services/firebase";
import validator from "validator";
import { CPhoneInput } from "../../components/PhoneInput";
import
{
  formatPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import getPossibleCountries from "libphonenumber-js";
import { SocialsDynamic } from "../../components/SocialsDynamic";
import { v4 as uuidv4 } from 'uuid';
import { Language, lang } from "../../languages";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../redux/feature/languageSlice";
import { phoneTypel18n, stringToPhoneType } from "../../utils/features";
import { backwardsWebsite, cn } from "../../utils/utils";
import { ProfileMenu } from "../../components/ProfileMenu";
type Phone = {
  number: string;
  ext: string;
  type: string;
};
export enum Role
{
  Black,
  Elite,
}
interface EditProfileProps
{
  type: Role;
}

export const EditProfile = ({ ...props }: EditProfileProps) =>
{
  const navigate = useNavigate();
  const { state } = useLocation();
  const orderNumber = state === null ? "none" : state.orderNumber;
  const [menuToggle, setMenuToggle] = useState(true);
  const [socials, setSocials] = useState<string[]>([]);
  const [media, setMedia] = useState<(File | null)[]>([]);
  const [profile, setProfile] = useState<File | null>(null);
  const [user, setUser] = useState<User>({} as User);
  const [currUser, setCurrentUser] = useState<User>({} as User);
  const [userRef, setUserRef] = useState<DocumentReference>();
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({
    media: [{} as Media],
    addresses: [{} as Address],
    phone: [{} as Phone],
    website: [{} as Website],
    email: [""],
  } as User);
  const [submit, setSubmit] = useState({
    uploaded: false,
    submit: false,
    valid: true,
  });
  const storage = useStorage();
  const promptDisplayedRef = useRef(false);
  const [empty, setEmpty] = useState("");
  const [eliteCompanyDoc, setEliteCompanyDoc] = useState<EliteCompany>();
  const language: Language = useSelector((state: any) => state.language);
  const [languageSelection, setLanguageSelection] = useState<Language | null>(null);
  const dispatch = useDispatch();

  const getUser = async () =>
  {
    let userDoc: DocumentData | undefined = undefined;
    if (props.type === Role.Black)
    {
      auth1.onAuthStateChanged(async (user) =>
      {
        if (user)
        {
          const authUserRef = doc(db, "users", auth1.currentUser?.uid!);
          userDoc = (await getDoc(authUserRef)).data();
          const currentUser = { ...userDoc, website: backwardsWebsite(userDoc!.website) } as User;
          if (currentUser.eliteCompany !== undefined)
          {
            const eliteCompanyDocs = (
              await getDoc(currentUser.eliteCompany)
            ).data();
            const eliteCompany = eliteCompanyDocs as EliteCompany;
            setEliteCompanyDoc(eliteCompany);
          }
          setUser(currentUser);
          setCurrentUser(currentUser);
          setLanguageSelection((currentUser).language);
        } else navigate("/login");
      });
    } else if (props.type === Role.Elite)
    {
      if (orderNumber === "none")
      {
        navigate(-1);
      } else
      {
        const q = query(
          collection(db, "users"),
          where("orderNumber", "==", orderNumber)
        );
        const querySnapshot = await getDocs(q);
        let data = querySnapshot.docs[0]?.data();
        let ref = querySnapshot.docs[0]?.ref!;
        const currentUser = { ...data, website: backwardsWebsite(data.website) } as User;

        if (currentUser.eliteCompany !== undefined)
        {
          const eliteCompanyDocs = await getDoc(currentUser.eliteCompany);
          const eliteCompany = eliteCompanyDocs.data() as EliteCompany;
          setEliteCompanyDoc(eliteCompany);
        }
        setUserRef(ref);
        if (data === undefined)
        {
          navigate(-1);
        } else
        {
          setUser(currentUser);
          setLanguageSelection(currentUser.language);
          setCurrentUser(currentUser);
        }
      }
    }
  };

  const navigateBack = () =>
  {
    navigate(-1);
  };
  const getData = async () =>
  {
    await getUser().then((a) =>
    {
      if (user.orderNumber)
      {
        setLoading(false);
        if (user)
        {
          initErrors();
        }
      }
    });
  };
  async function sendData()
  {
    let lUserRef = null;
    if (props.type === Role.Black)
    {
      lUserRef = doc(db, "users", auth1.currentUser?.uid!);
    } else if (props.type === Role.Elite)
    {
      lUserRef = userRef;
    }
    await updateDoc(lUserRef!,
      {
        ...user,
        language: language,
        website: user.website ? user.website?.map((e, i) =>
        {
          return { ...e, website: e.website.replace('https://', '').replace('http://', '').replace('https:/', '').replace('http:/', '').replace('http//', '').replace('https//', '').replace('https/', '').replace('http/', '') }
        }) : [],
        customUrl: user.customUrl?.toLowerCase().replace("businesscardx.com/", "") || "",
      } as User)
      .then((re) =>
      {
        if (props.type === Role.Elite)
        {
          navigateBack()
        } else
        {
          navigate("/profile");
        }
      })
      .catch((e) =>
      {

        if (props.type === Role.Elite)
        {
          navigateBack();
        } else
        {
          navigate("/profile");
        }
      });
  }
  function uploadImage(event: ChangeEvent<HTMLInputElement>)
  {
    const file = event.target.files?.[0];
    if (
      file &&
      (file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg")
    )
    {
      event.target.value = "";
      return new File([file], file.name, { type: file.type });
    } else
    {
      return null;
    }
  }
  function updateMedia(index: number, value: File | null)
  {
    const update = [...media];
    update[index] = value;
    setMedia(update);
  }
  function listifySocials()
  {
    if (user.twitter && user.twitter !== "no")
    {
      if (!socials.includes("X")) setSocials([...socials, "X"]);
    }
    if (user.facebook && user.facebook !== "no")
    {
      if (!socials.includes("Facebook")) setSocials([...socials, "Facebook"]);
    }
    if (user.tiktok && user.tiktok !== "no")
    {
      if (!socials.includes("TikTok")) setSocials([...socials, "TikTok"]);
    }
    if (user.instagram && user.instagram !== "no")
    {
      if (!socials.includes("Instagram")) setSocials([...socials, "Instagram"]);
    }
    if (user.linkedin && user.linkedin !== "no")
    {
      if (!socials.includes("LinkedIn")) setSocials([...socials, "LinkedIn"]);
    }
    if (user.whatsapp && user.whatsapp !== "no")
    {
      if (!socials.includes("WhatsApp")) setSocials([...socials, "WhatsApp"]);
    }
    if (user.snapchat && user.snapchat !== "no")
    {
      if (!socials.includes("Snapchat")) setSocials([...socials, "Snapchat"]);
    }
  }

  async function validateCustomUrl()
  {
    let valid = true;
    let newError = { customUrl: "" } as User;
    if (user.customUrl && user.customUrl !== "")
    {
      const pattern = /[!@#$%^&*()\=\[\]{};':"\\|,.<>\/?]+|\s/g;
      if (pattern.test(user.customUrl))
      {
        newError.customUrl = lang.customURLInvalidError[language];
        valid = false;
      }
      else if (user.customUrl.length >= 4)
      {
        const q = query(
          collection(db, "users"),
          and(
            where("customUrl", "==", user.customUrl.toLowerCase()),
            where("orderNumber", '!=', user.orderNumber))
        );
        const querySnapshot = await getDocs(q);
        let data = querySnapshot.docs[0]?.exists();
        if (data)
        {
          newError.customUrl = lang.customURLTakenError[language];
          valid = false;
        }
      } else
      {
        newError.customUrl = lang.customURLShortError[language];
        valid = false;
      }
    }
    return { urlValid: valid, urlErrors: newError };
  }
  function validateSocial()
  {
    let valid = true;
    let newSocialError = {} as User;

    if (socials.includes(""))
    {
      setEmpty(lang.emptyError[language]);
      valid = false;
    }
    if (
      (!user.twitter?.trim() || user.twitter.trim() === "no") &&
      socials.includes("X")
    )
    {
      newSocialError.twitter = lang.twitterError[language];
      valid = false;
    }
    if (
      (!user.facebook?.trim() || user.facebook.trim() === "no") &&
      socials.includes("Facebook")
    )
    {
      newSocialError.facebook = lang.facebookError[language];
      valid = false;
    }
    if (
      (!user.tiktok?.trim() || user.tiktok.trim() === "no") &&
      socials.includes("TikTok")
    )
    {
      newSocialError.tiktok = lang.tiktokError[language];
      valid = false;
    }
    if (
      (!user.instagram?.trim() || user.instagram.trim() === "no") &&
      socials.includes("Instagram")
    )
    {
      newSocialError.instagram = lang.instagramError[language];
      valid = false;
    }
    if (
      (!user.linkedin?.trim() || user.linkedin.trim() === "no") &&
      socials.includes("LinkedIn")
    )
    {
      newSocialError.linkedin = lang.linkedinError[language];
      valid = false;
    }
    if (
      (!user.whatsapp?.trim() || user.whatsapp.trim() === "no") &&
      socials.includes("WhatsApp")
    )
    {
      newSocialError.whatsapp = lang.whatsappError[language];
      valid = false;
    }
    if (
      (!user.snapchat?.trim() || user.snapchat.trim() === "no") &&
      socials.includes("Snapchat")
    )
    {
      newSocialError.snapchat = lang.snapchatError[language];
      valid = false;
    }

    return { socialValid: valid, socialErrors: newSocialError };
  }
  function validateMedia()
  {
    let valid = true;
    if (user.media)
    {
      let newError = {
        media: Array(user.media.length).fill({} as Media),
      } as User;

      user.media.forEach((e, i) =>
      {
        if (!e.mediaShowcase || !e.mediaShowcase.trim())
        {
          newError.media = [
            ...newError.media.slice(0, i),
            {
              ...newError.media[i],
              mediaShowcase: lang.mediaNameError[language],
            } as Media,
            ...newError.media.slice(i + 1),
          ];
          valid = false;
        }
        if (!e.mediaThumbnail)
        {
        }
        if (!e.mediaTitle)
        {
        }
        if (!e.mediaUrl)
        {
        }
        if (!e.mediaMessage)
        {
        }
      });
      return { mediaValid: valid, mediaErrors: newError };
    } else return { mediaValid: valid, mediaErrors: {} };
  }
  function validatePhone()
  {
    let valid = true;
    if (user.phone)
    {
      let newError = {
        phone: Array(user.phone.length).fill({} as Phone),
      } as User;

      user.phone.forEach((e, i) =>
      {
        if (!e.number || !isPossiblePhoneNumber(e.number))
        {
          newError.phone = [
            ...newError.phone.slice(0, i),
            {
              ...newError.phone[i],
              number: lang.phoneNumberError[language],
            } as Phone,
            ...newError.phone.slice(i + 1),
          ];

          valid = false;
        }

        if (
          e.type === "" ||
          !e.type ||
          ![lang.work[language], lang.cell[language], lang.home[language]].includes(phoneTypel18n(stringToPhoneType(e.type), language))
        )
        {
          newError.phone = [
            ...newError.phone.slice(0, i),
            {
              ...newError.phone[i],
              type: lang.numberTypeError[language],
            } as Phone,
            ...newError.phone.slice(i + 1),
          ];
          valid = false;
        }
      });
      return { phoneValid: valid, phoneError: newError };
    }
    else
    {
      return { phoneValid: valid, phoneError: {} };
    }
  }
  function validateEmail()
  {
    let valid = true;
    if (user.email)
    {
      let newError = { email: Array(user.email.length).fill("") } as User;

      user.email.forEach((e, i) =>
      {
        if (!e || e.trim() === "" || !validator.isEmail(e))
        {
          newError.email = [
            ...newError.email!.slice(0, i),
            lang.emailError[language],
            ...newError.email!.slice(i + 1),
          ];
          valid = false;
        }
      });
      return { emailValid: valid, emailErrors: newError };
    } else return { emailValid: valid, emailErrors: {} };
  }
  function validateWebsite()
  {
    let valid = true;
    if (user.website)
    {
      let newError = { website: Array(user.website.length).fill({ website: '', title: '' } as Website) } as User;
      user.website.forEach((e, i) =>
      {
        if (!e || e.website.trim() === "")
        {
          newError.website = [
            ...newError.website!.slice(0, i),
            { website: lang.websiteError[language], title: '' },
            ...newError.website!.slice(i + 1),
          ];
          valid = false;
        }
      });
      return { websiteValid: valid, websiteErrors: newError };
    } else return { websiteValid: valid, websiteErrors: {} };
  }
  function validateAddresses()
  {
    let valid = true;
    if (user.addresses)
    {
      let newError = {
        addresses: Array(user.addresses.length).fill({} as Address),
      } as User;
      user.addresses.forEach((e, i) =>
      {
        if (!e.name)
        {
          valid = false;
          newError.addresses = [
            ...newError.addresses.slice(0, i),
            {
              ...newError.addresses[i],
              name: lang.addressNameError[language],
            } as Address,
            ...newError.addresses.slice(i + 1),
          ];
        }
        if (!e.addressline1)
        {
          valid = false;
          newError.addresses = [
            ...newError.addresses.slice(0, i),
            {
              ...newError.addresses[i],
              addressline1: lang.addressError[language],
            } as Address,
            ...newError.addresses.slice(i + 1),
          ];
        }
        if (!e.city)
        {
          valid = false;
          newError.addresses = [
            ...newError.addresses.slice(0, i),
            {
              ...newError.addresses[i],
              city: lang.cityError[language],
            } as Address,
            ...newError.addresses.slice(i + 1),
          ];
        }
        if (!e.country)
        {
          valid = false;
          newError.addresses = [
            ...newError.addresses.slice(0, i),
            {
              ...newError.addresses[i],
              country: lang.countryError[language],
            } as Address,
            ...newError.addresses.slice(i + 1),
          ];
        }
      });
      return { addressValid: valid, addressErrors: newError };
    } else return { addressValid: valid, addressErrors: {} };
  }
  async function uploadImages()
  {
    let newUser = { ...user } as User;
    if (profile)
    {

      const storageRef = ref(storage, `/files/${uuidv4()}.${profile.name.split('.').pop()}`);
      await uploadBytes(storageRef, profile, {
        contentType: profile.type,
      }).then(async (snapshot) =>
      {
        await getDownloadURL(snapshot.ref).then((url) =>
        {
          newUser.photo = url;
        });
      });
    }

    await Promise.all(
      media.map(async (pic, ind) =>
      {
        if (pic)
        {
          const storageRef = ref(storage, `/files/${uuidv4()}.${pic.name.split('.').pop()}`);

          await uploadBytes(storageRef, pic, { contentType: pic.type }).then(
            async (snapshot) =>
            {
              await getDownloadURL(snapshot.ref).then((url) =>
              {
                newUser.media = [
                  ...newUser.media.slice(0, ind),
                  { ...newUser.media[ind], mediaThumbnail: url },
                  ...newUser.media.slice(ind + 1),
                ];
              });
            }
          );
        }
      })
    );
    setUser(newUser);
    return { ...submit, uploaded: true };
  }
  async function updateLanguage(lang: Language)
  {
    const userRef = doc(db, "users", auth1.currentUser?.uid!);
    const userDoc = (await getDoc(userRef)).data() as User;
    if (userDoc.orderNumber === user.orderNumber)
    {
      await updateDoc(userRef!, { language: lang });
    } else
    {
      if (userDoc.eliteAdmin && props.type === Role.Elite)
      {
        const q = query(
          collection(db, "users"),
          where("orderNumber", "==", user.orderNumber)
        );
        const querySnapshot = await getDocs(q);
        let ref = querySnapshot.docs[0]?.ref;
        await updateDoc(ref, { language: lang });
      }
    }
  }
  async function handleSubmit()
  {
    let valid = true;
    const { phoneValid, phoneError } = validatePhone();
    if (!phoneValid)
    {
      valid = false;
    }

    const { urlValid, urlErrors } = await validateCustomUrl()!;
    if (user.customUrl)
    {
      if (!urlValid)
      {
        valid = false;
      }
    }

    const { emailValid, emailErrors } = validateEmail()!;
    if (user.email)
    {
      if (!emailValid)
      {
        valid = false;
      }
    }
    const { websiteValid, websiteErrors } = validateWebsite()!;
    if (user.website)
    {
      if (!websiteValid)
      {
        valid = false;
      }
    }
    const { addressErrors, addressValid } = validateAddresses()!;
    if (user.addresses)
    {
      if (!addressValid)
      {
        valid = false;
      }
    }
    const { socialValid, socialErrors } = validateSocial();
    if (!socialValid)
    {
      valid = false;
    }
    const { mediaValid, mediaErrors } = validateMedia()!;
    if (!mediaValid)
    {
      valid = false;
    }
    let upload = {};
    if (valid)
    {
      upload = await uploadImages();
    }

    setErrors({
      ...errors,
      ...urlErrors,
      ...phoneError,
      ...emailErrors,
      ...websiteErrors,
      ...mediaErrors,
      ...socialErrors,
      ...addressErrors,
    });
    setSubmit((a) =>
    {
      return { ...submit, ...upload, valid: valid };
    });
  }
  function deleteMedia(index: number)
  {
    const deleted = [...media];
    setMedia(deleted.filter((e, i) => i !== index));
    setErrors({
      ...errors,
      media: [
        ...errors.media.slice(0, index),
        ...errors.media.slice(index + 1),
      ],
    });
  }
  function initErrors()
  {
    const media = user.media
      ? Array(user.media.length).fill({
        mediaShowcase: "",
        mediaMessage: "",
        mediaThumbnail: "",
        mediaTitle: "",
        mediaUrl: "",
      } as Media)
      : [
        {
          mediaShowcase: "",
          mediaMessage: "",
          mediaThumbnail: "",
          mediaTitle: "",
          mediaUrl: "",
        } as Media,
      ];
    const addresses = user.addresses
      ? Array(user.addresses.length).fill({
        addressline1: "",
        addressline2: "",
        country: "",
        city: "",
        name: "",
        postalCode: "",
        stateProvince: "",
      } as Address)
      : [
        {
          addressline1: "",
          addressline2: "",
          country: "",
          city: "",
          name: "",
          postalCode: "",
          stateProvince: "",
        } as Address,
      ];
    const phone = user.phone
      ? Array(user.phone.length).fill({
        number: "",
        ext: "",
        type: "",
      } as Phone)
      : [{ number: "", ext: "", type: "" } as Phone];
    const website = user.website ? Array(user.website.length).fill("") : [""];
    const email = user.email ? Array(user.email.length).fill("") : [""];
    setErrors({
      media: media,
      addresses: addresses,
      phone: phone,
      website: website,
      email: email,
    } as User);
  }

  useEffect(() =>
  {
    const handleBeforeUnload = (event: BeforeUnloadEvent) =>
    {
      if (!promptDisplayedRef.current)
      {
        event.preventDefault();
        event.returnValue = ""; // Required for Chrome to show the custom message
        const shouldNavigate = window.confirm(
          lang.unsavedWarning[language]);
        if (shouldNavigate)
        {
          const target = event.currentTarget as HTMLAnchorElement;
          promptDisplayedRef.current = true;
          window.removeEventListener("beforeunload", handleBeforeUnload);
          window.location.href = target.href || ""; // Navigate to the desired location
        } else
        {
          promptDisplayedRef.current = false;
        }
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () =>
    {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [user]);
  useEffect(() =>
  {
    if (loading)
    {
      getData();
      listifySocials();
    }
  });
  useEffect(() =>
  {
    if (submit.uploaded)
    {
      setSubmit({ ...submit, uploaded: false, submit: true });
    }
  }, [user, submit]);
  useEffect(() =>
  {
    if (submit.valid && submit.submit)
    {
      sendData();
    }
  }, [submit]);
  useEffect(() =>
  {
    if (languageSelection)
    {
      dispatch(setLanguage(languageSelection!));
      updateLanguage(languageSelection!);
    }
  }, [dispatch, languageSelection])

  if (loading)
  {
    return (
      <div className="overflow-hidden flex items-center justify-center h-screen relative w-[100vw] ">
        <div className=" relative">
          <CircularProgress color="inherit" />
        </div>
      </div>
    );
  }

  if (user.editingDisabled && !Role.Elite)
  {
    return (
      <div className="lg:mx-[80px] mx-[25px] mb-[100px] lg:mt-[110px] mt-[74px]">
        <h1 className=" inline-block font-custom font-black lg:text-[64px]/[86px] text-[30px]/[41px] lg:mb-[34px] lg:w-auto w-auto">
          {lang.editPermissionError[language]}
        </h1>
        <div className="bg-primary relative lg:w-[150%] w-[125%]  h-[37px] z-[-1]   lg:mt-[-70px] mt-[-18px] lg:mb-[37px] mb-[24px]" />
        <div>
          <div className="flex lg:justify-start justify-start items-center z-10">
            <button
              className=" font-custom font-medium text-white shadow-custom lg:text-[19px]/[32px] text-[16px]/[25px] text-center tracking-[0.05em] bg-button rounded-[33px] lg:w-auto w-[343px] lg:px-[71px] px-0 lg:pt-[10px] pt-[12px] lg:pb-[12px] pb-[14px]"
              style={{
                backgroundColor: eliteCompanyDoc?.useCustomColors
                  ? eliteCompanyDoc?.secondaryColor
                  : "#252529",
              }}
              onClick={() =>
              {
                navigate("/profile");
              }}
            >
              {lang.goToProfile[language]}
            </button>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="relative overflow-hidden">
      <div className="flex lg:flex-row flex-col lg:justify-between w-full">
        <div className="lg:block hidden">
          <img src={square_left} />
        </div>

        <div className="flex flex-col items-center justify-start w-full ">
          <div className="relative lg:w-[393px] w-full lg:mb-[138px] mb-[144px]">
            <div className=" absolute flex flex-row justify-between bg-text  w-full  ">
              <img
                className="mt-[46px] mb-[18px] ml-[30px] cursor-pointer"
                src={bcx_icon}
                onClick={() =>
                {
                  navigate("/");
                }}
              />
              <button
                className="w-[38px] self-start mt-[58px] mr-[38px]"
                onClick={() =>
                {
                  setMenuToggle(!menuToggle);
                }}
              >
                <img src={menu} />
              </button>
            </div>
          </div>

          <div className="mb-[87px] lg:w-auto w-[393px] px-[25px]">
            <div className="flex flex-col items-center mb-[34px] ">
              <label
                htmlFor="profile_picture_img"
                className="cursor-pointer relative"
              >
                <div className=" flex flex-col justify-center items-center lg:w-[393px] w-[334px] h-auto  overflow-clip lg:mb-[12px] mb-[37px]">
                  <button
                    onClick={() =>
                    {
                      setProfile(null);
                      setUser({ ...user, photo: "no" });
                    }}
                    className={
                      (!profile
                        ? user.photo && user.photo !== "no"
                          ? "block"
                          : "hidden"
                        : "block") +
                      " z-40 absolute mt-[-50px] mr-[-25px] top-0 right-0 rotate-45 font-custom text-[50px] text-red-600"
                    }
                  >
                    +
                  </button>
                  <img
                    className="z-30 rounded-[10px] lg:w-[393px] lg:h-[393px] w-[334px] h-[334px] object-cover"
                    src={
                      profile
                        ? URL.createObjectURL(profile)
                        : user.photo && user.photo !== "no"
                          ? user.photo
                          : add_img
                    }
                  />
                  <span className="font-custom font-light text-[10px]/[19px] text-left self-start">

                    {lang.sizeRecommendation[language]}
                  </span>
                  <input
                    id="profile_picture_img"
                    type="file"
                    accept="image/png, image/jpeg, image/jpg,.jpeg,.jpg,.png"
                    className="hidden"
                    onChange={(ev) =>
                    {
                      setProfile(uploadImage(ev));
                    }}
                  />
                </div>
              </label>
              <label
                htmlFor="profile_picture"
                className={
                  (profile || user.photo !== "no"
                    ? "bg-button hover:bg-inset shadow-custom"
                    : "bg-button hover:bg-inset shadow-custom") +
                  " lg:w-[393px] w-[343px] mt-[-6px] cursor-pointer font-custom font-medium  text-[20px]/[27px] text-center rounded-[26px] lg:pt-[10px] pt-[12px] lg:pb-[10px] pb-[12px] " + ((eliteCompanyDoc?.secondaryWhite || !eliteCompanyDoc?.useCustomColors) ? "text-white" : "text-black")
                }
                style={{
                  backgroundColor: eliteCompanyDoc?.useCustomColors
                    ? eliteCompanyDoc?.secondaryColor
                    : "#252529",
                }}
              >

                {
                  lang.uploadFromPhotos[language]
                }
                <input
                  id="profile_picture"
                  type="file"
                  accept="image/png"
                  className="hidden"
                  onChange={(ev) =>
                  {
                    setProfile(uploadImage(ev));
                  }}
                />
              </label>
            </div>
            <div className=" flex flex-col items-center gap-[36px] mb-[36px]">
              {/*<CTextField error={errors.customProfileUrl} mask='profile/***************' value={user.customProfileUrl ?? user.orderNumber} onChange={(a) => setUser({ ...user, customProfileUrl: a })} label='Custom URL' hint='Custom URL' w='lg:w-[393px] w-[344px]' />*/}
              <div className="relative">
                <CTextField
                  error={errors.customUrl}
                  errorclass="mt-[10px]"
                  value={user.customUrl}
                  onChange={(a) => setUser({ ...user, customUrl: a })}
                  label={lang.customURL[language]}
                  hint="custom-url"
                  //mask={['b', 'u', 's', 'i', 'n', 'e', 's', 's', 'c', 'a', 'r', 'd', 'x', '.', 'c', 'o', 'm', '/', /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/]}
                  w="lg:w-[393px] w-[344px]"
                />
                <div className="absolute top-[60px] mb-[5px] left-0 font-light font-custom tracking-[0.6px] text-[12px]/[19px] flex flex-row">
                  {lang.preview[language]}: businesscardx.com/profile/
                  <div className="font-medium">{!user.customUrl ? 'custom-url' : user.customUrl}</div>
                </div>
              </div>
              <CTextField
                error={errors.name}
                value={user.name}
                onChange={(a) => setUser({ ...user, name: a })}
                label={lang.name[language]}
                w="lg:w-[393px] w-[344px]"
              />
              <CTextField
                error={errors.lastName}
                value={user.lastName}
                onChange={(a) => setUser({ ...user, lastName: a })}
                label={lang.lastName[language]}
                w="lg:w-[393px] w-[344px]"
              />
              <CTextField
                error={errors.jobTitle}
                value={user.jobTitle}
                onChange={(a) => setUser({ ...user, jobTitle: a })}
                label={lang.jobTitle[language]}
                w="lg:w-[393px] w-[344px]"
              />
              <CTextField
                error={errors.company}
                value={user.company}
                onChange={(a) => setUser({ ...user, company: a })}
                label={lang.company[language]}
                w="lg:w-[393px] w-[344px]"
              />
            </div>
            <div className="mb-[29px] flex flex-col items-center w-full">
              {user.phone ? (
                user.phone.map((e, i) => (
                  <div className="relative lg:w-[393px] w-[344px]" key={"phone_" + i}>
                    <CPhoneInput
                      z={10000 - 50 * (i + 1)}
                      error={errors.phone[i].number ?? ""}
                      key={"phone_" + i}
                      label={lang.phone[language]}
                      classname="mb-[20px]"
                      code={getPossibleCountries(e.number ?? "")?.country}
                      w="w-full"
                      value={formatPhoneNumber(e.number ?? "")}
                      onChange={(arg) =>
                      {
                        setUser({
                          ...user,
                          phone: [
                            ...user.phone.slice(0, i),
                            { ...e, number: arg },
                            ...user.phone.slice(i + 1),
                          ],
                        });
                      }}
                    />
                    <button
                      className="absolute z-50 right-0 top-0 rotate-45 text-red-500 p-[3px] font-custom font-normal text-[25px]/[20px]"
                      onClick={() =>
                      {
                        setUser({
                          ...user,
                          phone: [
                            ...user.phone.slice(0, i),
                            ...user.phone.slice(i + 1),
                          ],
                        });
                      }}
                    >
                      +
                    </button>
                    <div
                      key={"phone_info_cont_" + i}
                      className=" pt-[3px] flex flex-row justify-between gap-[15px] mb-[22px]"
                    >
                      <CTextField
                        error={errors.phone[i].ext}
                        label={lang.ext[language]}
                        key={"ext_" + i}
                        classname=""
                        w="w-[100px] "
                        value={e.ext}
                        onChange={(arg) =>
                        {
                          setUser({
                            ...user,
                            phone: [
                              ...user.phone.slice(0, i),
                              { ...e, ext: arg },
                              ...user.phone.slice(i + 1),
                            ],
                          });
                        }}
                      />
                      <CSelect
                        value={phoneTypel18n(stringToPhoneType(user.phone[i].type), language)}
                        error={errors.phone[i].type as string}
                        hint={lang.type[language]}
                        errorclass="pt-[5px]"
                        items={[{ item: lang.work[language], value: PhoneTypes.Work }, { item: lang.cell[language], value: PhoneTypes.Cell }, { item: lang.home[language], value: PhoneTypes.Home }]}
                        key={"type_" + i}
                        label={lang.type[language]}
                        classname="lg:w-[280px] w-[230px]"
                        onChange={(arg) =>
                        {
                          setUser({
                            ...user,
                            phone: [
                              ...user.phone.slice(0, i),
                              { ...e, type: arg },
                              ...user.phone.slice(i + 1),
                            ],
                          });
                        }}
                      />
                    </div>
                  </div>
                ))
              ) : (
                <></>
              )}
              <button
                onClick={() =>
                {
                  setErrors({
                    ...errors,
                    phone: [...errors.phone, {} as Phone],
                  });
                  setUser({ ...user, phone: user.phone ? [...user.phone, {} as Phone] : [{} as Phone] });

                }}
                className={"lg:w-[393px] w-[344px] font-custom font-medium shadow-custom text-[14px]/[25px] text-center hover:bg-secondary bg-primary rounded-[26px]  lg:pt-[10px] pt-[12px] lg:pb-[10px] pb-[12px] " + ((eliteCompanyDoc?.primaryWhite && eliteCompanyDoc?.useCustomColors) ? "text-white" : "text-black")}
                style={{
                  backgroundColor: eliteCompanyDoc?.useCustomColors
                    ? eliteCompanyDoc?.primaryColor
                    : "#fbbc05",
                }}
              >
                + {`${lang.add[language]} ${lang.phoneNumber[language]}`}
              </button>
            </div>
            <div className="mb-[28px] flex flex-col items-center w-full">
              {user.email ? (
                user.email.map((e, i) => (
                  <div className="relative lg:w-[393px] w-[344px]" key={"email_" + i}>
                    <button
                      className="absolute z-40 right-0 top-0 rotate-45 text-red-500 p-[3px] font-custom font-normal text-[25px]/[20px]"
                      onClick={() =>
                      {
                        setUser({
                          ...user,
                          email: [
                            ...user.email!.slice(0, i),
                            ...user.email!.slice(i + 1),
                          ],
                        });
                      }}
                    >
                      +
                    </button>
                    <CTextField
                      error={errors.email![i]}
                      key={"email_" + i}
                      label={lang.email[language]}
                      classname="mb-[20px]"
                      value={e}
                      onChange={(arg) =>
                      {
                        setUser({
                          ...user,
                          email: [
                            ...user.email!.slice(0, i),
                            arg,
                            ...user.email!.slice(i + 1),
                          ],
                        });
                      }}
                    />
                  </div>
                ))
              ) : (
                <div></div>
              )}
              <button
                onClick={() =>
                {
                  setErrors({ ...errors, email: [...errors.email!, ""] });
                  setUser({ ...user, email: [...user.email!, ""] });
                }}
                className={"lg:w-[393px] w-[344px] font-custom font-medium shadow-custom text-[14px]/[25px] text-center hover:bg-secondary bg-primary rounded-[26px]  lg:pt-[10px] pt-[12px] lg:pb-[10px] pb-[12px] " + ((eliteCompanyDoc?.primaryWhite && eliteCompanyDoc?.useCustomColors) ? "text-white" : "text-black")}
                style={{
                  backgroundColor: eliteCompanyDoc?.useCustomColors
                    ? eliteCompanyDoc?.primaryColor
                    : "#fbbc05",
                }}
              >
                + {`${lang.add[language]} ${lang.email[language]}`}
              </button>
            </div>

            <div className="mb-[28px] flex flex-col items-center w-full">
              {user.website ? (
                user.website.map((e, i) => (
                  <div className="relative lg:w-[393px] w-[344px]" key={"website_" + i}>
                    <button
                      className="absolute z-40 right-0 top-0 rotate-45 text-red-500 p-[3px] font-custom font-normal text-[25px]/[20px]"
                      onClick={() =>
                      {
                        setUser({
                          ...user,
                          website: [
                            ...user.website!.slice(0, i),
                            ...user.website!.slice(i + 1),
                          ],
                        });
                      }}
                    >
                      +
                    </button>
                    <CTextField
                      error={errors.website?.[i].website}
                      errorclass="mb-[5px]"
                      key={"web_" + i}
                      label={lang.website[language]}
                      hint="URL"
                      classname="mb-[20px]"
                      value={e.website}
                      onChange={(arg) =>
                      {
                        setUser({
                          ...user,
                          website: [
                            ...user.website!.slice(0, i),
                            { ...e, website: arg },
                            ...user.website!.slice(i + 1),
                          ],
                        });
                      }}
                    />
                    <CTextField
                      error={errors.website?.[i].title}
                      key={"web-title_" + i}
                      label={lang.websiteTitle[language]}
                      hint={lang.websiteTitle[language]}
                      classname="mb-[20px]"
                      value={e.title}
                      onChange={(arg) =>
                      {
                        setUser({
                          ...user,
                          website: [
                            ...user.website!.slice(0, i),
                            { ...e, title: arg },
                            ...user.website!.slice(i + 1),
                          ],
                        });
                      }}
                    />
                  </div>
                ))
              ) : (
                <div></div>
              )}
              <button
                onClick={() =>
                {
                  setErrors({ ...errors, website: [...errors.website!, { website: '', title: '' }] });

                  setUser({ ...user, website: user.website ? [...user.website!, { website: '', title: '' }] : [{ website: '', title: '' }] });

                }}
                className={"lg:w-[393px] w-[344px] font-custom font-medium shadow-custom text-[14px]/[25px] text-center hover:bg-secondary bg-primary rounded-[26px]  lg:pt-[10px] pt-[12px] lg:pb-[10px] pb-[12px] " + ((eliteCompanyDoc?.primaryWhite && eliteCompanyDoc?.useCustomColors) ? "text-white" : "text-black")}
                style={{
                  backgroundColor: eliteCompanyDoc?.useCustomColors
                    ? eliteCompanyDoc?.primaryColor
                    : "#fbbc05",
                }}
              >
                + {`${lang.add[language]} ${lang.website[language]}`}
              </button>
            </div>

            <div className="mb-[34px] flex flex-col items-center lg:w-auto w-full ">
              <SocialsDynamic
                userSetter={(user) => setUser(user)}
                language={language}
                user={user}
                empty={empty}
                items={socials}
                error={errors}
                color={
                  eliteCompanyDoc?.useCustomColors
                    ? eliteCompanyDoc?.primaryColor
                    : "#fbbc05"
                }
                textColor={(eliteCompanyDoc?.primaryWhite && eliteCompanyDoc?.useCustomColors) ? "text-white" : "text-black"}
                itemsSetter={(items) => setSocials(items)}
              />
            </div>
            <div className="mb-[23px] flex flex-col w-full lg:w-auto items-center">
              <CTextField
                maxlength={1000}
                value={user.bio}
                onChange={(a) => setUser({ ...user, bio: a })}
                label={lang.bio[language]}
                w="lg:w-[393px] w-[344px]"
                hint={`1000 ${lang.characters[language]} ${lang.max[language]}`}
                multiline
              />
            </div>

            <div className="mb-[33px] flex flex-col items-center lg:w-auto w-full">
              <h3 className="font-custom self-start  font-medium text-[30px]/[41px] text-button mb-[20px]">
                {lang.location[language]}
              </h3>
              <div className="flex flex-col gap-[22px] lg:w-[393px] w-[344px] ">
                <CTextField
                  maxlength={40}
                  value={user.city}
                  onChange={(a) => setUser({ ...user, city: a })}
                  label={lang.city[language]}
                />
                <CTextField
                  value={user.stateProvince}
                  maxlength={40}
                  onChange={(a) => setUser({ ...user, stateProvince: a })}
                  label={lang.stateProvince[language]}
                />
                <CTextField
                  value={user.country}
                  maxlength={40}
                  onChange={(a) => setUser({ ...user, country: a })}
                  label={lang.country[language]}
                />
              </div>
            </div>

            <div className="mb-[33px]  flex flex-col items-center lg:w-auto w-full">
              <h3 className="font-custom font-medium self-start text-[30px]/[41px] text-button mb-[20px]">
                {lang.addresses[language]}
              </h3>
              {user.addresses ? (
                user.addresses.map((e, i) => (
                  <div
                    key={"address_container_" + i}
                    className="relative flex flex-col gap-[22px] mb-[20px] lg:w-[393px] w-[344px]"
                  >
                    <button
                      className="absolute z-50 right-0 top-0 rotate-45 text-red-500 p-[3px] font-custom font-normal text-[25px]/[20px]"
                      onClick={() =>
                      {
                        setUser({
                          ...user,
                          addresses: [
                            ...user.addresses!.slice(0, i),
                            ...user.addresses!.slice(i + 1),
                          ],
                        });
                      }}
                    >
                      +
                    </button>
                    <CTextField
                      key={"address_name_" + i}
                      value={e.name}
                      error={errors.addresses[i].name}
                      label={lang.name[language]}
                      onChange={(arg) =>
                      {
                        setUser({
                          ...user,
                          addresses: [
                            ...user.addresses!.slice(0, i),
                            { ...e, name: arg },
                            ...user.addresses!.slice(i + 1),
                          ],
                        });
                      }}
                    />
                    <CTextField
                      key={"address_line1_" + i}
                      value={e.addressline1}
                      error={errors.addresses[i].addressline1}
                      label={`${lang.address[language]} ${lang.line[language]} 1`}
                      onChange={(arg) =>
                      {
                        setUser({
                          ...user,
                          addresses: [
                            ...user.addresses!.slice(0, i),
                            { ...e, addressline1: arg },
                            ...user.addresses!.slice(i + 1),
                          ],
                        });
                      }}
                    />
                    <CTextField
                      key={"address_line2_" + i}
                      value={e.addressline2}
                      error={errors.addresses[i].addressline2}
                      label={`${lang.address[language]} ${lang.line[language]} 2`}
                      onChange={(arg) =>
                      {
                        setUser({
                          ...user,
                          addresses: [
                            ...user.addresses!.slice(0, i),
                            { ...e, addressline2: arg },
                            ...user.addresses!.slice(i + 1),
                          ],
                        });
                      }}
                    />
                    <CTextField
                      key={"address_city_" + i}
                      value={e.city}
                      error={errors.addresses[i].city}
                      label={lang.city[language]}
                      onChange={(arg) =>
                      {
                        setUser({
                          ...user,
                          addresses: [
                            ...user.addresses!.slice(0, i),
                            { ...e, city: arg },
                            ...user.addresses!.slice(i + 1),
                          ],
                        });
                      }}
                    />
                    <CTextField
                      key={"address_state_province_" + i}
                      value={e.stateProvince}
                      error={errors.addresses[i].stateProvince}
                      label={lang.stateProvince[language]}
                      onChange={(arg) =>
                      {
                        setUser({
                          ...user,
                          addresses: [
                            ...user.addresses!.slice(0, i),
                            { ...e, stateProvince: arg },
                            ...user.addresses!.slice(i + 1),
                          ],
                        });
                      }}
                    />
                    <CTextField
                      key={"address_country_" + i}
                      value={e.country}
                      error={errors.addresses[i].country}
                      label={lang.country[language]}
                      onChange={(arg) =>
                      {
                        setUser({
                          ...user,
                          addresses: [
                            ...user.addresses!.slice(0, i),
                            { ...e, country: arg },
                            ...user.addresses!.slice(i + 1),
                          ],
                        });
                      }}
                    />
                    <CTextField
                      key={"address_zip_" + i}
                      value={e.postalCode}
                      error={errors.addresses[i].postalCode}
                      label={lang.postalCode[language]}
                      onChange={(arg) =>
                      {
                        setUser({
                          ...user,
                          addresses: [
                            ...user.addresses!.slice(0, i),
                            { ...e, postalCode: arg },
                            ...user.addresses!.slice(i + 1),
                          ],
                        });
                      }}
                    />
                  </div>
                ))
              ) : (
                <div></div>
              )}

              <button
                onClick={() =>
                {
                  setErrors({
                    ...errors,
                    addresses: [...errors.addresses, {} as Address],
                  });
                  setUser({
                    ...user,
                    addresses: user.addresses
                      ? [...user.addresses, {} as Address]
                      : [{} as Address],
                  });
                }}
                className={"lg:w-[393px] w-[344px]  font-custom font-medium text-button shadow-custom text-[14px]/[25px] text-center hover:bg-secondary bg-primary rounded-[26px]  lg:pt-[10px] pt-[12px] lg:pb-[10px] pb-[12px] " + ((eliteCompanyDoc?.primaryWhite && eliteCompanyDoc?.useCustomColors) ? "text-white" : "text-black")}
                style={{
                  backgroundColor: eliteCompanyDoc?.useCustomColors
                    ? eliteCompanyDoc?.primaryColor
                    : "#fbbc05",
                }}
              >
                + {`${lang.add[language]} ${lang.addresses[language]}`}
              </button>
            </div>

            <div className="mb-[100px] flex flex-col items-center lg:w-auto w-full">
              <h3 className="font-custom self-start font-medium text-[30px]/[41px] text-button mb-[20px]">
                {`${lang.media[language]} ${lang.showcase[language]}`}
              </h3>
              {user.media ? (
                user.media.map((e, i) => (
                  <div
                    key={"media_container_" + i}
                    className="relative flex flex-col gap-[20px] mb-[20px] lg:w-[393px] w-[344px]"
                  >
                    <button
                      className="absolute z-50 mr-[-15px] mt-[-15px] right-0 top-0 rotate-45 text-red-500  font-custom font-normal text-[25px]/[20px] "
                      onClick={() =>
                      {
                        deleteMedia(i);
                        setUser({
                          ...user,
                          media: [
                            ...user.media!.slice(0, i),
                            ...user.media!.slice(i + 1),
                          ],
                        });
                      }}
                    >
                      +
                    </button>
                    <CTextField
                      error={errors.media[i].mediaShowcase}
                      label={lang.name[language]}
                      value={e.mediaShowcase}
                      onChange={(arg) =>
                      {
                        setUser({
                          ...user,
                          media: [
                            ...user.media.slice(0, i),
                            { ...e, mediaShowcase: arg },
                            ...user.media.slice(i + 1),
                          ],
                        });
                      }}
                    />
                    <label
                      htmlFor={"media_showcase_" + i}
                      className="cursor-pointer flex flex-row justify-center"
                    >
                      <input
                        id={"media_showcase_" + i}
                        type="file"
                        accept="image/png, image/jpeg, image/jpg"
                        onChange={(ev) =>
                        {
                          updateMedia(i, uploadImage(ev));
                        }}
                        className="hidden"
                      />
                      <img
                        className={
                          (e.mediaThumbnail || media[i]
                            ? "rounded-[7px] h-[195px] w-[393px] object-cover"
                            : "h-[195px] w-auto self-center") +
                          " cursor-pointer"
                        }
                        src={
                          media[i]
                            ? URL.createObjectURL(media[i]!)
                            : user.media[i].mediaThumbnail
                              ? user.media[i].mediaThumbnail
                              : add_img
                        }
                      />
                    </label>
                    <label
                      htmlFor={"media_showcase_btn_" + i}
                      className={
                        (e.mediaThumbnail
                          ? "shadow-inset bg-inset"
                          : "bg-button shadow-custom") +
                        " lg:w-[393px] w-[344px]  cursor-pointer bg-button  font-custom font-medium text-[14px]/[25px] tracking-[0.05em] text-center rounded-[26px] lg:pt-[10px] pt-[12px] lg:pb-[10px] pb-[12px] " + ((eliteCompanyDoc?.secondaryWhite || !eliteCompanyDoc?.useCustomColors) ? "text-white" : "text-black")
                      }
                      style={{
                        backgroundColor: eliteCompanyDoc?.useCustomColors
                          ? eliteCompanyDoc?.secondaryColor
                          : "#252529",
                      }}
                    >
                      {lang.uploadThumbnail[language]}
                      <input
                        id={"media_showcase_btn_" + i}
                        type="file"
                        accept="image/png, image/png, image/jpeg, image/jpg"
                        onChange={(ev) =>
                        {
                          updateMedia(i, uploadImage(ev));
                        }}
                        className="hidden"
                      />
                    </label>
                    <CTextField
                      error={errors.media[i].mediaUrl}
                      value={e.mediaUrl}
                      label="URL"
                      onChange={(arg) =>
                      {
                        setUser({
                          ...user,
                          media: [
                            ...user.media.slice(0, i),
                            { ...e, mediaUrl: arg },
                            ...user.media.slice(i + 1),
                          ],
                        });
                      }}
                    />
                    <CTextField
                      maxlength={250}
                      hint={`250 ${lang.characters[language]} ${lang.max[language]}`}
                      error={errors.media[i].mediaMessage}
                      value={e.mediaMessage}
                      label={lang.message[language]}
                      mask=""
                      multiline
                      onChange={(arg) =>
                      {
                        setUser({
                          ...user,
                          media: [
                            ...user.media.slice(0, i),
                            { ...e, mediaMessage: arg },
                            ...user.media.slice(i + 1),
                          ],
                        });
                      }}
                    />
                  </div>
                ))
              ) : (
                <div></div>
              )}
              <button
                onClick={() =>
                {
                  setErrors({
                    ...errors,
                    media: [...errors.media, {} as Media],
                  });
                  setUser({
                    ...user,
                    media: user.media
                      ? [...user.media, {} as Media]
                      : [{} as Media],
                  });
                }}
                className={"lg:w-[393px] w-[344px]  font-custom font-medium text-button shadow-custom text-[14px]/[25px] text-center hover:bg-secondary bg-primary rounded-[26px]  lg:pt-[10px] pt-[12px] lg:pb-[10px] pb-[12px] " + ((eliteCompanyDoc?.primaryWhite && eliteCompanyDoc?.useCustomColors) ? "text-white" : "text-black")}
                style={{
                  backgroundColor: eliteCompanyDoc?.useCustomColors
                    ? eliteCompanyDoc?.primaryColor
                    : "#fbbc05",
                }}
              >
                + {`${lang.add[language]} ${lang.showcase[language]}`}
              </button>
            </div>
            <div className="fixed z-50 bottom-0 flex flex-col mb-[25px]">
              <button
                onClick={() =>
                {
                  handleSubmit();
                }}
                className={"lg:w-[393px] w-[344px]  font-custom font-medium shadow-custom text-[14px]/[25px] text-center hover:bg-inset bg-button rounded-[26px]  lg:pt-[10px] pt-[12px] lg:pb-[10px] pb-[12px] " + ((eliteCompanyDoc?.secondaryWhite || !eliteCompanyDoc?.useCustomColors) ? "text-white" : "text-black")}
                style={{
                  backgroundColor: eliteCompanyDoc?.useCustomColors
                    ? eliteCompanyDoc?.secondaryColor
                    : "#252529",
                }}
              >
                {lang.saveChanges[language]}
              </button>
              <div
                className={cn('mt-[15px] rounded-[6px] overflow-clip hidden', { 'block': !submit.valid })}

              >
                <Alert
                  style={{
                    fontFamily: "Satoshi-Variable",
                    fontWeight: "300",
                    fontSize: "15px",
                    lineHeight: "20px",
                  }}
                  severity="warning"
                >
                  {
                    lang.missingInfo[language]
                  }
                </Alert>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:block hidden">
          <img src={square_right} />
        </div>
      </div>

      <ProfileMenu
        editProfile
        language={language}
        navigate={navigate}
        onOpen={() => { setMenuToggle(!menuToggle) }}
        open={menuToggle}
        setLanguageSelection={setLanguageSelection}
        user={user}
        currUser={currUser}
      />

      <div className="lg:block hidden">
        <Footer translate />
      </div>
    </div >
  );
};




