import { useState } from "react";
import CTextField from "./CTextField"
import { CircularProgress } from "@mui/material";


interface DiscountProps
{
    onClick: (a: string) => void,
    error: string,
    loading: boolean
    percent: number
    code: string
}
export const Discount = ({ ...props }: DiscountProps) =>
{
    const [code, setCode] = useState(props.code);

    function handleClick()
    {
        props.onClick(code)
    }
    return <div className="flex flex-col gap-[16px]">
        <div className="flex flex-row gap-[22px]">
            <CTextField value={code} w="w-[262px]" label="Code" onChange={(a) => setCode(a)} error={props.error} />
            <div className="flex flex-col font-custom gap-[5px] justify-start  ">
                {
                    props.loading ? (
                        <>
                            <div className="">
                                <CircularProgress color="inherit" />
                            </div>
                        </>) :
                        (<>
                            {props.percent !== 0 ? (<>
                                <div className="font-normal text-[14px]/[20px]">
                                    Discount
                                </div>
                                <div className="text-discount font-semibold text-[15px]/[20px]">
                                    {props.percent * 100}% off
                                </div>
                            </>) : <></>}

                        </>)
                }
            </div>
        </div>
        <div>
            <button onClick={handleClick} className="font-custom font-bold text-white shadow-custom w-full text-[16px]/[20px] text-center hover:bg-inset bg-button rounded-[33px]  pt-[12px] pb-[14px]">Check Discount Code</button>
        </div>
    </div>
}
