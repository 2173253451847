import React, { FormEvent, useEffect, useState } from "react";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import get_in_touch from "../../assets/images/get-in-touch.png";
import CTextField from "../../components/CTextField";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import { Cart } from "../../components/Cart";
import { Email } from "../../types";
import { sendEmail } from "../../utils/utils";
import ReactGA from 'react-ga4';

export const Contact = () =>
{
  const navigate = useNavigate();
  const [submit, setSubmit] = useState(true);
  const [errors, setErrors] = useState<Email>({
    fullName: "",
    sender: "",
    subject: "",
    message: "",
    dest: "",
  });
  const [mail, setMail] = useState<Email>({
    dest: process.env.REACT_APP_EMAIL!,
    fullName: "",
    message: "",
    sender: "",
    subject: "",
  });
  const handleSubmit = (e: FormEvent<HTMLFormElement>) =>
  {
    e.preventDefault();
    const newErrors = {
      fullName: "",
      sender: "",
      subject: "",
      message: "",
      dest: "",
    };
    let valid = true;
    if (!mail.fullName.trim())
    {
      newErrors.fullName = "Your Full Name is required";
      valid = false;
    }
    if (!mail.sender.trim() || !validator.isEmail(mail.sender.trim()))
    {
      newErrors.sender = "Provide a valid Email";
      valid = false;
    }
    if (!mail.message.trim())
    {
      newErrors.message = "A Message is required";
      valid = false;
    }
    if (!mail.subject.trim())
    {
      newErrors.subject = "A Subject is required";
      valid = false;
    }
    setErrors(newErrors);
    if (valid)
    {
      sendData(mail);
    }
  };
  const sendData = async (mail: Email) =>
  {
    setSubmit(false);
    await sendEmail(mail, (response) =>
    {
      setSubmit(true);
      if (response.status === 200)
      {
        navigate("/submit-successfully");
      }
    }, (response) =>
    {
      ReactGA.event('EmailError', {
        name: 'Contact_Form',
        mail: JSON.stringify(mail),
        response: JSON.stringify(response.statusText)
      })
    })
  };

  useEffect(() => { window.scrollTo(0, 0); }, []);
  return (
    <>
      <Cart />
      <div className="overflow-hidden">
        <Navbar />
        <div className="flex lg:flex-row lg:pl-[80px] lg:pr-0 px-[25px] lg:mt-[58px] mt-[20px] justify-between">
          <div className=" lg:mt-[52px] relative">
            <h1 className=" inline-block font-custom font-black lg:text-[64px]/[86px] text-[30px]/[41px] lg:mb-[34px]">
              Let's Get In Touch
            </h1>
            <div className="bg-primary relative lg:w-[150%] w-[125%]  h-[37px] z-[-1]   lg:mt-[-70px] mt-[-18px] lg:mb-[34px] mb-[16px]" />
            <p className="font-custom font-light lg:text-[20px]/[32px] text-[14px]/[25px] lg:px-0  lg:w-[630px]  tracking-[0.05em] mt-0 lg:mb-[32px] mb-[32px]">
              Don't be a stranger! We love connecting with our customers and
              welcome any comments or inquiries you may have.
            </p>
            <form id="contact" onSubmit={handleSubmit}>
              <CTextField
                value={mail.fullName}
                onChange={(a) =>
                {
                  setMail({ ...mail, fullName: a });
                }}
                classname="mb-[32px]"
                label="Full Name"
                error={errors.fullName}
              />
              <CTextField
                value={mail.sender}
                onChange={(a) =>
                {
                  setMail({ ...mail, sender: a });
                }}
                classname="mb-[32px]"
                label="Email"
                error={errors.sender}
              />
              <CTextField
                value={mail.subject}
                onChange={(a) =>
                {
                  setMail({ ...mail, subject: a });
                }}
                classname="mb-[32px]"
                label="Subject"
                error={errors.subject}
              />
              <CTextField
                value={mail.message}
                onChange={(a) =>
                {
                  setMail({ ...mail, message: a });
                }}
                classname="mb-[37px]"
                label="Message"
                multiline
                error={errors.message}
              />
              <div className="flex lg:justify-end justify-center items-center z-10">
                <button
                  disabled={!submit}
                  type="submit"
                  form="contact"
                  className="font-custom font-medium text-button shadow-custom lg:text-[19px]/[32px] text-[16px]/[25px] text-center tracking-[0.05em] bg-primary hover:bg-secondary rounded-[33px] lg:px-[71px] px-[69px] lg:pt-[16px] pt-[12px] lg:pb-[18px] pb-[14px]"
                >
                  Submit Message
                </button>
              </div>
            </form>
          </div>
          <div className="lg:flex items-start justify-center flex-shrink-0 hidden xl:mt-0 lg:mt-[87px] xl:ml-0 ">
            <img
              className="xl:max-w-[100%] lg:max-w-[75%] h-auto mt-[25px]"
              src={get_in_touch}
              alt="Top view of man shaking someone else's hand surrounded by group of people"
            />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
