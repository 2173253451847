import { ChangeEvent, useContext, useState } from 'react'
import Navbar from '../../components/Navbar'
import square_left from "../../assets/images/onboarding1-left-squares.svg";
import square_right from "../../assets/images/onboarding1-right-squares.svg";
import upload from "../../assets/images/onboarding1-upload.svg";
import { useNavigate } from 'react-router-dom';
import { OnboardingUser, UserContext } from '../../components/contexts/UserContext';
import { getDownloadURL, ref, uploadBytes } from '@firebase/storage';
import { useStorage } from '../../services/firebase';
import { CircularProgress } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { cn } from '../../utils/utils';
export const Onboarding = () =>
{
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const [pic, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const storage = useStorage();
  function uploadImage(event: ChangeEvent<HTMLInputElement>) 
  {
    const file = event.target.files?.[0];
    if (file && (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg'))
    {
      event.target.value = '';
      return new File([file], file.name, { type: file.type });
    } else
    {
      return null;
    }
  }
  function handleSubmit()
  {
    if (pic)
    {
      setLoading(true);
      const storageRef = ref(storage, `/files/${uuidv4()}.${pic.name.split('.').pop()}`);
      uploadBytes(storageRef, pic, { contentType: pic.type }).then((snapshot) =>
      {
        getDownloadURL(snapshot.ref).then((url) =>
        {
          setUser({
            ...user,
            photo: url,
          } as OnboardingUser);
          setLoading(false);
          navigate('/onboarding-2');
        })
      });
    }
    else if (user.photo)
    {
      navigate('/onboarding-2');
    }
    else
    {
      setUser({
        ...user,
        photo: 'no',
      } as OnboardingUser);
      navigate('/onboarding-2');
    }

  }
  return (
    <div className="">
      <Navbar classname="lg:hidden block mb-[24px]" hidecurr alternate />
      <div className="lg:relative flex lg:flex-row flex-col justify-between">
        <div className="lg:block hidden h-full justify-start z-50 pointer-events-none">
          <img className=" max-h-[95%] h-auto" src={square_left} />
        </div>
        <div className="flex flex-col lg:absolute lg:left-[50%] lg:translate-x-[-50%]  pb-[66px]">
          <Navbar classname="lg:block hidden self-start mb-[40px] " alternate />
          <div className="self-center flex flex-col justify-center lg:mx-0 mx-[25px]">
            <h2 className=" font-custom font-medium self-center text-center lg:text-[57px]/[77px] text-[30px]/[41px] lg:mb-[23px] mb-[18px] ">
              Let's Get Started
            </h2>
            <h4 className="lg:block hidden lg:w-[305px] w-[343px] font-custom font-light self-center lg:text-left lg:text-[20px]/[32px] text-[14px]/[25px] tracking-[0.05em] lg:mb-[40px] mb-[53px] ">
              Your profile photo is often the first thing people see when they connect with you. Make sure it's a good one!
            </h4>

            <h4 className="lg:hidden block lg:w-[305px] w-[343px] font-custom font-light self-center lg:text-left lg:text-[20px]/[32px] text-[14px]/[25px] tracking-[0.05em] mb-[40px]  ">
              Upload a profile photo! Make sure it's a good one!.
            </h4>
            <label htmlFor='profilePic'
              className={cn('cursor-pointer self-center lg:mb-[56px] mb-[64px] w-auto h-[165px]',
                {
                  'cursor-default opacity-70': loading
                })}>
              <img className=' object-cover w-[180px] h-[180px] rounded-[5px] overflow-hidden' src={((user.photo && user.photo !== 'no') ? user.photo : (pic ? URL.createObjectURL(pic) : upload))} />
              <input disabled={loading} type="file" accept="image/png, image/jpeg, image/jpg" className='hidden' id='profilePic' onChange={(e) => { setFile(uploadImage(e)) }} />
            </label>
            <label htmlFor='profilePicbtn'
              className={cn('cursor-pointer w-[333px] self-center font-custom font-medium text-white shadow-custom text-[20px]/[27px] text-center tracking-[0.05em] bg-button rounded-[26px] lg:mb-[52px] mb-[152px] lg:pt-[10px] pt-[12px] lg:pb-[14px] pb-[12px]',
                { 'opacity-70 cursor-default': loading })}>
              Upload from Photos
              <input disabled={loading} type="file" accept="image/png, image/jpeg, image/jpg" className='hidden' id='profilePicbtn' onChange={(e) => { setFile(uploadImage(e)) }} />
            </label>

            <div className='flex flex-row  items-center gap-[29px] self-center'>

              <button className='pointer-events-none invisible w-[98px] mb-[12px] font-custom font-medium text-white shadow-custom text-[20px]/[27px] text-center tracking-[0.05em] bg-black rounded-[26px]  lg:pt-[10px] pt-[12px] lg:pb-[14px] pb-[12px]' onClick={() =>
              {
                navigate('/onboarding')
              }}>
                Back
              </button>
              <div className='flex flex-row gap-[10px] self-end mt-[90px] '>
                <div className='w-[9px] h-[9px] bg-primary rounded-full' />
                <div className='w-[9px] h-[9px] bg-slate-400 rounded-full' />
                <div className='w-[9px] h-[9px] bg-slate-400 rounded-full' />
                <div className='w-[9px] h-[9px] bg-slate-400 rounded-full' />
                <div className='w-[9px] h-[9px] bg-slate-400 rounded-full' />
                <div className='w-[9px] h-[9px] bg-slate-400 rounded-full' />
              </div>
              <button disabled={loading} className={cn('relative w-[110px] mb-[12px] font-custom font-medium text-button shadow-custom text-[20px]/[27px] tracking-[0.05em] bg-primary hover:bg-secondary hover:text-white rounded-[26px] mr-0 lg:pt-[12px] pt-[12px] lg:pb-[12px] pb-[12px]'
                , { 'hover:bg-primary hover:text-button opacity-70': loading })
              } onClick={() =>
              {
                handleSubmit();
              }}>
                <div className='flex flex-row justify-center items-center'>
                  <div className={cn('-ml-[15px]', { 'ml-0': !loading })}>
                    Next
                  </div>
                  <div className={cn('absolute right-0 mr-[15px] mt-[6px]', { 'hidden': !loading })}>
                    <CircularProgress size={20} color='inherit' />
                  </div>
                </div>
              </button>
            </div>


          </div>
        </div>
        <div className=" lg:block hidden h-full justify-end z-50 pointer-events-none">
          <img className=" max-h-[100%] h-auto" src={square_right} />
        </div>

      </div>

    </div>
  )
}
