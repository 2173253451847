import React, { ChangeEvent, useEffect, useLayoutEffect, useRef, useState } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import cart from "../../assets/images/cart.svg";
import square from "../../assets/images/square-black-card.svg";
import sm_square from "../../assets/images/square-sm-black-card.svg";
import extra_cards from "../../assets/images/extra-cards.png";
import mock_card from "../../assets/images/black-mock.svg";
import mock_card_gold from "../../assets/images/black-mock-gold.svg";
import mock_card_silver from "../../assets/images/black-mock-silver.svg";
import card_logo from "../../assets/images/card-logo.svg";
import logopark_big from "../../assets/images/black-logopark.svg";
import logopark_big_gold from "../../assets/images/black-logopark-gold.svg";
import logopark_big_silver from "../../assets/images/black-logopark-silver.svg";
import { v4 as uuidv4 } from 'uuid';
import logopark_silver from "../../assets/images/logopark-silver.svg";
import logopark_gold from "../../assets/images/logopark-gold.svg";
import logopark from "../../assets/images/logopark.svg";

import your_logo_here from "../../assets/images/your-logo-here.svg";
import your_logo_here_gold from "../../assets/images/your-logo-here-gold.svg";
import your_logo_here_silver from "../../assets/images/your-logo-here-silver.svg";

import { useNavigate } from "react-router-dom";
import Incrementer from "../../components/Incrementer";
import CTextField from "../../components/CTextField";
import CCheckBox from "../../components/CCheckBox";
import CSelect from "../../components/CSelect";
import
{
  Black,
  Colors,
  Purchase,
  PurchaseType,
  getColorData,
  getSubTotal,
  setOrder,
} from "../../redux/feature/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import { Alert, CircularProgress } from "@mui/material";
import { Cart } from "../../components/Cart";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useStorage } from "../../services/firebase";
import { Currency, curr } from "../../currencies";
import { ColorLabels } from "../../types";
import { colorToLabel, labelToColor } from "../../utils/features";

export const BlackCard = () =>
{
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [uploadLater, setUploadLater] = useState(false);
  const [loadingLogo, setLoadingLogo] = useState([false, false, false])
  const [first, setFirst] = useState(true);
  const name = useRef<HTMLDivElement>(null);
  const company = useRef<HTMLDivElement>(null);
  const cartRx: Purchase = useSelector((state: any) => state.cart);
  const currency: Currency = useSelector((state: any) => state.currency);
  const [order, setlocalOrder] = useState(cartRx.order.type !== PurchaseType.Black ? { submit: false, valid: undefined, logoAdd: false, type: PurchaseType.Black, blank: false, color: '', company: '', extra: 0, fullName: '', logo: ['', '', ''] } as Black : {
    submit: false,
    valid: (cartRx.order as Black).valid ?? undefined,
    logoAdd: (cartRx.order as Black).logoAdd ?? false,
    type: PurchaseType.Black,
    blank: (cartRx.order as Black).blank ?? false,
    color: (cartRx.order as Black).color ?? "",
    company: (cartRx.order as Black).company ?? "",
    extra: (cartRx.order as Black).extra ?? 0,
    fullName: (cartRx.order as Black).fullName ?? "",
    logo: (cartRx.order as Black).logo ?? ['', '', ''],
  } as Black);
  const [errors, setErrors] = useState({
    fullname: "",
    company: "",
    color: "",
    logo: ''
  });
  const total = useSelector((state: any) =>
  {
    return state.cart.subtotal;
  });
  const dispatch = useDispatch();
  const storage = useStorage();
  async function uploadLogo(event: ChangeEvent<HTMLInputElement>)
  {
    const file = event.target.files?.[0];
    let upUrl = '';
    if (
      file &&
      (file.type.toLocaleLowerCase() === "image/png" ||
        file.type.toLocaleLowerCase() === "image/jpeg" ||
        file.type.toLocaleLowerCase() === "image/jpg" ||
        file.type.toLocaleLowerCase() === "image/svg+xml")
    )
    {
      const storageRef = ref(storage, `/files/${uuidv4()}.${file.name.split('.').pop()}`);
      await uploadBytes(storageRef, file, { contentType: file.type }).then(async (snapshot) =>
      {
        await getDownloadURL(snapshot.ref).then((url) =>
        {
          upUrl = url;
        })
      });
      setUploadLater(false);
      event.target.value = "";
    }
    return upUrl
  }
  function validateFields()
  {
    const newError = { fullname: "", company: "", color: "", logo: '' };
    let valid = true;
    if (!order.blank)
    {
      if (!order.fullName.trim())
      {
        newError.fullname = "Please provide a Full Name for the card";
        valid = false;
      }

      if (order.logo.filter((e, i) => e.length === 0).length === 3 && !uploadLater && order.logoAdd)
      {
        newError.logo = "Please provide at least one logo or choose to Upload Later";
        valid = false;
      }
    }
    if (order.color === Colors.White)
    {
      newError.color = "The color you picked is currently unavailable";
      valid = false;
    }
    if (order.color === "")
    {
      newError.color = "Please choose a print color for the card.";
      valid = false;
    }
    setErrors(newError);
    return valid;
  }
  function handleSubmit()
  {

    if (validateFields())
    {
      setlocalOrder({ ...order, valid: true, submit: true });
    } else
    {
      setlocalOrder({ ...order, valid: false, submit: false });
    }
  }
  const handleColorChange = (color: string) =>
  {
    const newError = { fullname: "", company: "", color: "", logo: '' };

    const isSelectable = color !== ColorLabels.White;

    if (!isSelectable)
    {

      newError.color = "The color you picked is currently unavailable";
    }
    setlocalOrder({ ...order, color: labelToColor(color) });
    setErrors(newError);
  };

  useEffect(() =>
  {
    if (loading)
    {
      window.scrollTo(0, 0);
      if (order.valid !== undefined && !order.valid)
      { validateFields() }
    }
  }, []);

  useEffect(() =>
  {
    if (order.valid && order.submit)
    {
      navigate("/cart");
    }
  }, [order.valid, order.submit])

  useEffect(() =>
  {
    if (order.valid && !order.submit)
    {
      dispatch(setOrder({ ...order, valid: false }))
      dispatch(getSubTotal(currency))
    } else
    {
      dispatch(setOrder({ ...order }))
      dispatch(getSubTotal(currency))
    }
    setFirst(false)
  }, [order, currency])


  useEffect(() =>
  {
    if (window.innerWidth < 1024)
    {
      if (name.current?.offsetWidth! >= 220 && name.current?.offsetHeight === 34)
      {
        name.current?.classList.add('h-[68px]')
        name.current?.classList.remove('h-[34px]')
      }
      if (name.current?.offsetWidth! < 220 && name.current?.offsetHeight === 68)
      {
        name.current?.classList.remove('h-[60px]')
      }
    } else
    {
      if (name.current?.offsetWidth! >= 423 && name.current?.offsetHeight === 76)
      {
        name.current?.classList.add('lg:h-[152px]')
        name.current?.classList.add('h-[34px]')
        name.current?.classList.remove('lg:h-[76px]')
        name.current?.classList.remove('h-[34px]')
      }
      if (name.current?.offsetWidth! < 423 && name.current?.offsetHeight === 152)
      {
        name.current?.classList.remove('lg:h-[152px]')
        name.current?.classList.remove('h-[34px]')
      }
      /*if (name.current?.offsetWidth! >= 423 && name.current?.offsetHeight === 152)
      {
        name.current.classList.add('lg:text-[42px]/[76px]')
        name.current.classList.remove('lg:text-[56px]/[76px]')
      }*/

    }
  }, [name.current?.innerText])

  useEffect(() =>
  {
    if (window.innerWidth < 1024)
    {
      if (name.current?.offsetHeight === 60)
      {
        company.current?.classList.add('max-w-[180px]')
        company.current?.classList.remove('max-w-[205px]')
      }
      if (company.current?.offsetWidth! >= 205 && company.current?.offsetHeight! > 30)
      {
        company.current?.classList.add('max-w-[180px]')
        company.current?.classList.remove('max-w-[205px]')
      }
    } else
    {

      if (name.current?.offsetHeight === 152)
      {
        company.current?.classList.add('max-w-[344px]')
        company.current?.classList.remove('max-w-[388px]')
      }
      if (company.current?.offsetWidth! >= 388 && company.current?.offsetHeight! > 45)
      {
        company.current?.classList.add('max-w-[344px]')
        company.current?.classList.remove('max-w-[388px]')
      }
    }
  }, [name.current?.innerText, company.current?.innerText])



  return (<>
    <Cart onClick={() => handleSubmit()} />
    <div className="overflow-hidden">
      <Navbar />

      <div className="flex lg:flex-row flex-col justify-between items-center lg:px-[80px] px-[25px] lg:mb-[62px] mb-[72px]">
        <div className="flex flex-col relative lg:mr-[20px] lg:w-auto w-full">


          <div className="relative pt-[10px]">
            <h1 className=" inline-block font-custom font-black lg:text-[64px]/[86px] text-[30px]/[41px] lg:mb-[34px]">
              Black Card
            </h1>
            <div className="bg-primary relative lg:w-[150%] w-[125%] h-[37px] z-[-1] lg:mt-[-70px] mt-[-18px] lg:mb-[37px] mb-[28px]" />
            <p className="font-custom font-light lg:text-[20px]/[32px] text-[14px]/[25px] lg:px-0 lg:w-[310px] tracking-[0.05em] mt-0 lg:mr-0 mr-[-5px] lg:mb-[48px] mb-[47px]">
              Upgrade your networking game with bcX BLACK and experience the
              convenience and efficiency of a digital business card.
            </p>
            <div className="flex gap-[42px] lg:mb-[31px] mb-[46px]">
              <div className="flex w-[100px]">
                <p className="font-custom font-normal text-[36px]/[49px] ">
                  ${75}
                </p>
                <p className="font-custom font-light text-[18px]/[25px] lg:mr-[119px] flex self-start pt-[5px] pl-[6px]">
                  {" "}
                  {currency}
                </p>
              </div>
              <button
                className="w-[201px] font-custom font-medium text-white  lg:text-[20px]/[27px] text-[16px]/[25px] text-center bg-button hover:bg-inset rounded-[33px] lg:mr-0  lg:pt-[12px] pt-[12px] lg:pb-[14px] pb-[14px]"
                onClick={() =>
                {
                  dispatch(setOrder({ valid: false } as Black))
                  dispatch(getSubTotal(currency))
                  navigate('/shop')
                }}
              >
                Remove from Cart
              </button>
            </div>

            <div className="relative lg:mb-[18px] mb-[33px] lg:ml-[-80px] ml-[-25px]">
              <img className="-z-10 lg:block hidden" src={square} alt="" />
              <img className="-z-10  lg:hidden block" src={sm_square} alt="" />
              <h3 className="absolute font-custom font-normal text-[36px]/[49px] z-10 bottom-[11px] lg:ml-[82px] ml-[25px]">
                Add-Ons
              </h3>
            </div>
            <h4 className="font-custom font-normal text-text text-[25px]/[34px] mb-[21px] m-0">
              Extra Cards
            </h4>
            <p className="lg:block hidden font-custom font-light lg:text-[20px]/[32px] lg:w-[258px] tracking-[0.05em] mb-[17px] ">
              Want to add extra cards to your purchase?
            </p>
            <p className="lg:hidden block font-custom font-light  text-[18px]/[25px] w-[223px] tracking-[0.05em] mb-[20px] ">
              Want to add extra cards into your purchase?
            </p>
            <div className="hidden lg:flex items-start justify-start flex-shrink-0  lg:mt-[17px] mt-[15px] lg:ml-[-26px] lg:mb-[41px]">
              <img
                className="xl:max-w-[100%] lg:max-w-[75%] h-auto"
                src={extra_cards}
                alt="stack of 3 bcx cards"
              />
            </div>
            <div className="flex lg:flex-row lg:justify-between flex-col lg:mb-[52px] mb-[23px]">
              <div className="flex">
                <p className="font-custom font-normal text-[36px]/[49px]">
                  $40
                </p>
                <p className="font-custom font-light text-[18px]/[25px]  flex self-start pt-[5px] pl-[6px]">
                  {currency}
                </p>
                <p className="font-custom font-light lg:text-[18px]/[25px] text-[20px]/[32px] tracking-[0.05em] flex self-center pt-[5px] pl-[3px]">
                  /card
                </p>
              </div>
              <Incrementer
                minimum={0}
                value={order.extra}
                classname="min-[400px]:ml-[169px] min-[400px]:self-start self-end"
                onDecrement={() =>
                {
                  setlocalOrder({ ...order, extra: order.extra - 1 });
                }}
                onIncrement={() =>
                {
                  setlocalOrder({ ...order, extra: order.extra + 1 });
                }}
              />
            </div>
            <h4 className="lg:hidden block font-custom font-normal text-[36px]/[49px] mb-[24px]">
              What Is On The Card
            </h4>
            <div className="lg:block hidden">
              <h4 className="font-custom font-normal text-[35px]/[48px] lg:mb-[18px]">
                Already Have a Card?
              </h4>
              <button
                className="font-custom font-medium text-button shadow-custom lg:text-[20px]/[27px] text-[16px]/[25px] text-center  bg-primary hover:bg-secondary rounded-[33px] px-[111px] pt-[12px] pb-[14px]"
                onClick={() =>
                {
                  navigate("/login");
                }}
              >
                Sign In Here
              </button>
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-start lg:w-auto w-full lg:pt-[162px] ">
          {/*CARD */}
          <div className="lg:mb-[35px] mb-[31px] lg:w-[618px] w-[350px] lg:h-[353px] h-[200px]">
            <div className="flex flex-col w-full h-full ">
              <div className="relative w-full h-full xl:max-w-full lg:max-w-[80%] max-w-[89%] ">
                <img
                  className="absolute z-0"
                  src={(order.color === Colors.Silver ? mock_card_silver : (order.color === Colors.Gold ? mock_card_gold : mock_card))}
                  alt="preview of the resulting bcx card"
                />
                <div className="absolute z-50 top-0 left-0 flex flex-col lg:gap-[16px] gap-[5px] lg:ml-[43px] ml-[23px] lg:mt-[31px] mt-[18px]">
                  <div className={"flex flex-row w-full lg:pr-[152px]  items-start  "}>
                    <div ref={name} className={(order.color === Colors.Silver ? 'silver-gradient' : '') + (order.color === Colors.Gold ? 'gold-gradient' : '') + " lg:h-[76px] h-[34px] lg:max-w-[423px] max-w-[220px] overflow-hidden lg:text-[56px]/[76px] text-[28px]/[34px] custom-div font-custom font-medium  break-words text-white "}>
                      {order.blank ? "" : (order.fullName || order.company) ? order.fullName : "John Doe"}
                    </div>
                  </div>
                  <div className={" flex flex-row w-full lg:pr-[231px]  items-start"}>
                    <div ref={company} className={(order.color === Colors.Silver ? 'silver-gradient' : '') + (order.color === Colors.Gold ? 'gold-gradient' : '') + " h-fit lg:max-w-[388px] max-w-[205px] overflow-hidden custom-div font-custom font-normal break-words text-white lg:text-[33px]/[45px] text-[16px]/[30px] "}>
                      {order.blank ? "" : (order.fullName || order.company) ? order.company : "LogoPark"}
                    </div>
                  </div>

                </div>

                <img
                  className={
                    (order.company.length > 0 ||
                      order.fullName.length > 0 ||
                      order.blank
                      ? "hidden"
                      : "lg:block hidden") +
                    "  absolute bottom-[10%]  left-[65%]  w-[160px]  h-auto"
                  }
                  src={(order.color === Colors.Silver ? logopark_big_silver : (order.color === Colors.Gold ? logopark_big_gold : logopark_big))}
                  alt=""
                />
                <img
                  className={
                    (order.company.length > 0 ||
                      order.fullName.length > 0 ||
                      order.blank
                      ? "hidden"
                      : "lg:hidden block") +
                    "  absolute bottom-0 right-0 lg:mr-[25px] mr-[20px] lg:mb-[23px] mb-[47px]  w-[35px] h-auto"
                  }
                  src={(order.color === Colors.Silver ? logopark_silver : (order.color === Colors.Gold ? logopark_gold : logopark))}
                  alt=""
                />
                <img
                  className={
                    ((order.company.length > 0 || order.fullName.length > 0) && order.logoAdd ? order.blank ? "hidden" : "" : "hidden") + " z-50 absolute lg:bottom-[10%] lg:right-0 lg:left-[72%] bottom-[20%] right-[8%]  lg:w-[125px] w-[60px] h-auto"
                  }
                  src={(order.color === Colors.Silver ? your_logo_here_silver : (order.color === Colors.Gold ? your_logo_here_gold : your_logo_here))}
                  alt=""
                />
              </div>
            </div>




          </div>
          <CTextField
            maxlength={30}
            error={errors.fullname}
            value={order.fullName}
            label="Full Name"
            w="lg:w-[618px] w-[312px]"
            classname="lg:mb-[24px] mb-[31px]"
            onChange={(a) => setlocalOrder({ ...order, fullName: a })}
          />
          <CTextField
            error={errors.company}
            value={order.company}
            maxlength={30}
            w="lg:w-[618px] w-[312px]"
            label="Company"
            classname="lg:mb-[33px] mb-[29px]"
            onChange={(a) => setlocalOrder({ ...order, company: a })}
          />
          <CCheckBox
            id="blank"
            label="Want a blank card?"
            classname="lg:mb-[26px] mb-[21px]"
            checked={order.blank}
            onCheck={(a: boolean) => setlocalOrder({ ...order, blank: a })}
          />
          <h3 className="font-custom font-medium lg:text-[39px]/[53px] text-[30px]/[41px] lg:mb-[13px] mb-[25px]">
            Upload Your Logo Here
          </h3>
          <div>
            <div className="flex justify-between mb-[23px] lg:w-auto w-full">
              <div className="flex flex-col w-full">
                <div className="flex flex-row lg:gap-[34px]">
                  <div className="flex mb-[11px]">
                    <p className="font-custom font-normal text-[36px]/[49px]">
                      +$65
                    </p>
                    <p className="font-custom font-light text-[18px]/[25px] pt-[5px] pl-[6px] ">
                      {currency}
                    </p>
                  </div>

                  <div>
                    <button
                      className={
                        (order.logoAdd
                          ? "bg-button hover:bg-inset shadow-custom text-white"
                          : "shadow-custom bg-primary hover:bg-secondary text-button") +
                        " w-[201px] lg:block hidden font-custom font-medium lg:text-[20px]/[27px] text-[16px]/[25px] text-center  rounded-[33px]  pt-[12px] pb-[14px]"
                      }
                      onClick={() =>
                      {
                        if (!order.logoAdd)
                        {
                          setlocalOrder({ ...order, logoAdd: !order.logoAdd });
                        } else
                        {
                          setlocalOrder({ ...order, logoAdd: !order.logoAdd, logo: ['', '', ''] });
                          setUploadLater(false);
                        }
                      }}
                    >
                      {order.logoAdd ? "Remove from Cart" : "Add to Cart"}
                    </button>
                  </div>
                </div>

                <p className="lg:w-[545px] w-[335px] mb-[25px] font-custom font-light lg:text-[20px]/[32px] text-[14px]/[25px] tracking-[0.05em]">
                  Upload 1-3 versions of your logo that you'd like to see mocked-up. We will add our custom, sleek, monotone filter to them and send them back to you for approval.
                </p>
                <div className="self-start flex flex-row lg:gap-[17px]  items-center lg:justify-start sm:justify-center justify-between flex-shrink-0 ">
                  {order.logo.map((e, i) => (

                    <div className="relative">
                      <button onClick={() => { if (!loadingLogo[i]) { setlocalOrder({ ...order, logo: [...order.logo.slice(0, i), '', ...order.logo.slice(i + 1)] }) } }} className={(order.logo[i] !== '' ? 'flex' : 'hidden') + " absolute z-50 right-[-15px] top-[-30px] rotate-45 text-red-500 p-[3px] font-custom font-medium text-[25px]"}>
                        +
                      </button>
                      <label htmlFor={"logoInput_" + i}>
                        <img
                          className={(loadingLogo[i] ? 'hidden' : 'block') + ((uploadLater || order.blank) ? ' opacity-50 cursor-default' : ' opacity-100 cursor-pointer') + " lg:w-[117px] w-[101px] lg:h-[117px] h-[101px] object-cover"}
                          src={
                            (order.logo[i] && order.logo.length > 0) ? order.logo[i] : card_logo
                          }
                        />
                        <div className={(loadingLogo[i] ? 'block' : 'hidden') + ' flex flex-col justify-center items-center lg:w-[117px] w-[101px] lg:h-[117px] h-[101px]'}>
                          <CircularProgress color="inherit" />
                        </div>
                        <input
                          disabled={uploadLater || order.blank}
                          id={"logoInput_" + i}
                          key={"logoInput_" + i}
                          type="file"
                          accept="image/png, image/jpeg, image/jpg, .svg, image/JPEG"
                          className="hidden"
                          onChange={async (a) => { setLoadingLogo([...loadingLogo.slice(0, i), true, ...loadingLogo.slice(i + 1)]); setlocalOrder({ ...order, logoAdd: true, logo: [...order.logo.slice(0, i), await uploadLogo(a).then((a) => { setLoadingLogo([...loadingLogo.slice(0, i), false, ...loadingLogo.slice(i + 1)]); return a; }), ...order.logo.slice(i + 1)] }) }}
                        />
                      </label>
                    </div>
                  ))}
                </div>
                <div className={(errors.logo ? 'block ' : 'hidden ') + ' pt-[10px]  rounded-[6px] overflow-clip'}>
                  <Alert style={{ fontFamily: 'Satoshi-Variable', fontWeight: '300', fontSize: '15px', lineHeight: '20px' }} severity="warning">{errors.logo}</Alert>
                </div>
              </div>
            </div>
          </div>

          <div className="lg:hidden flex flex-col gap-[26px] mb-[33px]">
            <button
              className={
                (order.logoAdd
                  ? "bg-button shadow-custom text-white"
                  : "shadow-custom bg-primary text-button") +
                " flex-grow font-custom font-medium text-[16px]/[22px] text-center  w-[333px] rounded-[33px]  pt-[12px] pb-[14px]"
              }
              onClick={() =>
              {

                setlocalOrder({ ...order, logoAdd: !order.logoAdd });

              }}
            >
              {order.logoAdd ? "Remove from Cart" : "Add to Cart"}
            </button>

            <label
              htmlFor="logoFromPics"
              className={((order.logo.findIndex((e, i) => e.length === 0) === -1 || uploadLater || order.blank) ? 'cursor-default opacity-50' : 'cursor-pointer opacity-100') + "  w-[333px] shadow-custom bg-primary hover:bg-secondary flex-grow font-custom font-medium text-[16px]/[22px] text-center rounded-[33px] pt-[12px] pb-[14px]"}
            >
              Upload from Photos
              <input
                disabled={(order.logo.findIndex((e, i) => e.length === 0) === -1 || uploadLater || order.blank)}
                id="logoFromPics"
                type="file"
                accept="image/png, image/jpeg, image/jpg, .svg"
                className="hidden"
                onChange={async (a) =>
                {
                  let idx = order.logo.findIndex((e, i) => e.length === 0);
                  if (idx !== -1)
                  {
                    setLoadingLogo([...loadingLogo.slice(0, idx), true, ...loadingLogo.slice(idx + 1)]); setlocalOrder({ ...order, logoAdd: true, logo: [...order.logo.slice(0, idx), await uploadLogo(a).then((a) => { setLoadingLogo([...loadingLogo.slice(0, idx), false, ...loadingLogo.slice(idx + 1)]); return a; }), ...order.logo.slice(idx + 1)] })
                  }
                }}
              />
            </label>
            <button
              disabled={order.blank}
              className={(uploadLater ?
                "shadow-custom  bg-inset text-white" :
                "shadow-custom bg-text  text-white") + (order.blank ? ' opacity-50 ' : '  opacity-100 ') + "  w-[333px] font-custom font-medium  text-[16px]/[22px] text-center bg-button rounded-[33px]  pt-[12px] pb-[14px]"}
              onClick={() =>
              {
                setUploadLater(!uploadLater);
                if (!uploadLater)
                { setlocalOrder({ ...order, logoAdd: true, logo: ['', '', ''] }) };
              }}
            >
              Upload Later
            </button>
          </div>

          <div className="lg:flex hidden justify-between mb-[44px]">
            <label
              htmlFor="logoFromPics"
              className={((order.logo.findIndex((e, i) => e.length === 0) === -1 || uploadLater || order.blank) ? 'cursor-default opacity-50 ' : 'hover:bg-secondary cursor-pointer opacity-100') + " shadow-custom bg-primary  lg:w-[270px] w-[343px]  font-custom font-medium text-button lg:text-[20px]/[27px] text-[16px]/[22px] text-center rounded-[33px] pt-[12px] pb-[14px]"}
            >
              Upload from Photos
              <input
                disabled={(order.logo.findIndex((e, i) => e.length === 0) === -1 || uploadLater || order.blank)}
                id="logoFromPics"
                type="file"
                accept="image/png, image/jpeg, image/jpg, .svg"
                className="hidden"
                onChange={async (a) =>
                {
                  let idx = order.logo.findIndex((e, i) => e.length === 0);
                  if (idx !== -1)
                  {
                    setLoadingLogo([...loadingLogo.slice(0, idx), true, ...loadingLogo.slice(idx + 1)]); setlocalOrder({ ...order, logoAdd: true, logo: [...order.logo.slice(0, idx), await uploadLogo(a).then((a) => { setLoadingLogo([...loadingLogo.slice(0, idx), false, ...loadingLogo.slice(idx + 1)]); return a; }), ...order.logo.slice(idx + 1)] })
                  }
                }}
              />
            </label>

            <button
              disabled={order.blank}
              className={
                (uploadLater
                  ? "shadow-inset bg-inset hover:bg-button "
                  : "shadow-custom bg-button  ") + (order.blank ? ' opacity-50 ' : '  opacity-100 hover:bg-inset') +
                " text-white lg:w-[270px] w-[343px] font-custom font-medium  lg:text-[20px]/[27px] text-[16px]/[22px] text-center rounded-[33px] pt-[12px] pb-[14px]"
              }
              onClick={() =>
              {
                setUploadLater(!uploadLater);
                if (!uploadLater)
                {
                  setlocalOrder({ ...order, logo: ['', '', ''], logoAdd: true })
                }

                ;
              }}
            >
              {uploadLater ? 'Upload Now' : 'Upload Later'}
            </button>
          </div>
          <div>
            <h3 className="font-custom font-medium  lg:text-[39px]/[53px] text-[30px]/[41px] lg:mb-[23px] mb-[25px]">
              Which Colour Of Print?
            </h3>
            <CSelect
              id="color"
              classname="lg:mb-[50px] mb-[43px] lg:w-[618px] w-[343px]"
              errorclass="mt-[-40px] mb-[10px] lg:w-[618px] w-[343px]"
              error={errors.color}
              hint="Please Choose One"
              required
              items={[
                { item: ColorLabels.White, class: "bg-gray-700 line-through", active: false },
                { item: ColorLabels.Silver, class: "", active: true },
                { item: ColorLabels.Gold, class: "", active: true },
              ]}
              value={colorToLabel(order.color, currency)}
              onChange={(a) =>
                handleColorChange(a)
              }
            />
          </div>
          <div className="flex flex-col justify-end">
            <div className="flex flex-col items-end lg:w-auto w-[340px]">
              <button
                id="go-to-cart"
                className="flex font-custom items-center justify-center font-medium text-white shadow-custom lg:text-[24px]/[32px] text-[16px]/[25px] text-center hover:bg-inset bg-button rounded-[42px] lg:px-[37px] lg:pt-[25px] pt-[18px] lg:pb-[23px] pb-[16px] lg:mb-0 mb-[25px] lg:w-fit w-full"
                onClick={(e) =>
                {
                  handleSubmit();
                }}
              >
                <img alt="" src={cart} className="mr-[20px]"></img>
                <div className="mr-[15px]">Go To Cart</div>
              </button>
              <div
                className={
                  (!(
                    errors.fullname === "" &&
                    errors.color === "" &&
                    errors.company === ""
                  )
                    ? "block "
                    : "hidden ") +
                  "lg:px-[37px]  lg:pt-[25px] lg:pb-[23px] pb-[18px] rounded-[6px] lg:w-fit w-full"
                }
              >
                <Alert
                  style={{
                    fontFamily: "Satoshi-Variable",
                    fontWeight: "300",
                    fontSize: "15px",
                    lineHeight: "20px",
                  }}
                  severity="warning"
                >
                  Missing Information
                </Alert>
              </div>
            </div>
          </div>
          <div className="lg:hidden flex flex-col gap-[21px]  justify-start">
            <h4 className="font-custom font-normal text-[35px]/[48px] ">
              Already Have a Card?
            </h4>
            <button
              className=" w-[333px] flex-grow  font-custom font-medium text-button shadow-custom text-[16px]/[22px] text-center bg-primary rounded-[33px] py-[12px] "
              onClick={() =>
              {
                navigate("/login");
              }}
            >
              Sign In Here
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </>
  );
};
