import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { styled } from "@mui/material/styles";
import { ChangeEvent, ReactNode } from "react";

interface CRadioProps
{
    label: string,
    value: string,
    classname?: string,
    children?: ReactNode
    checked?: boolean,
    textcolor?: string,
    fontsize?: string,
    fontheight?: string,
    fontweight?: string,
    onCheck?: CallableFunction
    labelwidth?: string,
    height?: string,
    flex?: string,
}


const Label = styled(FormControlLabel, { shouldForwardProp: (prop) => prop !== 'props' })<{ props: CRadioProps }>
    (({ props }) => `
& .MuiTouchRipple-child{
    background-color: #252529;
}
& .MuiFormControlLabel-label{
    color: ${props.textcolor ?? '#252529'};
    font-family: 'Satoshi-Variable';
    font-size: ${props.fontsize ?? '16px'};
    line-height: ${props.fontheight ?? '20px'};
    font-weight: ${props.fontweight ?? '600'};
    letter-spacing: 0.05em;
    width: ${props.labelwidth ?? ''};
}

& .MuiSvgIcon-root
    {
    color: #000;

    }
`);

const CRadio = ({ ...props }: CRadioProps) =>
{
    const oncheck = (event: ChangeEvent<HTMLInputElement>) =>
    {
        if (props.onCheck)
        {
            props.onCheck(props.value);
        }
    }

    return <div className="flex flex-grow h-full">
        <div className={props.height + " relative flex-grow  justify-start  border-[1px] rounded-[4px] " + (props.checked ? " border-radio" : "border-gray-400")}>
            <div className={" absolute h-full w-full top-0   flex-grow " + (props.checked ? " bg-radio opacity-10" : "  ")} />
            <div className="relative top-0 opacity-100  ">
                <div className=" top-0 ">
                    <div className={props.flex + " flex justify-start items-start"}>
                        <div className="mt-[12px] ml-[18px]">
                            <Label
                                props={props}
                                value={props.value}
                                label={props.label}
                                control={
                                    <Radio
                                        onChange={oncheck}
                                        sx={{
                                            padding: '0',
                                            paddingLeft: '11px',
                                            paddingRight: '8px',
                                        }}
                                        name={props.label}
                                    />}
                            />
                        </div>
                        <div className="flex-col flex w-full pt-[15px] pb-[19px] pl-[24px] pr-[16px]">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default CRadio;