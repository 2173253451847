import { DocumentData, DocumentReference, collection, deleteDoc, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import bcx_icon from "../../assets/images/bcx-icon.svg";
import menu from "../../assets/images/hamburger.svg";
import menu_black from "../../assets/images/hamburger-black.svg";
import { auth1, db } from "../../services/firebase";
import { ContactDoc, EliteCompany, User } from "../../types";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Language, lang } from "../../languages";
import CTextField from "../../components/CTextField";
import { LanguageSelector } from "../../components/LanguageSelector";
import { setLanguage } from "../../redux/feature/languageSlice";
import { ContactCard } from "../../components/ContactCard";
import { CircularProgress } from "@mui/material";
import { saveContact as saveVCF } from '../../utils/utils'
import { ConfirmationPopUp } from "../../components/ConfirmationPopUp";
import { LoadingPopup } from "../../components/LoadingPopup";
import { ProfileMenu } from "../../components/ProfileMenu";
export const ContactList = () =>
{
    const navigate = useNavigate();
    const [menuToggle, setMenuToggle] = useState(true);
    const [user, setUser] = useState<User>({} as User);
    const [contacts, setContacts] = useState<ContactDoc[]>([]);
    const [searchItems, setSearchItems] = useState<ContactDoc[]>([]);
    const [search, setSearch] = useState<String>('');
    const [eliteCompanyDoc, setEliteCompanyDoc] = useState<EliteCompany>();
    const [loading, setLoading] = useState(true);
    const language: Language = useSelector((state: any) => state.language);
    const [languageSelection, setLanguageSelection] = useState<Language | null>(null);
    const dispatch = useDispatch();
    const [confirmation, setConfirmation] = useState({ firstName: '', lastName: '', ref: {} as DocumentReference<DocumentData>, open: false });
    const [loadingContact, setLoadingContact] = useState(false);


    async function getUser(
        callbackFunction: Function,
        eliteCallback: Function
    )
    {
        auth1.onAuthStateChanged(async (user) =>
        {
            let userDoc: DocumentData | undefined = undefined;
            if (user)
            {
                const authUserRef = doc(db, "users", auth1.currentUser?.uid!);
                userDoc = (await getDoc(authUserRef)).data();
                const q = query(
                    collection(db, "contacts"),
                    where("user", "==", authUserRef))
                const querySnapshot = await getDocs(q);
                let data = querySnapshot.docs.map((e, i) => { return { ...e.data(), ref: e.ref } as ContactDoc });
                data = data.sort((a, b) => { return a.date.toMillis() > b.date.toMillis() ? 1 : -1; });
                setContacts(data as ContactDoc[]);
                const userTyped = userDoc as User;
                if (userTyped.eliteCompany !== undefined)
                {
                    await eliteCallback(userTyped.eliteCompany);
                }
                await callbackFunction(userDoc as User);
            }
        });
    }
    const setStateUser = (user: User) =>
    {
        setUser(user);
        setLanguageSelection(user.language ?? Language.ENG);
        setLoading(false);
    };

    const setStateElite = async (data: DocumentReference) =>
    {
        const eliteCompanyDocs = (await getDoc(data)).data();
        const eliteCompany = eliteCompanyDocs as EliteCompany;
        setEliteCompanyDoc(eliteCompany);
        setLoading(false);
    };
    async function updateLanguage(lang: Language)
    {
        const userRef = doc(db, "users", auth1.currentUser?.uid!);
        const userDoc = (await getDoc(userRef)).data() as User;
        if (userDoc.orderNumber === user.orderNumber)
        {
            await updateDoc(userRef!, { language: lang });
        }
    }

    async function deleteContact(ref: DocumentReference)
    {
        setContacts(contacts.filter((e) => { return e.ref !== ref }));
        await deleteDoc(ref);
    }
    async function saveContact(contact: ContactDoc, i: number)
    {
        await updateDoc(contact.ref!, { saved: true });
        setContacts([...contacts.slice(0, i), { ...contact, saved: true }, ...contacts.slice(i + 1)])
        await saveVCF({
            name: contact.firstName,
            lastName: contact.lastName,
            email: contact.email ? [contact.email] : undefined,
            phone: contact.phone ? [contact.phone] : undefined
        } as User);
    }

    useEffect(() =>
    {
        window.scrollTo(0, 0);
        const getData = async () =>
        {
            await getUser(
                setStateUser,
                setStateElite
            );
        };
        getData();
    }, []);

    useEffect(() =>
    {
        let result = contacts.filter((e, i) =>
        {
            let searchWords = search.toLowerCase();
            return (e.firstName.toLowerCase().includes(searchWords) ||
                e.lastName.toLowerCase().includes(searchWords) ||
                `${e.firstName.toLowerCase()} ${e.lastName.toLowerCase()}`.includes(searchWords) ||
                (e.email ?? '').toLowerCase().includes(searchWords) ||
                (e.phone ? (e.phone.number).toLowerCase().includes(searchWords) : false))
        });
        setSearchItems(result);

    }, [search, contacts])

    useEffect(() =>
    {
        if (languageSelection)
        {
            dispatch(setLanguage(languageSelection!));
            updateLanguage(languageSelection!);
        }
    }, [dispatch, languageSelection])

    if (loading)
    {
        return (
            <div className="overflow-hidden flex items-center justify-center h-screen relative w-[100vw] ">
                <div className=" relative">
                    <CircularProgress color="inherit" />
                </div>
            </div>
        );
    }

    return <>
        <div className="z-0 relative flex flex-col items-center w-full min-h-screen bg-ofwhite ">
            <header className=" z-[5] fixed top-0 lg:w-[393px] w-full flex flex-col bg-offwhite ">
                <div className="flex flex-row items-center justify-center bg-text w-full "
                    style={{
                        backgroundColor: eliteCompanyDoc?.useCustomColors
                            ? "#FFFFFF"
                            : "#161617",
                    }}
                >
                    <div className="flex-1"></div>
                    <img
                        className="mt-[46px] mb-[18px]  cursor-pointer max-w-[150px] max-h-[50px] object-cover"
                        src={
                            eliteCompanyDoc?.useCustomColors
                                ? eliteCompanyDoc?.logo[0]
                                : bcx_icon
                        }
                        onClick={() =>
                        {
                            navigate("/");
                        }}
                    />


                    <div className="flex-1">
                        <button
                            className={" block ml-auto self-start mt-[58px] lg:mr-[38px] mr-[29px] mb-[21px]"
                            }
                            onClick={() =>
                            {
                                setMenuToggle(!menuToggle);
                            }}
                        >
                            <img
                                alt="menu"
                                src={eliteCompanyDoc?.useCustomColors ? menu_black : menu}
                            />
                        </button>


                    </div>
                </div>
                <h3 className="w-full font-custom font-medium text-[30px] text-center mt-[20px] bg-white">{lang.contactList[language]}</h3>
            </header>

            <LoadingPopup
                isOpen={loadingContact}
                message={lang.loadingContact[language]} />

            <ConfirmationPopUp
                cancel={lang.cancel[language]}
                title={`${lang.deleteContactConf[language]} ${confirmation.firstName} ${confirmation.lastName} ${lang.asAContact[language]}`}
                confirm={lang.deleteContact[language]}
                isOpen={confirmation.open}
                onCancel={() => { setConfirmation({ ...confirmation, open: false }) }}
                onClose={() => { setConfirmation({ ...confirmation, open: false }) }}
                onConfirm={async () =>
                {
                    setConfirmation({ ...confirmation, open: false })
                    await deleteContact(confirmation.ref);
                }}
            />

            <div className=" flex-1 mt-[190px]  mb-[90px] min-h-screen ">
                {
                    <div className="relative flex flex-col justify-start h-full items-center gap-[18px] bg-white font-custom font-medium  text-button text-[25px] ">

                        {(search.length <= 0 ? contacts : searchItems).length > 0 &&
                            (search.length <= 0 ? contacts : searchItems).map((e, i) => (
                                <ContactCard
                                    onSave={async () =>
                                    {
                                        setLoadingContact(true)
                                        await new Promise(r => setTimeout(r, 1000));
                                        await saveContact(e, i)
                                        setLoadingContact(false)
                                    }}
                                    onDelete={(arg) => { setConfirmation({ firstName: arg.firstName, lastName: arg.lastName, ref: arg.ref, open: true }) }}
                                    customColor={eliteCompanyDoc ? eliteCompanyDoc.useCustomColors : false}
                                    language={language}
                                    key={"contact_" + i}
                                    contact={e}
                                    elite={eliteCompanyDoc?.useCustomColors ? eliteCompanyDoc : undefined}
                                />
                            ))}

                        {(search.length > 0 && searchItems.length <= 0 || contacts.length <= 0) && (
                            <h3 className="text-center mt-auto mb-auto">
                                {search.length > 0 ? lang.noMatches[language] : lang.noContacts[language]}
                            </h3>
                        )}
                    </div>

                }
            </div>

            <footer className="fixed bg-white bottom-0 w-full  flex flex-row justify-center pb-[20px] pt-[10px]">
                <CTextField
                    onChange={(a) => { setSearch(a) }}
                    label={lang.search[language]}
                    w="w-[360px]"
                    hint={lang.searchParams[language]}
                />
            </footer>
        </div>


        <ProfileMenu
            language={language}
            navigate={navigate}
            open={menuToggle}
            onOpen={() => { setMenuToggle(!menuToggle) }}
            setLanguageSelection={setLanguageSelection}
            user={user}
        />

    </>
}
