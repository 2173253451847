import { useRef } from "react";
import { useOutsideAlerter } from "./SocialDropDown";
import { CircularProgress } from "@mui/material";

interface LoadingPopUpProps
{
    isOpen: boolean
    message: String
}

export const LoadingPopup = ({ ...props }: LoadingPopUpProps) =>
{

    const element = useRef<HTMLDivElement>(null);
    useOutsideAlerter(element, () => { /*handleClose()*/ });



    return <div className={(props.isOpen ? 'block backdrop-blur-sm' : 'hidden') + " z-[100000000] fixed bottom-0 top-0 left-0 right-0 w-full h-[200hv] bg-opacity-50 bg-black"}>
        <div ref={element} className={"p-[34px] relative rounded-2xl md:w-[420px] w-[360px]  left-1/2 transform -translate-x-1/2 top-1/2 -translate-y-1/2 bg-white shadow-lg flex flex-col justify-between "}>

            <h2 className=" text-[24px] font-custom font-medium text-center mb-[27px]">
                {props.message}
            </h2>
            <div className="flex flex-row justify-center text-primary">
                <CircularProgress color="inherit" />
            </div>

        </div>
    </div>
}
