import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Language } from "../../languages";

const languageSlice = createSlice({
    name: "language",
    initialState: Language.ENG,
    reducers: {
        setLanguage: (state, action: PayloadAction<Language>) =>
        {
            state = action.payload;
            return state
        }
    }
})

export const {
    setLanguage
} = languageSlice.actions;

export default languageSlice.reducer;
