import { ChangeEvent, RefObject, useEffect, useRef, useState } from "react";
import { Alert } from "@mui/material";
import arrow from '../assets/images/arrow.svg';
import { getSocialIcon } from "./SocialsDynamic";


interface SocialDropDownProps
{
    classname?: string,
    items: { image: string, text: string, class: string }[],
    value?: string,
    label?: string,
    required?: boolean,
    hint?: string
    error?: string
    errorClass?: string
    onChange: CallableFunction & ((arg: string) => void),
}

export function useOutsideAlerter(ref: RefObject<HTMLDivElement>, outsideCallback: CallableFunction)
{
    useEffect(() =>
    {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: any)
        {
            if (ref.current && !ref.current!.contains(event.target))
            {
                outsideCallback()
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () =>
        {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

export const SocialDropDown = ({ ...props }: SocialDropDownProps) =>
{
    const [open, setOpen] = useState(false);
    const select = useRef<HTMLDivElement>(null);
    useOutsideAlerter(select, () => { setOpen(false) });

    const handleOpen = () =>
    {
        setOpen(!open);
    };
    const handleKey = (event: any) =>
    {

        if (event.key === 'Enter')
        {
            handleOpen();
        }
    };


    return <div ref={select} className="w-full">
        <div className={props.classname ?? '' + ' flex flex-row flex-grow'}>
            <div onClick={handleOpen} onKeyDown={handleKey} tabIndex={0} className={" flex-grow flex flex-col justify-start font-custom font-light tracking-[0.05em]  border-button border-[2px] " + (open ? ' border-b-transparent shadow-none rounded-t-[3px]' : ' rounded-[3px] shadow-custom  ')}>
                <input type="select" className="hidden" onChange={() => { }} value={props.value} />
                <div className="relative">
                    {props.label ?
                        <div className="absolute top-0 left-0 ml-[21px] mt-[-10px] px-[4px] text-[10px]/[19px] bg-white">
                            {(props.label) + (props.required ? ' *' : '')}
                        </div> : ''
                    }
                </div>
                <div className={(props.value === '' ? 'block' : 'hidden') + " flex justify-between font-light w-full text-[20px]/[32px] px-[24px] pt-[11px] pb-[10px] select-none cursor-pointer"}>
                    {props.hint}
                    <img className={(open ? "rotate-180 duration-75" : 'rotate-0 duration-75') + ' w-[16px] h-[7px] self-center'} src={arrow} />
                </div>
                <div className={(props.value === '' ? 'hidden' : 'block') + " flex justify-between font-light  text-[20px]/[32px] px-[24px] pt-[11px] pb-[10px] select-none cursor-pointer"}>
                    <div className="flex flex-row gap-[22px]">
                        <img className="w-[30px] ml-[-8px]" src={getSocialIcon(props.value ?? '')} />
                        {props.value}
                    </div>
                    <img className={(open ? "rotate-180 duration-75" : 'rotate-0 duration-75') + ' w-[16px] h-[7px] self-center'} src={arrow} />
                </div>

                <div className="relative ">
                    <div className={(open ? 'block' : 'hidden') + " absolute z-[51] mt-[-2px] border-black border-b-[2px] w-full opacity-25"} />
                    <div className={"absolute inset-[-1.6px] absolute-offset z-50  "}>
                        <div className={open ? ' max-h-[200px] flex flex-col overflow-y-auto social-dropdown-scrollbar border-t-0 border-button border-[2px] shadow-custom rounded-b-[3px]' : 'hidden '}>
                            {props.items.map((element, i) =>
                            {
                                if (typeof element === 'string')
                                {
                                    return <div key={i} className=" basis-1/5 z-50 text-[16px]/[32px] bg-white">
                                        <div className=" border-black border-[1px] opacity-25 " />
                                        <div className=" py-[4px] px-[24px] cursor-pointer hover:bg-gray-200" onClick={(a) => { props.onChange(a.currentTarget.innerText); }}>{element}</div>
                                    </div>
                                } else
                                {
                                    return <div key={i} className=" basis-1/5 z-50 text-[16px]/[32px] bg-white">
                                        <div className=" border-black border-[1px] opacity-25 " />
                                        <div className="flex flex-row items-center w-full hover:bg-slate-200 cursor cursor-pointer" onClick={(a) => { props.onChange(element.text); }}>
                                            <div className="ml-[17px] w-[30px]"><img src={element.image} /></div>
                                            <div className={element.class + " py-[10px] px-[24px] cursor-pointer "} >{element.text}</div>
                                        </div>
                                    </div>
                                }
                            })}</div>
                    </div>
                </div>
            </div>
        </div>
        <div className={(props.error ? 'block ' : 'hidden ') + (props.errorClass ?? ' mt-[-28px]') + ' rounded-[6px] overflow-clip'}>
            <Alert style={{ fontFamily: 'Satoshi-Variable', fontWeight: '300', fontSize: '15px', lineHeight: '20px' }} severity="warning">{props.error}</Alert>
        </div>
        <div className={(open ? 'block' : 'hidden') + " pointer-events-none fixed top-0 left-0 w-screen h-screen "}>

        </div>
    </div >
}