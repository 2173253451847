import React from 'react'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import { useNavigate } from 'react-router-dom';

export const EmptyCart = () =>
{
    const navigate = useNavigate();
    return (
        <div className='overflow-hidden'>
            <Navbar />
            <div className='lg:mx-[80px] mx-[25px] lg:mt-[110px] mt-[74px]'>
                <h1 className=' inline-block font-custom font-black lg:text-[64px]/[86px] text-[30px]/[41px] lg:mb-[34px]'>
                    Cart
                </h1>
                <div className='bg-primary relative lg:w-[150%] w-[125%]  h-[37px] z-[-1]   lg:mt-[-70px] mt-[-18px] lg:mb-[34px] mb-[16px]' />
                <div>
                    <h3 className=' inline-block font-custom font-normal lg:text-[36px]/[49px] text-[30px]/[41px] lg:mb-[34px] mb-[28px]'>
                        Your Cart is empty.
                    </h3>
                    <div className='flex lg:justify-start justify-center items-center z-10 mb-[100px]'>
                        <button className='font-custom font-medium text-white shadow-custom lg:text-[19px]/[32px] text-[16px]/[25px] text-center tracking-[0.05em] hover:bg-inset bg-button rounded-[33px] lg:px-[71px] px-[69px] lg:pt-[16px] pt-[12px] lg:pb-[18px] pb-[14px]' onClick={() => { navigate('/shop'); }}>Go to Shop</button>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
