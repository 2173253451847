import { PaymentElement } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import security from "../../assets/images/payment-security.svg";
import CCheckBox from "../../components/CCheckBox";
import { Link } from "react-router-dom";

interface CheckoutProps
{
  onStripeFinishLoading: Function,
  onPaymentComplete: () => Promise<void>;
  totalAmount: number;
}

export default function CheckoutForm({ ...props }: CheckoutProps)
{
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [consent, setConsent] = useState(false);
  const [isStripeLoading, setIsStripLoading] = useState(true)

  useEffect(() =>
  {
    if (elements)
    {
      const element = elements.getElement('payment')
      element?.on('ready', () =>
      {
        props.onStripeFinishLoading();
      })
    }
  }, [elements])

  const handleSubmit = async (e: any) =>
  {
    e.preventDefault();

    if (!stripe || !elements)
    {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/confirmOrder`,
      },
      redirect: "if_required",
    });
    if (error === undefined)
    {
      await props.onPaymentComplete().then(() =>
      {
        setIsProcessing(false);
      });
      return;
    }

    if (error?.type === "card_error" || error?.type === "validation_error")
    {
      setMessage(error.message!);
    } else if (error)
    {
      setMessage("An unexpected error occured.");
    }
    setIsProcessing(false);
  };

  return (
    <div className="shadow-md border-1 border-borderColor rounded-[4px] pt-[14px] pb-[21px] px-[21px] mb-[25px] lg:mx-[24px] h-fit grow">
      <div className="font-custom font-bold text-button text-[16px]/[20px] mb-[29px]">
        Payment Method
      </div>
      <form

        onSubmit={handleSubmit}
      >
        <PaymentElement id="payment-element" />
        <div className="flex flex-row justify-start items-center mt-[20px]">
          <CCheckBox
            checked={consent}
            onCheck={(a: boolean) =>
            {
              setConsent(a);
            }}
            classname=""
            label=""
          />
          <div className="font-custom text-[14px]/[20px] font-normal text-check ml-[-15px]">
            Please check to acknowledge our{" "}
            <Link
              className="text-blue-500"
              to="/terms-of-use"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy & Terms Policy
            </Link>
          </div>
        </div>
        <button
          id="checkout-pay"
          disabled={isProcessing || !stripe || !elements || !consent}
          className={'disabled:cursor-default disabled:hover:bg-primaryDisabled disabled:bg-primaryDisabled bg-primary hover:bg-secondary mb-[23px] mt-[10px] font-custom font-bold text-button shadow-custom w-full text-[16px]/[20px] text-center rounded-[33px] pt-[12px] pb-[14px]'}
        >
          <span className="">
            {isProcessing
              ? "Processing ... "
              : `Pay $${(props.totalAmount / 100).toFixed(2)}`}
          </span>
        </button>

        <div className="flex flex-row items-center gap-[10px]">
          <img src={security} />
          <div className="font-custom font-normal text-gray-400 text-[12px]/[20px]">
            We protect your payment information using encryption to provide
            bank-level security.
          </div>
        </div>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>
    </div>
  );
}
