import React, { ReactNode, useEffect, useRef, useState } from "react";
import validator from "validator";
import { getFunctions, httpsCallable } from "firebase/functions";
import CTextField from "./CTextField";
import { CircularProgress } from "@mui/material";
import
{
  DocumentReference,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { auth1, db } from "../services/firebase";
import { EliteCompany, Email } from "../types";
import { useNavigate } from "react-router-dom";
import { generateRandomPassword, sendEmail } from "../utils/utils";
import ReactGA from 'react-ga4'

const functions = getFunctions();
const createUser = httpsCallable(functions, "createUser");

type ActivateCardModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  orderNumber: string;
  children: ReactNode;
  eliteCompanyRef: DocumentReference;
};

const ActivateCardModal: React.FC<ActivateCardModalProps> = ({
  isOpen,
  onClose,
  orderNumber,
  children,
  eliteCompanyRef,
  onConfirm,
}) =>
{
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [creating, setCreating] = useState(false);
  const [profileCreated, setProfileCreated] = useState(false);
  const [user, setUser] = useState<DocumentReference>();
  const [haveTheUserSetUpAccount, setHaveTheUserSetUpAccount] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const password = generateRandomPassword(12);
  const handleSubmit = async (event: React.FormEvent) =>
  {
    event.preventDefault();
    let newError = "";
    let valid = true;
    if (!email.trim() || !validator.isEmail(email.trim()))
    {
      newError = "Provide a valid Email";
      valid = false;
    }
    setEmailError(newError);
    if (valid)
    {
      setCreating(true);
      const data = {
        email: email,
        password: password,
        disabled: false,
      };
      try
      {
        // Check the response and display a message accordingly
        const querySnapshot = await getDocs(
          query(collection(db, "users"), where("email", "array-contains", email.trim()))
        );

        if (!querySnapshot.empty)
        {
          let userDocument = querySnapshot.docs[0].data()
          if (userDocument.orderNumber)
          {
            newError = "The user already exists and already has an order number attached to his account";
            valid = false;
            setEmailError(newError);
            setCreating(false);
            return;
          } else
          {
            const elite = await getDoc(eliteCompanyRef);
            await updateDoc(userDocument.ref, {
              eliteCompany: elite.ref,
              orderNumber: orderNumber,
              editingDisabled: true,
              email: [email],
              finishedCreating: false,
            });
            await updateDoc(elite.ref, {
              activeCards: arrayUnion(orderNumber),
            });
            await sendEmail({
              dest: email,
              fullName: "businesscardX",
              message: `You were invited to join businesscardX, click here to login to your new account https://businesscardx.com/Login <br>email:${email} <br>Temporary password:${password}`,
              sender: "",
              subject: "You were invited to join businesscardX",
            }, (res) => { },
              (res) =>
              {
                ReactGA.event('EmailError', {
                  name: 'ActivateCard',
                  mail: JSON.stringify({
                    dest: email,
                    fullName: "businesscardX",
                    message: `You were invited to join businesscardX, click here to login to your new account https://businesscardx.com/Login <br>email:${email} <br>Temporary password:${password}`,
                    sender: "",
                    subject: "You were invited to join businesscardX",
                  }),
                  response: JSON.stringify(res.statusText)
                })
              });

            await sendEmail({
              dest: auth1.currentUser?.email as string,
              fullName: "businesscardX",
              message: `You were invited to join businesscardX, click here to login to your new account https://businesscardx.com/Login <br>email:${email} <br>Temporary password:${password}`,
              sender: "",
              subject: "You were invited to join businesscardX",
            }, (res) =>
            {
              setUser(userDocument.ref);
              setProfileCreated(true);
              setCreating(false);
            }, (res) =>
            {
              ReactGA.event('EmailError', {
                name: 'ActivateCard',
                mail: JSON.stringify({
                  dest: auth1.currentUser?.email as string,
                  fullName: "businesscardX",
                  message: `You were invited to join businesscardX, click here to login to your new account https://businesscardx.com/Login <br>email:${email} <br>Temporary password:${password}`,
                  sender: "",
                  subject: "You were invited to join businesscardX",
                }),
                response: JSON.stringify(res.statusText)
              })
            });
            return;
          }

        } else
        {
          const result = await createUser(data);
          const user = result.data as any;
          if (
            result.data ===
            "The email address is already in use by another account."
          )
          {
            newError = "Email already in use";
            valid = false;
            setEmailError(newError);
            setCreating(false);
          } else
          {
            const elite = await getDoc(eliteCompanyRef);
            await updateDoc(elite.ref, {
              activeCards: arrayUnion(orderNumber),
            });

            const ref = doc(db, "users", user.response.uid);
            setUser(ref);
            await setDoc(ref, {
              orderNumber: orderNumber,
              email: [user.response.email],
              finishedCreating: false,
              eliteCompany: elite.ref,
              editingDisabled: true,
            })

            // Call sendEmail function twice for email chosen and auth user email
            await sendEmail({
              dest: email,
              fullName: "businesscardX",
              message: `You were invited to join businesscardX, click here to login to your new account https://businesscardx.com/Login <br>email:${email} <br>Temporary password:${password}`,
              sender: "",
              subject: "You were invited to join businesscardX",
            }, (res) => { }, (res) =>
            {
              ReactGA.event('EmailError', {
                name: 'ActivateCard',
                mail: JSON.stringify({
                  dest: email,
                  fullName: "businesscardX",
                  message: `You were invited to join businesscardX, click here to login to your new account https://businesscardx.com/Login <br>email:${email} <br>Temporary password:${password}`,
                  sender: "",
                  subject: "You were invited to join businesscardX",
                }),
                response: JSON.stringify(res.statusText)
              })
            });
            await sendEmail({
              dest: auth1.currentUser?.email as string,
              fullName: "businesscardX",
              message: `You were invited to join businesscardX, click here to login to your new account https://businesscardx.com/Login <br>email:${email} <br>Temporary password:${password}`,
              sender: "",
              subject: "You were invited to join businesscardX",
            }, () =>
            {
              setProfileCreated(true);
              setCreating(false);
            }, (res) =>
            {
              ReactGA.event('EmailError', {
                name: 'ActivateCard',
                mail: JSON.stringify({
                  dest: auth1.currentUser?.email as string,
                  fullName: "businesscardX",
                  message: `You were invited to join businesscardX, click here to login to your new account https://businesscardx.com/Login <br>email:${email} <br>Temporary password:${password}`,
                  sender: "",
                  subject: "You were invited to join businesscardX",
                }),
                response: JSON.stringify(res.statusText)
              })
            })
          }
        }
      } catch (error: any)
      {

      }
    }
  };

  useEffect(() =>
  {
    const handleEscape = (event: KeyboardEvent) =>
    {
      if (event.key === "Escape" && !creating)
      {
        onClose();
      }
    };

    const handleOutsideClick = (event: MouseEvent) =>
    {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node) &&
        !creating
      )
      {
        onClose();
      }
    };

    if (isOpen)
    {
      document.addEventListener("keydown", handleEscape);
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () =>
    {
      document.removeEventListener("keydown", handleEscape);
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, onClose, creating]);

  if (!isOpen)
  {
    return null;
  }
  if (creating)
  {
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50 ">
        <div className="modal-container" ref={modalRef}>
          <div className="flex flex-col bg-white rounded-lg shadow-lg w-[446px] h-[522px] justify-center items-center p-[52px]">
            <CircularProgress color="inherit" />
            <div className="font-custom text-[24px] font-medium mt-[67px]">
              Activating your card...
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (haveTheUserSetUpAccount)
  {
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50 ">
        <div className="modal-container" ref={modalRef}>
          <div className="flex flex-col bg-white rounded-lg shadow-lg w-[446px] h-[522px] justify-center items-center p-[52px]">
            <div className="mb-[26px] font-custom text-[18px] font-light">
              Please ask the user to get the credentials on his email address.
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (profileCreated)
  {
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50 ">
        <div className="modal-container" ref={modalRef}>
          <div className="flex flex-col bg-white rounded-lg shadow-lg w-[446px]  justify-center items-center p-[52px]">
            <h2 className="font-custom text-button text-[39px]/[43px] mb-[22px]">
              Profile Created Successfully
            </h2>
            <div className="flex flex-col p-4 bg-white rounded-lg shadow-lg w-[342px] min-h-[241px]">
              <div className="mb-[26px] font-custom text-[25px]">
                New Profile
              </div>
              <button
                className=" font-custom font-medium text-white shadow-custom text-[15px]/[25px] tracking-[0.05em] text-center bg-button rounded-[50px]  lg:pt-[10px] pt-[12px] lg:pb-[10px] pb-[10px] px-[10px] mb-[17px]"
                onClick={async () =>
                {
                  await updateDoc(user!, {
                    finishedCreating: true,
                    editingDisabled: true,
                  });
                  navigate("/admin-edit-profile/", {
                    state: { orderNumber: orderNumber },
                  });
                }}
              >
                Set Profile Up
              </button>
              <button
                className=" font-custom font-medium text-button shadow-custom text-[15px]/[25px] tracking-[0.05em] text-center bg-primary rounded-[50px]  lg:pt-[10px] pt-[12px] lg:pb-[10px] pb-[10px] px-[10px]"
                onClick={async () =>
                {
                  setHaveTheUserSetUpAccount(true);
                  await updateDoc(user!, {
                    finishedCreating: false,
                    editingDisabled: false,
                  }).then((res) =>
                  {
                    onConfirm();
                  });
                }}
              >
                Have User Set Up Account
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 ">
      <div className="modal-container" ref={modalRef}>
        <div className="flex flex-col bg-white rounded-lg shadow-lg w-[446px] min-h-[522px] justify-center items-start p-[52px]">
          {children}
          <div className="flex flex-col p-4 bg-white rounded-lg shadow-lg w-[342px] min-h-[276px]">
            <form id="setEmail" onSubmit={handleSubmit}>
              <div className="mb-[30px]">
                <CTextField
                  error={emailError}
                  value={email}
                  label="Email Address"
                  onChange={(a) =>
                  {
                    setEmail(a);
                  }}
                />
              </div>
            </form>
            <div className="mb-[26px] font-custom text-[18px] font-light">
              We will be creating a temporary password for the user. This will
              need to be changed later.
            </div>
            <button
              className=" font-custom font-medium text-white shadow-custom text-[15px]/[25px] tracking-[0.05em] text-center bg-button rounded-[50px]  lg:pt-[10px] pt-[12px] lg:pb-[10px] pb-[10px] px-[10px]"
              onClick={handleSubmit}
            >
              Set Up Account
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivateCardModal;
