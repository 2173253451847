import { useNavigate } from "react-router-dom";
import { auth1, db } from "../services/firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";

const getUser = async (callbackFunction: Function) =>
{
    auth1.onAuthStateChanged(async (user) =>
    {

        if (user)
        {
            const q = query(collection(db, 'dashboard'), where('whitelist', 'array-contains', user.email?.toLowerCase()));
            const doc = await getDocs(q);
            if (doc.docs[0]?.exists())
            {
                callbackFunction(true);
            } else
            {
                callbackFunction(false);
            }
        }
        else
        {
            callbackFunction(false);
        }
    });
};


function DashboardCheck({ children }: any)
{
    const [allowed, setAllowed] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    async function getData()
    {
        await getUser(setStateUser);
    }

    const setStateUser = (all: boolean) =>
    {
        setAllowed(all);
        setLoading(false)
    }

    useEffect(() =>
    {
        Promise.resolve(getData());
    }, [])

    useEffect(() =>
    {
        if (!loading && !allowed)
        {
            navigate(-1)
        }
    }, [allowed, loading])

    if (!loading && allowed)
    {
        return children;
    }

    if (loading && !allowed)
    {
        return (
            <div className="overflow-hidden flex items-center justify-center h-screen relative w-[100vw] ">
                <div className=" relative">
                    <CircularProgress color="inherit" />
                </div>
            </div>
        );
    } else if (allowed)
    {
        return children
    }
}

export { DashboardCheck };
