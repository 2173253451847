import { Navigate, useNavigate } from 'react-router-dom';
import React from 'react'
import Navbar from '../../components/Navbar'
import square_left from "../../assets/images/signupsucc-left-squares.svg";
import square_right from "../../assets/images/signupsucc-right-squares.svg";
export const UserNotSetUp = () =>
{
    const navigate = useNavigate();
    return (
        <div className="">
            <Navbar classname="lg:hidden block mb-[129px]" hidecurr alternate />
            <div className="lg:relative flex lg:flex-row flex-col justify-between">
                <div className="lg:block hidden h-full justify-start lg:z-50 pointer-events-none">
                    <img className=" max-h-[95%] h-auto" src={square_left} />
                </div>
                <div className="flex flex-col lg:absolute lg:left-[50%] lg:translate-x-[-50%] pb-[66px]">
                    <Navbar classname="lg:block hidden self-start mb-[112px] " alternate />
                    <div className="self-center flex flex-col justify-center ">
                        <h2 className="lg:w-[666px] w-[350px] font-custom font-medium self-center text-center lg:text-[57px]/[77px] text-[30px]/[41px] lg:mb-[81px] mb-[19px] ">
                            It Looks Like This Account
                            Hasn’t Been Set Up Yet!
                        </h2>
                        <h4 className="lg:w-[303px] w-[187px] font-custom font-light self-center text-left lg:text-[20px]/[32px] text-[14px]/[25px] tracking-[0.05em] lg:mb-[81px] mb-[65px] ">
                            That’s okay, it’s very simple. Let’s get you started.
                        </h4>
                        <button className='w-[333px] self-center font-custom font-medium text-button shadow-custom text-[20px]/[27px] text-center tracking-[0.05em] hover:bg-secondary bg-primary rounded-[26px] lg:mb-0 mb-[62px] lg:pt-[10px] pt-[12px] lg:pb-[14px] pb-[12px]'
                            onClick={() => { navigate('/onboarding') }}>
                            Create Profile
                        </button>
                    </div>
                </div>
                <div className=" lg:block hidden h-full justify-end lg:z-50 pointer-events-none">
                    <img className=" max-h-[100%] h-auto" src={square_right} />
                </div>
            </div>
        </div>
    )

}