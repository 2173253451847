import React, { useEffect, useState } from "react";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { Order as OrderType, } from "../../types";
import { Order } from "../../components/Order";
import { db } from "../../services/firebase";
import { orderDescriptionToType } from "../../utils/features";
import { PurchaseType } from "../../redux/feature/cartSlice";
import CTextField from "../../components/CTextField";
import { CircularProgress } from "@mui/material";

const Dashboard: React.FC = () =>
{
  const [orders, setOrders] = useState<OrderType[]>([]);
  const [searchOrders, setSearchOrders] = useState<OrderType[]>([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() =>
  {
    setSearchOrders(orders.filter((e) =>
    {
      if (orderDescriptionToType(e.type) === PurchaseType.ReplacementElite)
      {
        return e.buyerEmail.toLowerCase().includes(search.toLowerCase()) || (e.cards as { orderNumber: string, extra: number }[])[0].orderNumber.includes(search);
      } else
      {
        return e.buyerEmail.toLowerCase().includes(search.toLowerCase()) || (e.cards as string[])[0].includes(search);
      }
    }
    ));

  }, [search])



  useEffect(() =>
  {
    const fetchOrders = async () =>
    {
      const ordersRef = collection(db, "orders");
      const orderSnapshot = await getDocs(
        query(ordersRef, orderBy("date", "desc"))
      );

      const orderData: OrderType[] = orderSnapshot.docs.map((doc) =>
      {
        const data = doc.data()
        return { ...data, id: doc.id } as OrderType
      });

      setOrders(orderData);
      setLoading(false);
    }

    fetchOrders();
  }, []);
  if (loading)
  {
    return (
      <div className="overflow-hidden flex items-center justify-center h-screen relative w-[100vw] ">
        <div className=" relative">
          <CircularProgress color="inherit" />
        </div>
      </div>
    );
  }
  return (
    <div className="pb-[70px] p-4 w-full">
      <h1 className="text-2xl font-bold mb-4">Dashboard</h1>
      <div className=" px-[20px] pt-[10px] pb-[5px] fixed z-50 left-0 bottom-0 bg-white flex flex-col gap-[15px] w-full">
        <CTextField onChange={(a) => { setSearch(a) }} label="Search" hint="Order Number or Email" />
      </div>
      <div className="grid grid-cols-1 gap-4 w-full">
        {searchOrders.length > 0 ?
          searchOrders.map((order) => (<Order key={order.id} order={order} />)) :
          orders.map((order) => (
            <Order key={order.id} order={order} />
          ))}
      </div>
    </div>
  );
};

export default Dashboard;
