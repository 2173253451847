import { useNavigate } from "react-router-dom";
import { auth1, db } from "../services/firebase";
import { doc, getDoc } from "firebase/firestore";
import { User } from "../types";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";


function CheckOrder({ children }: any)
{
  const [user, setUser] = useState<User>({} as User);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() =>
  {
    const unsub = auth1.onAuthStateChanged(async (user) =>
    {
      if (user)
      {
        const authUserRef = doc(db, "users", auth1.currentUser?.uid!);
        const userDoc = (await getDoc(authUserRef)).data() as User;
        setUser(userDoc)
        setLoading(false)
      }
    });
    return unsub
  }, [])

  if (loading)
  {
    return (
      <div className="overflow-hidden flex items-center justify-center h-screen relative w-[100vw] ">
        <div className=" relative">
          <CircularProgress color="inherit" />
        </div>
      </div>
    );
  }

  if (!user || !user.orderNumber || user.orderNumber === 'none')
  {
    navigate('/order-number-validation')
    return;
  } else
  {
    return children;
  }

}

export { CheckOrder };
