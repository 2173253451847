import React, { useEffect } from 'react'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import about_bcx from '../../assets/images/about-bcx.png';
import the_idea from '../../assets/images/the-idea-bcx.png';
import { Navigate, useNavigate } from 'react-router-dom';
import { Cart } from '../../components/Cart';
export const About = () =>
{
  const navigate = useNavigate();
  useEffect(() => { window.scrollTo(0, 0); }, []);
  return (
    <>
      <Cart />
      <div className='overflow-hidden'>
        <Navbar />
        <div className='flex flex-col lg:mt-[58px] mt-[20px]'>
          <div className='flex flex-row justify-between lg:mx-[80px]  mx-[30px] lg:mb-[62px] mb-[47px] '>
            <div className=' lg:mt-[52px] relative'>
              <h1 className=' inline-block font-custom font-black lg:text-[64px]/[86px] text-[30px]/[41px] lg:mb-[34px]'>
                About businesscardX?
              </h1>
              <div className='bg-primary relative lg:w-[100%] w-[125%]  h-[37px] z-[-1] lg:mt-[-70px] mt-[-18px] lg:mb-[34px] mb-[16px]' />
              <p className='font-custom font-light lg:text-[20px]/[32px] text-[14px]/[25px] lg:px-0 min-[1430px]:w-[630px] min-[1024px]:w-[520px] tracking-[0.05em] mt-0 lg:mb-[105px] mb-[41px]'>
                We recognize that we're living in a digital age where technology is rapidly changing the way we work and connect with each other. With traditional business cards becoming increasingly outdated, it's more important than ever to stay ahead of the game with the latest technology in networking. That's why we created our innovative digital business card solution - to help professionals make lasting connections and elevate their professional image in today's tech-driven world. Our mission is to simplify the networking process and stay ahead of the curve with the latest advancements in digital technology. At businesscardX, we believe that the future of networking is digital.
              </p>
              <div className='lg:block flex justify-end '>
                <button className='font-custom font-medium hover:text-white text-button hover:bg-secondary bg-primary shadow-custom lg:text-[20px]/[32px] text-[16px]/[25px] text-center tracking-[0.05em]  rounded-[33px] lg:px-[61px] px-[69px] lg:pt-[16px] pt-[12px] lg:pb-[18px] pb-[14px]' onClick={() => { navigate('/shop') }}>See Our Products</button>
              </div>
            </div>
            <div className='lg:flex items-center min-[1024px]:justify-end  hidden xl:mt-0 lg:mt-[87px] xl:ml-0  xl:w-[100%] lg:w-[65%]'>
              <img className='object-contain h-auto ' src={about_bcx} />
            </div>
          </div>

          <div className='flex flex-row lg:mr-[80px] lg:bg-bg bg-button lg:pt-[77px] pt-[48px] lg:pr-[97px] lg:pb-[97px] overflow-hidden'>
            <div className='flex lg:flex-row flex-col min-[1024px]:gap-[40px] min-[1330px]:gap-[125px] justify-between lg:p-0 px-[30px] '>
              <div className='lg:flex hidden items-start justify-start min-[1024px]:w-[70%] min-[1330px]:w-full xl:basis-1/2 basis-2/5]'>
                <img className='h-auto object-contain ' src={the_idea} />
              </div>
              <div className=' xl:basis-1/2 basis-3/5 sm:w-auto w-[350px]'>
                <h1 className='relative inline-block font-custom font-semibold text-white w-[516px]  z-20 lg:text-[59px]/[71px] text-[30px]/[41px] lg:mb-[34px] mb-[33px]'>
                  The Idea Behind It
                </h1>
                <div className='lg:block hidden bg-secondary relative h-[37px] z-10 xl:ml-[245px] lg:ml-[245px] xl:w-[100%] lg:w-[150%]  lg:mt-[-70px] mt-[-18px] lg:mb-[46px] mb-[16px]' />

                <p className='font-custom font-light text-white lg:text-[20px]/[32px] text-[14px]/[25px] lg:px-0  tracking-[0.05em] mt-0 '>
                  Traditional business cards are often printed on paper and can easily get lost, damaged, or forgotten. They're also not environmentally sustainable, as they contribute to paper waste. With businesscardX, we saw an opportunity to address these pain points and create a more sustainable, efficient, and memorable way for professionals to share their information.
                </p>
                <br />
                <p className='font-custom font-light text-white lg:text-[20px]/[32px] text-[14px]/[25px] lg:px-0  tracking-[0.05em] mt-0 '>
                  Digital technology allows for increased customization, flexibility, and convenience when it comes to sharing information. With businesscardX, you can create a personalized online profile with all of your relevant information and easily share it with others through a simple tap or scan. Plus, you can easily update your information as it changes, eliminating the need for costly reprinting of traditional business cards.
                </p>
                <br />
                <p className='font-custom font-light text-white lg:text-[20px]/[32px] text-[14px]/[25px] lg:px-0   tracking-[0.05em] mt-0 '>
                  Digital business cards offer a more modern and efficient way to network. They're more memorable, and can be easily customized to fit your brand and personal style. With businesscardX, you can also add features like social media links and a bio, which are not possible with traditional business cards.
                </p>
                <br />
                <p className='font-custom font-light text-white lg:text-[20px]/[32px] text-[14px]/[25px] lg:px-0  tracking-[0.05em] mt-0 '>
                  In today's fast-paced, tech-driven world, it's more important than ever to stay ahead of the curve when it comes to networking. By using businesscardX, you can showcase your tech-savviness and leave a lasting impression on clients and contacts. Plus, with our enterprise package, you can customize your branding and ensure that your organization is always at the forefront of the latest digital technology.
                </p>

                <div className=' flex justify-end lg:mt-[130px] lg:mb-0 mb-[36px] mt-[41px] '>
                  <button className='font-custom font-medium text-button shadow-custom lg:text-[20px]/[25px] text-[16px]/[25px] text-center tracking-[0.05em] bg-white hover:bg-primary rounded-[33px] lg:px-[61px] px-[69px] lg:pt-[18px] pt-[12px] lg:pb-[20px] pb-[14px]' onClick={() => { navigate('/shop'); }}>See Our Products</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}
