import { FirebaseApp, initializeApp } from 'firebase/app';
import { getAuth, Auth } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
import { getFunctions } from 'firebase/functions';
import { getStorage } from "firebase/storage";
import Geocode from "react-geocode";

let firebaseApp: FirebaseApp;

export const setupFirebase = () =>
{
  try
  {
    firebaseApp = initializeApp({
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    });

  } catch (error)
  {
    console.error({ error })
  }
};

const app = firebaseApp = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

let auth: Auth;
let firestore: ReturnType<typeof getFirestore>;
let storage: ReturnType<typeof getStorage>;
const functions = getFunctions(app);

export const useAuth = () =>
{
  auth = getAuth(firebaseApp);
  return auth;
};

export const useFirestore = () =>
{
  if (!firestore)
  {
    firestore = getFirestore();
  }
  return firestore;
};

export const useStorage = () =>
{
  if (!storage)
  {
    storage = getStorage();
  }
  return storage;
};

const geocode = Geocode.setApiKey(process.env.REACT_APP_MAPS_API_KEY!);


export const db = getFirestore(app);
export const auth1 = getAuth(firebaseApp);