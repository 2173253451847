import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga4';
import { Helmet } from "react-helmet";
interface TrackerProps
{
    children: any,
    title: string
}
export const Tracker = ({ ...props }: TrackerProps) =>
{

    const location = useLocation();
    useEffect(() =>
    {
        // Google Analytics
        ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: `businesscardX | ${props.title}` });

    }, [location]);


    return <>
        <Helmet>
            <title>businesscardX | {props.title}</title>
        </Helmet>
        {props.children}
    </>
}
