import CTextField from "./CTextField";

interface PersonalizedCardProps
{
    key?: string,
    number: string,
    classname?: string,
    w?: string,
    hideLabel?: boolean
    border?: string
    name?: string,
    error_name?: string
    onChangeName?: CallableFunction & ((arg: string) => void),
}

const PersonalizedCard = ({ ...props }: PersonalizedCardProps) =>
{
    function empty(a: String)
    {

    }
    return <div className={props.classname} key={props.key}>
        <div className=' bg-white rounded-lg shadow-custom py-[28px] px-[22px] mb-[28px] flex flex-col justify-center items-start w-full'>
            <div className={props.w}>
                <p className={props.hideLabel ? 'hidden ' : '' + 'font-custom font-light text-[14px]/[19px] mb-[22px]'}>
                    Personalized Card #{props.number}
                </p>
                <CTextField value={props.name ?? ''} onChange={(a) => { props.onChangeName ? props.onChangeName(a) : empty(a) }} bordercolor='#FBBC05' error={props.error_name} label='Full Name' />

            </div>
        </div>
    </div>
}

export default PersonalizedCard;