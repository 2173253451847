import { useRef, useState } from "react"
import { Language, lang } from "../languages";
import CTextField from "./CTextField";
import { CPhoneInput } from "./PhoneInput";
import CSelect from "./CSelect";
import { phoneTypel18n, stringToPhoneType } from "../utils/features";
import { useOutsideAlerter } from "./SocialDropDown";
import { Contact, Phone, PhoneTypes } from "../types";
import { formatPhoneNumber, isPossiblePhoneNumber } from "react-phone-number-input";
import validator from "validator";

import { ConfirmationPopUp } from "./ConfirmationPopUp";
import { deepEqual } from "../utils/utils";

interface ShareInfoPopUpProps
{
    isOpen: boolean
    language: Language
    onClose: CallableFunction
    onSubmit: CallableFunction & ((arg: any) => void)
}

export const ShareInfoPopUp = ({ ...props }: ShareInfoPopUpProps) =>
{

    const [contact, setContact] = useState({ phone: {} as Phone } as Contact)
    const [contactError, setContactError] = useState({ email: '', firstName: '', lastName: '', notes: '', phone: { ext: '', number: '', type: '' } } as Contact)
    const [confirmation, setConfirmation] = useState(false);
    const [loading, setLoading] = useState(false);
    const element = useRef<HTMLDivElement>(null);
    useOutsideAlerter(element, () => { /*handleClose()*/ });

    function handleClose()
    {
        if (!deepEqual(contact, { phone: {} as Phone } as Contact))
        {
            setConfirmation(true);
        } else
        {
            props.onClose()
        }

    }
    function validateNames()
    {
        let valid = true;

        let newError = {} as Contact;

        if (!contact.firstName || contact.firstName.trim() === "")
        {
            newError.firstName = lang.firstNameError[props.language]
            valid = false;
        }

        if (!contact.lastName || contact.lastName.trim() === "")
        {
            newError.lastName = lang.lastNameError[props.language]
            valid = false;
        }

        return { nameValid: valid, nameErrors: newError };

    }


    function validatePhone()
    {
        let valid = true;
        let newError = { phone: {} as Phone } as Contact;

        if (!contact.phone.number || !isPossiblePhoneNumber(contact.phone.number))
        {
            newError.phone.number = lang.phoneNumberError[props.language]
            valid = false;
        }

        if (
            contact.phone.type === "" ||
            !contact.phone.type ||
            ![lang.work[props.language], lang.cell[props.language], lang.home[props.language]].includes(phoneTypel18n(stringToPhoneType(contact.phone.type), props.language))
        )
        {
            newError.phone.type = lang.numberTypeError[props.language]
            valid = false;
        }

        return { phoneValid: valid, phoneError: newError };
    }

    function validateEmail()
    {
        let valid = true;

        let newError = {} as Contact;

        if (!contact.email || contact.email.trim() === "" || !validator.isEmail(contact.email))
        {
            newError.email = lang.emailError[props.language]
            valid = false;
        }

        return { emailValid: valid, emailErrors: newError };

    }

    function handleSubmit()
    {
        let valid = true;

        const { phoneValid, phoneError } = validatePhone()!;
        const { emailValid, emailErrors } = validateEmail()!;
        const { nameValid, nameErrors } = validateNames()!;
        let neitherError = '';

        if (contact.email && contact.phone.number)
        {
            if (!(emailValid && phoneValid))
            {
                valid = false;

            }
        }
        // If either email or phone is provided, check its validity
        else if (contact.email)
        {
            if (!emailValid)
            {
                valid = false;
            }
        } else if (contact.phone.number)
        {
            if (!phoneValid)
            {
                valid = false;
            }
        } else
        {
            // None of them are provided
            valid = false;
            neitherError = lang.neitherError[props.language];
        }

        if (!nameValid)
        {
            valid = false;
        }

        if (valid && !loading)
        {
            setLoading(true);
            props.onSubmit(contact);
            setLoading(false);
        }

        setContactError({
            ...(contact.phone.number ? phoneError : { phone: {} }),
            ...(contact.email ? emailErrors : {}),
            ...nameErrors,
            notes: neitherError
        } as Contact);
    }




    return <>
        <div className={(props.isOpen ? 'block' : 'hidden') + " z-[10000] absolute w-full h-full bg-opacity-50 bg-black"}>
            {confirmation && <ConfirmationPopUp
                cancel={lang.cancel[props.language]}
                title={lang.confirmClose[props.language]}
                confirm={lang.confirm[props.language]}
                isOpen={confirmation}
                onCancel={() => { setConfirmation(false) }}
                onClose={() => { setConfirmation(false) }}
                onConfirm={() => { setConfirmation(false); props.onClose() }}
            />}
            <div ref={element} className={"p-[34px] relative rounded-2xl md:w-[420px] w-[360px]  left-1/2 transform -translate-x-1/2 translate-y-[10%] bg-white shadow-lg flex flex-col justify-between "}>
                <button onClick={() => { handleClose() }} className="absolute right-0 top-0 mr-[12px] transform rotate-45 text-[38px] font-normal font-custom ">+</button>
                <h2 className=" text-[39px] font-custom font-medium text-center mb-[27px]">
                    {lang.shareYourInfo[props.language]}
                </h2>
                <div className="flex flex-col gap-[24px]">
                    <CTextField
                        label={lang.firstName[props.language]}
                        error={contactError.firstName}
                        value={contact.firstName}
                        onChange={(a) =>
                        {
                            setContact({ ...contact, firstName: a })
                        }
                        } />
                    <CTextField
                        label={lang.lastName[props.language]}
                        value={contact.lastName}
                        error={contactError.lastName}
                        onChange={(a) =>
                        {
                            setContact({ ...contact, lastName: a })
                        }
                        } />
                    <CTextField
                        label={lang.email[props.language]}
                        value={contact.email}
                        error={contactError.email}
                        onChange={(a) =>
                        {
                            setContact({ ...contact, email: a })
                        }
                        } />
                    <CPhoneInput
                        value={formatPhoneNumber(contact.phone.number ?? '')}
                        error={contactError.phone.number}
                        label={lang.phone[props.language]}
                        onChange={(a) =>
                        {

                            setContact({ ...contact, phone: { ...contact.phone, number: a } });
                        }} />
                    <div
                        className=" pt-[3px] flex flex-row justify-between gap-[15px] "
                    >
                        <CTextField
                            error={contactError.phone.ext}
                            label={lang.ext[props.language]}
                            classname=""
                            w="w-[100px] "
                            value={contact.phone.ext}
                            onChange={(arg) =>
                            {
                                setContact({ ...contact, phone: { ...contact.phone, ext: arg } });
                            }}
                        />
                        <CSelect
                            value={phoneTypel18n(stringToPhoneType(contact.phone.type), props.language)}
                            error={contactError.phone.type as string}
                            errorclass="pt-[5px]"
                            items={[{ item: lang.work[props.language], value: PhoneTypes.Work }, { item: lang.cell[props.language], value: PhoneTypes.Cell }, { item: lang.home[props.language], value: PhoneTypes.Home }]}
                            label={lang.type[props.language]}
                            hint={lang.type[props.language]}
                            classname="md:w-[230px] w-[170px]"
                            onChange={(arg) =>
                            {
                                setContact({ ...contact, phone: { ...contact.phone, type: arg } });
                            }}
                        />
                    </div>
                    <CTextField
                        label={lang.notes[props.language]}
                        value={contact.notes}
                        error={contactError.notes}
                        multiline
                        maxlength={250}
                        hint={`250 ${lang.characters[props.language]} ${lang.max[props.language]}`}
                        onChange={(arg) =>
                        {
                            setContact(
                                { ...contact, notes: arg }
                            )
                        }} />
                    <button disabled={loading} onClick={handleSubmit} className="self-center w-full font-custom text-[20px]/[32px] font-medium rounded-[23px] tracking-[0.05em] hover:bg-inset hover:text-button text-white bg-button pt-[10px] pb-[10px]">{lang.sendInformation[props.language]}</button>
                </div>
            </div>
        </div>
    </>
}
