import React, { useContext, useEffect, useState } from 'react'
import Navbar from '../../components/Navbar'
import square_left from "../../assets/images/onboarding2-left-squares.svg";
import square_right from "../../assets/images/onboarding2-right-squares.svg";
import CTextField from '../../components/CTextField'
import { Navigate, useNavigate } from 'react-router-dom';
import { OnboardingUser, UserContext } from '../../components/contexts/UserContext';
import { valid } from 'semver';

export const Onboarding2 = () =>
{
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const [errors, setErrors] = useState({ name: '', lastName: '' });

  function handleSubmit()
  {
    let valid = true;
    let newUser = { ...user };
    let errors = { name: '', lastName: '' }
    if (!user.name?.trim())
    {
      errors.name = 'Please provide your name'
      valid = false;
    }

    if (!user.lastName?.trim())
    {
      errors.lastName = 'Please provide your last name'
      valid = false;
    }

    if (!user.bio)
    {
      newUser.bio = 'no';
    }

    if (valid)
    {
      navigate('/onboarding-3');
    }
    setErrors({ ...errors })
  }

  return (
    <div >
      <Navbar classname="lg:hidden block mb-[24px]" hidecurr alternate />
      <div className="lg:relative flex lg:flex-row flex-col justify-between">
        <div className="lg:block hidden h-full justify-start">
          <img className="max-h-full h-auto" src={square_left} />
        </div>

        <div className="flex flex-col lg:absolute lg:left-[50%] lg:translate-x-[-50%]  pb-[66px]">
          <Navbar classname="lg:block hidden self-start mb-[40px] " alternate />
          <div className="self-center flex flex-col justify-center lg:mx-0 mx-[25px]">
            <h2 className=" font-custom font-medium self-center text-center lg:text-[57px]/[77px] text-[30px]/[41px] lg:mb-[15px] mb-[18px] ">
              Who Are You?
            </h2>
            <div className='relative self-center'>
              <CTextField value={user.name} error={errors.name} onChange={(a) => { setUser({ ...user, name: a }) }} label='Name' w='lg:w-[354px] w-[344px]' classname='mb-[37px]' />
              <div className='absolute top-0 right-0 mt-[-25px] mr-[-10px] text-[25px] font-semibold text-primary'>
                *
              </div>
            </div>
            <div className='relative self-center'>
              <CTextField value={user.lastName} error={errors.lastName} onChange={(a) => { setUser({ ...user, lastName: a }) }} label='Last Name' w='lg:w-[354px] w-[344px]' classname='mb-[37px]' />
              <div className='absolute top-0 right-0 mt-[-25px] mr-[-10px] text-[25px] font-semibold text-primary'>
                *
              </div>
            </div>
            <h2 className=" font-custom font-medium self-center text-center lg:text-[39px]/[53px] text-[30px]/[41px] lg:mb-[8px] mb-[18px] ">
              Write a Bio!
            </h2>
            <CTextField maxlength={1000} value={user.bio} onChange={(a) => { setUser({ ...user, bio: a }) }} label='Bio' hint='1000 characters max.' w='lg:w-[354px] w-[344px]' multiline classname='mb-[23px] self-center' />

            <h2 className=" font-custom font-medium self-center text-center lg:text-[39px]/[53px] text-[30px]/[41px] lg:mb-[8px] mb-[18px] ">
              Have a Website?
            </h2>
            <CTextField value={user.website?.[0]}
              onChange={(a) => { setUser({ ...user, website: [a] }) }}
              mask={['h', 't', 't', 'p', 's', ':', '/', '/', /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/]}
              label='URL'
              hint='Website Link' w='lg:w-[354px] w-[344px]'
              classname='mb-[23px] self-center' />
            <h4 className=" font-custom font-light lg:self-center lg:text-left lg:text-[18px]/[24px] text-[14px]/[25px] tracking-[0.05em]  ">
              You can always change this info later.
            </h4>

            <div className='flex flex-row gap-[29px] items-center self-center'>

              <button className='w-[98px] mb-[12px] font-custom font-medium text-white shadow-custom text-[20px]/[27px] text-center tracking-[0.05em] hover:bg-inset bg-black rounded-[26px]  lg:pt-[10px] pt-[12px] lg:pb-[14px] pb-[12px]' onClick={() =>
              {
                navigate('/onboarding')
              }}>
                Back
              </button>
              <div className='flex flex-row gap-[10px] self-end mt-[90px] '>
                <div className='w-[9px] h-[9px] bg-slate-400 rounded-full' />
                <div className='w-[9px] h-[9px] bg-primary rounded-full' />
                <div className='w-[9px] h-[9px] bg-slate-400 rounded-full' />
                <div className='w-[9px] h-[9px] bg-slate-400 rounded-full' />
                <div className='w-[9px] h-[9px] bg-slate-400 rounded-full' />
                <div className='w-[9px] h-[9px] bg-slate-400 rounded-full' />
              </div>
              <button className='w-[98px] mb-[12px] font-custom font-medium hover:bg-secondary hover:text-white text-button shadow-custom text-[20px]/[27px] text-center tracking-[0.05em] bg-primary rounded-[26px]   mr-0  lg:pt-[10px] pt-[12px] lg:pb-[14px] pb-[12px]' onClick={() =>
              {
                handleSubmit();
              }}>
                Next
              </button>
            </div>

          </div>
        </div>

        <div className="lg:block hidden h-full justify-end">
          <img className=" max-h-full h-auto" src={square_right} />
        </div>

      </div>

    </div>
  )
}
