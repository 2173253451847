import { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import cart from "../../assets/images/cart.svg";
import { useNavigate } from "react-router-dom";
import personal from "../../assets/images/personalized-elite-card.svg";
import mock from "../../assets/images/elite-mock.svg";
import ledgerluxe from "../../assets/images/ledgerluxe.svg";
import your_logo_here from "../../assets/images/your-logo-here.svg";
import Incrementer from "../../components/Incrementer";

import
{
    Black,
    Elite,
    PCard,
    Purchase,
    PurchaseType,
    getSubTotal,
    setOrder,
} from "../../redux/feature/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { Cart } from "../../components/Cart";
import { auth1, db } from "../../services/firebase";
import { DocumentData, collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { Card, User } from "../../types";
import { Currency, curr } from "../../currencies";
import { CardSelector } from "../../components/CardSelector";
import PersonalizedCard from "../../components/PersonalizedCard";

interface ReplacementCardProps
{
    type: PurchaseType
}

function getLocalOrder(type: PurchaseType, cartRx: Purchase)
{
    switch (type)
    {
        case PurchaseType.ReplacementBlack:
            return cartRx.order.type !== PurchaseType.ReplacementBlack ? { submit: false, valid: undefined, type: PurchaseType.ReplacementBlack } as Black : {
                submit: false,
                valid: (cartRx.order as Black).valid ?? undefined,
                logoAdd: (cartRx.order as Black).logoAdd ?? false,
                type: PurchaseType.ReplacementBlack,
                blank: (cartRx.order as Black).blank ?? false,
                color: (cartRx.order as Black).color ?? "",
                company: (cartRx.order as Black).company ?? "",
                extra: (cartRx.order as Black).extra ?? 1,
                fullName: (cartRx.order as Black).fullName ?? "",
                logo: (cartRx.order as Black).logo ?? ['', '', ''],
            } as Black

        case PurchaseType.ReplacementElite:
            return cartRx.order.type !== PurchaseType.ReplacementElite ? { base: 2, blank: false, color: "", company: '', extra: 0, logo: ['', '', ''], nonPersonalized: 2, pCard: [], personalized: 0, submit: false, type: PurchaseType.ReplacementElite, valid: undefined, npCard: [] } as Elite : {
                npCard: (cartRx.order as Elite).npCard ?? [],
                base: (cartRx.order as Elite).base ?? 2,
                nonPersonalized: (cartRx.order as Elite).nonPersonalized ?? 2,
                personalized: (cartRx.order as Elite).personalized ?? 0,
                pCard: (cartRx.order as Elite).pCard ?? [],
                valid: (cartRx.order as Elite).valid ?? undefined,
                type: PurchaseType.ReplacementElite,
                blank: (cartRx.order as Elite).blank ?? false,
                color: (cartRx.order as Elite).color ?? "",
                company: (cartRx.order as Elite).company ?? "",
                extra: (cartRx.order as Elite).extra ?? 0,
                logo: (cartRx.order as Elite).logo ?? ['', '', ''],
            } as Elite;
        case PurchaseType.NewMember:
            return cartRx.order.type !== PurchaseType.NewMember ? { base: 1, blank: false, color: "", company: '', extra: 0, logo: ['', '', ''], nonPersonalized: 1, pCard: [], personalized: 0, submit: false, type: PurchaseType.NewMember, valid: undefined, npCard: [] } as Elite : {
                npCard: (cartRx.order as Elite).npCard ?? [],
                base: (cartRx.order as Elite).base ?? 1,
                nonPersonalized: (cartRx.order as Elite).nonPersonalized ?? 1,
                personalized: (cartRx.order as Elite).personalized ?? 0,
                pCard: (cartRx.order as Elite).pCard ?? [],
                valid: (cartRx.order as Elite).valid ?? undefined,
                type: PurchaseType.NewMember,
                blank: (cartRx.order as Elite).blank ?? false,
                color: (cartRx.order as Elite).color ?? "",
                company: (cartRx.order as Elite).company ?? "",
                extra: (cartRx.order as Elite).extra ?? 0,
                logo: (cartRx.order as Elite).logo ?? ['', '', ''],
            } as Elite;
        default:
            return {} as Black;
    }
}

export const ReplacementCard = ({ ...props }: ReplacementCardProps) =>
{
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const cartRx: Purchase = useSelector((state: any) => state.cart);
    const currency: Currency = useSelector((state: any) => state.currency);
    const [order, setlocalOrder] = useState(getLocalOrder(props.type, cartRx));
    const [error, setError] = useState('');
    const [cards, setCards] = useState<PCard[]>([]);
    const [errors, setErrors] = useState({
        company: "",
        color: "",
        logo: "",
        pcards: Array((order as Elite).personalized).fill({
            fullName: "",
        } as PCard),
    });

    function getCardData(callback: (a: Card, u: User) => void)
    {
        auth1.onAuthStateChanged(async (user) =>
        {
            let userDoc: DocumentData | undefined = undefined;
            if (user)
            {
                const authUserRef = doc(db, "users", auth1.currentUser?.uid!);
                userDoc = (await getDoc(authUserRef)).data();

                const userTyped = userDoc as User;
                if (!userTyped.eliteAdmin && props.type === PurchaseType.ReplacementBlack && !userTyped.eliteCompany)
                {
                    const q = query(
                        collection(db, "cards"),
                        where("orderNumber", "==", userDoc!.orderNumber)
                    );
                    const querySnapshot = await getDocs(q);
                    let data = querySnapshot.docs[0]?.data();
                    let blackCard = data as Card;
                    callback(blackCard, userTyped)

                } else if (userTyped.eliteAdmin && props.type === PurchaseType.ReplacementElite)
                {

                    const c = query(
                        collection(db, "cards"),
                        where("orderNumber", "==", userDoc!.orderNumber)
                    );

                    const cSnapshot = await getDocs(c);

                    let cData = cSnapshot.docs[0]?.data();
                    let eliteCard = cData as Card;
                    callback(eliteCard, userTyped)
                }
                else if (userTyped.eliteAdmin && props.type === PurchaseType.NewMember)
                {
                    const c = query(
                        collection(db, "cards"),
                        where("orderNumber", "==", userTyped.orderNumber)
                    );
                    const cSnapshot = await getDocs(c);
                    let cData = cSnapshot.docs[0]?.data();
                    let eliteCard = cData as Card;
                    callback(eliteCard, userTyped)
                }
                else
                {
                    navigate(-1)
                }
            }
        });
    }

    useEffect(() =>
    {
        window.scrollTo(0, 0);
        if (loading)
        {
            getCardData(async (a: Card, u: User) =>
            {
                if (props.type === PurchaseType.ReplacementBlack)
                {
                    const ord: Black = { orderNumber: u!.orderNumber, blank: a.blank, color: a.color, company: a.companyName ?? '', extra: order.extra ?? 1, fullName: a.blank ? '' : u!.name!, logo: a.logo, logoAdd: a.customLogo, submit: false, type: PurchaseType.ReplacementBlack, valid: undefined }
                    setlocalOrder(ord);
                } else if (props.type === PurchaseType.ReplacementElite)
                {
                    const company = (await getDoc(u.eliteCompany)).data();
                    const ord: Elite = { orderNumber: u.orderNumber, base: (order as Elite).base ?? 1, nonPersonalized: (order as Elite).nonPersonalized ?? 1, personalized: (order as Elite).personalized ?? 0, npCard: (order as Elite).npCard ?? [], pCard: (order as Elite).pCard ?? [], blank: a.blank, color: a.color, company: a.companyName, extra: 0, logo: a.logo, submit: false, type: PurchaseType.ReplacementElite, valid: undefined }
                    if (company)
                    {
                        const c = query(
                            collection(db, "users"),
                            where("eliteCompany", "==", a.eliteCompanyRef)
                        );

                        const cSnapshot = (await getDocs(c))
                        const cardsData = cSnapshot.docs.map((e, i) => { return e.data() });
                        const picked = (order as Elite).npCard.map((e, i) => { return e.orderNumber })
                        setCards(cardsData.map((e, i) => { return { fullName: e.name, extra: picked.includes(e.orderNumber) ? (order as Elite).npCard.find((b, idx) => { return b.orderNumber === e.orderNumber })?.extra : 0, orderNumber: e.orderNumber } as PCard }))
                    }
                    setlocalOrder(ord);
                }
                else if (props.type === PurchaseType.NewMember)
                {
                    const ord: Elite = { reference: u.eliteCompany.path, orderNumber: u.orderNumber, base: (order as Elite).base, nonPersonalized: (order as Elite).nonPersonalized, personalized: (order as Elite).personalized, npCard: (order as Elite).npCard, pCard: (order as Elite).pCard, blank: a.blank, color: a.color, company: a.companyName, extra: 0, logo: a.logo, submit: false, type: PurchaseType.NewMember, valid: undefined }
                    setlocalOrder(ord);
                }
                setLoading(false)
            })
        }
    }, []);

    useEffect(() =>
    {
        if (order.valid && order.submit)
        {
            navigate(`/replacement-cart`);
        }
    }, [order.valid, order.submit])

    useEffect(() =>
    {
        if (props.type === PurchaseType.NewMember)
        {
            if ((order as Elite).personalized + (order as Elite).nonPersonalized < (order as Elite).base)
            {
                setlocalOrder({ ...order, nonPersonalized: (order as Elite).nonPersonalized + 1 });
            } else if ((order as Elite).personalized + (order as Elite).nonPersonalized > (order as Elite).base)
            {
                if ((order as Elite).personalized > (order as Elite).nonPersonalized)
                {
                    setlocalOrder({
                        ...order,
                        personalized: (order as Elite).personalized - 1,
                        pCard: (order as Elite).pCard.filter((e, i) => i !== (order as Elite).pCard.length - 1),
                    });
                } else
                {
                    setlocalOrder({ ...order, nonPersonalized: (order as Elite).nonPersonalized - 1 });
                }
            }
        }
    }, [(order as Elite).base]);

    useEffect(() =>
    {

        dispatch(setOrder(order));
        dispatch(getSubTotal(currency));
    }, [order, dispatch, currency])

    useEffect(() =>
    {
        setlocalOrder({ ...order, npCard: cards.filter((e, i) => { return e.extra! > 0 }) })
    }, [cards])


    async function handleSubmit()
    {
        if (props.type === PurchaseType.ReplacementBlack)
        {
            setlocalOrder({ ...order, submit: true, valid: true })
        }
        else if (props.type === PurchaseType.ReplacementElite)
        {
            let ord = order as Elite;
            let valid = true
            if (ord.personalized === 0 && ord.nonPersonalized === 0 && ord.npCard.length === 0 && ord.pCard.length === 0)
            {
                valid = false
                setError('Please select the card(s) to replace')
            }
            setlocalOrder({ ...order, valid: valid, submit: true })
        } else if (props.type === PurchaseType.NewMember)
        {


            const newError = {
                company: "",
                color: "",
                logo: "",
                pcards: Array((order as Elite).personalized).fill({
                    fullName: "",
                } as PCard),
            };
            let valid = true;

            (order as Elite).pCard.forEach((e, i) =>
            {
                if (!e.fullName.trim())
                {
                    newError.pcards = [
                        ...newError.pcards.slice(0, i),
                        {
                            ...newError.pcards[i],
                            fullName: "Please provide a Name for the card",
                        } as PCard,
                        ...newError.pcards.slice(i + 1),
                    ];
                    valid = false;
                }
            });
            setErrors(newError);
            if (valid)
            {
                setlocalOrder({ ...order, valid: true, submit: true });
            } else
            {
                setlocalOrder({ ...order, valid: false, submit: false });
            }
        }
    }

    if (loading)
    {
        return (
            <div className="overflow-hidden flex items-center justify-center h-screen relative w-[100vw] ">
                <div className=" relative">
                    <CircularProgress color="inherit" />
                </div>
            </div>
        );
    }
    return (<>
        <Cart onClick={() => handleSubmit()} />
        <div className="overflow-hidden">
            <Navbar />
            <div className="flex lg:flex-row flex-col justify-between items-center lg:px-[80px] px-[25px] lg:mb-[20px]">
                <div className="flex flex-col relative lg:mr-[20px] lg:w-auto w-full">
                    <div className="relative pt-[10px]">
                        {props.type === PurchaseType.ReplacementBlack ? (<>
                            <h1 className=" inline-block font-custom font-black lg:text-[64px]/[86px] text-[30px]/[41px] lg:mb-[34px]">
                                Replace BLACK Card
                            </h1>
                            <div className="bg-primary relative lg:w-[110%] w-[125%] h-[37px] z-[-1] lg:mt-[-70px] mt-[-18px] lg:mb-[37px] mb-[28px]" />
                            <p className="font-custom font-light lg:text-[20px]/[32px] text-[14px]/[25px] lg:px-0 lg:w-[393px] tracking-[0.05em] mt-0 lg:mr-0 mr-[-5px] lg:mb-[28px] mb-[47px]">
                                Need a replacement card for your BLACK profile? Not to worry we got you covered.
                            </p>

                            <div className="flex gap-[42px] lg:mb-[7px] mb-[46px]">
                                <div className="flex w-[100px]">
                                    <p className="font-custom font-normal text-[36px]/[49px] ">
                                        ${curr['blackReplacement'][currency]}
                                    </p>
                                    <p className="font-custom font-light text-[18px]/[25px] flex self-start pt-[5px] pl-[6px]">
                                        {" "}
                                        {currency}
                                    </p>
                                    <p className="font-custom font-light text-[20px]/[25px] flex self-end mb-[5px] ">
                                        /card
                                    </p>
                                </div>
                            </div>
                            <div className="mb-[26px]">
                                <Incrementer minimum={1}

                                    onDecrement={() =>
                                    {
                                        setlocalOrder({ ...order, extra: order.extra - 1 });
                                    }}
                                    onIncrement={() =>
                                    {
                                        setlocalOrder({ ...order, extra: order.extra + 1 });
                                    }}
                                    value={order.extra} />
                            </div>
                            <div className="flex lg:justify-between flex-col lg:mb-[52px] mb-[23px] gap-[26px]">
                                <button
                                    className="w-[201px] font-custom font-medium text-white  lg:text-[20px]/[27px] text-[16px]/[25px] text-center bg-button hover:bg-inset rounded-[33px] lg:mr-0  lg:pt-[12px] pt-[12px] lg:pb-[14px] pb-[14px]"
                                    onClick={() =>
                                    {
                                        dispatch(setOrder({ valid: false } as Black))
                                        dispatch(getSubTotal(currency))
                                        navigate('/profile');

                                    }}
                                >
                                    Remove from Cart
                                </button>

                            </div>
                        </>) : <></>}
                        {props.type === PurchaseType.ReplacementElite ? (<>
                            <h1 className=" inline-block font-custom font-black lg:text-[64px]/[86px] text-[30px]/[41px] lg:mb-[34px]">
                                Replace Elite Card
                            </h1>
                            <div className="bg-primary relative lg:w-[110%] w-[125%] h-[37px] z-[-1] lg:mt-[-70px] mt-[-18px] lg:mb-[37px] mb-[28px]" />
                            <p className="font-custom font-light lg:text-[20px]/[32px] text-[14px]/[25px] lg:px-0 lg:w-[393px] tracking-[0.05em] mt-0 lg:mr-0 mr-[-5px] lg:mb-[37px] mb-[20px]">
                                Need a replacement card for your ELITE team? Not to worry we got you covered.
                            </p>
                            <h3 className='w-[337px] font-custom text-[25px]/[34px] font-normal mb-[19px]'>Which Card(s) Do You Need A Replacement For?</h3>
                            <div className="w-[342px] rounded-[10px] shadow-custom bg-white px-[18px] py-[32px] mb-[37px]">
                                <CardSelector errorClass="mt-[10px]" error={error} value={(order as Elite).npCard.length > 0 ? `${(order as Elite).npCard.reduce((p, c) => { return { extra: (p.extra ?? 0) + (c.extra ?? 1) } as PCard }).extra ?? 0} Replacement Cards` : ''} hint="Select Card(s)" label="Card Number" items={cards.map((e, i) => { return { value: cards[i].extra!, orderNumber: e.orderNumber, fullName: e.fullName, onChange: (a) => { setCards([...cards.slice(0, i), { ...cards[i], extra: a }, ...cards.slice(i + 1)]) } } })} onChange={() => { }} />
                            </div>
                            <div className="flex gap-[42px] lg:mb-[19px] mb-[46px]">
                                <div className="flex w-[100px]">
                                    <p className="font-custom font-normal text-[36px]/[49px] ">
                                        ${curr['eliteReplacement'][currency]}
                                    </p>
                                    <p className="font-custom font-light text-[18px]/[25px] flex self-start pt-[5px] pl-[6px]">
                                        {" "}
                                        {currency}
                                    </p>
                                    <p className="font-custom font-light text-[20px]/[25px] flex self-end mb-[5px] ">
                                        /card
                                    </p>
                                </div>
                            </div>

                            <div className="flex lg:justify-between flex-col lg:mb-[52px] mb-[23px] gap-[26px]">
                                <button
                                    className="w-[201px] font-custom font-medium text-white  lg:text-[20px]/[27px] text-[16px]/[25px] text-center bg-button hover:bg-inset rounded-[33px] lg:mr-0  lg:pt-[12px] pt-[12px] lg:pb-[14px] pb-[14px]"
                                    onClick={() =>
                                    {
                                        dispatch(setOrder({ valid: false } as Black))
                                        dispatch(getSubTotal(currency))
                                        navigate('/profile');

                                    }}
                                >
                                    Remove from Cart
                                </button>

                            </div>
                        </>) : <></>}
                        {
                            props.type === PurchaseType.NewMember ? (<>

                                <h1 className=" inline-block font-custom font-black lg:text-[64px]/[86px] text-[30px]/[41px] lg:mb-[34px]">
                                    Add New Member
                                </h1>
                                <div className="bg-primary relative lg:w-[150%] w-[125%] h-[37px] z-[-1] lg:mt-[-70px] mt-[-18px] lg:mb-[37px] mb-[22px]" />
                                <p className="font-custom font-light lg:text-[20px]/[32px] text-[14px]/[25px] lg:px-0  lg:w-[335px] w-[341px] tracking-[0.05em] lg:mb-[34px] mb-[14px]">
                                    Need to increase your team size? We’ve got you covered!
                                </p>
                                <div className="mb-[50px]">
                                    <div className="flex flex-row items-start justify-start gap-[28px]">
                                        <div className="flex flex-row lg:mb-0 mb-[20px]">
                                            <p className="font-custom font-normal text-[36px]/[49px]">
                                                $
                                                {curr['eliteBaseUnit'][currency]}
                                            </p>
                                            <p className="font-custom font-light text-[18px]/[25px]  flex self-start pt-[5px] pl-[6px]">
                                                {" "}
                                                {currency}
                                            </p>
                                            <p className="font-custom font-light text-[20px]/[25px] flex self-end mb-[5px] ">
                                                /card
                                            </p>
                                        </div>
                                        <div className="flex flex-col gap-[16px]">
                                            <Incrementer
                                                minimum={1}
                                                value={(order as Elite).base}
                                                onDecrement={() =>
                                                {
                                                    setlocalOrder({ ...order, base: (order as Elite).base - 1 });
                                                }}
                                                onIncrement={() =>
                                                {
                                                    setlocalOrder({ ...order, base: (order as Elite).base + 1 });
                                                }}
                                            />
                                            <button
                                                className="font-custom font-medium text-white shadow-custom text-[20px]/[27px]  text-center hover:bg-inset bg-button rounded-[33px] lg:mb-[15px] lg:px-[14px] px-[14px] w-[201px] h-[52px]"
                                                onClick={() =>
                                                {
                                                    dispatch(setOrder({ valid: false } as Elite))
                                                    dispatch(getSubTotal(currency))
                                                    navigate('/shop')
                                                }}
                                            >
                                                Remove from Cart
                                            </button>
                                        </div>
                                    </div>
                                </div>



                                <div className="lg:mb-[41px] mb-[30px] flex flex-col ">
                                    <h4 className="font-custom font-normal text-[25px]/[34px] mb-[21px]">
                                        Personalized Cards
                                    </h4>
                                    <p className="font-custom font-light text-[20px]/[32px] lg:mb-[6px] mb-[16px] lg:w-auto w-[281px] tracking-[0.05em]">
                                        Want to personalize your ELITE cards?
                                    </p>
                                    <p className="font-custom font-light text-[20px]/[32px] lg:mb-[15px] mb-[16px] tracking-[0.05em]">
                                        Example of Personalized:
                                    </p>

                                    <div className="flex items-start justify-start flex-shrink-0  lg:mb-[18px] mb-[21px]">
                                        <img
                                            className="xl:max-w-[100%] lg:max-w-[75%] h-auto "
                                            src={personal}
                                        />
                                    </div>

                                    <p className="font-custom font-light text-[20px]/[32px] lg:mb-[15px] mb-[16px] tracking-[0.05em]">
                                        Example of Non-Personalized:
                                    </p>
                                    <div className="relative flex items-start justify-start flex-shrink-0 lg:mb-[35px] mb-[39px]">
                                        <img
                                            className="lg:max-w-[350]  h-[200px]"
                                            src={mock}
                                        />
                                        <img
                                            className={
                                                (order.company.length > 0 || order.blank
                                                    ? "hidden"
                                                    : "") +
                                                " absolute top-[60px] lg:left-[74px] left-[74px] lg:w-[65px] w-[65px] h-auto"
                                            }
                                            src={ledgerluxe}
                                            alt=""
                                        />
                                        <img
                                            className={
                                                (order.company.length > 0
                                                    ? order.blank
                                                        ? "hidden"
                                                        : ""
                                                    : "hidden") +
                                                " absolute top-[60px] lg:left-[74px] left-[74px] lg:w-[65px] w-[65px] h-auto"
                                            }
                                            src={your_logo_here}
                                            alt=""
                                        />
                                    </div>

                                    <div className="flex lg:mb-[22px] mb-[13px]">
                                        <p className="font-custom font-normal text-[36px]/[49px]">
                                            $10
                                        </p>
                                        <p className="font-custom font-light text-[18px]/[25px]  flex self-start pt-[5px] pl-[6px]">
                                            {currency}
                                        </p>
                                        <p className="font-custom font-light lg:text-[18px]/[25px] text-[20px]/[32px] flex self-center pt-[5px] pl-[3px]">
                                            /card
                                        </p>
                                    </div>
                                    <div className="flex lg:flex-row flex-col lg:gap-[57px] gap-[10px] lg:justify-between lg:items-center items-start mb-[21px]">
                                        <p className="font-custom font-light text-[14px]/[19px]">
                                            Personalized Cards{" ".repeat(6)}
                                        </p>
                                        <Incrementer
                                            value={(order as Elite).personalized}
                                            maximum={(order as Elite).base}
                                            minimum={0}
                                            onDecrement={() =>
                                            {
                                                setlocalOrder({
                                                    ...order,
                                                    personalized: (order as Elite).personalized - 1,
                                                    nonPersonalized: (order as Elite).nonPersonalized + 1,
                                                    pCard: (order as Elite).pCard.filter(
                                                        (e, i) => i !== (order as Elite).pCard.length - 1
                                                    ),
                                                });
                                            }}
                                            onIncrement={() =>
                                            {
                                                if ((order as Elite).nonPersonalized - 1 >= 0)
                                                {
                                                    setErrors({
                                                        ...errors,
                                                        pcards: [
                                                            ...errors.pcards,
                                                            { fullName: "", } as PCard,
                                                        ],
                                                    });
                                                    setlocalOrder({
                                                        ...order,
                                                        personalized: (order as Elite).personalized + 1,
                                                        nonPersonalized: (order as Elite).nonPersonalized - 1,
                                                        pCard: [
                                                            ...(order as Elite).pCard,
                                                            {
                                                                fullName: "",
                                                                orderNumber: "",
                                                                qr: "",
                                                            },
                                                        ],
                                                    });
                                                }
                                            }}
                                        />
                                    </div>

                                    <div className="flex lg:flex-row flex-col lg:gap-[57px] gap-[10px] lg:justify-between lg:items-center items-start mb-[21px]">
                                        <p className="font-custom font-light text-[14px]/[19px]">
                                            Non-Personalized Cards
                                        </p>
                                        <Incrementer
                                            value={(order as Elite).nonPersonalized}
                                            maximum={(order as Elite).base}
                                            minimum={0}
                                            onDecrement={() =>
                                            {
                                                if ((order as Elite).nonPersonalized - 1 >= 0)
                                                {
                                                    setErrors({
                                                        ...errors,
                                                        pcards: [
                                                            ...errors.pcards,
                                                            { fullName: "", } as PCard,
                                                        ],
                                                    });
                                                    setlocalOrder({
                                                        ...order,
                                                        personalized: (order as Elite).personalized + 1,
                                                        nonPersonalized: (order as Elite).nonPersonalized - 1,
                                                        pCard: [
                                                            ...(order as Elite).pCard,
                                                            {
                                                                fullName: "",
                                                                orderNumber: "",
                                                                qr: "",
                                                            },
                                                        ],
                                                    });
                                                }
                                            }}
                                            onIncrement={() =>
                                            {
                                                if ((order as Elite).personalized - 1 >= 0)
                                                {
                                                    setlocalOrder({
                                                        ...order,
                                                        personalized: (order as Elite).personalized - 1,
                                                        nonPersonalized: (order as Elite).nonPersonalized + 1,
                                                        pCard: (order as Elite).pCard.filter(
                                                            (e, i) => i !== (order as Elite).pCard.length - 1
                                                        ),
                                                    });
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                {/*----------------- */}
                                <div className="flex flex-col items-start justify-start w-full">
                                    {(order as Elite).pCard.map((e, i) => (
                                        <PersonalizedCard
                                            onChangeName={(a) =>
                                            {
                                                setlocalOrder({
                                                    ...order,
                                                    pCard: [
                                                        ...(order as Elite).pCard.slice(0, i),
                                                        { ...e, fullName: a },
                                                        ...(order as Elite).pCard.slice(i + 1),
                                                    ],
                                                });
                                            }}

                                            error_name={errors.pcards[i].fullName}
                                            key={i.toString()}
                                            name={e.fullName}
                                            number={(i + 1).toString()}
                                            w="lg:w-[344px] w-[288px]"
                                            classname=""
                                        />
                                    ))}
                                </div>

                            </>) : <></>
                        }
                    </div>
                </div>
            </div>
            <div className="lg:mx-[80px] mx-[25px] flex flex-row justify-end z-50 relative">
                <button
                    className=" flex font-custom items-center justify-center font-medium text-white shadow-custom lg:text-[24px]/[32px] text-[16px]/[25px] text-center hover:bg-inset bg-button rounded-[42px] lg:px-[37px] lg:pt-[25px] pt-[18px] lg:pb-[23px] pb-[16px] lg:mb-0 mb-[25px] lg:w-fit w-full"
                    onClick={(e) =>
                    {
                        handleSubmit();
                    }}
                >
                    <img alt="" src={cart} className="mr-[20px]"></img>
                    <div className="mr-[15px]">Go To Cart</div>
                </button>
            </div>
            <Footer />
        </div>
    </>
    );
}
