import { DocumentReference, Timestamp } from "firebase/firestore";
import { Currency, curr } from "../currencies";
import store from "../redux/store";
import { Language } from "../languages";


export type User = {
  customUrl: string | undefined
  photo: string | undefined;
  name: string | undefined;
  lastName: string | undefined;
  email: string[] | undefined;
  bio: string | undefined;
  website: Website[] | undefined;
  phone: Phone[];
  jobTitle: string | undefined;
  company: string | undefined;
  industry: string | undefined;
  city: string | undefined;
  stateProvince: string | undefined;
  country: string | undefined;
  linkedin: string | undefined;
  facebook: string | undefined;
  instagram: string | undefined;
  whatsapp: string | undefined;
  tiktok: string | undefined;
  twitter: string | undefined;
  snapchat: string | undefined;
  finishedCreating: boolean;
  addresses: Address[];
  media: Media[];
  orderNumber: string;
  eliteAdmin: boolean;
  eliteCompany: DocumentReference;
  editingDisabled: boolean;
  language: Language;
};

export interface Website
{
  website: string,
  title: string
}
export interface Metric
{
  orderNumber: string
  visits?: number
  contactsSaved?: number
  leadsCreated?: number
  phoneCalls?: number
  websiteVisits?: number
  getACard?: number
  emailTaps?: number
  addressLookups?: number
  mediaLookups?: number
  fb?: number
  ig?: number
  tw?: number
  tk?: number
  sc?: number
  li?: number
  wp?: number
  locations?: Location[]
}


export interface location
{
  city: string,
  state: string,
  country: string
}

export interface Reseller
{
  url: string
  orderNumber: string,
  code: string
}

export type EliteCompany = {
  companyId: string;
  companyName: string;
  logo: string[];
  cards: string[];
  activeCards: string[];
  adminCards: string[];
  useCustomColors: boolean;
  primaryColor: string;
  secondaryColor: string;
  primaryWhite: boolean;
  secondaryWhite: boolean;
  whiteArrows: boolean;
  logoURL?: string
  useLogoURL?: boolean
}

export type Phone = {
  number: string;
  ext: string;
  type: string | PhoneTypes;
}

export enum PhoneTypes
{
  Work = 1,
  Cell = 2,
  Home = 3
}
export interface ContactDoc extends Contact
{
  ownerOrder: string
  latitude?: number
  longitude?: number
  ref?: DocumentReference
  saved: boolean
  date: Timestamp
}

export type Contact =
  {
    firstName: string,
    lastName: string,
    email?: string,
    phone: Phone,
    notes: string,
    user?: DocumentReference
  }

export type Address = {
  name: string;
  addressline1: string;
  addressline2: string;
  city: string;
  stateProvince: string;
  country: string;
  postalCode: string;
}

export type Media = {
  mediaShowcase: string;
  mediaThumbnail: string;
  mediaTitle: string;
  mediaUrl: string;
  mediaMessage: string;
}

export type Card = {
  type: string,
  orderNumber: string,
  qrCode: string,
  url: string,
  name: string,
  companyName: string,
  logo: string[],
  customLogo: boolean,
  blank: boolean,
  color: string,
  personalized: boolean,
  specialInstructions: string,
  eliteCompanyRef: DocumentReference,
}
export interface CardObject
{
  name: string;
  orderNumber: string;
  personalized: boolean;
}

export interface Billing
{
  firstName: string;
  lastName: string;
  emailAddress: string;
  streetAddress: string;
  stateProvince: string;
  city: string;
  zipCode: string;
  phone: string;
  country: string;
}

export interface Discount
{
  active: Boolean,
  affiliateName: string,
  createdOn: string,
  code: string,
  percentDiscount: number,
  timesUsed: number,
  totalDiscounted: number
}

export interface Shipping
{
  firstName: string;
  lastName: string;
  emailAddress: string;
  streetAddress: string;
  stateProvince: string;
  city: string;
  zipCode: string;
  phone: string;
  country: string;
}

export interface Order
{
  id: string;
  cards: string[] | { extra: number, orderNumber: string }[];
  date: Timestamp;
  extraCards: number;
  type: string;
  grandTotal: number;
  uploadLogoLater: boolean;
  shippingMethod: string;
  company: string;
  color: string;
  buyerEmail: string;
  logo: string[];
  blank: boolean;
  tax: number;
  currency: Currency
  personalizedCards: string[] | "none";
  billing?: Billing;
  shipping?: Shipping;
  discountAffiliate?: string
  discountAmount?: number,
  discountCode?: string
}

export interface Dictionary<Type>
{
  [key: string]: Type
}
export type Email = {
  fullName: string;
  sender: string;
  subject: string;
  message: string;
  dest: string;
  attachments?: Attachments[];
};
export type Attachments = {
  filename: string;
  content: string;
  encoding: string;
};

export let ColorLabels = {
  Silver: `Silver +$${curr['silver'][store.getState().currency]}`,
  Gold: `Gold +$${curr['silver'][store.getState().currency]}`,
  White: 'White (SOLD OUT)'
}

let listener = () =>
{
  let currency = store.getState().currency;
  ColorLabels = {
    Silver: `Silver +$${curr['silver'][currency]}`,
    Gold: `Gold +$${curr['silver'][currency]}`,
    White: 'White (SOLD OUT)'
  }
}
store.subscribe(listener);
