import { useRef, useState } from "react";
import { useOutsideAlerter } from "./SocialDropDown";
import arrow from '../assets/images/arrow.svg';
import { Currency } from "../currencies";

interface CurrencySelectorProps
{
    classname?: string,
    items: { image: string, text: Currency, class: string }[],
    value?: string,
    onChange: CallableFunction & ((arg: Currency) => void),
}
export const CurrencySelector = ({ ...props }: CurrencySelectorProps) =>
{
    const [open, setOpen] = useState(false);
    const select = useRef<HTMLDivElement>(null);
    const [img, setImg] = useState(props.items.find((e, i) => { return e.text === props.value })?.image);
    useOutsideAlerter(select, () => { setOpen(false) });

    const handleOpen = () =>
    {
        setOpen(!open);
    };
    function handleClick(a: any, i: number)
    {
        let c = a.target.innerText as Currency
        props.onChange(c);
        setImg(props.items[i].image)
    }
    return <div ref={select} className="w-full z-[1000000]">
        <div className={props.classname ?? '' + ' flex flex-row flex-grow'}>
            <div onClick={handleOpen} tabIndex={0} className={" flex-grow flex flex-col justify-start font-custom font-light tracking-[0.05em]  "}>
                <input type="select" className="hidden" onChange={() => { }} value={props.value} />
                <div className={(props.value === '' ? 'hidden' : 'block') + " flex justify-between font-medium  text-[13px]/[19px] tracking-[0.65px] px-[24px] pt-[11px] pb-[10px] select-none cursor-pointer"}>
                    <div className="flex flex-row gap-[10px]">
                        <img className="w-[30px] ml-[-8px]" src={img} />
                        {props.value}
                    </div>
                    <img className={(open ? "rotate-180 duration-75" : 'rotate-0 duration-75') + ' w-[16px] h-[7px] self-center'} src={arrow} />
                </div>

                <div className="relative ">
                    <div className={(open ? 'block' : 'hidden') + " absolute z-[51] mt-[-2px]  w-full opacity-25"} />
                    <div className={"absolute inset-[-1.6px] absolute-offset z-50  "}>
                        <div className={open ? ' max-h-[200px] flex flex-col overflow-y-auto rounded-b-[4px] shadow-xl ' : 'hidden '}>
                            {props.items.map((element, i) =>
                            {
                                if (typeof element === 'string')
                                {
                                    return <div key={i} className=" basis-1/5 z-50 text-[16px]/[32px] bg-white">
                                        <div className=" border-black border-[1px] opacity-25 " />
                                        <div className=" py-[4px] px-[24px] cursor-pointer hover:bg-gray-200" onClick={(a) => { handleClick(a, i) }}>{element}</div>
                                    </div>
                                } else
                                {
                                    return <div key={i} className=" basis-1/5 z-50 text-[13px]/[19px] font-medium tracking-[0.65px] bg-white">
                                        <div className="flex flex-row items-center w-full hover:bg-slate-300 ">
                                            <div className="ml-[18px] w-[30px]"><img src={element.image} /></div>
                                            <div className={element.class + " py-[10px] pl-[10px] cursor-pointer "} onClick={(a) => { handleClick(a, i) }}>{element.text}</div>
                                        </div>
                                    </div>
                                }
                            })}</div>
                    </div>
                </div>
            </div>
        </div>
        <div className={(open ? 'block' : 'hidden') + " pointer-events-none fixed top-0 left-0 w-screen h-screen "}>

        </div>
    </div >
}
