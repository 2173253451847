import React, { useEffect } from 'react'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import bcx_black from '../../assets/images/shop-bcx-black.png';
import bcx_elite from '../../assets/images/shop-bcx-elite.png';
import { Navigate, useNavigate } from 'react-router-dom';
import { Cart } from '../../components/Cart';
import { Currency, curr } from '../../currencies';
import { useSelector } from 'react-redux';

export const Shop = () =>
{
  useEffect(() =>
  {
    window.scrollTo(0, 0)
  }, [])
  const navigate = useNavigate();
  const currency: Currency = useSelector((state: any) => state.currency);
  return (
    <>
      <Cart />
      <div className='overflow-hidden'>
        <Navbar />
        <div className='flex lg:flex-row lg:pl-[80px] lg:pr-[178px] px-[25px] lg:mt-[110px] mt-[20px] mb-[67px] justify-between'>
          <div className='relative' >
            <h1 className=' inline-block font-custom font-black lg:text-[64px]/[86px] text-[30px]/[41px] lg:mb-[34px]'>
              Shop
            </h1>
            <div className='bg-primary relative lg:w-[150%] w-[125%]  h-[37px] z-[-1]  lg:mt-[-70px] mt-[-18px] lg:mb-[98px] mb-[30px]' />
            <h3 className='font-custom font-medium text-text text-[39px]/[53px] mb-[24px] m-0'>
              bcX BLACK
            </h3>
            <p className='font-custom font-light lg:text-[20px]/[32px] text-[14px]/[25px] lg:px-0  lg:w-[342px]  tracking-[0.05em] mt-0 lg:mb-[25px] mb-[32px]'>
              Upgrade your networking game with bcX BLACK and experience the convenience and efficiency of a digital business card.
            </p>
            <div className='lg:hidden flex items-start justify-center flex-shrink-0  lg:mt-[136px] mt-[15px] xl:ml-0 '>
              <img className='xl:max-w-[100%] lg:max-w-[75%] h-auto' src={bcx_black} />
            </div>
            <div className=' flex lg:flex-row flex-col justify-start lg:mb-[34px] mb-[9px] lg:items-end'>
              <p className='font-custom font-light text-[20px]/[32px] lg:mr-[10px]'>Starting at</p>
              <div className='flex'>
                <p className='font-custom font-normal text-[36px]/[49px]'>${curr['blackBase'][currency]}</p>
                <p className='lg:hidden font-custom font-light text-[18px]/[25px] flex self-start pt-[5px] pl-[6px]'> {' ' + currency}</p>
              </div>
              <p className='hidden font-custom font-light text-[18px]/[25px] lg:flex self-start pt-[5px] pl-[6px]'>{' ' + currency}</p>
            </div>
            <div className='lg:block flex justify-end'>
              <button id='add-black' className='font-custom font-medium text-button shadow-custom lg:text-[19px]/[32px] text-[16px]/[25px] text-center tracking-[0.05em] bg-primary hover:bg-secondary rounded-[33px]  lg:px-[106px] px-[99px] lg:pt-[16px] pt-[12px] lg:pb-[18px] pb-[14px]' onClick={() => { navigate('/blackcard'); }}>
                Add to Cart
              </button>
            </div>
          </div>
          <div className='lg:flex items-start justify-center flex-shrink-0 hidden  lg:mt-[136px] xl:ml-0 '>
            <img className='xl:max-w-[100%] lg:max-w-[75%] h-auto' src={bcx_black} />
          </div>
        </div>

        <div className='flex lg:flex-row lg:mt-[111px] lg:mb-[112px] mb-[54px] lg:pl-[80px] pl-[25px] lg:pr-[106px] pr-[25px] justify-between'>
          <div className='relative'>
            <h3 className='font-custom font-medium text-text text-[39px]/[53px] mb-[33px] m-0 lg:mt-[110px]'>
              bcX ELITE
            </h3>
            <p className='font-custom font-light lg:text-[20px]/[32px] text-[14px]/[25px] lg:px-0  lg:w-[342px]  tracking-[0.05em] mt-0 lg:mb-[25px] mb-[13px]'>
              Give your employees the tools they need to make lasting connections with bcX ELITE's managed employee dashboard and custom branding.
            </p>
            <div className='lg:hidden flex items-start justify-center flex-shrink-0  mb-[10px]'>
              <img className='xl:max-w-[100%] lg:max-w-[75%] h-auto' src={bcx_elite} />
            </div>
            <div className=' flex lg:flex-row flex-col justify-start lg:mb-[19px] mb-[10px] lg:items-end'>
              <p className='font-custom font-light text-[20px]/[32px] lg:mr-[10px]'>Starting at</p>
              <div className='flex items-end'>
                <p className='font-custom font-normal text-[36px]/[49px]'>${curr['eliteBase'][currency]}</p>
                <p className='lg:hidden font-custom font-light text-[18px]/[25px] flex self-start pt-[5px] pl-[6px]'>{' ' + currency}</p>
              </div>
              <p className='hidden font-custom font-light text-[18px]/[25px] lg:flex self-start pt-[5px] pl-[6px]'>{' ' + currency}</p>
            </div>
            <p className='font-custom font-light text-[14px]/[19px] '>Minimum 2 users.</p>
            <div className='lg:block flex justify-end lg:mt-[37px] mt-[33px]'>
              <button id='add-elite' className='font-custom font-medium text-white shadow-custom lg:text-[19px]/[32px] text-[16px]/[25px] text-center tracking-[0.05em] bg-button hover:bg-inset rounded-[33px] lg:mr-0 mr-[5px] lg:px-[106px] px-[99px] lg:pt-[16px] pt-[12px] lg:pb-[18px] pb-[14px]' onClick={() => { navigate('/elitecard'); }}>
                Add to Cart
              </button>
            </div>
          </div>
          <div className='lg:flex items-start justify-start xl:ml-0 lg:ml-[40px]  flex-shrink-0 hidden  '>
            <img className='xl:max-w-[100%] lg:max-w-[75%] h-auto' src={bcx_elite} />
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}
