import React, { ChangeEvent, ReactNode, useEffect, useRef, useState } from 'react'
import menu from "../../assets/images/hamburger.svg";
import CTextField from '../../components/CTextField';
import { useNavigate } from 'react-router-dom';
import { auth1, db } from '../../services/firebase';
import validator from 'validator';
import { deleteUser, sendPasswordResetEmail } from 'firebase/auth';
import { Alert } from '@mui/material';
import { Help, Warning, WarningAmber } from '@mui/icons-material';
import { collection, deleteDoc, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore';

export const DeleteAccount = () =>
{
  const [menuToggle, setMenuToggle] = useState(true);
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [error, setError] = useState({ email: '', confirm: '' });
  const [deleting, setDeleting] = useState(false);
  const navigate = useNavigate();

  const deleteAccount = async () =>
  {
    try
    {
      setDeleting(true);

      const user = auth1.currentUser;
      if (user)
      {
        const currentUserRef = doc(db, 'users', user.uid);
        const userSnapshot = await getDoc(currentUserRef);

        const deletionPromises: Promise<void>[] = [];
        const eliteUpdatePromises: Promise<void>[] = [];

        const userData = userSnapshot.data();
        const orderNumber = userData?.orderNumber;

        const eliteRef = collection(db, 'eliteCompanies');
        const eliteQuery = query(eliteRef, where('cards', 'array-contains', orderNumber));
        eliteUpdatePromises.push(
          getDocs(eliteQuery).then((eliteDocsSnapshot) =>
          {
            eliteDocsSnapshot.forEach((eliteDoc) =>
            {
              const eliteData = eliteDoc.data();

              const updatedActiveCards = eliteData.activeCards.filter((card: string) => card !== orderNumber);
              const updatedAdminCards = eliteData.adminCards.filter((card: string) => card !== orderNumber);

              eliteUpdatePromises.push(
                updateDoc(eliteDoc.ref, {
                  activeCards: updatedActiveCards,
                  adminCards: updatedAdminCards,
                })
              );
            });
          })
        );

        deletionPromises.push(deleteDoc(currentUserRef));

        await Promise.all(eliteUpdatePromises);
        await Promise.all(deletionPromises);

        await deleteUser(user);
        // Account successfully deleted
      }
    } catch (error)
    {
      // Handle any errors
    } finally
    {
      setDeleting(false);
    }
  };

  async function handleSubmit()
  {
    let valid = true
    let error = {
      email: '',
      confirm: ''
    }
    if (email !== confirmEmail)
    {
      valid = false
      error.confirm = "Both emails don't match"
    }
    if (email !== auth1.currentUser?.email)
    {
      valid = false
      error.email = 'Please put your own email address'
    }
    if (!validator.isEmail(email))
    {
      valid = false
      error.email = 'Please provide a valid email'
    }
    if (!email)
    {
      valid = false
      error.email = 'Please confirm your email'
    }
    if (valid)
    {
      await deleteAccount();
    }
    setError(error);
  }
  return <div className='w-full'>
    <div className="flex flex-col items-center bg-button lg:pr-[80px] px-[25px] lg:h-full h-[100vh] w-full lg:pt-[58px] pt-[59px] lg:pb-[220px]">
      <div className="self-end lg:mb-[70px] mb-[29px] ">
        <button onClick={() => { setMenuToggle(!menuToggle) }}><img src={menu} /></button>
      </div>
      <h3 className='font-custom font-medium text-white lg:text-[57px]/[77px] text-[30px]/[41px] lg:mb-[31px] mb-[29px]'>Deactivate Account</h3>
      <div className='flex bg-deletePageBox lg:w-[467px] w-full p-[20px] mb-[29px] items-center font-light text-[20px]/[32px] font-custom text-[red]'>
        <WarningAmber style={{ color: 'red' }} />
        <p className='ml-[20px] '>
          Deactivating your account will remove your profile and email address entirely, and you will need to create a new account.
        </p>
      </div>
      <h1 className='lg:self-center self-start font-custom font-light text-white text-[14px]/[25px] tracking-[0.05em] mb-[38px]'>
        Type your email below to continue.
      </h1>
      <div className='lg:w-[354px] w-full mb-[41px]'>
        <CTextField error={error.email} label='Email' textcolor='#fff' bgcolor='#252529' bordercolor='#FBBC05' type='email' value={email} onChange={(e) => { setEmail(e) }} classname='w-full' />
      </div>
      <div className='lg:w-[354px] w-full mb-[62px]'>
        <CTextField error={error.confirm} label='Confirm Email' textcolor='#fff' bgcolor='#252529' bordercolor='#FBBC05' type='email' value={confirmEmail} onChange={(e) => { setConfirmEmail(e) }} classname=' w-full' />
      </div>
      <button onClick={() => handleSubmit()} className='lg:w-[393px] w-[333px] font-custom font-medium text-white shadow-custom text-[20px]/[27px] text-center bg-poppy rounded-[26px] mb-[30px] lg:pt-[10px] pt-[12px] lg:pb-[10px] pb-[12px]' >
        Deactivate Account
      </button>
    </div>
    <div className={(menuToggle ? 'hidden' : ' animated-slide-left ') + ' z-50 opacity-100 absolute right-0 top-0 lg:w-1/2 w-3/4 h-full bg-button'}>
      <div className='fixed lg:w-1/2 w-3/4'>
        <div className=' flex flex-col lg:mt-[52px] mt-[47px] lg:ml-[80px] ml-[25px] lg:mr-[80px] mr-[25px]'>
          <div className='flex flex-row justify-between mb-[61px]'>
            <h2 className='font-custom font-medium text-white text-[30px]/[41px]'>
              Settings
            </h2>
            <button className='' onClick={() => { setMenuToggle(!menuToggle) }}>
              <img src={menu} />
            </button>
          </div>
          <div className='flex flex-col gap-[20px] items-end'>
            <button onClick={() => navigate('/profile')} className=' font-custom text-white font-light lg:text-[20px]/[32px] text-[14px]/[25px] tracking-[0.05em]'>Profile</button>
            <button onClick={() => navigate('/about')} className=' font-custom text-white font-light lg:text-[20px]/[32px] text-[14px]/[25px] tracking-[0.05em]'>About</button>
            <button onClick={() => navigate('/contact')} className=' font-custom text-white font-light lg:text-[20px]/[32px] text-[14px]/[25px] tracking-[0.05em]'>Contact</button>
            <button onClick={() => navigate('/shop')} className=' font-custom text-white font-light lg:text-[20px]/[32px] text-[14px]/[25px] tracking-[0.05em] mb-[102px]'>Shop</button>
            <button className=' font-custom text-white font-light lg:text-[20px]/[32px] text-[14px]/[25px] tracking-[0.05em]' onClick={() =>
            {
              auth1.signOut();
              navigate("/login");
            }
            }>Logout</button>
          </div>
        </div>
      </div>
    </div>
  </div >
}
