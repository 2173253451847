import { useRef, useState } from "react";
import { useOutsideAlerter } from "./SocialDropDown";
import { Language, lang } from "../languages";
import CTextField from "./CTextField";
interface LogoURLModalProps
{
    isOpen: boolean
    onClose: CallableFunction
    onSubmit: CallableFunction & ((arg: any) => void)
    value: string
};

export const LogoURLModal = ({
    ...props
}: LogoURLModalProps) =>
{


    const [urlError, setURLError] = useState('')
    const [url, setURL] = useState(props.value)
    const [loading, setLoading] = useState(false);
    const element = useRef<HTMLDivElement>(null);
    useOutsideAlerter(element, () => { /*handleClose()*/ });
    /*
    function handleClose()
    {
        if (!deepEqual(contact, { phone: {} as Phone } as Contact))
        {
            setConfirmation(true);
        } else
        {
            props.onClose()
        }
    
    }*/


    function validateURL()
    {
        let valid = true;
        let newError = '';
        try
        {
            if (!url)
            {
                valid = false
                newError = 'Please provide a valid URL'
            }
            new URL(url)

        } catch (error)
        {
            console.log(error)
            valid = false
            newError = 'Please provide a valid URL'
        } finally
        {
            return { urlValid: valid, urlError: newError };
        }
    }


    function handleSubmit()
    {

        const { urlValid, urlError } = validateURL();

        if (urlValid && !loading)
        {
            setLoading(true);
            props.onSubmit(url);
            setLoading(false);
        }
        setURLError(urlError);
    }


    return <>
        <div className={(props.isOpen ? 'block' : 'hidden') + ""}>
            <div ref={element} className={"p-[34px] relative rounded-2xl md:w-[420px] w-[360px]  left-1/2 transform -translate-x-1/2 translate-y-[10%] bg-white shadow-lg flex flex-col justify-between "}>
                <button onClick={() => { props.onClose() }} className="absolute right-0 top-0 mr-[12px] transform rotate-45 text-[38px] font-normal font-custom ">+</button>
                <h2 className=" text-[39px] font-custom font-medium text-center mb-[27px]">
                    Set the URL to redirect to when users click your company's logo
                </h2>
                <div className="flex flex-col gap-[24px]">
                    <CTextField
                        label={'Logo URL'}
                        error={urlError}
                        value={url}
                        onChange={(a) =>
                        {
                            setURL(a)
                        }
                        } />

                    <button disabled={loading} onClick={handleSubmit} className="self-center w-full font-custom text-[20px]/[32px] font-medium rounded-[23px] tracking-[0.05em] hover:bg-inset hover:text-button text-white bg-button pt-[10px] pb-[10px]">Save</button>
                </div>
            </div>
        </div>
    </>
};


