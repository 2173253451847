import { useRef, useState } from "react";
import { Language } from "../languages";
import { useOutsideAlerter } from "./SocialDropDown";
import arrow from '../assets/images/arrow.svg';

interface LanguageSelectorProps
{
    classname?: string,
    items: { text: Language, class?: string }[],
    value?: string,
    onChange: CallableFunction & ((arg: Language) => void),
    arrow: boolean,
    valueClass?: string
}

export const LanguageSelector = ({ ...props }: LanguageSelectorProps) =>
{
    const [open, setOpen] = useState(false);
    const select = useRef<HTMLDivElement>(null);
    useOutsideAlerter(select, () => { setOpen(false) });
    const handleOpen = () =>
    {
        setOpen(!open);
    };
    function handleClick(a: any, i: number)
    {
        let c = a.target.innerText.trim() as Language;
        if (Object.values(Language).includes(c))
        {
            props.onChange(c);
        }
    }
    return <div ref={select} className="w-full z-[1000000]">
        <div className={props.classname ?? '' + ' flex flex-row flex-grow'}>
            <div onClick={handleOpen} tabIndex={0} className={" flex-grow flex flex-col justify-start font-custom font-light tracking-[0.05em]  "}>
                <input type="select" className="hidden" onChange={() => { }} value={props.value} />
                <div className={(props.value === '' ? 'hidden' : 'block') + " flex font-medium  text-[13px]/[19px] tracking-[0.65px] self-center text-center pt-[11px] pb-[10px] select-none cursor-pointer"}>
                    <div className={props.valueClass ?? '' + " w-[30px] text-white"}>
                        {props.value}
                    </div>
                    {
                        props.arrow &&
                        <img className={(open ? "rotate-180 duration-75" : 'rotate-0 duration-75') + ' w-[16px] h-[7px] self-center'} src={arrow} />
                    }
                </div>

                <div className="relative ">
                    <div className={(open ? 'block' : 'hidden') + " absolute z-[51] mt-[-2px]  w-full opacity-25"} />
                    <div className={"absolute inset-[-1.6px] absolute-offset z-50  "}>
                        <div className={open ? ' max-h-[200px] flex flex-col overflow-y-auto rounded-b-[4px] shadow-xl ' : 'hidden '}>
                            {props.items.map((element, i) =>
                            {
                                if (typeof element === 'string')
                                {
                                    return <div key={i} className=" basis-1/5 z-50 text-[16px]/[32px] bg-button" onClick={(a) => { handleClick(a, i) }}>
                                        <div className=" border-black border-[1px] opacity-25 " />
                                        <div className=" py-[4px] px-[24px] cursor-pointer hover:bg-gray-200" >{element}</div>
                                    </div>
                                } else
                                {
                                    return <div key={i} className=" basis-1/5 z-50 text-[13px]/[19px] font-medium tracking-[0.65px] bg-button" onClick={(a) => { handleClick(a, i) }}>
                                        <div className="flex flex-row items-center w-full hover:bg-slate-300 text-white">
                                            <div className={element.class + " py-[10px] pl-[10px] cursor-pointer "}>{element.text}</div>
                                        </div>
                                    </div>
                                }
                            })}</div>
                    </div>
                </div>
            </div>
        </div>
        <div className={(open ? 'block' : 'hidden') + " pointer-events-none fixed top-0 left-0 w-screen h-screen "}>

        </div>
    </div >
}
