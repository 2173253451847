import { useNavigate } from 'react-router-dom';
import logo from '../assets/images/bcx_logo.svg';
import cad from '../assets/images/CAD.svg';
import usd from '../assets/images/USD.svg';
import { useEffect, useState } from 'react';
import { DocumentData, doc, getDoc } from 'firebase/firestore';
import { auth1, db } from '../services/firebase';
import { Black, Purchase, PurchaseType, getSubTotal, setOrder } from '../redux/feature/cartSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Currency } from '../currencies';
import { CurrencySelector } from './CurrencySelector';
import { setCurrency } from '../redux/feature/currencySlice';
import { Timestamp } from 'firebase/firestore';
import { getCurrentTimeInEST } from '../utils/features';

interface NavbarProps
{
    alternate?: boolean,
    hidecurr?: boolean,
    classname?: string,
    translate?: boolean
}
export enum Mode
{
    LoggedIn,
    Public
}

const Navbar = ({ ...props }: NavbarProps) =>
{
    const [mode, setMode] = useState(Mode.Public);
    const [loading, setLoading] = useState(true)
    const cart: Purchase = useSelector((state: any) => state.cart);
    const dispatch = useDispatch();
    const currency: Currency = useSelector((state: any) => state.currency);
    const [localCurrency, setLocalCurrency] = useState<Currency>(currency);

    const getUser = async () =>
    {
        let userDoc: DocumentData | undefined = undefined;
        auth1.onAuthStateChanged(async (user) =>
        {
            if (user)
            {
                setMode(Mode.LoggedIn)
                setLoading(false)
            }
            else
            {
                setMode(Mode.Public)
                setLoading(false)
            }
        });
    };
    function handleCart()
    {
        if (!cart.order.orderNumber)
        {
            if (cart.subtotal && cart.subtotal > 0)
            {

                if (cart.order.valid)
                {

                    navigate('/cart');
                } else
                {
                    cart.order.type === PurchaseType.Black ? navigate('/blackcard') : navigate('/elitecard')
                }
            }
            else
            {
                navigate('/empty-cart')
            }
        }
        else
        {
            navigate(`/replacement-cart`)
        }
    }

    useEffect(() =>
    {
        if (loading)
        {
            getUser()
        }
    }, [mode])

    useEffect(() =>
    {
        dispatch(setCurrency(localCurrency))
    }, [dispatch, localCurrency])


    async function logout()
    {
        dispatch(setOrder({ valid: false } as Black))
        dispatch(getSubTotal(localCurrency))
        await auth1.signOut();
        navigate('/login');
    }

    const navigate = useNavigate();
    return (
        <div className={props.classname}>
            <div className={(props.alternate || props.hidecurr ? 'hidden ' : '') + 'flex justify-end items-center flex-row lg:pt-[28px] pt-[10px] lg:pr-[110px] pr-[16px] w-full'}>
                <div className='font-custom text-text font-medium tracking-[0.05em] text-[13px]/[19px] mr-[10px]'>
                    Currency:
                </div>

                <div className='flex items-center justify-between gap-[8px] w-[150px]'>
                    <CurrencySelector items={[{ text: Currency.CAD, image: cad, class: '' },]} onChange={(a) => { setLocalCurrency(a) }} value={localCurrency} />
                </div>
            </div>
            <nav className="bg-white h-full lg:mt-[15px] mt-[17px] lg:px-[80px] px-[21px] relative m-0 ">
                <div className={(props.alternate ? '' : 'lg:flex-row lg:gap-0 ') + 'flex  flex-col items-center gap-[27px] h-full w-full justify-between relative'}>
                    <div className={(props.alternate ? ' ' : 'self-start ml-[10px]') + " flex lg:flex-grow-0 lg:flex-shrink-0 lg:self-center  lg:ml-0 justify-center items-center"}>
                        <button onClick={() => { navigate('/'); }}>
                            <img src={logo} alt="bcx's Logo" className="max-h-[100%] max-w-[100%]" />
                        </button>
                    </div>
                    <div className="flex items-center justify-center lg:w-auto w-full">
                        <div className="flex flex-row lg:gap-[81px] w-full items-center lg:justify-center justify-between">
                            <button onClick={() => { navigate('/about'); }} className="px-[10px] text-text self-center text-center tracking-[0.05em] font-custom font-light text-[16px]/[32px] menu-button ">About</button>
                            <button onClick={() => { navigate('/shop'); }} className="px-[10px] text-text self-center text-center tracking-[0.05em] font-custom font-light text-[16px]/[32px] menu-button">Shop</button>
                            <button onClick={() => { handleCart() }} className="px-[10px] text-text self-center text-center tracking-[0.05em] font-custom font-light text-[16px]/[32px] menu-button">Cart</button>
                            <button onClick={() => { navigate('/login'); }} className={(mode === Mode.Public ? 'block' : 'hidden') + " px-[10px] text-text self-center text-center tracking-[0.05em] font-custom font-light text-[16px]/[32px] menu-button"}>Login</button>
                            <button onClick={() => { navigate('/profile'); }} className={(mode !== Mode.Public ? 'block' : 'hidden') + " px-[10px] text-text self-center text-center tracking-[0.05em] font-custom font-light text-[16px]/[32px] menu-button"}>Profile</button>
                            <button onClick={() => { logout() }} className={(mode !== Mode.Public ? 'block' : 'hidden') + " px-[10px] text-text self-center text-center tracking-[0.05em] font-custom font-light text-[16px]/[32px] menu-button"}>Logout</button>
                        </div>

                    </div>
                </div>
            </nav>
        </div>
    );
};

export default Navbar;
