
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar'
import square_left from "../../assets/images/signupsucc-left-squares.svg";
import square_right from "../../assets/images/signupsucc-right-squares.svg";
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from "../../services/firebase";
import { CircularProgress } from '@mui/material';
export const UserNotFound = () =>
{
    const navigate = useNavigate();
    const params = useParams();
    const param = params.param;
    const [exists, setExists] = useState(false);
    const [loading, setLoading] = useState(true);
    useEffect(() =>
    {
        const checkOrder = async () =>
        {
            const q = query(collection(db, 'cards'), where('orderNumber', '==', param));
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty)
            {
                let data = querySnapshot.docs[0]?.data();

                setExists(true);
            } else
            {
                setExists(false);
            }
            setLoading(false);
        }
        checkOrder();
    }, []);
    if (loading)
    {
        return (
            <div className="overflow-hidden flex items-center justify-center h-screen relative w-[100vw] ">
                <div className=" relative">
                    <CircularProgress color="inherit" />
                </div>
            </div>
        );
    }

    return (
        <div className="">
            <Navbar classname="lg:hidden block mb-[129px]" hidecurr alternate />
            <div className="lg:relative flex lg:flex-row flex-col justify-between">
                <div className="lg:block hidden h-full justify-start lg:z-50 pointer-events-none">
                    <img className=" max-h-[95%] h-auto" src={square_left} />
                </div>
                <div className="flex flex-col lg:absolute lg:left-[50%] lg:translate-x-[-50%] pb-[66px]">
                    <Navbar classname="lg:block hidden self-start mb-[112px] " alternate />
                    <div className="self-center flex flex-col justify-center ">
                        <h2 className="lg:w-[666px] w-[350px] font-custom font-medium self-center text-center lg:text-[57px]/[77px] text-[30px]/[41px] lg:mb-[81px] mb-[19px] ">
                            It Looks Like This Account
                            Hasn’t Been Set Up Yet Or Doesn't Exist!
                        </h2>

                        <button className='w-[333px]  self-center font-custom font-medium text-button shadow-custom text-[20px]/[27px] text-center tracking-[0.05em] hover:bg-secondary bg-primary rounded-[26px] lg:mb-[42px] mb-[62px] lg:pt-[10px] pt-[12px] lg:pb-[14px] pb-[12px]'
                            onClick={() => { navigate('/') }}>
                            Go Home
                        </button>
                        <div className='flex flex-col font-custom font-medium text-center self-center justify-center lg:text-[26px]/[32px] tracking-[0.05em]'>
                            <div className='mb-[12px]'>{exists ? 'Order Number' : 'Invalid URL'}:</div>
                            <div>
                                {param}
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" lg:block hidden h-full justify-end lg:z-50 pointer-events-none">
                    <img className=" max-h-[100%] h-auto" src={square_right} />
                </div>
            </div>
        </div>
    )

}
