import React, { ReactNode, useEffect, useRef, useState } from "react";

import { CircularProgress } from "@mui/material";
import
{
  DocumentReference,
  getDoc,
} from "firebase/firestore";
import { EliteCompany } from "../types";
import { SketchPicker } from "react-color";

type ColorPickerModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (primary: string, secondary: string) => void;
  primaryColor: string;
  secondaryColor: string;
  children: ReactNode;
  eliteCompanyRef: DocumentReference;
};

const ColorPickerModal: React.FC<ColorPickerModalProps> = ({
  isOpen,
  onClose,
  primaryColor,
  secondaryColor,
  children,
  eliteCompanyRef,
  onConfirm,
}) =>
{
  const modalRef = useRef<HTMLDivElement>(null);
  const [sketchPickerColor, setSketchPickerColor] = useState<string>(primaryColor);
  const [sketchPickerSecondaryColor, setSketchPickerSecondaryColor] = useState<string>(secondaryColor);
  const [loading, setLoading] = useState(false)
  const [eliteCompany, setEliteCompany] = useState<EliteCompany>();
  const [changingColor, setChangingColor] = useState(false);

  const getEliteCompany = async () =>
  {
    const elite = await getDoc(eliteCompanyRef);
    const eCompany = elite.data() as EliteCompany;
    setEliteCompany(eCompany);
  };

  const handleChangeColor = async () =>
  {
    setChangingColor(true);
    onConfirm(sketchPickerColor, sketchPickerSecondaryColor)
  }

  useEffect(() =>
  {
    async function fetchData()
    {
      await getEliteCompany();
      setLoading(false);
    }
    fetchData();
  }, [loading]);

  useEffect(() =>
  {
    const handleEscape = (event: KeyboardEvent) =>
    {
      if (event.key === "Escape" && !loading && !changingColor)
      {
        onClose();
      }
    };

    const handleOutsideClick = (event: MouseEvent) =>
    {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node) && !loading && !changingColor
      )
      {
        onClose();
      }
    };

    if (isOpen)
    {
      document.addEventListener("keydown", handleEscape);
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () =>
    {
      document.removeEventListener("keydown", handleEscape);
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, onClose, loading, changingColor]);

  if (!isOpen)
  {
    return null;
  }
  if (changingColor)
  {
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50 ">
        <div className="modal-container" ref={modalRef}>
          <div className="flex flex-col bg-white rounded-lg shadow-lg w-[446px] h-[522px] justify-center items-center p-[52px]">
            <CircularProgress color="inherit" />
            <div className="font-custom text-[24px] font-medium mt-[67px]">
              Changing company's color...
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (loading)
  {
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50 ">
        <div className="modal-container" ref={modalRef}>
          <div className="flex flex-col bg-white rounded-lg shadow-lg w-[446px] h-[422px] justify-center items-center p-[52px]">
            <CircularProgress color="inherit" />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 ">
      <div className="modal-container" ref={modalRef}>
        <div className="flex flex-col bg-white rounded-lg shadow-lg w-[446px] min-h-[422px] justify-center items-start p-[52px]">
          {children}
          <div className="flex flex-col p-4 bg-white rounded-lg shadow-lg w-[342px] min-h-[176px]">
            <div className="flex flex-row mb-[16px]">
              <SketchPicker
                className="mr-[16px]"
                onChange={(color) =>
                {
                  setSketchPickerColor(color.hex);
                }}
                disableAlpha={true}
                color={sketchPickerColor}
              />
              <SketchPicker
                onChange={(color) =>
                {
                  setSketchPickerSecondaryColor(color.hex);
                }}
                disableAlpha={true}
                color={sketchPickerSecondaryColor}
              />
            </div>
            <button
              className=" font-custom font-medium text-white shadow-custom text-[15px]/[25px] tracking-[0.05em] text-center bg-button rounded-[50px]  lg:pt-[10px] pt-[12px] lg:pb-[10px] pb-[10px] px-[10px]"
              onClick={async () =>
              {
                await handleChangeColor();
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColorPickerModal;
