import { useEffect, useRef, useState } from "react"
import { Language, lang } from "../languages";
import CTextField from "./CTextField";
import { CPhoneInput } from "./PhoneInput";
import CSelect from "./CSelect";
import { phoneTypel18n, stringToPhoneType } from "../utils/features";
import { useOutsideAlerter } from "./SocialDropDown";
import { Contact, Phone, PhoneTypes } from "../types";
import { formatPhoneNumber, isPossiblePhoneNumber } from "react-phone-number-input";
import validator from "validator";

interface ConfirmationPopUpProps
{
    isOpen: boolean
    title: string
    cancel: string
    confirm: string
    cancelClass?: String
    confirmClass?: String
    onClose: CallableFunction
    onConfirm: CallableFunction
    onCancel: CallableFunction
}

export const ConfirmationPopUp = ({ ...props }: ConfirmationPopUpProps) =>
{

    const element = useRef<HTMLDivElement>(null);
    useOutsideAlerter(element, () => { handleClose() });

    function handleClose()
    {
        props.onClose()
    }

    function handleConfirm()
    {
        props.onConfirm()
    }
    function handleCancel()
    {
        props.onCancel()
    }


    return <div className={(props.isOpen ? 'block backdrop-blur-sm' : 'hidden') + " z-[100000000] fixed bottom-0 top-0 left-0 right-0 w-full h-[200hv] bg-opacity-50 bg-black"}>
        <div ref={element} className={"p-[34px] relative rounded-2xl md:w-[420px] w-[360px]  left-1/2 transform -translate-x-1/2 top-1/2 -translate-y-1/2 bg-white shadow-lg flex flex-col justify-between "}>
            <button onClick={() => { handleClose() }} className="absolute z-10 text-center right-0 top-0 mr-[12px] transform rotate-45 text-[38px] font-normal font-custom ">+</button>
            <h2 className=" text-[30px] font-custom font-medium text-center mb-[27px]">
                {props.title}
            </h2>
            <div className="flex flex-row justify-center gap-[50px]">
                <button onClick={() => { handleCancel() }} className="self-center w-full font-custom text-[20px]/[32px] font-medium rounded-[23px] tracking-[0.05em] hover:bg-inset hover:text-button text-white bg-button pt-[10px] pb-[10px]">{props.cancel}</button>
                <button onClick={() => { handleConfirm() }} className="self-center w-full font-custom text-[20px]/[32px] font-medium rounded-[23px] tracking-[0.05em] hover:bg-inset hover:text-button text-white bg-deleteText pt-[10px] pb-[10px]">{props.confirm}</button>
            </div>

        </div>
    </div>

}
