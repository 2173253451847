import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Currency } from "../../currencies";


const currencySlice = createSlice({
    name: "currency",
    initialState: Currency.CAD,
    reducers: {
        setCurrency: (state, action: PayloadAction<Currency>) =>
        {
            state = action.payload;
            return state
        }
    }
})

export const {
    setCurrency
} = currencySlice.actions;

export default currencySlice.reducer;