import React, { FormEvent, useState } from "react";
import Navbar from "../../components/Navbar";
import
{
  FacebookLogin,
  GoogleSignIn,
  Apple,
  handleSignUp,
} from "../../auth/auth";
import square_left from "../../assets/images/signup-left-squares.svg";
import square_right from "../../assets/images/signup-right-squares.svg";
import CTextField from "../../components/CTextField";
import { Navigate, useNavigate } from "react-router-dom";
import validator from "validator";
type SignUpFields = {
  email: string;
  password: string;
  confirmPassword: string;
};

export const SignUp = () =>
{
  const navigate = useNavigate();
  const [submit, setSubmit] = useState(true);
  const [errors, setErrors] = useState<SignUpFields>({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [signup, setSignup] = useState<SignUpFields>({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) =>
  {
    e.preventDefault();
    const newErrors = {
      email: "",
      password: "",
      confirmPassword: "",
    };
    let valid = true;

    if (!signup.email.trim() || !validator.isEmail(signup.email.trim()))
    {
      newErrors.email = "Provide a valid Email";
      valid = false;
    }
    if (!signup.password.trim())
    {
      newErrors.password = "A Password is required";
      valid = false;
    }
    if (!signup.password.trim() !== !signup.confirmPassword)
    {
      newErrors.confirmPassword = "The passwords don't match";
      valid = false;
    }
    if (valid)
    {
      const result = await handleSignUp(signup.email, signup.password, signup.confirmPassword).then((a) =>
      {

        if (a === 'Firebase: Error (auth/email-already-in-use).')
        {
          newErrors.email = 'This email is not available'
        } else if (a === 'Firebase: Password should be at least 6 characters (auth/weak-password).')
        {
          newErrors.password = 'The Password should be at least 6 characters'
        } else if (a === "Passwords don't match")
        {
          newErrors.confirmPassword = "The Passwords don't match"
        }
      });

    }
    setErrors(newErrors);
  };

  return (
    <div className="">
      <Navbar classname="lg:hidden block mb-[21px]" hidecurr alternate />
      <div className="lg:relative flex lg:flex-row flex-col justify-between">
        <div className="lg:block hidden h-full justify-start">
          <img className=" max-h-[95%] h-auto" src={square_left} />
        </div>

        <div className="flex flex-col lg:absolute lg:left-[50%] lg:translate-x-[-50%]  pb-[66px]">
          <Navbar classname="lg:block hidden self-start mb-[40px]" alternate />
          <div className="self-center flex flex-col justify-center ">

            <h4 className="self-center font-custom font-light text-[18px]/[24px]  lg:tracking-normal tracking-[0.05em] lg:text-center mb-[31px]">
              Sign up with
            </h4>
            <div className="flex flex-row justify-center gap-[24px] mb-[43px]">

              <GoogleSignIn classname="" />

            </div>
            <h4 className=" self-center font-custom font-light lg:text-[18px]/[24px]  lg:tracking-normal tracking-[0.05em] lg:text-center mb-[38px]">
              Or with email
            </h4>

            <div className="flex flex-col mb-[30px] self-center justify-center">
              <form id="signup" className="flex flex-col items-center justify-center" onSubmit={handleSubmit}>
                <CTextField
                  value={signup.email}
                  onChange={(a) =>
                  {
                    setSignup({ ...signup, email: a });
                  }}
                  label="Email"
                  w="w-[354px]"
                  classname="self-center mb-[39px]"
                  error={errors.email}
                />
                <CTextField
                  value={signup.password}
                  onChange={(a) =>
                  {
                    setSignup({ ...signup, password: a });
                  }}
                  label="Password"
                  w="w-[354px]"
                  type="password"
                  classname="self-center lg:mb-[38px] mb-[21px]"
                  error={errors.password}
                />
                <CTextField
                  value={signup.confirmPassword}
                  onChange={(a) =>
                  {
                    setSignup({ ...signup, confirmPassword: a });
                  }}
                  label="Confirm Password"
                  w="w-[354px]"
                  type="password"
                  classname="self-center lg:mb-[66px] mb-[21px]"
                  error={errors.confirmPassword}
                />

                <button
                  disabled={!submit}
                  type="submit"
                  form="signup"
                  className=" w-[343px] self-center font-custom font-medium text-button shadow-custom  text-[20px]/[27px]  text-center tracking-[0.05em] hover:bg-secondary bg-primary rounded-[26px]  lg:mx-[122px] lg:mb-0 mb-[62px] lg:pt-[10px] pt-[12px] lg:pb-[14px] pb-[12px]"
                >
                  Sign Up
                </button>
              </form>
            </div>
            <h2 className=" self-center font-custom font-normal text-[35px]/[48px]  lg:tracking-normal tracking-[0.05em] lg:text-center mb-[31px]">
              Have an Account?
            </h2>

            <button
              className=" w-[343px] self-center font-custom font-medium text-white shadow-custom  text-[20px]/[27px]   text-center tracking-[0.05em] hover:bg-inset bg-button rounded-[26px]  lg:mx-[122px] lg:pt-[10px] pt-[12px] lg:pb-[14px] pb-[12px]"
              onClick={() =>
              {
                navigate("/login");
              }}
            >
              Login
            </button>
          </div>
        </div>

        <div className=" lg:block hidden h-full justify-end">
          <img className=" max-h-[100%] h-auto" src={square_right} />
        </div>
      </div>
    </div>
  );
};
