import Resizer from "react-image-file-resizer";
import { Email, User, Website } from "../types";
import { phoneTypel18n, stringToPhoneType } from "./features";
import { Language } from "../languages";
import getPossibleCountries from "libphonenumber-js";
import { twMerge } from "tailwind-merge";
import clsx, { ClassValue } from "clsx";


export function cn(...inputs: ClassValue[])
{
    return twMerge(clsx(inputs));
}

export function getTintedColor(color: string, v: number): string
{
    if (color.length > 6)
    {
        color = color.substring(1, color.length);
    }
    const rgb = parseInt(color, 16);
    let r = Math.abs(((rgb >> 16) & 0xFF) + v);
    r = r > 255 ? r - (r - 255) : r;
    r = Number(r < 0 || isNaN(r)) ? 0 : (r > 255 ? 255 : r);
    let g = Math.abs(((rgb >> 8) & 0xFF) + v);
    g = g > 255 ? g - (g - 255) : g;
    g = Number(g < 0 || isNaN(g)) ? 0 : (g > 255 ? 255 : g);
    let b = Math.abs((rgb & 0xFF) + v);
    b = b > 255 ? b - (b - 255) : b;
    b = Number(b < 0 || isNaN(b)) ? 0 : (b > 255 ? 255 : b);
    r = Math.round(r);
    g = Math.round(g);
    b = Math.round(b);
    const hexR = r.toString(16).padStart(2, '0');
    const hexG = g.toString(16).padStart(2, '0');
    const hexB = b.toString(16).padStart(2, '0');
    return `#${hexR}${hexG}${hexB}`;
}


export async function fetchData(url: string): Promise<Blob>
{
    return new Promise((resolve, reject) =>
    {
        const xhr = new XMLHttpRequest();
        xhr.onload = () =>
        {
            if (xhr.status === 200)
            {
                resolve(xhr.response);
            } else
            {
                reject(new Error(`Failed to fetch data. Status: ${xhr.status}`));
            }
        };
        xhr.onerror = () =>
        {
            reject(new Error('Failed to make the request.'));
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    });
}

export async function readAndCompressImage(blob: Blob, url: string): Promise<string>
{
    return new Promise((resolve, reject) =>
    {
        const reader = new FileReader();
        reader.onloadend = async () =>
        {
            try
            {
                const compressedBlob = await compressImage(new Blob([reader.result as ArrayBuffer]), url) as Blob;
                const compressedData = await compressedBlob.text();
                const imageData = compressedData.replace(/^data:image\/?[A-z]*;base64,/, '');
                resolve(imageData);
            } catch (error)
            {
                reject(error);
            }
        };
        reader.readAsArrayBuffer(blob);
    });
}

async function compressImage(img: Blob, url: string)
{
    const imgUrl = getFileTypeFromUrl(url).toUpperCase();

    if (imgUrl === "JPEG" || imgUrl === "PNG" || imgUrl === "JPG")
    {
        try
        {
            return new Promise((resolve) =>
            {
                Resizer.imageFileResizer(img, 750, 750, imgUrl, 80, 0, (uri) =>
                {
                    resolve(new Blob([uri as string]));
                });
            });
        } catch { }
    } else
    {
        return img;
    }
}
export function getFileTypeFromUrl(url: string)
{
    const fileName = url.substring(url.lastIndexOf("/") + 1);
    const fileExtension = fileName.substring(fileName.lastIndexOf(".") + 1);
    return fileExtension.toLowerCase().substring(0, fileExtension.indexOf("?"));
}

export async function saveContact(user: User)
{
    let dataURL = '';
    if (user.photo && user.photo !== 'no' && user.photo !== '')
    {
        console.log('a');
        const blob = await fetchData(user.photo);
        dataURL = await readAndCompressImage(blob, user.photo);
    }

    let vCardData =
        `BEGIN:VCARD
VERSION:3.0
KIND: individual
N:${user.lastName ?? ''};${user.name ?? ''};;;
FN: ${user.name ?? ''} ${user.lastName ?? ''}
${user.email ? user.email.map((e) => { if (!e) { return '' } return `EMAIL:${e}` }).join("\n") : ''}
${user.addresses ? user.addresses.map((e) => { if (!e) { return '' } return `ADR;TYPE=work:;;${e.addressline1 ?? ''} ${e.addressline2 ?? ''};${e.city ?? ''};${e.stateProvince ?? ''};${e.postalCode ?? ''};${e.country ?? ''}` }).join("\n") : ''}
${user.phone ? user.phone.map((e) =>
        {
            if (!e.number)
            {
                return ''
            }
            const phone = getPossibleCountries(e.number);
            return `TEL;TYPE=${phoneTypel18n(stringToPhoneType(e.type), Language.ENG).toLocaleLowerCase()}:${"+" + phone?.countryCallingCode + "-" + phone?.formatNational()}`;
        }).join("\n") : ''}
${user.company ? `ORG:${user.company}` : ''}
${user.jobTitle ? `ROLE:${user.jobTitle}` : ''}
${user.website ? user.website.map((e) => { return `URL:${e.website}` }).join("\n") : ''}
${`URL:https://businesscardx.com/profile/${user.orderNumber}`}
PHOTO;TYPE=${getFileTypeFromUrl(
            user.photo ?? ""
        ).toUpperCase()};ENCODING=b;VALUE=BASE64:${dataURL}

END:VCARD`;
    vCardData = vCardData.replaceAll(/\n{2,}/g, '\n')
    var filename = `${user.name ?? ''} ${user.lastName ?? ''}.vcf`;
    var myFile = new File([vCardData], filename, {
        type: "text/vcard;charset=utf-8",
    });
    const url = window.URL.createObjectURL(myFile);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
}

export function backwardsWebsite(website: string[] | Website[])
{
    if (!website)
    {
        return undefined
    }

    const result = website.map((e: string | Website) =>
    {
        if (typeof e === 'string')
        {
            return { website: e, title: '' }
        } else
        {
            return e
        }
    })

    return result


}

export function deepEqual(obj1: any, obj2: any): boolean
{
    // If both objects are strictly equal, they are the same
    if (obj1 === obj2)
    {
        return true;
    }

    // If either of the objects is null or not an object, they are not the same
    if (
        typeof obj1 !== "object" ||
        obj1 === null ||
        typeof obj2 !== "object" ||
        obj2 === null
    )
    {
        return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // If the number of keys is different, they are not the same
    if (keys1.length !== keys2.length)
    {
        return false;
    }

    for (const key of keys1)
    {
        // If the key is not present in obj2 or the values are not deeply equal, they are not the same
        if (!obj2.hasOwnProperty(key) || !deepEqual(obj1[key], obj2[key]))
        {
            return false;
        }
    }

    return true;
}

export async function sendEmail(mail: Email, onSuccess: (response: Response) => void, onError?: (response: Response) => void)
{
    await fetch(process.env.REACT_APP_SENDMAIL_URL!, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(mail),
    }).then((response) =>
    {
        if (response.status === 200 || response.status === 204)
        {
            onSuccess(response)
        } else
        {
            if (onError)
            { onError(response) }
        }
    }).catch((response) =>
    {
        if (onError)
        { onError(response) }
    });
}

export function generateRandomPassword(length: number): string 
{
    const charset =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()";
    let password = "";

    for (let i = 0; i < length; i++)
    {
        const randomIndex = Math.floor(Math.random() * charset.length);
        password += charset[randomIndex];
    }

    return password;
};
