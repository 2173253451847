import QRCode from "qrcode"
export default function generateQRCode(urlToQR: string): string
{
  let callbackUrl = '';
  QRCode.toDataURL(urlToQR, {
    width: 400,
    margin: 2,
    color: {
      dark: '#000000FF',
      light: '#EEEEEE00'
    }
  }, (err, url) =>
  {
    if (err) return console.error(err)
    callbackUrl = url;
  })
  return callbackUrl;
}