import React, { ChangeEvent, ReactNode, useEffect, useRef, useState } from 'react'
import menu from "../../assets/images/hamburger.svg";
import CTextField from '../../components/CTextField';
import { useNavigate } from 'react-router-dom';
import { auth1 } from '../../services/firebase';
import validator from 'validator';
import { sendPasswordResetEmail } from 'firebase/auth';

export const ProfileChangePassword = () =>
{
    const [menuToggle, setMenuToggle] = useState(true);
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    async function handleSubmit()
    {
        let valid = true
        let error = ''

        if (!validator.isEmail(email))
        {
            valid = false
            error = 'Please provide a valid email'
        }
        if (!email)
        {
            valid = false
            error = 'Please confirm your email'
        }

        if (valid)
        {

            await sendPasswordResetEmail(auth1, email).then(async (a) =>
            {
                await auth1.signOut().then(() =>
                {
                    navigate("/login");
                });
            }).catch((a) =>
            {
                if (a.message === 'Firebase: Error (auth/user-not-found).')
                {
                    error = "The email you provided doesn't match with our info.";
                }
            });
        }
        setError(error);
    }
    return <div className='w-full'>
        <div className="flex flex-col items-center bg-button lg:pr-[80px] px-[25px] lg:h-full h-[100vh] w-full lg:pt-[58px] pt-[59px] lg:pb-[220px]">
            <div className="self-end lg:mb-[70px] mb-[29px] ">
                <button onClick={() => { setMenuToggle(!menuToggle) }}><img src={menu} /></button>
            </div>
            <h3 className='font-custom font-medium text-white lg:text-[57px]/[77px] text-[30px]/[41px] lg:mb-[31px] mb-[29px]'>Password Change</h3>
            <h1 className='lg:self-center self-start font-custom font-light text-white text-[14px]/[25px] tracking-[0.05em] mb-[68px]'>
                Want to change your password?
            </h1>
            <div className='lg:w-[354px] w-full mb-[142px]'>
                <CTextField error={error} label='Email' textcolor='#fff' bgcolor='#252529' bordercolor='#FBBC05' type='email' value={email} onChange={(e) => { setEmail(e) }} classname='mb-[41px] w-full' />
            </div>
            <button onClick={() => handleSubmit()} className='lg:w-[393px] w-[333px] font-custom font-medium text-button shadow-custom text-[20px]/[27px] text-center hover:bg-secondary bg-primary rounded-[26px] mb-[30px] lg:pt-[10px] pt-[12px] lg:pb-[10px] pb-[12px]' >
                Change Password
            </button>
        </div>
        <div className={(menuToggle ? 'hidden' : ' animated-slide-left ') + ' z-50 opacity-100 absolute right-0 top-0 lg:w-1/2 w-3/4 h-full bg-button'}>
            <div className='fixed lg:w-1/2 w-3/4'>
                <div className=' flex flex-col lg:mt-[52px] mt-[47px] lg:ml-[80px] ml-[25px] lg:mr-[80px] mr-[25px]'>
                    <div className='flex flex-row justify-between mb-[61px]'>
                        <h2 className='font-custom font-medium text-white text-[30px]/[41px]'>
                            Settings
                        </h2>
                        <button className='' onClick={() => { setMenuToggle(!menuToggle) }}>
                            <img src={menu} />
                        </button>
                    </div>
                    <div className='flex flex-col gap-[20px] items-end'>
                        <button onClick={() => navigate('/edit-profile')} className='hover:border-b-inherit border-b-[1px] border-b-transparent font-custom text-white font-light lg:text-[20px]/[32px] text-[14px]/[25px] tracking-[0.05em]'>Edit Profile</button>

                        <button onClick={() => navigate('/about')} className='hover:border-b-inherit border-b-[1px] border-b-transparent font-custom text-white font-light lg:text-[20px]/[32px] text-[14px]/[25px] tracking-[0.05em]'>About</button>
                        <button onClick={() => navigate('/contact')} className='hover:border-b-inherit border-b-[1px] border-b-transparent font-custom text-white font-light lg:text-[20px]/[32px] text-[14px]/[25px] tracking-[0.05em]'>Contact</button>
                        <button onClick={() => navigate('/shop')} className='hover:border-b-inherit border-b-[1px] border-b-transparent font-custom text-white font-light lg:text-[20px]/[32px] text-[14px]/[25px] tracking-[0.05em] mb-[102px]'>Shop</button>
                        <button className='hover:border-b-inherit border-b-[1px] border-b-transparent font-custom text-white font-light lg:text-[20px]/[32px] text-[14px]/[25px] tracking-[0.05em]' onClick={() =>
                        {
                            auth1.signOut();
                            navigate("/login");
                        }
                        }>Logout</button>
                    </div>
                </div>
            </div>
        </div>
    </div >
}
