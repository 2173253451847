import { Language, lang } from "../languages";
import menu from "../assets/images/hamburger.svg";
import { User } from "../types";
import { LanguageSelector } from "./LanguageSelector";
import { auth1 } from "../services/firebase";
import { Link, NavigateFunction, useLocation } from "react-router-dom";
import { cn, deepEqual } from "../utils/utils";
import { useEffect, useRef, useState } from "react";

interface ProfileMenuProps
{
    open: boolean
    onOpen: VoidFunction
    language: Language
    user: User
    navigate: NavigateFunction
    orderNumber?: string
    setLanguageSelection: (a: Language) => void
    currUser?: User
    editProfile?: boolean
}
export const ProfileMenu = ({ ...props }: ProfileMenuProps) =>
{
    const location = useLocation()
    const promptDisplayedRef = useRef(false);

    function confirmUnsaved(route: CallableFunction)
    {
        if (props.editProfile && !deepEqual(props.user, props.currUser))
        {
            const confirm = window.confirm(
                lang.unsavedWarning[props.language]);
            if (confirm)
            {
                route();
            }
        } else
        {
            route();
        }
    }
    useEffect(() =>
    {
        if (props.editProfile)
        {
            const handleBeforeUnload = (event: BeforeUnloadEvent) =>
            {
                if (!promptDisplayedRef.current)
                {
                    event.preventDefault();
                    event.returnValue = "";
                    const shouldNavigate = window.confirm(
                        lang.unsavedWarning[props.language]);
                    if (shouldNavigate)
                    {
                        const target = event.currentTarget as HTMLAnchorElement;
                        promptDisplayedRef.current = true;
                        window.removeEventListener("beforeunload", handleBeforeUnload);
                        window.location.href = target.href || ""; // Navigate to the desired location
                    } else
                    {
                        promptDisplayedRef.current = false;
                    }
                }
            };

            window.addEventListener("beforeunload", handleBeforeUnload);
            return () =>
            {
                window.removeEventListener("beforeunload", handleBeforeUnload);
            };
        }
    }, [props.user, props.editProfile]);

    return <div
        className={
            (props.open ? "hidden" : " animated-slide-left ") +
            " z-50 opacity-100 absolute right-0 top-0 lg:w-1/2 w-3/4 h-full  bg-button"
        }
    >
        <div className="fixed lg:w-1/2 w-3/4">
            <div className=" flex flex-col lg:mt-[52px] mt-[47px] lg:ml-[80px] ml-[25px] lg:mr-[80px] mr-[25px]">
                <div className="flex flex-row justify-between mb-[31px]">
                    <h2 className="font-custom font-medium text-white text-[30px]/[41px]">
                        {lang.settings[props.language]}
                    </h2>
                    <button
                        className=""
                        onClick={() =>
                        {
                            props.onOpen()
                        }}
                    >
                        <img alt="Hamburger menu icon" src={menu} />
                    </button>
                </div>
                <div className="flex flex-col gap-[20px] items-end">
                    <button
                        onClick={() =>
                        {
                            confirmUnsaved(() => { props.navigate("/profile") })
                        }}
                        className={cn("hover:border-b-inherit border-b-[1px] border-b-transparent font-custom text-white font-light lg:text-[20px]/[32px] text-[14px]/[25px] tracking-[0.05em]"
                            , { 'hidden': location.pathname == '/profile' }
                        )}>
                        {lang.profile[props.language]}
                    </button>
                    <button
                        className={cn("hover:border-b-inherit border-b-[1px] border-b-transparent font-custom text-white font-light lg:text-[20px]/[32px] text-[14px]/[25px] tracking-[0.05em]"
                            , { 'hidden': location.pathname == '/edit-profile' || location.pathname == '/editprofile' }
                        )}
                        onClick={() =>
                        {
                            if (props.user.editingDisabled)
                            {
                                alert(
                                    "You don't have permissions to edit your profile, please check with your company's admin"
                                );
                            } else
                            {

                                confirmUnsaved(() => { props.navigate("/edit-profile") })
                            }
                        }}
                    >
                        {lang.editAccount[props.language]}
                    </button>
                    <button
                        onClick={() =>
                        {
                            confirmUnsaved(() => { props.navigate("/metrics") })
                        }}
                        className={cn("hover:border-b-inherit border-b-[1px] border-b-transparent font-custom text-white font-light lg:text-[20px]/[32px] text-[14px]/[25px] tracking-[0.05em]"
                            , { 'hidden': location.pathname == '/metrics' }
                        )}>
                        {lang.metrics[props.language]}
                    </button>

                    <button
                        onClick={() =>
                        {
                            confirmUnsaved(() => { props.navigate("/contact-list") })
                        }}
                        className={cn("hover:border-b-inherit border-b-[1px] border-b-transparent font-custom text-white font-light lg:text-[20px]/[32px] text-[14px]/[25px] tracking-[0.05em]"
                            , { 'hidden': location.pathname == '/contact-list' }
                        )}>
                        {lang.contactList[props.language]}
                    </button>

                    <button
                        onClick={
                            () =>
                            {
                                confirmUnsaved(() => { props.navigate("/profile-change-password") })
                            }}
                        className="hover:border-b-inherit border-b-[1px] border-b-transparent font-custom text-white font-light lg:text-[20px]/[32px] text-[14px]/[25px] tracking-[0.05em]"
                    >
                        {lang.changePassword[props.language]}
                    </button>
                    <button
                        className={
                            (props.user.eliteAdmin && props.orderNumber === undefined
                                ? "block"
                                : "hidden") +
                            " hover:border-b-inherit border-b-[1px] border-b-transparent font-custom text-white font-light lg:text-[20px]/[32px] text-[14px]/[25px] tracking-[0.05em]"
                        }
                        onClick={() =>
                        {
                            confirmUnsaved(() => { props.navigate("/admin-view") })
                        }}
                    >
                        {lang.elitePortal[props.language]}
                    </button>
                    <Link
                        className={
                            (!props.user.eliteAdmin && props.orderNumber === undefined
                                ? "block"
                                : "hidden") +
                            " hover:border-b-inherit border-b-[1px] border-b-transparent font-custom text-white font-light lg:text-[20px]/[32px] text-[14px]/[25px] tracking-[0.05em]"
                        }
                        to={'/replacement-blackcard'}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {lang.orderReplacementCard[props.language]}
                    </Link>

                    <div className='flex flex-row justify-between'>
                        <div className='hover:border-b-inherit border-b-[1px] border-b-transparent font-custom text-white font-light lg:text-[20px]/[32px] text-[14px]/[25px] tracking-[0.05em]'>
                            {lang.language[props.language]}
                        </div>
                        <div className="ml-[10px]">
                            <LanguageSelector arrow={true} items={[{ text: Language.ENG }, { text: Language.FRE }]} onChange={(a) => { props.setLanguageSelection(a) }} value={props.language} />
                        </div>
                    </div>

                    <Link
                        to={'/about'}
                        target="_blank"
                        rel="noreferrer"
                        className=" hover:border-b-inherit border-b-[1px] border-b-transparent font-custom text-white font-light lg:text-[20px]/[32px] text-[14px]/[25px] tracking-[0.05em]"
                    >
                        {lang.about[props.language]}
                    </Link>
                    <Link
                        to={'/contact'}
                        target="_blank"
                        rel="noreferrer"
                        className=" hover:border-b-inherit border-b-[1px] border-b-transparent font-custom text-white font-light lg:text-[20px]/[32px] text-[14px]/[25px] tracking-[0.05em]"
                    >
                        {lang.contact[props.language]}
                    </Link>
                    <Link
                        to={'/shop'}
                        target="_blank"
                        rel="noreferrer"
                        className="hover:border-b-inherit border-b-[1px] border-b-transparent font-custom text-white font-light lg:text-[20px]/[32px] text-[14px]/[25px] tracking-[0.05em]"
                    >
                        {lang.shop[props.language]}
                    </Link>
                    <button
                        className=" hover:border-b-inherit border-b-[1px] border-b-transparent font-custom text-white font-light lg:text-[20px]/[32px] text-[14px]/[25px] tracking-[0.05em] mt-auto"
                        onClick={async () =>
                        {
                            confirmUnsaved(async () =>
                            {
                                await auth1.signOut().then(() =>
                                {
                                    props.navigate("/login");
                                });
                            })
                        }}
                    >
                        {lang.logout[props.language]}
                    </button>
                    <button
                        onClick={() =>
                        {
                            confirmUnsaved(() => { props.navigate("/delete-account") });
                        }}
                        className="hover:border-b-inherit border-b-[1px] border-b-transparent font-custom text-poppy font-light lg:text-[20px]/[32px] text-[14px]/[25px] tracking-[0.05em]"
                    >
                        {lang.deactivateAccount[props.language]}
                    </button>
                </div>
            </div>
        </div>
    </div>
}
