import * as React from 'react';
import { styled, } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import InputMask from 'react-input-mask';
import { Alert, Tooltip } from '@mui/material';

interface CTextFieldProps
{
    id?: string,
    name?: string
    label: string,
    hint?: string
    disabled?: boolean
    classname?: string,
    multiline?: boolean
    bordercolor?: string,
    bgcolor?: string,
    textcolor?: string,
    error?: any;
    w?: string,
    h?: string,
    variant?: string,
    onChange?: CallableFunction & ((arg: string) => void),
    type?: string
    required?: boolean,
    mask?: string | (string | RegExp)[],
    showmask?: boolean,
    placeholder?: string
    value?: string,
    textsize?: string,
    maxlength?: number
    innerRef?: React.RefObject<HTMLInputElement>,
    hidden?: boolean
    errorclass?: string
}


const Help = styled(FormHelperText, { shouldForwardProp: (prop) => prop !== 'props' })<{ props: CTextFieldProps }>

    (({ props }) => `
    font-family: 'Satoshi-Variable';
    font-size: ${props.textsize ?? '20px'};
    line-height: 32px;
    font-weight: 300;
    letter-spacing: 0.05em;
    position:absolute;
    padding-left: 23px;
    top: 18%;
    margin-bottom:0px;
    margin:0;
    color: ${props.textcolor ?? (props.disabled ? '#BDBDBD' : '#252529')};
&.Mui-focused .MuiFormHelperText-root{
    display: none;
}
`);
const Field = styled(TextField, { shouldForwardProp: (prop) => prop !== 'props' })<{ props: CTextFieldProps }>
    (({ props }) => `

    border-radius: 3px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;
    width: 100%;
    height:100%;
    
    & .MuiOutlinedInput-root {
        color: ${props.textcolor ?? '#252529'};
        height:100%;
        & fieldset {
            border: 2px solid ${props.bordercolor ?? '#252529'};
            border-radius: 3px;
            color: ${props.textcolor ?? '#252529'};
            
        }
        &:hover fieldset {
          border-color: ${props.bordercolor ?? (props.disabled ? '#BDBDBD' : '#252529')};
          color: ${props.textcolor ?? (props.disabled ? '#BDBDBD' : '#252529')};
        };
        &.Mui-focused fieldset {
          border-color:${props.bordercolor ?? '#252529'};
          color: ${props.textcolor ?? '#252529'};
        };
        & input {
        padding-top: 14px;
        padding-bottom: 14px;
        padding-left:24px;
        padding-right:24px;
        font-family: 'Satoshi-Variable';
        font-size: ${props.textsize ?? '20px'};
        line-height: 32px;
        font-weight: 300;
        letter-spacing: 0.05em;
        width:100%;
        height:100%;
        }
    }
    & .MuiInputBase-multiline{
        & fieldset {
            border: 2px solid ${props.bordercolor ?? '#252529'};
            border-radius: 3px;
        }
        &:hover fieldset {
          border-color: ${props.bordercolor ?? '#252529'};
        };
        &.Mui-focused fieldset {
          border-color:${props.bordercolor ?? '#252529'};
        };
        & textarea {
          padding-top: 16px;
          padding-bottom: 16px;
          padding-left:10px;
          padding-right:24px;
          font-family: 'Satoshi-Variable';
          font-size: ${props.textsize ?? '20px'};
          line-height: 32px;
          font-weight: 300;
          letter-spacing: 0.05em;
        }
    }
    & .MuiFormLabel-root .MuiInputBase-multiline
    {
        color: ${props.textcolor ?? '#252529'};
        font-family: 'Satoshi-Variable';
        font-size: 10px;
        line-height: 19px;
        font-weight: 300;
        letter-spacing: 0.05em;s
        };
    &:hover .MuiFormLabel-root  {
        color:${props.textcolor ?? (props.disabled ? '#C5C5C5' : '#252529')};
    };
    &.Mui-focused .MuiOutlinedInput-root .MuiFormLabel-root  {
        color:${props.textcolor ?? '#252529'};
    };
    & label.Mui-focused {
        color: ${props.textcolor ?? '#252529'};
        padding-left: 4px;
        padding-right: 4px;
        margin-top: 2px;
        margin-left: 6px;
        margin-right: 0;
      };
    & label{
        color: ${props.textcolor ?? '#252529'};
        padding-left: 4px;
        padding-right: 4px;
        margin-top: 2px;
        margin-left: 6px;
        margin-right: 0;
        font-family: 'Satoshi-Variable';
        font-size: 17px;
        line-height: 19px;
        font-weight: 300;
        letter-spacing: 0.05em;
        background-color: ${props.bgcolor ?? '#fff'};
    }
    & legend{
        font-family: 'Satoshi-Variable';
        font-size: 10px;
        line-height: 19px;
        font-weight: 300;
        letter-spacing: 0.05em;
        padding: 0;
    }
    & legend>span{
        display: none;
    }
    `);




const CTextField = ({ ...props }: CTextFieldProps) =>
{
    const [content, setContent] = React.useState(props.value ?? '');
    const [hidden, setHidden] = React.useState(false);
    const [error, setError] = React.useState(props.error ?? '');
    React.useEffect(() =>
    {
        setError(props.error);
    }, [props.error])

    const handlechange = (event: React.ChangeEvent<HTMLInputElement>) =>
    {

        var content = event.target.value.replaceAll(props.placeholder ?? '', '');

        if (props.maxlength)
        {

            if (content.length > (props.maxlength ?? Infinity))
            {
                content = content.slice(0, props.maxlength ?? Infinity)

                setError(`${props.label} has reached the limit of ${props.maxlength} characters`);
            }
            else
            {
                setError(props.error ?? '');
            }

        }


        if (!props.value)
        {
            setContent(content);
        }

        if (props.onChange)
        {
            props.onChange(content)
            setContent(event.target.value);
        }


    }
    React.useEffect(() =>
    {
        setHidden((content !== '' || ((props.value ? (props.value!.length > 0) : false) || (Boolean(props.mask) && content !== ''))))
    })

    return <div className={(props.classname ?? '') + (props.hidden ? ' hidden' : ' block')}>
        <div className={props.w + ' relative ' + props.h ?? ''}>
            {props.multiline ? <div className='z-10'><Field
                props={props}
                variant="outlined"
                type={props.type}
                label={props.label}
                sx={{ zIndex: '1' }}
                multiline={props.multiline}
                rows={3} required={props.required}
                value={props.value ?? content}
                onChange={handlechange}
                InputLabelProps={{ shrink: true, style: {} }} /></div>
                :
                <div className='z-10'>
                    <InputMask autoComplete='off' onFocusCapture={(a) => { if (a.target.autocomplete) { a.target.autocomplete = "whatever"; } }}
                        id={props.id}
                        disabled={props.disabled}
                        name={props.name}
                        mask={props.mask ?? ''}
                        onFocus={(a) => { if (a.target.autocomplete) { a.target.autocomplete = "whatever"; }; setHidden(true) }}
                        alwaysShowMask={props.showmask ?? false}
                        maskPlaceholder={props.placeholder ?? ''}
                        value={props.value ?? content}
                        onChange={handlechange}>
                        <Field
                            autoComplete='off'
                            onFocusCapture={(a: any) => { if (a.target.autocomplete) { a.target.autocomplete = "whatever"; } }}
                            inputProps={{

                                form: {
                                    autocomplete: 'off',
                                }, onFocus: (a: any) => { if (a.target.autocomplete) { a.target.autocomplete = "whatever"; } }
                            }}
                            sx={{ zIndex: '1' }}
                            props={props}
                            variant="outlined"
                            type={props.type}
                            label={props.label}
                            multiline={props.multiline}
                            rows={3} required={props.required}
                            ref={props.innerRef}
                            InputLabelProps={{ shrink: true, style: {} }} />
                    </InputMask>
                </div>}

            <div className='-z-10'>
                <Help props={props}
                    hidden={hidden}>
                    {props.hint ?? props.label}
                </Help>
            </div>

        </div>
        <div className={(error ? 'block ' : 'hidden ') + props.w + '  pt-[15px] mb-[-15px] rounded-[6px] overflow-clip ' + props.errorclass}>
            <Alert style={{ fontFamily: 'Satoshi-Variable', fontWeight: '300', fontSize: '15px', lineHeight: '20px' }} severity="warning">{error}</Alert>
        </div>
    </div>
};
export default CTextField;
