import { Alert } from '@mui/material';
import { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import CTextField from './CTextField';
import { getCountryCallingCode } from 'react-phone-number-input/input';
import { CountryCode } from 'libphonenumber-js';

interface CPhoneInputProps
{
    value: string,
    code?: string
    onChange: CallableFunction & ((arg: string) => void),
    placeholder?: string
    error?: any;
    w?: string
    classname?: string
    z?: number,
    name?: string
    label?: string
    id?: string
}


export const CPhoneInput = ({ ...props }: CPhoneInputProps) =>
{
    const [code, setCode] = useState(props.code ? getCountryCallingCode(props.code as CountryCode).toString() : '1');
    const [phone, setPhone] = useState(props.value);

    useEffect(() =>
    {
        merge(code, phone)
    }, [phone, code])

    function merge(code: string, number: string)
    {
        const mergedValue = ((number ?? '').length === 0 ? '' : '+' + code) + number;

        if (props.onChange)
        {
            props.onChange(mergedValue);
        }
    }




    return <div className={props.classname}>
        <div className={props.w ?? '' + 'relative z-[' + (props.z ?? 50) + ']'}>
            <div className='flex flex-row  justify-start items-center gap-[5px] '>
                <div >
                    <PhoneInput
                        enableSearch
                        country={props.code ? props.code?.toLowerCase() : 'ca'}
                        placeholder={props.placeholder}
                        containerStyle={{
                            height: '0%',
                            width: '0%',
                            color: '#000',
                        }}

                        dropdownStyle={
                            {
                                border: "2px solid black",
                                borderRadius: '4px',
                                backgroundColor: ' #FFFFFF',
                                left: '0px',
                                position: 'absolute',
                                zIndex: '100000',
                            }
                        }
                        buttonStyle={{
                            position: 'relative',
                            borderWidth: '2px 2px 2px 2px',
                            border: "2px solid black",
                            borderRadius: '3px',
                            width: '46px',
                            height: '55px',
                            backgroundColor: '#fff',
                            paddingTop: '13px',
                            paddingBottom: '13px',
                        }}
                        inputStyle={{
                            display: 'none',
                            visibility: 'hidden',
                            overflow: 'hidden',
                            width: '0px',
                            height: '0%',
                            paddingRight: '0px',
                            marginRight: '0px',
                            fontFamily: 'Satoshi-Variable',
                            fontWeight: '300',
                            fontSize: '20px',
                            lineHeight: '32px',
                            color: '#000',
                            paddingTop: '13px',
                            paddingBottom: '13px',
                        }}
                        inputProps={{
                            name: props.placeholder,
                            disabled: true,
                            onFocus: (a: any) => { if (a.target.autocomplete) { a.target.autocomplete = "whatever"; } }

                        }}
                        onChange={setCode} />
                </div>
                <div className={props.w}>
                    <div className={' flex flex-row gap-[8px] w-full'}>
                        <div className=' min-w-[48px] my-[-0.5px] font-light opacity-50 bg-white flex items-center justify-center font-custom text-[20px]/[32px] text-center top-[10px]'>
                            <div>
                                {((phone && phone.length === 0) ? '' : '+' + code)}
                            </div>
                        </div>
                        <CTextField id={props.id ? `${props.id}_phone` : undefined} mask={'9999999999999999999999'} classname='w-full' w=' basis-auto w-full flex-grow flex-1' value={phone} label={props.label ?? 'Phone'} onChange={setPhone} />
                    </div>
                </div>
            </div>
            <div className={(props.error ? 'block' : 'hidden') + '  pt-[15px]  rounded-[6px] overflow-clip'}>
                <Alert style={{ fontFamily: 'Satoshi-Variable', fontWeight: '300', fontSize: '15px', lineHeight: '20px' }} severity="warning">{props.error}</Alert>
            </div>
        </div>
    </div>
}
