import { useState } from 'react'
import { isMobile } from 'react-device-detect';
import generateQRCode from '../utils/qr_code_generator';
import moment from 'moment-timezone';
import { Black, Colors, Elite, Itemized, PCard, Purchase, PurchaseType, getColorData } from '../redux/feature/cartSlice';
import { Currency, curr } from '../currencies';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { Billing, Card, CardObject, ColorLabels, Discount, Order, PhoneTypes, Shipping } from '../types';
import { Billing as BillingDetails, Shipping as ShippingDetails } from '../redux/feature/cartSlice';
import { getOrderType } from '../pages/Cart';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Language, lang } from '../languages';

export function getCurrentTimeInEST(format: string = 'YYYY-MM-DD HH:mm:ss', date: Date = new Date())
{
  const localDate = date;
  const estDate = moment.tz(localDate, 'America/New_York');
  const formattedEST = estDate.format(format);
  return formattedEST
}

export enum HBool
{
  Yes = 'Yes',
  No = 'No'
}

export type Receipt =
  {
    orderNumber: string,
    name?: string,
    company?: string,
    logo: HBool,
    email?: string,
    phone?: string,
    address?: string,
    date: string,
    shippingMethod: string
    type?: PurchaseType,
    color: string,
    currency: Currency
    tax: number,
    total: number,
    items?: Itemized,
    discount?: Discount
  }

export function phoneTypel18n(type: PhoneTypes | String, language: Language)
{
  switch (type)
  {
    case PhoneTypes.Work:
      return lang.work[language];
    case PhoneTypes.Cell:
      return lang.cell[language];
    case PhoneTypes.Home:
      return lang.home[language];
    default:
      return '';

  }
}

export function stringToPhoneType(type: String | number): PhoneTypes | String
{
  if (typeof type === 'number')
  {
    return type;
  }

  switch (type)
  {
    case "Work":
      return PhoneTypes.Work;

    case "Cell":
      return PhoneTypes.Cell;

    case "Home":
      return PhoneTypes.Home;

    default:
      return '';
  }
}


export function labelToColor(color: string)
{
  switch (color)
  {
    case ColorLabels.Silver:
      return Colors.Silver;
    case ColorLabels.Gold:
      return Colors.Gold;
    case ColorLabels.White:
      return Colors.White;
    default:
      return '';
  }
}

export function colorToLabel(color: string, currency: Currency)
{
  switch (color)
  {
    case Colors.Silver:
      return ColorLabels.Silver
    case Colors.Gold:
      return ColorLabels.Gold
    case Colors.White:
      return ColorLabels.White
    default:
      return '';
  }
}

export function orderDescriptionToType(type: string)
{
  switch (type)
  {
    case "BLACK Card":
      return PurchaseType.Black;
    case "ELITE Card":
      return PurchaseType.Elite;
    case "BLACK Card Replacement":
      return PurchaseType.ReplacementBlack;
    case "ELITE Card Replacement":
      return PurchaseType.ReplacementElite;
    case "ELITE Card New Member":
      return PurchaseType.NewMember;
    default:
      return '';
  }
}

export function orderToItemized(order: Order, currency: Currency)
{
  const color = getColorData(order.color, currency);
  let personalized = order.personalizedCards ? ((typeof order.personalizedCards !== 'string') ? order.personalizedCards.length : 0) : 0;
  switch (orderDescriptionToType(order.type))
  {
    case PurchaseType.Black:
      const logo = order.uploadLogoLater ? true : (order.logo.filter((e) => { return e.length > 0 }).length >= 1)
      return {
        type: { value: curr['blackBase'][currency], description: 'BLACK Card' },
        logo: { description: 'Logo', value: logo ? curr['blackLogo'][currency] : 0 },
        color: { value: color.value, description: color.description },
        extra: { description: `Extra Card X ${order.extraCards}`, value: order.extraCards * curr['blackExtra'][currency] }
      } as Itemized

    case PurchaseType.Elite:

      return { type: { value: curr['eliteBaseUnit'][currency] * order.cards.length, description: `ELITE Card X ${order.cards.length}` }, portal: { description: 'ELITE Portal', value: (order.cards.length < 8 ? curr['eliteQuantityBase'][currency] - order.cards.length * curr['eliteQuantityDiscount'][currency] : 0) }, personalized: { description: `Personalized Card X ${personalized}`, value: personalized * curr['elitePersonalized'][currency] }, extra: { description: `Extra Set X ${order.extraCards}`, value: order.extraCards > 0 ? order.cards.length * order.extraCards * curr['eliteExtra'][currency] : 0 }, color: { value: color.value * order.cards.length, description: `${color.description} X ${order.cards.length}` }, } as Itemized;

    case PurchaseType.ReplacementBlack:
      return { type: { value: curr['blackReplacement'][currency] * order.extraCards, description: `BLACK Card Replacement X ${order.extraCards}` } } as Itemized
    case PurchaseType.ReplacementElite:
      const extra = (order.cards as { extra: number, orderNumber: string }[]).reduce((p: { extra: number, orderNumber: string }, c: { extra: number, orderNumber: string }, i) => { return { extra: p.extra! + c.extra!, orderNumber: '' } }).extra;
      return {
        type: { value: curr['eliteReplacement'][currency] * extra!, description: `ELITE Card Replacement X ${extra}` },
      } as Itemized;
    case PurchaseType.NewMember:

      return { type: { value: curr['eliteBaseUnit'][currency] * order.cards.length, description: `ELITE Card X ${order.cards.length}` }, personalized: { description: `Personalized Card X ${personalized}`, value: personalized * curr['elitePersonalized'][currency] }, color: { value: color.value * order.cards.length, description: `${color.description} X ${order.cards.length}` }, } as Itemized;
    default:
      return {};
  }
}

export function getReceipt(receipt: Receipt)
{

  const template = `
<head>
  <meta name="color-scheme" content="light dark">
  <meta name="supported-color-schemes" content="light dark">
  <style>
  @media (prefers-color-scheme: dark) {
    .darkmode
    {
      background-color:#FBBC05
    }
    .darkmode-logo
    {
      background-color:white
    }
  }
  [data-ogsc] .darkmode
  {
    background-color:#FBBC05
  }

  [data-ogsc] .darkmode-logo
  {
    background-color:white
  }

</style>
  <title>Email Styling Example</title>
</head>
<body style="font-family: Helvetica;">
  <div class="darkmode-logo"
      style="color-scheme: light only; width: 180px; height: 150px; display: flex; align-items: center; justify-content: center; margin: 0 auto;">
      <a href="https://businesscardx.com/">
          <img style="width: 100%;" alt="Logo"
              src="https://firebasestorage.googleapis.com/v0/b/business-card-x.appspot.com/o/files%2FBCXLogo%20.png?alt=media&token=310fe858-6a27-4243-b460-0fc432607070">
      </a>
  </div>
  <div class="darkmode" style="width: auto; height: 20px; background-color: #FBBC05;"></div>
  <div style="width: auto; height: auto; background-color: #252529;  padding-top: 10px; padding-bottom: 40px;">
      <p style="padding-left:25px;padding-right:25px;color-scheme: light only;color: white; font-weight: 100; font-size: 40px; text-align: center;">Welcome to the Future of
          Networking.</p>
      <div class="darkmode" style="width: 250px; height: 1px; margin: 0 auto 20px; background-color: #FBBC05;"></div>
      <div style="width: 250px; height: 20px; margin: 0 auto;"></div>
      <div
          style="width: 220px; height: auto;color-scheme: light only; background-color: white; border-radius: 10px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4); margin: 0 auto; padding: 10px 20px 10px;">
          <p style="font-size: 20px; font-weight: bold;">Order #: </p>
          <p style="font-size: 18px; font-weight: bold;">${receipt.orderNumber}</p>
          ${receipt.name && receipt.name.length > 0 ? `
          <p style="font-size: 18px; ">Name: </p>
          <p style="font-size: 16px; font-weight: 100;">${receipt.name}</p>
          `: ''
    }
          ${receipt.company && receipt.company.length > 0 ? `
          <p style="font-size: 18px; ">Company: </p>
          <p style="font-size: 16px; font-weight: 100;">${receipt.company}</p>
          `: ''
    }
          <p style="font-size: 18px; ">Logo: </p>
          <p style="font-size: 16px; font-weight: 100;">${receipt.logo}</p>
      </div>
      <div
          style="width: 220px; height: auto; background-color: white; border-radius: 10px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4); margin: 15px auto; padding: 10px 20px 25px;">
          <h3 style="font-size: 20px; font-weight: bold;">Info</h3>
          <p style="font-size: 18px; ">Email: </p>
          <p style="font-size: 16px; font-weight: 100;">${receipt.email}</p>
          <p style="font-size: 18px; ">Phone: </p>
          <p style="font-size: 16px; font-weight: 100;">${formatPhoneNumberIntl(receipt.phone!)}</p>
          <p style="font-size: 18px; ">Shipping Address: </p>
          <p style="font-size: 16px; font-weight: 100;">${receipt.address}</p>
          <p style="font-size: 18px; ">Order Date: </p>
          <p style="font-size: 16px; font-weight: 100;">${receipt.date} EST</p>
          <p style="font-size: 18px; ">Shipping Method: </p>
          <p style="font-size: 16px; font-weight: 100;">${receipt.shippingMethod}</p>
      </div>
      <div
          style="width: 220px; height: auto;color-scheme: light only; background-color: white; border-radius: 10px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4); margin: 15px auto; padding: 10px 20px 10px;">
          <h3 style="font-size: 20px; font-weight: bold;">Order</h3>
          <p style="font-size: 18px; font-weight: 100;">${receipt.items && receipt.items[Object.keys(receipt.items)[0]!].description} -
              $${receipt.items && receipt.items[Object.keys(receipt.items)[0]!].value} ${receipt.currency}</p>
          <ul>
              ${receipt.items && Object.keys(receipt.items).map((e, i) =>
    {
      if (i !== 0 && receipt.items && receipt.items[e].value !== 0)
      {
        return `<li style="font-size: 18px; font-weight: 100; margin-bottom: 5px;">
                  ${receipt.items[e].description} - ${receipt.items[e].value < 0 ? `-$${Math.abs(receipt.items[e].value)}` : `$${receipt.items[e].value}`} ${receipt.currency}</li>`;
      }
      else
      {
        return '';
      }
    }).join('')
    }
          </ul>
          <br></br>
          ${(receipt.discount && receipt.discount.code && receipt.discount.totalDiscounted) ? `
          <div style="display: flex; justify-content: space-between; margin-bottom: -15px;">
              <p style="font-size: 20px; text-align: left; font-weight: 100;margin-right:10px;">Promo: </p>
              <p style="font-size: 20px; text-align: right; font-weight: 100;"> ($${receipt.discount?.totalDiscounted} ${receipt.currency})</p>
          </div>`: ``
    }

          <div style="display: flex; justify-content: space-between; margin-bottom: -30px;">
              <p style="font-size: 20px; text-align: left; font-weight: 100;margin-right:10px;">Taxes: </p>
              <p style="font-size: 20px; text-align: right; font-weight: 100;"> $${receipt.tax} ${receipt.currency}</p>
          </div>
          <div style="display: flex; justify-content: space-between;">
              <p style="font-size: 20px; text-align: left; font-weight: 500;margin-right:10px;">Total: </p>
              <p style="font-size: 20px; text-align: right; font-weight: 100;"> $${receipt.total} ${receipt.currency}
              </p>
          </div>
      </div>
      <div style="width: 250px; height: 20px; margin: 0 auto;"></div>
      <h1 style="color: #FBBC05; text-align: center;">Instructions</h1>
      <div style="width: 250px; height: auto; margin: 0 auto;">
          <h2 style="color: #FBBC05;">Step 1.</h2>
          <p style="font-size: 18px;color-scheme: light only; color: white; font-weight: bold;">Set up your businesscardX profile</p>
          <p style="font-size: 18px;color-scheme: light only; color: white; font-weight: 100;">Scan this QR code to sign up</p>
          <div style="width: 250px; height: 250px; display: flex; align-items: center; justify-content: center;">
              <img style="width: 100%;" alt="QR Code"
                  src="https://firebasestorage.googleapis.com/v0/b/business-card-x.appspot.com/o/files%2FbusinesscardXSignUpQRWhite.png?alt=media&token=fdd87a76-493a-4184-bff9-2b79d0983bf2">
          </div>
          <p style="font-size: 18px;color-scheme: light only; color: white; font-weight: 100;">Or go to:</p>
          <p style="font-size: 18px;color-scheme: light only; color: white; font-weight: 100;"><a href="https://businesscardx.com/signup"
                  style="color: #FBBC05;">businesscardx.com/signup</a></p>
          <p style="font-size: 12px;color-scheme: light only; color: white; font-weight: 100;">(You'll need your order number to sign up.)</p>
      </div>
      <div style="width: 250px; height: 25px; margin: 0 auto;"></div>
      <div style="width: 250px; height: auto; margin: 0 auto;">
          <h2 style="color: #FBBC05;">Step 2.</h2>
          <p style="font-size: 18px;color-scheme: light only; color: white; font-weight: bold;">How does your card work?</p>
          <p style="font-size: 18px;color-scheme: light only; color: white; font-weight: 100;">Either have your connection scan the QR code on
              the back of your card or tap your card on their phone (see below).</p>
          <div style="width: 250px; height: 320px;">
              <img style="width: 100%;" alt="Phone antena over nfc tag"
                  src="https://firebasestorage.googleapis.com/v0/b/business-card-x.appspot.com/o/files%2FDisplay.png?alt=media&token=2c0a9f49-9671-48f7-9809-f9c82a487b92">
          </div>
      </div>
      <div style="width: 250px; height: 25px; margin: 0 auto;"></div>
      <div class="darkmode" style="width: 200px; height: 1px; margin: 0 auto; background-color: #FBBC05;"></div>
      <p style="color-scheme: light only;color: white; font-weight: 100; text-align: center;">Thank you for your business.</p>
      <div class="darkmode" style="width: 200px; height: 1px; margin: 0 auto; background-color: #FBBC05;"></div>
  </div>
  <div class="darkmode" style="width: auto; height: 20px; background-color: #FBBC05;"></div>
  <div class="darkmode-logo"
      style="width: 180px; height: 150px; display: flex; align-items: center; justify-content: center; margin: 0 auto;">
      <a href="https://businesscardx.com/">
          <img style="width: 100%;" alt="Logo"
              src="https://firebasestorage.googleapis.com/v0/b/business-card-x.appspot.com/o/files%2FBCXLogo%20.png?alt=media&token=310fe858-6a27-4243-b460-0fc432607070">
      </a>
  </div>
</body>`
  return template
}
function billingToBillingDetails(b: Billing)
{
  return { city: b.city, country: b.country, email: b.emailAddress, firstName: b.firstName, lastName: b.lastName, phone: b.phone, stateProvince: b.stateProvince, street1: b.streetAddress, street2: '', zip: b.zipCode } as BillingDetails
}
function shippingToShippingDetails(b: Shipping)
{
  return { city: b.city, country: b.country, email: b.emailAddress, firstName: b.firstName, lastName: b.lastName, phone: b.phone, stateProvince: b.stateProvince, street1: b.streetAddress, street2: '', zip: b.zipCode } as ShippingDetails
}
export function orderToPurchase(order: Order, cards: Card[], currency: Currency)
{
  const type = orderDescriptionToType(order.type);
  const ord = (type === PurchaseType.Black || type === PurchaseType.ReplacementBlack) ?
    { blank: cards[0].blank, color: cards[0].color, company: cards[0].companyName, extra: order.extraCards, fullName: cards[0].name, logo: order.logo, logoAdd: cards[0].customLogo, submit: true, type: type, valid: true, orderNumber: cards[0].orderNumber } as Black :
    {
      base: order.cards.length, blank: order.blank, color: order.color, company: order.company, extra: order.extraCards, logo: order.logo, nonPersonalized: 0, personalized: 0, pCard: [], npCard: type === PurchaseType.ReplacementElite ? (order.cards as { extra: number, orderNumber: string }[]).map((r) => { return { fullName: '', orderNumber: r.orderNumber, extra: r.extra } as PCard }) : cards.map((e) => { return { fullName: e.name, orderNumber: e.orderNumber } as PCard }), submit: true, type: type, valid: true
    } as Elite;

  return {
    total: order.grandTotal,
    order: ord,
    subtotal: order.grandTotal,
    itemized: orderToItemized(order, currency),
    tax: order.tax,
    discount: { affiliateName: order.discountAffiliate, code: order.discountCode, totalDiscounted: order.discountAmount } as Discount,
    details: {
      billingDetails: billingToBillingDetails(order.billing!),
      shippingDetails: shippingToShippingDetails(order.shipping!)
    }
  } as Purchase;
}
type Merged = Elite & Black

export function getOrderSummary(cart: Purchase, currency: Currency, cards: CardObject[], date: Date)
{
  const order = cart.order as Merged;
  const template = `
    <head>
            <style>
              b{
                margin: 8px;
              }
            </style>
          </head>
          <body>
            <b>First Name: </b> ${cart.details.shippingDetails.firstName}<br />
            <b>Last Name: </b> ${cart.details.shippingDetails.lastName}<br />
            ${order.company ? `<b>Company:</b> ${order.company}<br />` : ``}
            <b>Email:</b> ${cart.details.shippingDetails.email}<br />
            <b>Country:</b> ${cart.details.shippingDetails.country}<br/>
            <b>Address:</b> ${cart.details.shippingDetails.street1}
            ${cart.details.shippingDetails.street2}<br/>
            <b>City:</b> ${cart.details.shippingDetails.city}<br/>
            <b>State/Province:</b> ${cart.details.shippingDetails.stateProvince}<br/>
            <b>Zip Code:</b> ${cart.details.shippingDetails.zip}<br />
            <b>Phone:</b> ${cart.details.billingDetails.phone}<br />
            <b>Shipping Method:</b> Canada Post<br />
            <b>Card Type:</b> ${getOrderType(order.type)}<br />
            <b>Blank:</b> ${cart.order.blank}<br />
            ${order.logoAdd ? `<b>Logo AddOn:</b> ${order.logoAdd}<br />` : ``}
            ${order.logoAdd ? `
            <b>Logo 1:</b> ${order.logo[0] ?? ''}<br />
            <b>Logo 2:</b> ${order.logo[1] ?? ''}<br />
            <b>Logo 3:</b> ${order.logo[2] ?? ''}<br />` : ``}
            ${order.type === PurchaseType.Black || order.type === PurchaseType.ReplacementBlack ? `
            <b>Order Number: </b> ${cards[0].orderNumber}<br/>
            `: ``}
            ${order.fullName ? `<b>Name For Card:</b> ${order.fullName}<br />` : ``}
            ${order.type === PurchaseType.ReplacementElite ? `
            <b>Cards:</b><br/>
            <ul>
              ${order.npCard.map((e: PCard, i: number) => { return `<li style="margin-bottom: 5px;">${e.orderNumber} - X ${e.extra}</li>` }).join('')}
            </ul>
            `: ``}
            <b>Print Color:</b> ${getColorData(order.color, currency).description}<br />
            ${(order.extra && (order.type === PurchaseType.Black || order.type === PurchaseType.Elite)) ? `<b>Extra Cards:</b> ${order.extra}<br />` : ``}
            ${(order.extra && (order.type === PurchaseType.ReplacementBlack)) ? `<b>Number of Cards:</b> ${order.extra}<br />` : ``}
            <b>Currency:</b> ${currency}<br />
            <b>Grand Total:</b>$ ${cart.total}<br />
            <b>Tax:</b>$ ${cart.tax}<br />
            ${(cart.discount && cart.discount.affiliateName && cart.discount.totalDiscounted && cart.discount.code) ? `
            <b>Affiliate:</b> ${cart.discount.affiliateName}<br />
            <b>Discount Code:</b> ${cart.discount.code}<br />
            <b>Discount:</b>$ ${cart.discount.totalDiscounted}<br />` : ``}
            <b>Date:</b> ${getCurrentTimeInEST('YYYY-MM-DD ** h:mmA', date).replace('**', 'at')}<br />
            ${order.type === PurchaseType.Elite || order.type === PurchaseType.NewMember ? `<b>Cards:</b><br /><ul>${cards.map((e) => { return `<li style="margin-bottom: 5px;"><b>Order Number: </b>${e.orderNumber} <b>Personalized: </b>${e.personalized} ${e.personalized ? `<b>Name: </b>${e.name}` : ``}</li>` }).join('')}</ul>` : ``}
          </body>
    `
  //Only for Elite Card and add new member


  return template;
}
export function getEmailSubject(type: PurchaseType)
{
  switch (type)
  {
    case PurchaseType.Black:

      return 'Your businesscardX BLACK Card receipt'
    case PurchaseType.Elite:

      return 'Your businesscardX ELITE Card receipt'
    case PurchaseType.ReplacementBlack:

      return 'Your businesscardX Replacement BLACK Card receipt'
    case PurchaseType.ReplacementElite:

      return 'Your businesscardX Replacement ELITE Card receipt'
    case PurchaseType.NewMember:

      return 'Your businesscardX ELITE Card New Member receipt'

    default:
      return '';
  }
}
export async function updateDiscount(disc: Discount)
{
  if (disc.totalDiscounted && disc.code)
  {
    const functions = getFunctions();
    const updateDiscount = httpsCallable(functions, "updateDiscountUsage");
    const result = await updateDiscount({ code: disc.code.toUpperCase(), amount: disc.totalDiscounted })
      .then(async (r) =>
      {

      });
  }
}
