import { useEffect } from "react";
import '@stripe/stripe-js';
import { AppRoutes } from "./routes/routes";
import { setupFirebase } from "./services/firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import OnboardingUserProvider, { useSignIn, useSignOut } from "./components/contexts/UserContext";
import { LoadScript } from "@react-google-maps/api";
import ReactGA from 'react-ga4';

export const App = () =>
{
  const { signIn } = useSignIn();
  const { signOut } = useSignOut();
  const TRACKING_ID = process.env.REACT_APP_TRACKING_ID!; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);

  useEffect(() =>
  {
    setupFirebase();
    const auth = getAuth();

    onAuthStateChanged(auth, (user) =>
    {
      if (user)
      {
        signIn(user);
      } else
      {
        signOut();
      }
    });
  }, []);




  return (
    <OnboardingUserProvider>
      <LoadScript loadingElement={<div></div>} googleMapsApiKey={process.env.REACT_APP_MAPS_API_KEY!}>
        <AppRoutes />
      </LoadScript>

    </OnboardingUserProvider>
  );
};
