import React, { ReactNode, useEffect, useRef, useState } from "react";
import { CircularProgress } from "@mui/material";
import
{
  DocumentReference,
  collection,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../services/firebase";
import { EliteCompany } from "../types";
import { useNavigate } from "react-router-dom";
import CSelect from "./CSelect";
import { WarningAmber } from "@mui/icons-material";

type ReassignCardModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  orderNumber: string;
  children: ReactNode;
  eliteCompanyRef: DocumentReference;
};

const ReassignCardModal: React.FC<ReassignCardModalProps> = ({
  isOpen,
  onClose,
  orderNumber,
  children,
  eliteCompanyRef,
  onConfirm,
}) =>
{

  const [creating, setCreating] = useState(false);
  const [loading, setLoading] = useState(true);
  const [activating, setActivating] = useState(false);
  const [confirmChange, setConfirmChange] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const [cardsOptions, setCardsOptions] = useState<string[]>();
  const [eliteCompany, setEliteCompany] = useState<EliteCompany>();
  const [cardWithIndex, setCardWithIndex] = useState<string[]>();
  const [selectedCard, setSelectedCard] = useState<string>();
  const [isAdmin, setIsAdmin] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<string>()

  const getEliteCompany = async () =>
  {
    const elite = await getDoc(eliteCompanyRef);
    const eCompany = elite.data() as EliteCompany;
    setEliteCompany(eCompany);

    const activeCards = [...eCompany.activeCards];
    const cards = [...eCompany.cards];
    const iCards = cards.filter((item) => !activeCards.includes(item));
    const oCards = [orderNumber, ...iCards];
    const indexes = oCards.map((item) => `Card #${cards.indexOf(item) + 1}`);
    const isAdminCard = eCompany.adminCards.includes(orderNumber);


    setIsAdmin(isAdminCard);
    setSelectedCard(orderNumber);
    setCardWithIndex(indexes);
    setCardsOptions(oCards);
  };

  useEffect(() =>
  {
    async function fetchData()
    {
      await getEliteCompany();
      setLoading(false);
    }
    fetchData();
  }, [loading]);

  const handleReassign = async (
    selectedCardOrderNumber: string,
    closeModal: Function
  ) =>
  {
    setActivating(true);
    const q = query(
      collection(db, "users"),
      where("orderNumber", "==", orderNumber)
    );
    const querySnapshot = await getDocs(q);
    let data = querySnapshot.docs[0]?.data();
    let ref = querySnapshot.docs[0]?.ref;

    await updateDoc(ref!, { ...data, orderNumber: selectedCardOrderNumber });

    if (isAdmin)
    {
      const activeCards = eliteCompany?.activeCards;
      const adminCards = eliteCompany?.adminCards;
      activeCards?.splice(
        activeCards.indexOf(orderNumber),
        1,
        selectedCardOrderNumber
      );
      adminCards?.splice(
        adminCards.indexOf(orderNumber),
        1,
        selectedCardOrderNumber
      );
      await updateDoc(eliteCompanyRef, {
        ...eliteCompany,
        activeCards: activeCards,
        adminCards: adminCards,
      }).then((res) =>
      {
        onConfirm();
      });
    } else
    {
      const activeCards = eliteCompany?.activeCards;
      activeCards?.splice(
        activeCards.indexOf(orderNumber),
        1,
        selectedCardOrderNumber
      );

      await updateDoc(eliteCompanyRef, {
        ...eliteCompany,
        activeCards: activeCards,
      }).then((res) =>
      {
        setActivating(false);
        onConfirm();
      });
    }
  };

  useEffect(() =>
  {
    const handleEscape = (event: KeyboardEvent) =>
    {
      if (event.key === "Escape" && !loading && !activating)
      {
        onClose();
      }
    };

    const handleOutsideClick = (event: MouseEvent) =>
    {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node) && !loading && !activating
      )
      {
        onClose();
      }
    };

    if (isOpen)
    {
      document.addEventListener("keydown", handleEscape);
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () =>
    {
      document.removeEventListener("keydown", handleEscape);
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, onClose, loading, activating]);

  if (!isOpen)
  {
    return null;
  }
  if (creating)
  {
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50 ">
        <div className="modal-container" ref={modalRef}>
          <div className="flex flex-col bg-white rounded-lg shadow-lg w-[446px] h-[522px] justify-center items-center p-[52px]">
            <CircularProgress color="inherit" />
            <div className="font-custom text-[24px] font-medium mt-[67px]">
              Activating your card...
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (loading)
  {
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50 ">
        <div className="modal-container" ref={modalRef}>
          <div className="flex flex-col bg-white rounded-lg shadow-lg w-[446px] h-[422px] justify-center items-center p-[52px]">
            <CircularProgress color="inherit" />
          </div>
        </div>
      </div>
    );
  }
  if (activating)
  {
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50 ">
        <div className="modal-container" ref={modalRef}>
          <div className="flex flex-col bg-white rounded-lg shadow-lg w-[446px] h-[422px] justify-center items-center p-[52px]">
            <CircularProgress color="inherit" />
            <div className="font-custom text-[24px] font-medium mt-[67px]">Changing your card number...</div>
          </div>
        </div>
      </div>
    );
  }
  if (confirmChange)
  {
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50 ">
        <div className="modal-container" ref={modalRef}>
          <div className="flex flex-col bg-white rounded-lg shadow-lg w-[446px] min-h-[422px] justify-center items-start p-[52px]">
            <h2 className="font-custom text-button text-[39px]/[43px] mb-[22px]">
              Reassign Card
            </h2>
            <div className="flex flex-row bg-primaryLight text-primary font-custom text-[20px] p-[18px] mb-[17px] items-center">
              <WarningAmber className="mr-[20px]" />
              <p>
                Reassigning a card will swap the users profiles associated with
                the cards.
              </p>
            </div>
            <div className="flex flex-row p-4 bg-white ">
              <button
                className=" font-custom font-medium text-white shadow-custom text-[20px] tracking-[0.05em] text-center bg-button rounded-[50px] w-[146px] h-[46px] mr-[13px]"
                onClick={async () =>
                {
                  await handleReassign(selectedCard!, onClose);
                }}
              >
                Continue
              </button>
              <button
                className=" font-custom font-medium text-white shadow-custom text-[20px] tracking-[0.05em] text-center bg-poppy rounded-[50px] w-[146px] h-[46px] "
                onClick={() =>
                {
                  setConfirmChange(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 ">
      <div className="modal-container" ref={modalRef}>
        <div className="flex flex-col bg-white rounded-lg shadow-lg w-[446px] min-h-[422px] justify-center items-start p-[52px]">
          {children}
          <div className="flex flex-col p-4 bg-white rounded-lg shadow-lg w-[342px] min-h-[176px]">
            <div className="mb-[30px]">
              <CSelect
                value={selectedIndex}
                maxh={"max-h-[130px]"}
                errorclass="pt-[5px]"
                items={cardWithIndex!}
                key={"type_" + 1}
                label="Card Number"
                hint="Card Number"
                onChange={(arg) =>
                {
                  setSelectedIndex(arg)
                  setSelectedCard(cardsOptions![cardWithIndex!.indexOf(arg)]);
                }}
              />
            </div>

            <button
              className=" font-custom font-medium text-white shadow-custom text-[15px]/[25px] tracking-[0.05em] text-center bg-button rounded-[50px]  lg:pt-[10px] pt-[12px] lg:pb-[10px] pb-[10px] px-[10px]"
              onClick={() =>
              {
                setConfirmChange(true);
              }}
            >
              Reassign
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReassignCardModal;
