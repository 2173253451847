import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import CAccordion from '../../components/CAccordion';
import PaymentScreen from '../PaymentScreen';
import { CircularProgress } from '@mui/material';
import { Details, Elite, Purchase, PurchaseType, getColorData } from '../../redux/feature/cartSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { NotFound } from '../NotFound';
import { addDoc, collection, doc, updateDoc, Timestamp, getDoc, query, where, getDocs, arrayUnion } from 'firebase/firestore';
import { db } from '../../services/firebase';
import generateQRCode from '../../utils/qr_code_generator';
import { Attachments, Email } from '../../types';
import { getOrderType } from '../Cart';
import { HBool, Receipt, getCurrentTimeInEST, getEmailSubject, getOrderSummary, getReceipt, updateDiscount } from '../../utils/features';
import { Currency } from '../../currencies';
import { useSelector } from 'react-redux';
import { CardObject } from '../../types';


interface CheckoutProps
{
  cart: Purchase;
  order: any;
  sameAddress: boolean;
  details: Details;
}

const CheckoutPage: React.FC = () =>
{
  const [renderingStripe, setRenderingStripe] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as CheckoutProps | null;
  const currency: Currency = useSelector((state: any) => state.currency);
  useEffect(() => { window.scrollTo(0, 0); }, []);
  // Check if the state is null or undefined
  if (!state)
  {
    return <NotFound />;
  }

  const { cart, order, sameAddress, details } = location.state as CheckoutProps;

  if (!cart || !order || !onPaymentComplete)
  {
    return <NotFound />;
  }


  return (
    <div className="flex flex-col overflow-hidden">
      <Navbar />
      <div>
        <div className="lg:block hidden mt-[60px] mb-[60px] px-[81px]">
          <h1 className=" inline-block font-custom font-black lg:text-[64px]/[86px] text-[30px]/[41px] lg:mb-[34px]">
            Payment
          </h1>
          <div className="bg-primary relative lg:w-[150%] w-[125%] h-[37px] z-[-1] lg:mt-[-70px] mt-[-18px] lg:mb-[37px] mb-[28px]" />
        </div>
        {renderingStripe && (
          <div className="overflow-hidden flex items-center justify-center h-screen relative w-[100vw] ">
            <div className=" relative lg:mt-[-550px] mt-[-200px]">
              <CircularProgress color="inherit" />
            </div>
          </div>
        )}
        <div className="flex lg:flex-row flex-col lg:mx-[76px]">
          <CAccordion
            height="fit-content"
            disabled={true}
            expanded
            summary="Cart"
            iconangle={["0", "0"]}
            iconcolor="#0000"
            radius="4px"
            bgcolor="#fff"
            txcolor="#000"
            fontheight="20px"
            fontsize="16px"
            fontweight="700"
            shadow="0px 2px 6px rgba(0, 0, 0, 0.14);"
          >
            {
              Object.keys(cart.itemized).map((e, i) =>
              {
                if (cart.itemized[e].value !== 0)
                {
                  return <div className="flex flex-row justify-between font-custom text-[14px]/[20px] font-normal text-text mb-[14px]">
                    <div>
                      {cart.itemized[e].description}
                    </div>
                    <div className="font-black text-dark3">{cart.itemized[e].value < 0 ? `-$${Math.abs(cart.itemized[e].value)}` : `$${cart.itemized[e].value}`}</div>
                  </div>
                } else
                {
                  return <></>
                }
              })
            }
            <div className="border-b-[0px] border-gray-300 lg:w-[300px]" />
          </CAccordion>
          <div className="flex lg:flex-row flex-col-reverse">
            <PaymentScreen
              onStripeFinishLoading={onStripeFinishLoading}
              onPaymentComplete={onPaymentComplete}
              amount={cart.total * 100}
              currency="cad"
              email={details.billingDetails.email}
              referral={(window.Rewardful ? (window.Rewardful.referral ?? '') : '')}
            />
            {!renderingStripe && (
              <CAccordion
                height="fit-content"
                expanded
                summary="Billing Summary"
                iconangle={["0", "0"]}
                iconcolor="#000"
                radius="4px"
                bgcolor="#fff"
                txcolor="#000"
                fontheight="20px"
                fontsize="16px"
                fontweight="700"
                shadow="0px 2px 6px rgba(0, 0, 0, 0.14);"
              >
                <div className="flex flex-row justify-between font-custom text-[14px]/[20px] font-normal text-text mb-[12px]">
                  <div>Subtotal</div>
                  <div>${cart.subtotal}</div>
                </div>
                {!isNaN(cart.discount.percentDiscount) && <div className="flex flex-row justify-between font-custom text-[14px]/[20px] font-semibold text-discount mb-[12px]">
                  <div>Discount</div>
                  <div>-${cart.discount.totalDiscounted}</div>
                </div>}
                <div className="flex flex-row justify-between font-custom text-[14px]/[20px] font-normal text-text mb-[12px]">
                  <div>Shipping</div>
                  <div>{"$0"}</div>
                </div>
                <div className="flex flex-row justify-between font-custom text-[14px]/[20px] font-normal text-text mb-[29px]">
                  <div>Tax</div>
                  <div>
                    $
                    {cart.tax.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </div>
                </div>
                <div className="border-b-[1px] border-gray-300 mb-[18px] lg:w-[330px]" />
                <div className="flex flex-row justify-between font-custom text-[16px]/[20px] font-bold text-text ">
                  <div>Grand Total</div>
                  <div>${cart.total.toFixed(2)}</div>
                </div>
              </CAccordion>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
  function onStripeFinishLoading()
  {
    setRenderingStripe(false);
  }

  async function onPaymentComplete()
  {

    switch (cart.order.type)
    {
      case PurchaseType.Black:
        await sendDataBlack();
        break;
      case PurchaseType.Elite:
        await sendDataElite();
        break;
      case PurchaseType.ReplacementBlack:
        await sendDataReplacementBlack();
        break;
      case PurchaseType.ReplacementElite:
        await sendDataReplacementElite();
        break;
      case PurchaseType.NewMember:
        await sendDataNewEliteMember();
        break;

      default:
        break;
    }
  }
  async function sendDataElite()
  {
    let ord: Elite = order;
    let cardsOrderNumbers: string[] = [];
    let personalizedCardsOrderNumbers = [];
    let cardsOrderAndQRCodes: CardObject[] = [];
    let allAttachments: Attachments[] = [];


    const eliteCompanyRef = await addDoc(collection(db, "eliteCompanies"), {
      company: ord.company || "",
      logo: ord.logo,
    });

    for (let i = 0; i < order.personalized; i++)
    {
      const orderNumber = await getRandomOrderNumber(99999999999);
      cardsOrderNumbers.push(orderNumber);
      personalizedCardsOrderNumbers.push(orderNumber);
      const qrcode = generateQRCode(
        process.env.REACT_APP_PROFILE_URL! + orderNumber
      );
      const newAttachment: Attachments = {
        filename: `${orderNumber}.png`,
        content: qrcode.split('data:image/png;base64,')[1],
        encoding: "base64"
      }
      allAttachments.push(newAttachment);
      const newObject = { orderNumber: orderNumber, name: ord.pCard[i].fullName, qrcode: qrcode, personalized: true };
      cardsOrderAndQRCodes.push(newObject);
      await addDoc(collection(db, "cards"), {
        type: getOrderType(cart.order.type),
        orderNumber: orderNumber,
        qrCode: qrcode,
        url: process.env.REACT_APP_PROFILE_URL! + orderNumber,
        name: ord.pCard[i].fullName,
        companyName: ord.blank ? "" : ord.company,
        logo: ord.blank ? '' : ((ord.logo.filter((e: string, i: number) => { return e === '' }).length === 3) ? '' : ord.logo),
        customLogo: true,
        blank: ord.blank,
        color: getColorData(ord.color, currency).description,
        personalized: true,
        eliteCompanyRef: eliteCompanyRef,
      })
        .then((re) =>
        {

        })
        .catch((e) =>
        {

        });
    }

    for (let j = 0; j < order.nonPersonalized; j++)
    {
      const orderNumber = await getRandomOrderNumber(99999999999);
      cardsOrderNumbers.push(orderNumber);
      const qrcode = generateQRCode(
        process.env.REACT_APP_PROFILE_URL! + orderNumber
      );
      const newAttachment: Attachments = {
        filename: `${orderNumber}.png`,
        content: qrcode.split('data:image/png;base64,')[1],
        encoding: "base64"
      }
      allAttachments.push(newAttachment);
      const newObject = { orderNumber: orderNumber, name: '', qrcode: qrcode, personalized: false };
      cardsOrderAndQRCodes.push(newObject);
      await addDoc(collection(db, "cards"), {
        type: getOrderType(cart.order.type),
        orderNumber: orderNumber,
        qrCode: qrcode,
        url: process.env.REACT_APP_PROFILE_URL! + orderNumber,
        name: "",
        companyName: ord.blank ? "" : ord.company,
        logo: ord.logo,
        customLogo: true,
        blank: ord.blank,
        color: getColorData(ord.color, currency).description,
        personalized: false,
        specialInstructions: "",
        eliteCompanyRef: eliteCompanyRef,
      })
        .then((re) =>
        {

        })
        .catch((e) =>
        {

        });
    }

    await addDoc(collection(db, "orders"), {
      cards: cardsOrderNumbers,
      date: Timestamp.fromDate(new Date(getCurrentTimeInEST())),
      extraCards: ord.extra,
      type: getOrderType(cart.order.type),
      currency: currency,
      grandTotal: cart.total,
      tax: cart.tax,
      discountAffiliate: cart.discount.affiliateName ?? '',
      discountAmount: !isNaN(cart.discount.totalDiscounted) ? cart.discount.totalDiscounted : 0,
      discountCode: cart.discount.code ?? '',
      uploadLogoLater: ord.logo.filter((e) => { return e.length === 0 }).length === 3,
      shippingMethod:
        "Canada Post",
      company: ord.company || "",
      color: getColorData(ord.color, currency).description,
      buyerEmail: cart.details.billingDetails.email,
      logo: ord.logo,
      blank: ord.blank,
      personalizedCards:
        ord.pCard.length > 0 ? personalizedCardsOrderNumbers : "none",
    })
      .then(async (re) =>
      {
        const docRef = doc(db, "orders", re.id);
        const billingRef = collection(docRef, "billing");
        const billingInfo = {
          firstName: cart.details.billingDetails.firstName,
          lastName: cart.details.billingDetails.lastName,
          emailAddress: cart.details.billingDetails.email,
          streetAddress:
            cart.details.billingDetails.street1 +
            " " +
            (cart.details.billingDetails.street2 ?? ''),
          stateProvince: cart.details.billingDetails.stateProvince,
          city: cart.details.billingDetails.city,
          zipCode: cart.details.billingDetails.zip,
          phone: cart.details.billingDetails.phone,
          country: cart.details.billingDetails.country
        };
        const shippingInfo = sameAddress
          ? billingInfo
          : {
            firstName: cart.details.shippingDetails.firstName,
            lastName: cart.details.shippingDetails.lastName,
            emailAddress: cart.details.shippingDetails.email,
            streetAddress:
              cart.details.shippingDetails.street1 +
              " " +
              (cart.details.billingDetails.street2 ?? ''),
            stateProvince: cart.details.shippingDetails.stateProvince,
            city: cart.details.shippingDetails.city,
            zipCode: cart.details.shippingDetails.zip,
            phone: cart.details.shippingDetails.phone,
            country: cart.details.shippingDetails.country
          };
        await addDoc(billingRef, billingInfo);
        const shippingRef = collection(docRef, "shipping");
        await addDoc(shippingRef, shippingInfo);

        await updateDoc(eliteCompanyRef, {
          cards: cardsOrderNumbers,
          order: [docRef],
        });
      })
      .catch((e) =>
      {

      });

    updateDiscount(cart.discount);

    const subject = getEmailSubject(order.type);
    const receipt = getReceipt({ orderNumber: cardsOrderAndQRCodes[0].orderNumber, company: cart.order.company, logo: HBool.Yes, address: `${cart.details.shippingDetails.street1} ${cart.details.shippingDetails.street2 ?? ''}, ${cart.details.shippingDetails.city}, ${cart.details.shippingDetails.stateProvince}, ${cart.details.shippingDetails.zip}, ${cart.details.shippingDetails.country}`, date: getCurrentTimeInEST('YYYY-MM-DD ** h:mmA').replace('**', 'at'), email: cart.details.shippingDetails.email, phone: cart.details.shippingDetails.phone, shippingMethod: 'Canada Post', tax: cart.tax, total: cart.total, type: PurchaseType.Elite, color: cart.order.color, items: cart.itemized, currency: currency, discount: cart.discount } as Receipt);
    //EMAIL FOR THE CLIENT    
    await sendEmail({
      dest: details.billingDetails.email,
      fullName:
        "businesscardX",
      message: receipt,
      sender: "",
      subject: subject,
    });
    //EMAIL FOR US
    await sendEmail({
      dest: process.env.REACT_APP_EMAIL!,
      fullName:
        'businesscardX',
      message: getOrderSummary(cart, currency, cardsOrderAndQRCodes, new Date(getCurrentTimeInEST())),
      sender: process.env.REACT_APP_EMAIL!,
      subject: "New Order Arrived",
      attachments: allAttachments
    });
    if (window.rewardful)
    { window.rewardful('convert', { email: cart.details.billingDetails.email }); }

    navigate("/confirm-order", { state: { email: receipt, dest: details.billingDetails.email, subject: subject } });
  }
  async function sendDataBlack()
  {
    const orderNumber = await getRandomOrderNumber(99999999999);
    const qrcode = generateQRCode(
      process.env.REACT_APP_PROFILE_URL! + orderNumber
    );
    await addDoc(collection(db, "cards"), {
      type: getOrderType(cart.order.type),
      orderNumber: orderNumber,
      qrCode: qrcode,
      url: process.env.REACT_APP_PROFILE_URL! + orderNumber,
      name: order.blank ? "" : order.fullName,
      companyName: order.blank ? "" : order.company,
      logo: order.logoAdd ? order.logo : ['', '', ''],
      customLogo: order.logoAdd,
      blank: order.blank,
      color: getColorData(order.color, currency).description,
      personalized: false,
      specialInstructions: "",
    })
      .then((re) =>
      {

      })
      .catch((e) =>
      {

      });

    await addDoc(collection(db, "orders"), {
      cards: [`${orderNumber}`],
      date: Timestamp.fromDate(new Date(getCurrentTimeInEST())),
      extraCards: order.extra,
      type: getOrderType(cart.order.type),
      currency: currency,
      grandTotal: cart.total,
      tax: cart.tax,
      discountAffiliate: cart.discount.affiliateName ?? '',
      discountAmount: !isNaN(cart.discount.totalDiscounted) ? cart.discount.totalDiscounted : 0,
      discountCode: cart.discount.code ?? '',
      uploadLogoLater: order.logoAdd && order.logo.filter((e: string) => { return e.length === 0 }).length === 3,
      shippingMethod:
        "Canada Post",
      company: order.blank ? "" : order.company,
      color: getColorData(order.color, currency).description,
      buyerEmail: cart.details.billingDetails.email,
      name: order.blank ? "" : order.fullName,
      logo: order.logoAdd ? order.logo : ['', '', ''],
      blank: order.blank,
    })
      .then(async (re) =>
      {
        const docRef = doc(db, "orders", re.id);
        const billingRef = collection(docRef, "billing");
        const billingInfo = {
          firstName: cart.details.billingDetails.firstName,
          lastName: cart.details.billingDetails.lastName,
          emailAddress: cart.details.billingDetails.email,
          streetAddress:
            cart.details.billingDetails.street1 +
            " " +
            (cart.details.billingDetails.street2 ?? ''),
          stateProvince: cart.details.billingDetails.stateProvince,
          city: cart.details.billingDetails.city,
          zipCode: cart.details.billingDetails.zip,
          phone: cart.details.billingDetails.phone,
          country: cart.details.billingDetails.country
        };
        const shippingInfo = sameAddress
          ? billingInfo
          : {
            firstName: cart.details.shippingDetails.firstName,
            lastName: cart.details.shippingDetails.lastName,
            emailAddress: cart.details.shippingDetails.email,
            streetAddress:
              cart.details.shippingDetails.street1 +
              " " +
              (cart.details.billingDetails.street2 ?? ''),
            stateProvince: cart.details.shippingDetails.stateProvince,
            city: cart.details.shippingDetails.city,
            zipCode: cart.details.shippingDetails.zip,
            phone: cart.details.shippingDetails.phone,
            country: cart.details.shippingDetails.country
          };
        await addDoc(billingRef, billingInfo);
        const shippingRef = collection(docRef, "shipping");
        await addDoc(shippingRef, shippingInfo);

      })
      .catch((e) =>
      {

      });
    updateDiscount(cart.discount);

    //EMAIL FOR THE CLIENT      
    const receipt = getReceipt({ orderNumber: orderNumber, name: order.fullName, company: cart.order.company, logo: order.logoAdd ? HBool.Yes : HBool.No, address: `${cart.details.shippingDetails.street1} ${cart.details.shippingDetails.street2 ?? ''}, ${cart.details.shippingDetails.city}, ${cart.details.shippingDetails.stateProvince}, ${cart.details.shippingDetails.zip}, ${cart.details.shippingDetails.country}`, date: getCurrentTimeInEST('YYYY-MM-DD ** h:mmA').replace('**', 'at'), email: cart.details.shippingDetails.email, phone: cart.details.shippingDetails.phone, shippingMethod: 'Canada Post', tax: cart.tax, total: cart.total, type: PurchaseType.Black, color: cart.order.color, items: cart.itemized, currency: currency, discount: cart.discount } as Receipt)
    const subject = getEmailSubject(order.type)
    await sendEmail({
      dest: details.billingDetails.email,
      fullName: "businesscardX",
      message:
        receipt,
      sender: "",
      subject: subject,
    });
    //-----------------------------

    const newAttachment: Attachments = {
      filename: `${orderNumber}.png`,
      content: qrcode.split('data:image/png;base64,')[1],
      encoding: "base64"
    }
    //EMAIL FOR US
    await sendEmail({
      dest: process.env.REACT_APP_EMAIL!,
      fullName:
        'businesscardX',
      message: getOrderSummary(cart, currency, [{ name: order.fullName, orderNumber: orderNumber, personalized: false } as CardObject], new Date(getCurrentTimeInEST())),
      sender: process.env.REACT_APP_EMAIL!,
      subject: "New Order Arrived",
      attachments: [newAttachment]
    });
    if (window.rewardful)
    {
      window.rewardful('convert', { email: cart.details.billingDetails.email });
    }
    navigate("/confirm-order", { state: { email: receipt, dest: details.billingDetails.email, subject: subject } });
  }
  async function sendDataReplacementBlack()
  {

    const orderNumber = cart.order.orderNumber;
    const qrcode = generateQRCode(
      process.env.REACT_APP_PROFILE_URL! + orderNumber
    );

    await addDoc(collection(db, "orders"), {
      cards: [`${orderNumber}`],
      date: Timestamp.fromDate(new Date(getCurrentTimeInEST())),
      extraCards: order.extra,
      type: getOrderType(cart.order.type),
      currency: currency,
      grandTotal: cart.total,
      tax: cart.tax,
      discountAffiliate: cart.discount.affiliateName ?? '',
      discountAmount: !isNaN(cart.discount.totalDiscounted) ? cart.discount.totalDiscounted : 0,
      discountCode: cart.discount.code ?? '',
      uploadLogoLater: order.logoAdd && order.logo.filter((e: string) => { return e.length === 0 }).length === 3,
      shippingMethod:
        "Canada Post",
      company: order.blank ? "" : order.company,
      color: getColorData(order.color, currency).description,
      buyerEmail: cart.details.billingDetails.email,
      name: order.blank ? "" : order.fullName,
      logo: order.logoAdd ? order.logo : ['', '', ''],
      blank: order.blank,
    })
      .then(async (re) =>
      {
        const docRef = doc(db, "orders", re.id);
        const billingRef = collection(docRef, "billing");
        const billingInfo = {
          firstName: cart.details.billingDetails.firstName,
          lastName: cart.details.billingDetails.lastName,
          emailAddress: cart.details.billingDetails.email,
          streetAddress:
            cart.details.billingDetails.street1 +
            " " +
            (cart.details.billingDetails.street2 ?? ''),
          stateProvince: cart.details.billingDetails.stateProvince,
          city: cart.details.billingDetails.city,
          zipCode: cart.details.billingDetails.zip,
          phone: cart.details.billingDetails.phone,
          country: cart.details.billingDetails.country
        };
        const shippingInfo = sameAddress
          ? billingInfo
          : {
            firstName: cart.details.shippingDetails.firstName,
            lastName: cart.details.shippingDetails.lastName,
            emailAddress: cart.details.shippingDetails.email,
            streetAddress:
              cart.details.shippingDetails.street1 +
              " " +
              (cart.details.billingDetails.street2 ?? ''),
            stateProvince: cart.details.shippingDetails.stateProvince,
            city: cart.details.shippingDetails.city,
            zipCode: cart.details.shippingDetails.zip,
            phone: cart.details.shippingDetails.phone,
            country: cart.details.shippingDetails.country
          };
        await addDoc(billingRef, billingInfo);
        const shippingRef = collection(docRef, "shipping");
        await addDoc(shippingRef, shippingInfo);

      })
      .catch((e) =>
      {

      });
    updateDiscount(cart.discount);
    //EMAIL FOR THE CLIENT
    const receipt = getReceipt({ orderNumber: orderNumber, name: order.fullName, company: cart.order.company, logo: order.logoAdd ? HBool.Yes : HBool.No, address: `${cart.details.shippingDetails.street1} ${cart.details.shippingDetails.street2 ?? ''}, ${cart.details.shippingDetails.city}, ${cart.details.shippingDetails.stateProvince}, ${cart.details.shippingDetails.zip}, ${cart.details.shippingDetails.country}`, date: getCurrentTimeInEST('YYYY-MM-DD ** h:mmA').replace('**', 'at'), email: cart.details.shippingDetails.email, phone: cart.details.shippingDetails.phone, shippingMethod: 'Canada Post', tax: cart.tax, total: cart.total, type: PurchaseType.ReplacementBlack, color: cart.order.color, items: cart.itemized, currency: currency, discount: cart.discount } as Receipt);
    const subject = getEmailSubject(order.type)
    await sendEmail({
      dest: details.billingDetails.email,
      fullName: "businesscardX",
      message:
        receipt,
      sender: "",
      subject: subject,
    });

    const newAttachment: Attachments = {
      filename: `${orderNumber}.png`,
      content: qrcode.split('data:image/png;base64,')[1],
      encoding: "base64"
    }
    //EMAIL FOR US
    await sendEmail({
      dest: process.env.REACT_APP_EMAIL!,
      fullName:
        'businesscardX',
      message: getOrderSummary(cart, currency, [{ name: (order.fullName as string), orderNumber: orderNumber!, personalized: false } as CardObject], new Date(getCurrentTimeInEST())),
      sender: process.env.REACT_APP_EMAIL!,
      subject: "New Order Arrived",
      attachments: [newAttachment]
    });
    if (window.rewardful)
    { window.rewardful('convert', { email: cart.details.billingDetails.email }); }
    navigate("/confirm-order", { state: { email: receipt, dest: details.billingDetails.email, subject: subject } });
  }
  async function sendDataReplacementElite()
  {
    let ord: Elite = order;
    let QRCodes = [];
    let allAttachments: Attachments[] = [];
    for (let i = 0; i < ord.npCard.length; i++)
    {
      const orderNumber = ord.npCard[i].orderNumber;
      const qrcode = generateQRCode(
        process.env.REACT_APP_PROFILE_URL! + orderNumber
      );
      const newAttachment: Attachments = {
        filename: `${orderNumber}.png`,
        content: qrcode.split('data:image/png;base64,')[1],
        encoding: "base64"
      }
      allAttachments.push(newAttachment);
      const newObject = { qrcode: qrcode, };
      QRCodes.push(newObject);
    }

    await addDoc(collection(db, "orders"), {
      cards: ord.npCard.map((e, i) => { return { orderNumber: e.orderNumber, extra: e.extra } }),
      date: Timestamp.fromDate(new Date(getCurrentTimeInEST())),
      type: getOrderType(cart.order.type),
      currency: currency,
      grandTotal: cart.total,
      tax: cart.tax,
      discountAffiliate: cart.discount.affiliateName ?? '',
      discountAmount: !isNaN(cart.discount.totalDiscounted) ? cart.discount.totalDiscounted : 0,
      discountCode: cart.discount.code ?? '',
      uploadLogoLater: ord.logo.filter((e) => { return e.length === 0 }).length === 3,
      shippingMethod:
        "Canada Post",
      company: ord.company || "",
      color: getColorData(ord.color, currency).description,
      buyerEmail: cart.details.billingDetails.email,
      logo: ord.logo,
      blank: ord.blank,
    })
      .then(async (re) =>
      {
        const docRef = doc(db, "orders", re.id);
        const billingRef = collection(docRef, "billing");
        const billingInfo = {
          firstName: cart.details.billingDetails.firstName,
          lastName: cart.details.billingDetails.lastName,
          emailAddress: cart.details.billingDetails.email,
          streetAddress:
            cart.details.billingDetails.street1 +
            " " +
            (cart.details.billingDetails.street2 ?? ''),
          stateProvince: cart.details.billingDetails.stateProvince,
          city: cart.details.billingDetails.city,
          zipCode: cart.details.billingDetails.zip,
          phone: cart.details.billingDetails.phone,
          country: cart.details.billingDetails.country
        };
        const shippingInfo = sameAddress
          ? billingInfo
          : {
            firstName: cart.details.shippingDetails.firstName,
            lastName: cart.details.shippingDetails.lastName,
            emailAddress: cart.details.shippingDetails.email,
            streetAddress:
              cart.details.shippingDetails.street1 +
              " " +
              (cart.details.billingDetails.street2 ?? ''),
            stateProvince: cart.details.shippingDetails.stateProvince,
            city: cart.details.shippingDetails.city,
            zipCode: cart.details.shippingDetails.zip,
            phone: cart.details.shippingDetails.phone,
            country: cart.details.shippingDetails.country
          };
        await addDoc(billingRef, billingInfo);
        const shippingRef = collection(docRef, "shipping");
        await addDoc(shippingRef, shippingInfo);

      })
      .catch((e) =>
      {

      });
    updateDiscount(cart.discount);
    //EMAIL FOR THE CLIENT   
    const receipt = getReceipt({ orderNumber: ord.orderNumber, company: cart.order.company, logo: HBool.Yes, address: `${cart.details.shippingDetails.street1} ${cart.details.shippingDetails.street2 ?? ''}, ${cart.details.shippingDetails.city}, ${cart.details.shippingDetails.stateProvince}, ${cart.details.shippingDetails.zip}, ${cart.details.shippingDetails.country}`, date: getCurrentTimeInEST('YYYY-MM-DD ** h:mmA').replace('**', 'at'), email: cart.details.shippingDetails.email, phone: cart.details.shippingDetails.phone, shippingMethod: 'Canada Post', tax: cart.tax, total: cart.total, type: PurchaseType.ReplacementElite, color: cart.order.color, items: cart.itemized, currency: currency, discount: cart.discount } as Receipt);
    const subject = getEmailSubject(order.type)
    await sendEmail({
      dest: details.billingDetails.email,
      fullName:
        "businesscardX",
      message:
        receipt,
      sender: "",
      subject: subject,
    });
    //EMAIL FOR US
    await sendEmail({
      dest: process.env.REACT_APP_EMAIL!,
      fullName:
        'businesscardX',
      message: getOrderSummary(cart, currency, ord.npCard.map((e) => { return { name: e.fullName, orderNumber: e.orderNumber, personalized: false } as CardObject }), new Date(getCurrentTimeInEST())),
      sender: process.env.REACT_APP_EMAIL!,
      subject: "New Order Arrived",
      attachments: allAttachments
    });
    if (window.rewardful)
    { window.rewardful('convert', { email: cart.details.billingDetails.email }); }
    navigate("/confirm-order", { state: { email: receipt, dest: details.billingDetails.email, subject: subject } });
  }
  async function sendDataNewEliteMember()
  {

    let ord: Elite = order;
    let cardsOrderNumbers: string[] = [];
    let personalizedCardsOrderNumbers = [];
    let cardsOrderAndQRCodes: CardObject[] = [];
    let allAttachments: Attachments[] = [];

    const [collectionPath, docId] = ord.reference!.split('/');
    const reference = doc(db, collectionPath, docId);

    for (let i = 0; i < order.personalized; i++)
    {
      const orderNumber = await getRandomOrderNumber(99999999999);
      cardsOrderNumbers.push(orderNumber);
      personalizedCardsOrderNumbers.push(orderNumber);
      const qrcode = generateQRCode(
        process.env.REACT_APP_PROFILE_URL! + orderNumber
      );
      const newAttachment: Attachments = {
        filename: `${orderNumber}.png`,
        content: qrcode.split('data:image/png;base64,')[1],
        encoding: "base64"
      }
      allAttachments.push(newAttachment);
      const newObject = { orderNumber: orderNumber, name: ord.pCard[i].fullName, qrcode: qrcode, personalized: true };
      cardsOrderAndQRCodes.push(newObject);



      await addDoc(collection(db, "cards"), {
        type: getOrderType(cart.order.type),
        orderNumber: orderNumber,
        qrCode: qrcode,
        url: process.env.REACT_APP_PROFILE_URL! + orderNumber,
        name: ord.pCard[i].fullName,
        companyName: ord.blank ? "" : ord.company,
        logo: ord.blank ? '' : ord.logo,
        customLogo: true,
        blank: ord.blank,
        color: getColorData(ord.color, currency).description,
        personalized: true,
        eliteCompanyRef: reference,
      })
        .then((re) =>
        {

        })
        .catch((e) =>
        {

        });
    }

    for (let j = 0; j < order.nonPersonalized; j++)
    {
      const orderNumber = await getRandomOrderNumber(99999999999);
      cardsOrderNumbers.push(orderNumber);
      const qrcode = generateQRCode(
        process.env.REACT_APP_PROFILE_URL! + orderNumber
      );
      const newAttachment: Attachments = {
        filename: `${orderNumber}.png`,
        content: qrcode.split('data:image/png;base64,')[1],
        encoding: "base64"
      }
      allAttachments.push(newAttachment);
      const newObject = { orderNumber: orderNumber, name: '', qrcode: qrcode, personalized: false };
      cardsOrderAndQRCodes.push(newObject);

      await addDoc(collection(db, "cards"), {
        type: getOrderType(cart.order.type),
        orderNumber: orderNumber,
        qrCode: qrcode,
        url: process.env.REACT_APP_PROFILE_URL! + orderNumber,
        name: "",
        companyName: ord.blank ? "" : ord.company,
        logo: ord.blank ? '' : ord.logo,
        customLogo: true,
        blank: ord.blank,
        color: getColorData(ord.color, currency).description,
        personalized: false,
        specialInstructions: "",
        eliteCompanyRef: reference,
      })
        .then((re) =>
        {

        })
        .catch((e) =>
        {

        });
    }

    await addDoc(collection(db, "orders"), {
      cards: cardsOrderNumbers,
      date: Timestamp.fromDate(new Date(getCurrentTimeInEST())),
      extraCards: ord.extra,
      type: getOrderType(cart.order.type),
      currency: currency,
      grandTotal: cart.total,
      tax: cart.tax,
      discountAffiliate: cart.discount.affiliateName ?? '',
      discountAmount: !isNaN(cart.discount.totalDiscounted) ? cart.discount.totalDiscounted : 0,
      discountCode: cart.discount.code ?? '',
      uploadLogoLater: ord.logo.filter((e) => { return e.length === 0 }).length === 3,
      shippingMethod:
        "Canada Post",
      company: ord.company || "",
      color: getColorData(ord.color, currency).description,
      buyerEmail: cart.details.billingDetails.email,
      logo: ord.logo,
      blank: ord.blank,
      personalizedCards:
        ord.pCard.length > 0 ? personalizedCardsOrderNumbers : "none",
    })
      .then(async (re) =>
      {
        const docRef = doc(db, "orders", re.id);

        const billingRef = collection(docRef, "billing");

        const billingInfo = {
          firstName: cart.details.billingDetails.firstName,
          lastName: cart.details.billingDetails.lastName,
          emailAddress: cart.details.billingDetails.email,
          streetAddress:
            cart.details.billingDetails.street1 +
            " " +
            (cart.details.billingDetails.street2 ?? ''),
          stateProvince: cart.details.billingDetails.stateProvince,
          city: cart.details.billingDetails.city,
          zipCode: cart.details.billingDetails.zip,
          phone: cart.details.billingDetails.phone,
          country: cart.details.billingDetails.country
        };
        const shippingInfo = sameAddress
          ? billingInfo
          : {
            firstName: cart.details.shippingDetails.firstName,
            lastName: cart.details.shippingDetails.lastName,
            emailAddress: cart.details.shippingDetails.email,
            streetAddress:
              cart.details.shippingDetails.street1 +
              " " +
              (cart.details.billingDetails.street2 ?? ''),
            stateProvince: cart.details.shippingDetails.stateProvince,
            city: cart.details.shippingDetails.city,
            zipCode: cart.details.shippingDetails.zip,
            phone: cart.details.shippingDetails.phone,
            country: cart.details.shippingDetails.country
          };
        await addDoc(billingRef, billingInfo);

        const shippingRef = collection(docRef, "shipping");

        await addDoc(shippingRef, shippingInfo);


        const companyRef = doc(db, ord.reference!);
        const company = (await getDoc(companyRef)).data();
        const cards = [...company!.cards, ...cardsOrderNumbers]
        await updateDoc(companyRef, {
          cards: arrayUnion(...cards),
          order: arrayUnion(docRef)
        })
      })
      .catch((e) =>
      {

      });
    updateDiscount(cart.discount);
    //const objectStrings = cardsOrderAndQRCodes.map(obj => `Order Number: ${obj.orderNumber} - QRCode: See Attachments - Personalized: ${obj.personalized} - Name: ${obj.name}`);
    //const objectStringsClient = cardsOrderAndQRCodes.map(obj => `Order Number: ${obj.orderNumber} - Personalized: ${obj.personalized} - Name: ${obj.name}`);

    //EMAIL FOR THE CLIENT    
    const receipt = getReceipt({ orderNumber: cardsOrderAndQRCodes[0].orderNumber, company: cart.order.company, logo: HBool.Yes, address: `${cart.details.shippingDetails.street1} ${cart.details.shippingDetails.street2 ?? ''}, ${cart.details.shippingDetails.city}, ${cart.details.shippingDetails.stateProvince}, ${cart.details.shippingDetails.zip}, ${cart.details.shippingDetails.country}`, date: getCurrentTimeInEST('YYYY-MM-DD ** h:mmA').replace('**', 'at'), email: cart.details.shippingDetails.email, phone: cart.details.shippingDetails.phone, shippingMethod: 'Canada Post', tax: cart.tax, total: cart.total, type: PurchaseType.Elite, color: cart.order.color, items: cart.itemized, currency: currency, discount: cart.discount } as Receipt);
    const subject = getEmailSubject(order.type);
    await sendEmail({
      dest: details.billingDetails.email,
      fullName:
        "businesscardX",
      message:
        receipt,
      sender: "",
      subject: subject,
    });
    //EMAIL FOR US
    await sendEmail({
      dest: process.env.REACT_APP_EMAIL!,
      fullName:
        'businesscardX',
      message: getOrderSummary(cart, currency, cardsOrderAndQRCodes, new Date(getCurrentTimeInEST())),
      sender: process.env.REACT_APP_EMAIL!,
      subject: "New Order Arrived",
      attachments: allAttachments
    });
    if (window.rewardful)
    { window.rewardful('convert', { email: cart.details.billingDetails.email }); }
    navigate("/confirm-order", { state: { email: receipt, dest: details.billingDetails.email, subject: subject } });
  }
}
export async function sendEmail(mail: Email)
{
  try
  {
    fetch(
      process.env.REACT_APP_SENDMAIL_URL!,
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(mail),
      })
      .then((response) =>
      {
        if (response.status === 200)
        {

        }

      });
  } catch (error)
  {
    console.error(error)
  }
}
export async function getRandomOrderNumber(max: number)
{
  var zerofilled = ''
  while (true)
  {
    var n = Math.floor(Math.random() * max);
    zerofilled = ("00000000000" + n).slice(-11);
    const c = query(
      collection(db, "cards"),
      where("orderNumber", "==", zerofilled));
    let cData = false;
    await getDocs(c).then((a) =>
    {
      cData = a.docs[0]?.exists()!;
    });
    if (!cData)
    {
      break;
    }
  }
  return zerofilled;
}

export default CheckoutPage;
