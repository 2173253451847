import React, { ChangeEvent, useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import CTextField from "../../components/CTextField";
import CCheckBox from "../../components/CCheckBox";
import CSelect from "../../components/CSelect";
import Footer from "../../components/Footer";
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from "react-router-dom";
import square from "../../assets/images/square-elite-card.svg";
import extra_cards from "../../assets/images/extra-cards.png";
import card_logo from "../../assets/images/card-logo.svg";
import personal from "../../assets/images/personalized-elite-card.svg";
import PersonalizedCard from "../../components/PersonalizedCard";
import mock from "../../assets/images/elite-mock.svg";
import mock_gold from "../../assets/images/elite-mock-gold.svg";
import mock_silver from "../../assets/images/elite-mock-silver.svg";
import ledgerluxe from "../../assets/images/ledgerluxe.svg";
import ledgerluxe_gold from "../../assets/images/ledgerluxe-gold.svg";
import ledgerluxe_silver from "../../assets/images/ledgerluxe-silver.svg";
import your_logo_here from "../../assets/images/your-logo-here.svg";
import your_logo_here_gold from "../../assets/images/your-logo-here-gold.svg";
import your_logo_here_silver from "../../assets/images/your-logo-here-silver.svg";

import Incrementer from "../../components/Incrementer";
import cart from "../../assets/images/cart.svg";
import { useStorage } from '../../services/firebase';
import
{
  Colors,
  Elite,
  PCard,
  Purchase,
  PurchaseType,
  getColorData,
  getSubTotal,
  setOrder,
} from "../../redux/feature/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import { Cart } from "../../components/Cart";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { Alert, CircularProgress } from "@mui/material";
import { Currency, curr } from "../../currencies";
import { ColorLabels } from "../../types";
import { colorToLabel, labelToColor } from "../../utils/features";

export const EliteCard = () =>
{
  const navigate = useNavigate();
  const [uploadLater, setUploadLater] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [loadingLogo, setLoadingLogo] = useState([false, false, false])
  const cartRx: Purchase = useSelector((state: any) => state.cart);
  const [first, setFirst] = useState(true);
  const [loading, setLoading] = useState(true);
  const [order, setlocalOrder] = useState(cartRx.order.type !== PurchaseType.Elite ? { base: 2, blank: false, color: "", company: '', extra: 0, logo: ['', '', ''], nonPersonalized: 2, pCard: [], personalized: 0, submit: false, type: PurchaseType.Elite, valid: undefined, npCard: [] } as Elite : {
    npCard: (cartRx.order as Elite).npCard ?? [],
    base: (cartRx.order as Elite).base ?? 2,
    nonPersonalized: (cartRx.order as Elite).nonPersonalized ?? 2,
    personalized: (cartRx.order as Elite).personalized ?? 0,
    pCard: (cartRx.order as Elite).pCard ?? [],
    valid: (cartRx.order as Elite).valid ?? false,
    type: PurchaseType.Elite,
    blank: (cartRx.order as Elite).blank ?? false,
    color: (cartRx.order as Elite).color ?? "",
    company: (cartRx.order as Elite).company ?? "",
    extra: (cartRx.order as Elite).extra ?? 0,
    logo: (cartRx.order as Elite).logo ?? ['', '', ''],
  } as Elite);
  const currency: Currency = useSelector((state: any) => state.currency);
  const [errors, setErrors] = useState({
    company: "",
    color: "",
    logo: "",
    pcards: Array(order.personalized).fill({
      fullName: "",
    } as PCard),
  });

  const dispatch = useDispatch();
  const storage = useStorage();
  async function uploadLogo(event: ChangeEvent<HTMLInputElement>)
  {
    const file = event.target.files?.[0];
    let upUrl = '';
    if (
      file &&
      (file.type.toLocaleLowerCase() === "image/png" ||
        file.type.toLocaleLowerCase() === "image/jpeg" ||
        file.type.toLocaleLowerCase() === "image/jpg" ||
        file.type.toLocaleLowerCase() === "image/svg+xml")
    )
    {
      const storageRef = ref(storage, `/files/${uuidv4()}.${file.name.split('.').pop()}`);

      await uploadBytes(storageRef, file, { contentType: file.type }).then(async (snapshot) =>
      {
        await getDownloadURL(snapshot.ref).then((url) =>
        {
          upUrl = url;
        })
      });
      setUploadLater(false);
      event.target.value = "";
    }
    return upUrl
  }

  useEffect(() =>
  {
    if (loading)
    {
      window.scrollTo(0, 0);
      if (order.valid !== undefined && !order.valid)
      {

        validateFields()
      }
    }

  }, []);

  useEffect(() =>
  {
    if (order.valid && order.submit)
    {
      navigate("/cart");
    }
  }, [order.valid, order.submit])



  useEffect(() =>
  {

    if (order.valid && !order.submit && !first)
    {

      dispatch(setOrder({ ...order, valid: false }))
      dispatch(getSubTotal(currency))
    } else
    {
      dispatch(setOrder({ ...order }))
      dispatch(getSubTotal(currency))
    }
    setFirst(false)


  }, [order, currency])

  useEffect(() =>
  {
    if (order.personalized + order.nonPersonalized < order.base)
    {
      setlocalOrder({ ...order, nonPersonalized: order.nonPersonalized + 1 });
    } else if (order.personalized + order.nonPersonalized > order.base)
    {
      if (order.personalized > order.nonPersonalized)
      {
        setlocalOrder({
          ...order,
          personalized: order.personalized - 1,
          pCard: order.pCard.filter((e, i) => i !== order.pCard.length - 1),
        });
      } else
      {
        setlocalOrder({ ...order, nonPersonalized: order.nonPersonalized - 1 });
      }
    }
  }, [order.base]);
  const handleColorChange = (color: string) =>
  {
    const newError = {
      company: "",
      color: "",
      logo: "",
      pcards: Array(order.personalized).fill({
        fullName: "",
      } as PCard),
    };
    const isSelectable = color !== ColorLabels.White;

    if (!isSelectable)
    {
      newError.color = "The color you picked is currently unavailable";
    }
    setlocalOrder({ ...order, color: labelToColor(color.replace('/card', '')) });
    setErrors(newError);
  };

  function validateFields()
  {
    const newError = {
      company: "",
      color: "",
      logo: "",
      pcards: Array(order.personalized).fill({
        fullName: "",
      } as PCard),
    };
    let valid = true;
    if (!order.blank)
    {
      if (!order.company.trim())
      {
        newError.company = "Please provide a Company for the card";
        valid = false;
      }


      if (order.logo.filter((e, i) => e.length === 0).length === 3 && !uploadLater)
      {
        newError.logo = "Please provide at least one logo or choose to Upload Later";
        valid = false;
      }
    }

    if (order.color === Colors.White)
    {
      newError.color = "The color you picked is currently unavailable";
      valid = false;
    }
    if (order.color === "")
    {
      newError.color = "Please choose a print color for the card.";
      valid = false;
    }

    order.pCard.forEach((e, i) =>
    {
      if (!e.fullName.trim())
      {
        newError.pcards = [
          ...newError.pcards.slice(0, i),
          {
            ...newError.pcards[i],
            fullName: "Please provide a Name for the card",
          } as PCard,
          ...newError.pcards.slice(i + 1),
        ];
        valid = false;
      }

    });

    setErrors(newError);
    return valid;
  }
  function handleSubmit()
  {

    if (validateFields())
    {
      setlocalOrder({ ...order, valid: true, submit: true });
    } else
    {
      setlocalOrder({ ...order, valid: false, submit: false });
    }
  }

  return (
    <>
      <Cart onClick={() => { handleSubmit() }} />
      <div className="overflow-hidden">
        <Navbar />
        <div className="relative">
          <div className="flex lg:flex-row flex-col justify-between  lg:mt-[35px] mt-[56px] lg:px-[80px] px-[25px] mb-[104px]">


            <div className=" relative lg:mr-[20px] ">
              <div className="relative lg:mt-[75px]">
                <h1 className=" inline-block font-custom font-black lg:text-[64px]/[86px] text-[30px]/[41px] lg:mb-[34px]">
                  ELITE Card
                </h1>
                <div className="bg-primary relative lg:w-[150%] w-[125%] h-[37px] z-[-1] lg:mt-[-70px] mt-[-18px] lg:mb-[37px] mb-[22px]" />
                <p className="font-custom font-light lg:text-[20px]/[32px] text-[14px]/[25px] lg:px-0  lg:w-[381px] w-[341px] tracking-[0.05em] lg:mb-[34px] mb-[14px]">
                  Give your employees the tools they need to make lasting
                  connections with bcX ELITE's managed employee dashboard and
                  custom branding.
                </p>
                <div className="flex lg:flex-row flex-col justify-between lg:items-end items-start lg:mb-[43px] mb-[41px]">
                  <div className="flex flex-col gap-[14px] justify-center lg:items-center items-start">
                    <button
                      className="font-custom font-medium text-white shadow-custom text-[20px]/[27px]  text-center hover:bg-inset bg-button rounded-[33px] lg:mb-[15px] lg:px-[14px] px-[14px] w-[201px] h-[52px]"
                      onClick={() =>
                      {
                        dispatch(setOrder({ valid: false } as Elite))
                        dispatch(getSubTotal(currency))
                        navigate('/shop')
                      }}
                    >
                      Remove from Cart
                    </button>
                    <p className="font-custom font-light lg:text-[14px]/[19px] tracking-[0.05em]">
                      Minimum 2 users.
                    </p>
                    <Incrementer
                      minimum={2}
                      value={order.base}
                      onDecrement={() =>
                      {
                        setlocalOrder({ ...order, base: order.base - 1 });
                      }}
                      onIncrement={() =>
                      {
                        setlocalOrder({ ...order, base: order.base + 1 });
                      }}
                    />
                  </div>

                  <div className="flex lg:mb-0 mb-[20px]">
                    <p className="font-custom font-normal text-[36px]/[49px]">
                      $
                      {order.base * 75 +
                        (order.base < 8 ? 160 - order.base * 20 : 0)}
                    </p>
                    <p className="font-custom font-light text-[18px]/[25px]  flex self-start pt-[5px] pl-[6px]">
                      {" "}
                      {currency}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col lg:mb-[43px] mb-[18px]">
                  <p className="font-custom font-light lg:text-[20px]/[32px] text-[14px]/[25px] lg:px-0 tracking-[0.05em] mt-0 lg:mb-[24px] mb-[22px]">
                    Price Breakdown:
                  </p>
                  <div className="flex flex-row items-center">
                    <p className="break-keep min-w-[165px] lg:mr-[36px] mr-[-30px] text-[16px]/[32px] font-light font-custom text-gray ">
                      Number of Cards:
                    </p>
                    <div className="container mx-auto h-[52px]">
                      <div className="grid grid-cols-1 items-center content-center relative z-10 top-0 font-custom font-medium text-button w-[112px] text-[20px]/[27px]  text-center bg-white rounded-[33px] px-[14px] h-[52px] border-2 border-button">
                        <p className="text-[16px]/[32px] text-center font-medium font-custom">
                          {order.base}
                        </p>
                      </div>
                      <div className="grid grid-cols-1 items-center content-center relative -top-[52px] left-0 z-0 font-custom font-medium text-button w-[210px] text-[20px]/[27px]  text-center bg-primary rounded-[33px] lg:mb-[15px] lg:px-[14px] px-[14px] h-[52px]">
                        <p className="text-[16px]/[32px] text-right font-medium mr-[8px] font-custom">
                          $75 each
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=" lg:ml-[250px] min-[951px]:ml-[210px] md:max-[950px]:ml-[185px] ml-[185px] mb-[-15px] mt-[-5px]">+</div>
                  <div className="flex flex-row items-center mt-[16px]">
                    <p className="lg:w-[250px] min-w-[165px] lg:mr-[36px] mr-[-30px] text-[16px]/[32px] font-light font-custom text-gray ">
                      ELITE Dashboard:
                    </p>
                    <div className="container mx-auto h-[52px]">
                      <div className="grid grid-cols-1 items-center content-center relative z-10 top-0 font-custom font-medium text-button w-[112px] text-[20px]/[27px]  text-center bg-white rounded-[33px] px-[14px] h-[52px] border-2 border-button">
                        <p className="text-[16px]/[32px] text-center font-medium font-custom">
                          ${order.base < 8 ? 160 - order.base * 20 : 0}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-1 mt-[24px] bg-button items-center content-center font-custom font-medium text-white w-[328px] text-[20px]/[27px] rounded-[10px] px-[27px] h-[88px]">
                    <p className="text-[16px]/[24px] text-left font-medium font-custom">
                      Save $20 on the ELITE Dashboard for every card added.
                    </p>
                  </div>
                </div>
                {/*
              <div className="flex flex-col lg:mb-[43px] mb-[18px]">
                <p className="font-custom font-light lg:text-[20px]/[32px] text-[14px]/[25px] lg:px-0 tracking-[0.05em] mt-0 lg:mb-[24px] mb-[22px]">
                  What the package includes:
                </p>
                <ul className="list-disc ml-[20px] lg:w-[360px] w-[341px] space-y-1 ">
                  <li>
                    <p className="font-custom font-light lg:text-[20px]/[32px] text-[14px]/[25px] lg:px-0 tracking-[0.05em] mt-0 ">
                      {order.base} branded cards
                    </p>
                  </li>
                  <li>
                    <p className="font-custom font-light lg:text-[20px]/[32px] text-[14px]/[25px] lg:px-0 tracking-[0.05em] mt-0 ">
                      Branded user profile
                    </p>
                  </li>
                  <li>
                    <p className="font-custom font-light lg:text-[20px]/[32px] text-[14px]/[25px] lg:px-0 tracking-[0.05em] mt-0 ">
                      ELITE admin profile portal (Assign and reassign cards,
                      admin profile editing)
                    </p>
                  </li>
                </ul>
              </div>*/}

                <h4 className="font-custom font-normal text-text text-[25px]/[34px] lg:mb-[21px] mb-[14px] ">
                  Extra Set of Cards
                </h4>
                <p className="font-custom font-light lg:text-[20px]/[32px] text-[14px]/[25px] lg:w-[347px]  w-[190px] tracking-[0.05em] mt-0 lg:mb-[30px] mb-[18px]">
                  Want to add an extra set of cards to your purchase?
                </p>

                <div className="flex items-start justify-start flex-shrink-0  lg:mt-[17px] mt-[15px] lg:ml-[-26px] lg:mb-[41px] mb-[43px]">
                  <img
                    className="xl:max-w-[100%] lg:max-w-[75%] h-auto"
                    src={extra_cards}
                  />
                </div>

                <div className="flex lg:mb-[51px] mb-[49px] justify-between">
                  <div className="flex flex-col lg:mb-0 ">
                    <div className="flex flex-row lg:mb-[23px] mb-[6px]">
                      <p className="font-custom font-normal text-[36px]/[49px]">
                        $40
                      </p>
                      <p className="font-custom font-light text-[18px]/[25px]  flex self-start pt-[5px] pl-[6px]">
                        {currency}
                      </p>
                      <p className="font-custom font-light lg:text-[18px]/[25px] text-[20px]/[32px] flex self-center pt-[5px] pl-[3px]">
                        /card
                      </p>
                    </div>

                    <div className="flex flex-col">
                      <p className="font-custom lg:font-light font-normal text-[23px]/[32px] tracking-[0.05em] self-start lg:mb-0 mb-[13px]">
                        ({order.base} cards)
                      </p>
                      <Incrementer
                        value={order.extra}
                        minimum={0}
                        onDecrement={() =>
                        {
                          setlocalOrder({ ...order, extra: order.extra - 1 });
                        }}
                        onIncrement={() =>
                        {
                          setlocalOrder({ ...order, extra: order.extra + 1 });
                        }}
                        classname="lg:hidden block"
                      />
                    </div>
                  </div>
                  <div className="self-end justify-end lg:block hidden">
                    <Incrementer
                      value={order.extra}
                      minimum={0}
                      onDecrement={() =>
                      {
                        setlocalOrder({ ...order, extra: order.extra - 1 });
                      }}
                      onIncrement={() =>
                      {
                        setlocalOrder({ ...order, extra: order.extra + 1 });
                      }}
                    />
                  </div>
                </div>

                <div className="lg:mb-[41px] mb-[30px] flex flex-col ">
                  <h4 className="font-custom font-normal text-[25px]/[34px] mb-[21px]">
                    Personalized Cards
                  </h4>
                  <p className="font-custom font-light text-[20px]/[32px] lg:mb-[6px] mb-[16px] lg:w-auto w-[281px] tracking-[0.05em]">
                    Want to personalize your ELITE cards?
                  </p>
                  <p className="font-custom font-light text-[20px]/[32px] lg:mb-[15px] mb-[16px] tracking-[0.05em]">
                    Example of Personalized:
                  </p>

                  <div className="flex items-start justify-start flex-shrink-0  lg:mb-[18px] mb-[21px]">
                    <img
                      className="xl:max-w-[100%] lg:max-w-[75%] h-auto "
                      src={personal}
                    />
                  </div>

                  <p className="font-custom font-light text-[20px]/[32px] lg:mb-[15px] mb-[16px] tracking-[0.05em]">
                    Example of Non-Personalized:
                  </p>
                  <div className="relative flex items-start justify-start flex-shrink-0 lg:mb-[35px] mb-[39px]">
                    <img
                      className="lg:max-w-[350]  h-[200px]"
                      src={mock}
                    />
                    <img
                      className={
                        (order.company.length > 0 || order.blank
                          ? "hidden"
                          : "") +
                        " absolute top-[60px] lg:left-[74px] left-[74px] lg:w-[65px] w-[65px] h-auto"
                      }
                      src={ledgerluxe}
                      alt=""
                    />
                    <img
                      className={
                        (order.company.length > 0
                          ? order.blank
                            ? "hidden"
                            : ""
                          : "hidden") +
                        " absolute top-[60px] lg:left-[74px] left-[74px] lg:w-[65px] w-[65px] h-auto"
                      }
                      src={your_logo_here}
                      alt=""
                    />
                  </div>

                  <div className="flex lg:mb-[22px] mb-[13px]">
                    <p className="font-custom font-normal text-[36px]/[49px]">
                      $10
                    </p>
                    <p className="font-custom font-light text-[18px]/[25px]  flex self-start pt-[5px] pl-[6px]">
                      {currency}
                    </p>
                    <p className="font-custom font-light lg:text-[18px]/[25px] text-[20px]/[32px] flex self-center pt-[5px] pl-[3px]">
                      /card
                    </p>
                  </div>
                  <div className="flex lg:flex-row flex-col lg:gap-[57px] gap-[10px] lg:justify-between lg:items-center items-start mb-[21px]">
                    <p className="font-custom font-light text-[14px]/[19px]">
                      Personalized Cards{" ".repeat(6)}
                    </p>
                    <Incrementer
                      value={order.personalized}
                      maximum={order.base}
                      minimum={0}
                      onDecrement={() =>
                      {
                        setlocalOrder({
                          ...order,
                          personalized: order.personalized - 1,
                          nonPersonalized: order.nonPersonalized + 1,
                          pCard: order.pCard.filter(
                            (e, i) => i !== order.pCard.length - 1
                          ),
                        });
                      }}
                      onIncrement={() =>
                      {
                        if (order.nonPersonalized - 1 >= 0)
                        {
                          setErrors({
                            ...errors,
                            pcards: [
                              ...errors.pcards,
                              { fullName: "", } as PCard,
                            ],
                          });
                          setlocalOrder({
                            ...order,
                            personalized: order.personalized + 1,
                            nonPersonalized: order.nonPersonalized - 1,
                            pCard: [
                              ...order.pCard,
                              {
                                fullName: "",
                                orderNumber: "",
                                qr: "",
                              },
                            ],
                          });
                        }
                      }}
                    />
                  </div>

                  <div className="flex lg:flex-row flex-col lg:gap-[57px] gap-[10px] lg:justify-between lg:items-center items-start mb-[21px]">
                    <p className="font-custom font-light text-[14px]/[19px]">
                      Non-Personalized Cards
                    </p>
                    <Incrementer
                      value={order.nonPersonalized}
                      maximum={order.base}
                      minimum={0}
                      onDecrement={() =>
                      {
                        if (order.nonPersonalized - 1 >= 0)
                        {
                          setErrors({
                            ...errors,
                            pcards: [
                              ...errors.pcards,
                              { fullName: "", } as PCard,
                            ],
                          });
                          setlocalOrder({
                            ...order,
                            personalized: order.personalized + 1,
                            nonPersonalized: order.nonPersonalized - 1,
                            pCard: [
                              ...order.pCard,
                              {
                                fullName: "",
                                orderNumber: "",
                                qr: "",
                              },
                            ],
                          });
                        }
                      }}
                      onIncrement={() =>
                      {
                        if (order.personalized - 1 >= 0)
                        {
                          setlocalOrder({
                            ...order,
                            personalized: order.personalized - 1,
                            nonPersonalized: order.nonPersonalized + 1,
                            pCard: order.pCard.filter(
                              (e, i) => i !== order.pCard.length - 1
                            ),
                          });
                        }
                      }}
                    />
                  </div>
                </div>
                {/*----------------- */}
                <div className="flex flex-col items-start justify-start w-full">
                  {order.pCard.map((e, i) => (
                    <PersonalizedCard
                      onChangeName={(a) =>
                      {
                        setlocalOrder({
                          ...order,
                          pCard: [
                            ...order.pCard.slice(0, i),
                            { ...e, fullName: a },
                            ...order.pCard.slice(i + 1),
                          ],
                        });
                      }}

                      error_name={errors.pcards[i].fullName}
                      key={i.toString()}
                      name={e.fullName}
                      number={(i + 1).toString()}
                      w="lg:w-[344px] w-[288px]"
                      classname=""
                    />
                  ))}
                </div>
                <h4 className="lg:hidden block font-custom font-normal text-[36px]/[49px] mb-[24px]">
                  What Is On The Card
                </h4>

                <div className="lg:flex hidden flex-col items-center justify-center">
                  <h4 className="font-custom font-normal text-[35px]/[48px] lg:mb-[18px]">
                    Already Have a Card?
                  </h4>
                  <button
                    className="font-custom font-medium text-button shadow-custom lg:text-[20px]/[27px] text-[16px]/[25px] text-center hover:bg-secondary bg-primary rounded-[33px] px-[111px] pt-[12px] pb-[14px]"
                    onClick={() =>
                    {
                      navigate("/login");
                    }}
                  >
                    Sign In Here
                  </button>
                </div>
              </div>
            </div>

            <div className="flex flex-col ">
              <p className="lg:block hidden font-custom font-light text-[20px]/[32px] tracking-[0.05em] mb-[11px] h-[32px]">
                {(order.company.length > 0 || order.blank) ? "" : "Example Card for LedgerLuxe Partners"}
              </p>

              <div className="relative flex items-start justify-start flex-shrink-0 lg:w-[618px] w-[350px] lg:mb-[35px] mb-[39px]">
                <img
                  className="xl:max-w-[100%] lg:max-w-[75%] h-auto lg:w-[618px] w-[300px]"
                  src={(colorToLabel(order.color, currency) === ColorLabels.Silver ? mock_silver : (colorToLabel(order.color, currency) === ColorLabels.Gold ? mock_gold : mock))}
                />
                <img
                  className={
                    (order.company.length > 0 || order.blank ? "hidden" : "") +
                    " absolute lg:top-[30%] top-[28%] lg:left-[128px] left-[50px]  lg:w-[118px] w-[60px] h-auto"
                  }
                  src={(colorToLabel(order.color, currency) === ColorLabels.Silver ? ledgerluxe_silver : (colorToLabel(order.color, currency) === ColorLabels.Gold ? ledgerluxe_gold : ledgerluxe))}
                  alt=""
                />
                <img
                  className={
                    (order.company.length > 0
                      ? order.blank
                        ? "hidden"
                        : ""
                      : "hidden") +
                    " absolute lg:top-[33%] top-[26%] lg:left-[128px] left-[47px]  lg:w-[118px] w-[80px] h-auto"
                  }
                  src={(colorToLabel(order.color, currency) === ColorLabels.Silver ? your_logo_here_silver : (colorToLabel(order.color, currency) === ColorLabels.Gold ? your_logo_here_gold : your_logo_here))}
                  alt=""
                />
              </div>

              <CTextField
                error={errors.company}
                value={order.company}
                label="Company"
                w="lg:w-[618px] w-[342px]"
                classname="lg:mb-[24px] mb-[17px]"
                onChange={(a) => setlocalOrder({ ...order, company: a })}
              />
              <CCheckBox
                label="Want a blank card?"
                classname="lg:mb-[54px] mb-[31px]"
                checked={order.blank}
                onCheck={(a: boolean) => setlocalOrder({ ...order, blank: a })}
              />
              <h3 className="font-custom font-medium lg:text-[39px]/[53px] text-[30px]/[41px] lg:mb-[13px] mb-[22px]">
                Upload Your Logo Here
              </h3>

              <div className="flex lg:w-auto w-full lg:gap-[22px] gap-[15px] lg:mb-[23px] mb-[34px]">
                <div className="flex flex-col gap-[18px]">
                  <p className="lg:w-[545px] w-[340px] font-custom font-light text-[20px]/[32px] tracking-[0.05em]">
                    Upload 1-3 versions of your logo that you'd like to see mocked-up. We will add our custom, sleek, monotone filter to them and send them back to you for approval.
                  </p>
                  <div className="flex flex-row gap-[17px] items-center justify-start flex-shrink-0">
                    {order.logo.map((e, i) =>
                    {
                      return (
                        <div className="relative">
                          <button onClick={() => { if (!loadingLogo[i]) { setlocalOrder({ ...order, logo: [...order.logo.slice(0, i), '', ...order.logo.slice(i + 1)] }) } }} className={(order.logo[i] !== '' ? 'flex' : 'hidden') + " absolute z-50 right-[-15px] top-[-30px] rotate-45 text-red-500 p-[3px] font-custom font-medium text-[25px]"}>
                            +
                          </button>
                          <label htmlFor={"logoInput_" + i}>
                            <img
                              className={(loadingLogo[i] ? 'hidden' : 'block') + (uploadLater || order.blank ? ' opacity-50 cursor-default' : ' opacity-100 cursor-pointer') + " lg:w-[117px] w-[101px] lg:h-[117px] h-[101px] object-cover "}
                              src={
                                (order.logo[i] && order.logo.length > 0) ? order.logo[i] : card_logo
                              }
                            />
                            <div className={(loadingLogo[i] ? 'block' : 'hidden') + ' flex flex-col justify-center items-center lg:w-[117px] w-[101px] lg:h-[117px] h-[101px]'}>
                              <CircularProgress color="inherit" />
                            </div>
                            <input
                              disabled={uploadLater || order.blank}
                              id={"logoInput_" + i}
                              key={"logoInput_" + i}
                              type="file"
                              accept="image/png, image/jpeg, image/jpg, .svg"
                              className="hidden"
                              onChange={async (a) => { setLoadingLogo([...loadingLogo.slice(0, i), true, ...loadingLogo.slice(i + 1)]); setlocalOrder({ ...order, logo: [...order.logo.slice(0, i), await uploadLogo(a).then((a) => { setLoadingLogo([...loadingLogo.slice(0, i), false, ...loadingLogo.slice(i + 1)]); return a; }), ...order.logo.slice(i + 1)] }) }}
                            />
                          </label>
                        </div>)
                    })}
                  </div>
                  <div className={(errors.logo ? 'block ' : 'hidden ') + ' pt-[10px]  rounded-[6px] overflow-clip'}>
                    <Alert style={{ fontFamily: 'Satoshi-Variable', fontWeight: '300', fontSize: '15px', lineHeight: '20px' }} severity="warning">{errors.logo}</Alert>
                  </div>
                </div>
              </div>


              <div className="flex lg:flex-row lg:gap-0 gap-[38px] flex-col justify-between lg:mb-[44px] mb-[65px]">
                <label
                  htmlFor="logoFromPics"
                  className={((order.logo.findIndex((e, i) => e.length === 0) === -1 || uploadLater || order.blank) ? 'cursor-default opacity-50' : 'hover:bg-secondary cursor-pointer opacity-100') + " shadow-custom bg-primary lg:w-[270px] w-[343px] font-custom font-medium text-button lg:text-[20px]/[27px] text-[16px]/[22px] text-center rounded-[33px] pt-[12px] pb-[14px]"}
                >
                  Upload from Photos
                  <input
                    disabled={order.logo.findIndex((e, i) => e.length === 0) === -1 || uploadLater || order.blank}
                    id="logoFromPics"
                    type="file"
                    accept="image/png, image/jpeg, image/jpg, .svg"
                    className="hidden"
                    onChange={async (a) =>
                    {
                      let idx = order.logo.findIndex((e, i) => e.length === 0);
                      if (idx !== -1)
                      {
                        setLoadingLogo([...loadingLogo.slice(0, idx), true, ...loadingLogo.slice(idx + 1)]); setlocalOrder({ ...order, logo: [...order.logo.slice(0, idx), await uploadLogo(a).then((a) => { setLoadingLogo([...loadingLogo.slice(0, idx), false, ...loadingLogo.slice(idx + 1)]); return a; }), ...order.logo.slice(idx + 1)] })
                      }
                    }}
                  />
                </label>
                <button
                  disabled={order.blank}
                  className={
                    (uploadLater ?
                      "shadow-inset hover:bg-text bg-inset text-white" :
                      "shadow-custom hover:bg-inset bg-text text-white") + (order.blank ? ' opacity-50' : ' opacity-100') +
                    " lg:w-[270px] w-[343px] font-custom font-medium  lg:text-[20px]/[27px] text-[16px]/[22px] text-center  rounded-[33px] pt-[12px] pb-[14px]"
                  }
                  onClick={() =>
                  {
                    setUploadLater(!uploadLater);
                    if (!uploadLater)
                    { setlocalOrder({ ...order, logo: ['', '', ''] }) };
                  }}
                >
                  {uploadLater ? 'Upload Now' : 'Upload Later'}
                </button>
              </div>

              <div>
                <h3 className="font-custom font-medium lg:text-[39px]/[53px] text-[30px]/[41px] lg:mb-[23px] mb-[25px]">
                  Which Colour Of Print?
                </h3>

                <CSelect
                  error={errors.color}
                  errorclass="mt-[-40px] mb-[10px] lg:w-[618px] w-[343px]"
                  classname="lg:mb-[50px] mb-[51px] lg:w-[618px] w-[343px]"
                  hint="Please Choose One"
                  items={[
                    { item: ColorLabels.White, class: "line-through", active: false },
                    { item: ColorLabels.Silver + '/card', class: "", active: true },
                    { item: ColorLabels.Gold + '/card', class: "", active: true },
                  ]}
                  value={(order.color !== Colors.Silver) && (order.color.toString() !== Colors.Gold) ? colorToLabel(order.color, currency) : colorToLabel(order.color, currency) + '/card'}
                  onChange={(a) =>
                  {
                    handleColorChange(a)
                  }}
                />
              </div>

              <div className="flex flex-col justify-end">
                <div className="flex flex-col items-end lg:w-auto w-[340px]">
                  <button
                    className="flex font-custom items-center justify-center font-medium text-white shadow-custom lg:text-[24px]/[32px] text-[16px]/[25px] text-center hover:bg-inset bg-button rounded-[42px] lg:px-[37px] px-[15px] lg:pt-[25px] pt-[18px] lg:pb-[23px] pb-[16px] lg:mb-0 mb-[25px] sm:w-fit w-full"
                    onClick={() =>
                    {
                      handleSubmit();
                    }}
                  >
                    <img alt="" src={cart} className="lg:mr-[20px] mr-[10px] lg:w-auto w-[30px]"></img>
                    <div className="mr-[15px]">Go To Cart</div>
                  </button>
                  <div
                    className={
                      (!(errors.pcards.filter((e, i) => e.length !== 0).length === 0 &&
                        errors.color === "" &&
                        errors.company === ""
                      )
                        ? "block "
                        : "hidden ") +
                      "lg:px-[37px]  lg:pt-[25px] lg:pb-[23px] pb-[18px] rounded-[6px] lg:w-fit w-full"
                    }
                  >
                    <Alert
                      style={{
                        fontFamily: "Satoshi-Variable",
                        fontWeight: "300",
                        fontSize: "15px",
                        lineHeight: "20px",
                      }}
                      severity="warning"
                    >
                      Missing Information
                    </Alert>
                  </div>
                </div>
              </div>
              <div className="lg:hidden flex flex-col lg:items-center lg:justify-center justify-start">
                <h4 className="font-custom font-normal text-[34px]/[47px] mb-[21px]">
                  Already Have a Card?
                </h4>
                <button
                  className="w-[333px] font-custom font-medium text-button shadow-custom text-[16px]/[22px] text-center hover:bg-secondary bg-primary rounded-[33px] px-[111px] pt-[12px] pb-[14px]"
                  onClick={() =>
                  {
                    navigate("/login");
                  }}
                >
                  Sign In Here
                </button>
              </div>
            </div>
          </div>

          <div className="hidden absolute top-[1280px]  -z-10 right-[0%] lg:flex items-start justify-end flex-shrink-0">
            <img className="max-w-full h-auto" src={square} />
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
