import React, { useEffect, useRef, useState } from 'react'
import logo from '../../assets/images/bcx_logo.svg';
import what_is_bcx from '../../assets/images/what_is_bcx.png';
import squares_wibcx from '../../assets/images/decorative-squares-right-home.png';
import bcx_black from '../../assets/images/bcx-black.png';
import bcx_elite from '../../assets/images/bcx-elite.png';
import nfc_tap from '../../assets/images/nfc-tap.png';
import scan_qr_code from '../../assets/images/scan-qr-code.png';

import { Navigate, useNavigate } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import CAccordion from '../../components/CAccordion';
import Footer from '../../components/Footer';
import Splash from '../../components/Splash';
import { Cart } from '../../components/Cart';



export function Home()
{
  return (
    <div>
      <Splash duration={1500} imageUrl={logo} nextComponent={Content()} />
    </div>);
}
function Content() 
{

  const navigate = useNavigate();
  useEffect(() => { window.scrollTo(0, 0); }, []);
  return (
    <>
      <Cart />
      <div className='overflow-hidden'>
        <Navbar />
        {/*What is bcX */}
        <div className='flex lg:flex-row flex-col lg:px-[80px] px-[25px] lg:mt-[11px] justify-between'>
          <div className='lg:mt-[180px] mt-[35px] relative '>
            <h1 className=' inline-block font-custom font-black lg:text-[64px]/[86px] text-[30px]/[41px] lg:mb-[34px]'>
              What is<br />businesscardX?
            </h1>
            <div className='bg-primary relative w-[200%] h-[37px] z-[-1] lg:mt-[-70px] mt-[-18px] lg:mb-[34px] mb-[16px]' />
            <div className='lg:hidden items-end justify-end'>
              <img className='max-w-[100%] h-auto' alt='bcx card, next to phone showing contact information' src={what_is_bcx} />
            </div>
            <p className='font-custom font-light lg:text-[20px]/[32px] text-[14px]/[25px] lg:px-0 px-[11px] lg:w-[480px] tracking-[0.05em] mt-0 lg:mb-[34px] mb-[41px]'>
              Say goodbye to paper business cards and hello to
              the future of networking. At businesscardX, we
              offer a digital solution to the traditional business
              card - allowing you to easily share your
              professional information with just a tap or <br className='lg:block hidden' />
              scan of your card. - No app needed!
            </p>
            <div className='lg:block flex justify-end'>
              <button className='font-custom font-medium  shadow-custom lg:text-[20px]/[32px] text-[16px]/[25px] text-center tracking-[0.05em]  text-button hover:bg-secondary bg-primary rounded-[33px] lg:px-[91px] px-[69px] lg:pt-[16px] pt-[12px] lg:pb-[18px] pb-[14px]' onClick={() => { navigate('/shop'); }}>Get Yours Today</button>
            </div>
          </div>
          <div className='lg:flex items-center justify-center flex-shrink-0 hidden xl:mt-0 lg:mt-[87px] xl:ml-0 lg:ml-[-65px]'>
            <img className='xl:max-w-[100%] lg:max-w-[75%] h-auto' alt='bcx card, next to phone showing contact information' src={what_is_bcx} />
          </div>
        </div>
        {/*Why bcx*/}
        <div className='flex lg:mt-[138px] mt-[41px] lg:flex-row  items-center '>
          <div className='lg:w-auto w-full flex lg:flex-row flex-col lg:items-center items-start justify-between bg-text lg:rounded-r-lg lg:gap-[166px] gap-[51px] lg:py-[79px] py-[73px] lg:pl-[80px] pl-[57px] lg:pr-[62px] pr-[65px]'>
            <p className='lg:py-[18px] self-start text-white m-0 font-custom font-medium lg:text-[57px]/[77px] text-[30px]/[41px]'>
              Why businesscardX<br />
              Over Traditional<br />
              Business Cards?
            </p>
            <div className='flex flex-col items-start justify-center  w-[265px] '>

              <CAccordion iconangle={['-90', '-180']} summary='Convenience'>
                BusinesscardX is more convenient than traditional business cards, allowing you to easily share your information with just a tap or scan.
              </CAccordion>
              <CAccordion iconangle={['-90', '-180']} summary='Eco-Friendly'>
                BusinesscardX is more eco-friendly than traditional paper business cards, reducing waste and helping the environment.
              </CAccordion>
              <CAccordion iconangle={['-90', '-180']} summary='Memorable'>
                BusinesscardX is more memorable than traditional business cards, leaving a lasting impression on your contacts with its sleek design and personalized online profile.
              </CAccordion>
              <CAccordion iconangle={['-90', '-180']} summary='Showcase More'>
                BusinesscardX is more professional than traditional business cards, giving you the opportunity to showcase your expertise and accomplishments through your online profile.
              </CAccordion>
              <CAccordion iconangle={['-90', '-180']} summary='Cost-Effective'>
                BusinesscardX is more cost-effective than traditional business cards, saving you money on printing and design costs while still providing a high-quality and professional digital business card solution.
              </CAccordion>



            </div>
          </div>
          <div className='ml-[-150px] z-[-1] hidden lg:block'>
            <img src={squares_wibcx} alt='' />
          </div>
        </div>
        {/*Products-bcx BLACK */}
        <div className='flex lg:flex-row flex-col justify-between lg:px-[80px] px-[25px] lg:mt-[50px] mt-[59px]'>
          <div className='relative'>
            <h1 className=' inline-block font-custom font-black lg:text-[64px]/[86px] text-[30px]/[41px] lg:mb-[34px] lg:mt-[44px] '>
              Products
            </h1>
            <div className='bg-primary relative w-[640px] h-[37px] z-[-1] lg:mt-[-70px] mt-[-18px] lg:mb-[47px] mb-[35px]' />
            <h3 className='font-custom font-medium text-text text-[39px]/[53px] mb-[24px] m-0'>
              bcX BLACK
            </h3>
            <p className='font-custom font-light lg:text-[20px]/[32px] text-[14px]/[25px] w-[342px] tracking-[0.05em] mt-0  mb-[34px] '>
              Upgrade your networking game with bcX BLACK and experience the convenience and efficiency of a digital business card.
            </p>
            <div className='lg:block flex justify-end'>
              <button className='font-custom font-medium shadow-custom lg:text-[20px]/[32px] text-[16px]/[25px]  text-center tracking-[0.05em]  text-button hover:bg-secondary bg-primary rounded-[33px] lg:mr-0 mr-[5px] lg:px-[91px] px-[99px] lg:pt-[16px] pt-[12px] lg:pb-[18px] pb-[14px]' onClick={() => { navigate('/shop'); }}>
                Buy Now
              </button>
            </div>
          </div>
          <div className='flex items-center justify-center flex-shrink-0 xl:ml-[-532px] lg:ml-[-432px] ml-0 lg:mt-0 mt-[63px] '>
            <img className='xl:max-w-[100%] lg:max-w-[80%] h-auto' alt='bcx BLACK, card with qr code, next to phone showing contact information' src={bcx_black} />
          </div>
        </div>
        {/*bcx ELITE */}
        <div className='flex lg:flex-row flex-col justify-between lg:px-[80px] px-[25px] mt-[143px]'>
          <div >
            <h3 className='font-custom font-medium text-text text-[39px]/[53px] mb-[33px] m-0'>
              bcX ELITE
            </h3>

            <p className='font-custom font-light lg:text-[20px]/[32px] text-[14px]/[25px] w-[342px] tracking-[0.05em] mt-0 lg:mb-[34px] mb-0 '>
              Give your employees the tools they need to make lasting connections with bcX ELITE's managed employee dashboard and <br />custom branding.
            </p>
            <p className='block lg:hidden font-custom font-light lg:text-[20px]/[32px] text-[14px]/[25px] w-[342px] tracking-[0.05em] mt-[25px] mb-[23px]'>
              2 card minimum
            </p>
            <div className='lg:block flex justify-end'>
              <button className='font-custom font-medium text-white shadow-custom lg:text-[20px]/[32px] text-[16px]/[25px] text-center tracking-[0.05em] hover:bg-inset bg-text rounded-[33px] lg:mb-0 mb-[30px] lg:px-[91px] px-[99px] lg:pt-[16px] pt-[12px] lg:pb-[18px] pb-[14px]' onClick={() => { navigate('/shop'); }}>
                Buy Now
              </button>
            </div>
            <p className='lg:block hidden font-custom font-light text-[20px]/[32px] w-[342px] tracking-[0.05em] mt-[16px] '>
              2 card minimum
            </p>
          </div>
          <div className='flex items-center justify-center flex-shrink-0'>
            <img className='max-w-[100%] lg:max-w-[75%] h-auto' alt='bcx ELITE, card, next to phone showing contact information' src={bcx_elite} />
          </div>
        </div>

        {/*How it works */}
        <div className='lg:mt-[170px] mt-[128px] lg:px-[80px] pl-[25px] pr-[17px] relative'>
          <h1 className=' inline-block font-custom font-black lg:text-[64px]/[86px] text-[30px]/[41px] lg:mb-[34px] lg:mt-[44px] '>
            How It Works
          </h1>
          <div className='bg-primary relative w-[618px] h-[37px] z-[-1] lg:mt-[-70px] mt-[-18px] lg:mb-[47px] mb-[35px]' />
          <p className='font-custom font-light lg:text-[20px]/[32px] text-[14px]/[25px] w-[350px] tracking-[0.05em] mt-0'>
            Grow your network quickly all by simply scanning the QR code on the back of your bcX BLACK card. For others to gain access to your entire ways of contact.
          </p>
          <div className='lg:ml-[199px] relative'>
            <h1 className='relative inline-block font-custom font-black lg:text-[64px]/[86px] text-[30px]/[41px] lg:mt-[117px] mt-[58px] z-[-1]'>
              NFC Tap
              <img className='relative lg:pl-[31px] lg:mt-[-200px] mt-[-66px] max-w-[100%] h-auto' src={nfc_tap} alt="bcx card over phone's nfc sensor, showing contact information" />
            </h1>
          </div>
          <div className='lg:mt-[-180px]'>
            <h1 className='relative inline-block font-custom font-black lg:text-[64px]/[86px] text-[30px]/[41px] lg:pl-[279px] mt-[117px] z-[-1]'>
              Scan QR Code
              <img className='max-w-[100%] h-auto ' src={scan_qr_code} alt="bcx qr card behind phone's camera, showing contact information" />
            </h1>
          </div>
        </div>
        <div className='lg:pr-[80px] pr-[30px] lg:mt-[199px] mt-[43px] flex justify-end'>
          <button className='lg:block hidden font-custom font-medium  text-button hover:bg-secondary bg-primary shadow-custom lg:text-[20px]/[32px] text-[16px]/[25px] text-center tracking-[0.05em] rounded-[33px] px-[91px] pt-[16px] pb-[18px]' onClick={() => { navigate('/shop'); }}>
            Go To Shop
          </button>
          <button className='lg:hidden block font-custom font-medium  text-button hover:bg-secondary bg-primary shadow-custom lg:text-[20px]/[32px] text-[16px]/[25px] text-center tracking-[0.05em] rounded-[33px] px-[91px] pt-[16px] pb-[18px]' onClick={() => { navigate('/shop'); }}>
            Buy Now
          </button>
        </div>
        <Footer />
      </div >
    </>
  );
}



