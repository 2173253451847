import { ChangeEvent, useEffect, useRef, useState } from "react";

import arrow from '../assets/images/arrow.svg';
import { Alert } from "@mui/material";
import { useOutsideAlerter } from "./SocialDropDown";
import React from "react";
interface CSelectProps
{
    onChange: CallableFunction & ((arg: any) => void),
    items: Array<string | { item: string, class?: string, active?: boolean, value?: number }>,
    label?: string,
    classname?: string,
    required?: boolean
    id?: string,
    hint?: string,
    disabled?: boolean,
    error?: string,
    errorclass?: string,
    value?: string,
    maxh?: string,

}
const CSelect = ({ ...props }: CSelectProps) =>
{
    const [open, setOpen] = useState(false);

    const select = useRef<HTMLDivElement>(null);
    useOutsideAlerter(select, () => { setOpen(false) });

    const handleOpen = () =>
    {
        setOpen(!open);
    };

    const handleKey = (event: any) =>
    {

        if (event.key === 'Enter')
        {
            handleOpen();
        }
    };

    const handleClick = (a: any) =>
    {
        if (typeof a === 'number')
        {
            props.onChange(a)
        } else
        {

            props.onChange(a.currentTarget.innerText);
        }
    }




    return <div ref={select}>
        <div className={props.classname + ' flex flex-row flex-grow'}>
            <div id={props.id} onClick={handleOpen} onKeyDown={handleKey} tabIndex={0} className={" flex-grow flex flex-col justify-start font-custom font-light tracking-[0.05em]  border-button border-[2px] " + (open ? ' border-b-transparent shadow-none rounded-t-[3px]' : ' rounded-[3px] shadow-custom  ')}>
                <input type="select" className="hidden" onChange={() => { }} value={props.value} />
                <div className="relative">
                    {props.label ?
                        <div className="absolute top-0 left-0 ml-[21px] mt-[-10px] px-[4px] text-[10px]/[19px] bg-white">
                            {(props.label) + (props.required ? ' *' : '')}
                        </div> : ''
                    }
                </div>
                <div className={((!props.value || props.value === '') ? 'block' : 'hidden') + " flex justify-between font-light w-full text-[20px]/[32px] px-[24px] pt-[11px] pb-[10px] select-none cursor-pointer"}>
                    {props.hint}
                    <img className={(open ? "rotate-180 duration-75" : 'rotate-0 duration-75') + ' w-[16px] h-[7px] self-center'} src={arrow} />
                </div>
                <div className={(!props.value || props.value === '' ? 'hidden' : 'block') + " flex justify-between font-light  text-[20px]/[32px] px-[24px] pt-[11px] pb-[10px] select-none cursor-pointer"}>
                    {props.value}
                    <img className={(open ? "rotate-180 duration-75" : 'rotate-0 duration-75') + ' w-[16px] h-[7px] self-center'} src={arrow} />
                </div>

                <div className="relative ">
                    <div className={(open ? 'block' : 'hidden') + " absolute z-[52] mt-[-2px] border-black border-b-[2px] w-full opacity-25 "} />
                    <div className={"absolute inset-[-1.6px] absolute-offset z-[51]  "}>
                        <div className={open ? ` ${props.maxh ? props.maxh : 'max-h-[200px]'} flex flex-col overflow-y-auto  border-t-0 border-button border-[2px] rounded-b-[3px]` : 'hidden '}>
                            {props.items.map((element, i) =>
                            {
                                if (typeof element === 'string')
                                {
                                    return <div key={i} className=" basis-1/5 z-[51] text-[16px]/[32px] bg-white">
                                        <div className=" border-black border-[1px] opacity-25 "></div>
                                        <div id={`${props.id ?? ''}_${i}`} className=" py-[4px] px-[24px] cursor-pointer hover:bg-slate-200" onClick={handleClick}>{element}</div>
                                    </div>
                                } else
                                {
                                    return <div key={i} className=" basis-1/5 z-[51] text-[16px]/[32px] bg-white w-full">
                                        <div className=" border-black border-[1px] opacity-25 "></div>
                                        <div id={`${props.id ?? ''}_${i}`} className={element.class + " py-[4px] px-[24px] cursor-pointer hover:bg-slate-200"} onClick={(a) => { handleClick(element.value ?? a) }}>{element.item}</div>
                                    </div>
                                }

                            })}</div>
                    </div>
                </div>
            </div>
        </div>
        <div className={(props.error ? 'block ' : 'hidden ') + props.errorclass ?? ' mt-[-28px]' + ' rounded-[6px] overflow-clip'}>
            <Alert style={{ fontFamily: 'Satoshi-Variable', fontWeight: '300', fontSize: '15px', lineHeight: '20px' }} severity="warning">{props.error}</Alert>
        </div>
    </div >
}
export default CSelect;
