import { useContext, useState } from 'react'
import Navbar from '../../components/Navbar'
import square_left from "../../assets/images/onboarding5-left-squares.svg";
import square_right from "../../assets/images/onboarding5-right-squares.svg";
import CTextField from '../../components/CTextField'
import { useNavigate } from 'react-router-dom';
import { OnboardingUser, UserContext } from '../../components/contexts/UserContext';
import { collection, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { auth1 } from '../../services/firebase';
import { db } from '../../services/firebase';
import { Phone, User } from '../../types';
import { cn } from '../../utils/utils';
import { CircularProgress } from '@mui/material';

export const Onboarding6 = () =>
{
    const navigate = useNavigate();
    const { user, setUser } = useContext(UserContext);
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('');

    async function validateCustomUrl()
    {
        let valid = true;
        let newError = '';
        if (!user.customUrl || user.customUrl === '')
        {
            newError = 'Please provide a URL'
            valid = false
        } else
        {
            const pattern = /[!@#$%^&*()\=\[\]{};':"\\|,.<>\/?]+|\s/g;
            if (pattern.test(user.customUrl))
            {
                newError = "The URL can't contain special characters or spaces";
                valid = false;
            }
            else if (user.customUrl.length >= 4)
            {
                const q = query(
                    collection(db, "users"),
                    where("customUrl", "==", user.customUrl.toLowerCase()),
                );
                const querySnapshot = await getDocs(q);
                let data = querySnapshot.docs[0]?.exists();
                if (data)
                {
                    newError = 'This URL is taken, try a new one'
                    valid = false;
                }
            } else
            {
                newError = 'This URL is a little too short'
                valid = false;
            }

        }
        return { urlValid: valid, urlErrors: newError };
    }

    async function handleSubmit()
    {

        let valid = true;
        let { urlValid, urlErrors } = await validateCustomUrl()!
        if (!urlValid)
        {
            valid = false
        }
        setError(urlErrors);

        if (valid)
        {
            setLoading(true)
            await uploadData()
            setLoading(false)
        }
    }

    async function uploadData()
    {
        const authUserRef = doc(db, 'users', auth1.currentUser?.uid!);
        await updateDoc(authUserRef, { ...user, phone: [{ number: user.phone![0].number, type: 'Work', ext: '' } as Phone], finishedCreating: true, customUrl: user.customUrl?.toLowerCase().replace("businesscardx.com/", "") || "", } as User).then((re) =>
        {
            navigate('/profile');
        })
    }

    return (
        <div className="">
            <Navbar classname="lg:hidden block mb-[24px]" hidecurr alternate />
            <div className="lg:relative flex lg:flex-row flex-col justify-between">
                <div className="lg:block hidden h-full justify-start z-50 pointer-events-none">
                    <img className=" max-h-[95%] h-auto" src={square_left} />
                </div>
                <div className="flex flex-col lg:absolute lg:left-[50%] lg:translate-x-[-50%]  pb-[66px]">
                    <Navbar classname="lg:block hidden self-start mb-[40px] " alternate />
                    <div className="self-center flex flex-col justify-center items-center lg:mx-0 mx-[25px]">
                        <h2 className="lg:w-[490px] w-[300px] font-custom font-medium self-center text-center lg:text-[57px]/[67px] text-[30px]/[normal] lg:mb-[39px] mb-[32px] ">
                            Nice Going! You’re Almost Done.
                        </h2>

                        <h4 className="w-[303px]  font-custom font-light self-center text-left text-[20px]/[32px]  tracking-[0.05em] lg:mb-[29px] mb-[41px]">
                            We Just Need One More Thing. What would you like your custom URL to be?
                        </h4>

                        <div className='self-center lg:mb-[257px] mb-[269px]'>
                            <div className="relative">
                                <CTextField
                                    error={error}
                                    errorclass="mt-[15px]"
                                    value={user.customUrl}
                                    disabled={loading}
                                    onChange={(a) => setUser({ ...user, customUrl: a })}
                                    label="custom URL"
                                    hint='custom-url'
                                    w="lg:w-[393px] w-[344px]"
                                />
                                <div className='absolute top-0 right-0 mt-[-25px] mr-[-10px] text-[25px] font-semibold text-primary'>
                                    *
                                </div>
                                <div className="absolute lg:w-[400px] w-[340px] top-[68px] mb-[5px]  text-center font-light font-custom tracking-[0.6px] text-[12px]/[19px] items-center justify-center flex">
                                    <div className='flex flex-row'>
                                        Preview: businesscardx.com/profile/
                                        <div className="font-medium">{!user.customUrl ? 'custom-url' : user.customUrl}</div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className='flex flex-col  items-center self-center gap-[21px]'>
                            <div className='flex flex-row lg:gap-[38px] gap-[18px] self-center items-center lg:ml-[30px] ml-[8px]'>

                                <button
                                    disabled={loading}
                                    className={cn('w-[98px] font-custom font-medium text-white shadow-custom text-[20px]/[27px] text-center tracking-[0.05em] bg-button hover:bg-inset rounded-[26px]  lg:pt-[10px] pt-[12px] lg:pb-[14px] pb-[12px]',
                                        { 'opacity-70 hover:bg-button': loading })}
                                    onClick={() =>
                                    {
                                        navigate('/onboarding-5')
                                    }}>
                                    Back
                                </button>


                                <button
                                    disabled={loading}
                                    className={cn('relative w-[251px] self-center font-custom font-medium text-white shadow-custom text-[20px]/[27px] text-center tracking-[0.05em] bg-button hover:bg-inset rounded-[26px] lg:pt-[11px] pt-[11px] lg:pb-[13px] pb-[13px]',
                                        { 'opacity-70 hover:bg-button': loading })}
                                    onClick={async () =>
                                    {
                                        handleSubmit()
                                    }}>
                                    <div className='flex flex-row justify-center items-center'>
                                        Create Profile
                                        <div className={cn('absolute right-0 mr-[20px] mt-[5px]', { 'hidden': !loading })}>
                                            <CircularProgress color='inherit' size={20} />
                                        </div>
                                    </div>
                                </button>
                            </div>
                            <div className='flex flex-row gap-[10px] self-center '>
                                <div className='w-[9px] h-[9px] bg-slate-400 rounded-full' />
                                <div className='w-[9px] h-[9px] bg-slate-400 rounded-full' />
                                <div className='w-[9px] h-[9px] bg-slate-400 rounded-full' />
                                <div className='w-[9px] h-[9px] bg-slate-400 rounded-full' />
                                <div className='w-[9px] h-[9px] bg-slate-400 rounded-full' />
                                <div className='w-[9px] h-[9px] bg-primary rounded-full' />
                            </div>
                        </div>


                    </div>
                </div>
                <div className=" lg:block hidden h-full justify-end z-50 pointer-events-none">
                    <img className=" max-h-[100%] h-auto" src={square_right} />
                </div>

            </div>

        </div>
    )
}
