import { Dictionary } from "./types";


export enum Currency
{
    USD = 'USD',
    CAD = 'CAD'
}

export const curr: Dictionary<Dictionary<number>> = {
    blackReplacement: { USD: 45, CAD: 40 },
    eliteReplacement: { USD: 45, CAD: 40 },
    eliteBase: { USD: 275, CAD: 270 },
    eliteQuantityDiscount: { USD: 25, CAD: 20 },
    eliteExtra: { USD: 45, CAD: 40 },
    elitePersonalized: { USD: 15, CAD: 10 },
    eliteQuantityBase: { USD: 165, CAD: 160 },
    eliteBaseUnit: { USD: 80, CAD: 75 },
    blackBase: { USD: 80, CAD: 75 },
    blackExtra: { USD: 45, CAD: 40 },
    blackLogo: { USD: 70, CAD: 65 },
    silver: { USD: 10, CAD: 5 },
    gold: { USD: 10, CAD: 5 },
    white: { USD: 0, CAD: 0 },
}

