import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Purchase, PurchaseType } from '../redux/feature/cartSlice';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Draggable from 'react-draggable';
import { Alert } from '@mui/material';
interface CartProps
{
    classname?: string,
    total?: number,
    onClick?: CallableFunction,
}
function getCardUrl(type: PurchaseType)
{
    switch (type)
    {
        case PurchaseType.Black:
            return '/BlackCard'
        case PurchaseType.Elite:
            return '/EliteCard'
        case PurchaseType.ReplacementBlack:
            return '/replacement-blackcard'
        case PurchaseType.ReplacementElite:
            return '/replacement-elitecard'
        case PurchaseType.NewMember:
            return '/new-member'
        default:
            return '';
    }
}
export const Cart = ({ ...props }: CartProps) =>
{
    const [toggle, setToggle] = useState(false);
    const [error, setError] = useState(false);
    const cart: Purchase = useSelector((state: any) => state.cart);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isDragging, setIsDragging] = useState<any>(false);
    const location = useLocation()


    useEffect(() =>
    {
        if (cart.subtotal > 0)
        {
            setToggle(true);
        }
        else
        {
            setToggle(false);
        }
        if (window.innerWidth < 1024)
        {
            if (!cart.order.valid && cart.subtotal > 0 && cart.order.valid !== undefined)
            {
                setError(true);
            }
        } else
        {
            if (!cart.order.valid && cart.subtotal > 0 && cart.order.valid !== undefined && location.pathname.toLowerCase() !== '/blackcard' && location.pathname.toLowerCase() !== '/elitecard')
            {
                setError(true);
            }
        }
    },
        [dispatch, cart])
    function handleClick()
    {
        if (props.onClick)
        {
            props.onClick();
        } else
        {

            if (cart.subtotal && cart.subtotal > 0)
            {
                if (cart.order.valid)
                {

                    if (cart.order.type === PurchaseType.Black || cart.order.type === PurchaseType.Elite)
                    { navigate('/cart'); }
                    else
                    {
                        navigate('/replacement-cart')
                    }
                } else
                {
                    navigate(getCardUrl(cart.order.type))
                }
            }
            else
            {
                navigate('/empty-cart')
            }
        }
    }


    return <div onClick={handleClick} className='fixed w-full h-[100vh] z-[100] overflow-hidden lg:px-[80px] px-[21px] lg:pt-[136px] pt-[60px]  pointer-events-none '>
        <div className={props.classname ?? '' + ' pointer-events-auto cursor-pointer'}>
            <div className='flex flex-col w-[204px]'>
                <div style={{ cursor: 'pointer' }} className=" cursor-pointer group z-50 fixed lg:right-[80px] right-[25px] flex flex-row shadow-custom rounded-full ">
                    <div className={(toggle ? 'flex' : 'hidden') + ' cursor-pointer items-center justify-center rounded-l-full lg:pl-[27px] pl-[20px] lg:pr-[59px] lg:mr-[-36px] pr-[43px] mr-[-32px]  bg-primary group-hover:bg-secondary'}>
                        <div className='font-custom text-button lg:text-[20px]/[32px] text-[16px]/[32px] text-center tracking-[0.05em]'>
                            {cart.subtotal ? '$' + cart.subtotal.toFixed(2) : ''}
                        </div>
                    </div>
                    <button className=" cursor-pointer bg-button group-hover:bg-white text-white group-hover:text-button lg:p-[23px] p-[13px] rounded-full  ">
                        <svg className='' width="26" height="26" viewBox="0 0 34 34" fill="none" stroke='currentColor' xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.83334 2.8335H5.29834C6.82834 2.8335 8.0325 4.151 7.905 5.66683L6.72917 19.7768C6.68326 20.3235 6.75139 20.8737 6.92925 21.3926C7.10711 21.9115 7.39082 22.3879 7.7624 22.7914C8.13398 23.1949 8.58533 23.5169 9.08785 23.7369C9.59037 23.9569 10.1331 24.0701 10.6817 24.0693H25.7692C27.8092 24.0693 29.5942 22.3977 29.75 20.3718L30.515 9.74683C30.685 7.39516 28.9 5.48266 26.5342 5.48266H8.245M12.75 11.3335H29.75M23.0208 31.1668C23.4905 31.1668 23.9409 30.9803 24.273 30.6482C24.6051 30.3161 24.7917 29.8657 24.7917 29.396C24.7917 28.9263 24.6051 28.4759 24.273 28.1438C23.9409 27.8117 23.4905 27.6252 23.0208 27.6252C22.5512 27.6252 22.1008 27.8117 21.7687 28.1438C21.4366 28.4759 21.25 28.9263 21.25 29.396C21.25 29.8657 21.4366 30.3161 21.7687 30.6482C22.1008 30.9803 22.5512 31.1668 23.0208 31.1668ZM11.6875 31.1668C12.1572 31.1668 12.6076 30.9803 12.9397 30.6482C13.2718 30.3161 13.4583 29.8657 13.4583 29.396C13.4583 28.9263 13.2718 28.4759 12.9397 28.1438C12.6076 27.8117 12.1572 27.6252 11.6875 27.6252C11.2178 27.6252 10.7674 27.8117 10.4353 28.1438C10.1032 28.4759 9.91667 28.9263 9.91667 29.396C9.91667 29.8657 10.1032 30.3161 10.4353 30.6482C10.7674 30.9803 11.2178 31.1668 11.6875 31.1668Z" stroke="currentColor" strokeWidth="2.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button>
                </div>
                <div className={(error ? 'block ' : 'hidden ') + '  fixed lg:right-[75px] right-0 lg:pt-[80px] pt-[60px] w-[200px] rounded-[6px] overflow-clip'}>
                    <Alert style={{ fontFamily: 'Satoshi-Variable', fontWeight: '300', fontSize: '15px', lineHeight: '20px' }} severity="warning">{"Missing information"}</Alert>
                </div>
            </div>
        </div>
    </div>
}
