import React, { useEffect, useState } from "react";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import CTextField from "../../components/CTextField";
import CCheckBox from "../../components/CCheckBox";

import mock_elite from "../../assets/images/elite-mock.svg";
import mock_elite_gold from "../../assets/images/elite-mock-gold.svg";
import mock_elite_silver from "../../assets/images/elite-mock-silver.svg";

import mock_black from "../../assets/images/black-mock.svg";
import mock_black_gold from "../../assets/images/black-mock-gold.svg";
import mock_black_silver from "../../assets/images/black-mock-silver.svg";

import plus from "../../assets/images/plus-button.svg";
import minus from "../../assets/images/minus-button.svg";
import arrow_button from "../../assets/images/arrow-button.svg";
import shipping from "../../assets/images/canada-post.svg";

import your_logo_here from "../../assets/images/your-logo-here.svg";
import your_logo_here_gold from "../../assets/images/your-logo-here-gold.svg";
import your_logo_here_silver from "../../assets/images/your-logo-here-silver.svg";

import paypal from "../../assets/images/paypal.svg";
import security from "../../assets/images/payment-security.svg";
import credit_Cards from "../../assets/images/credit-cards.svg";
import CAccordion from "../../components/CAccordion";
import CRadio from "../../components/CRadioButton";
import { Alert, CircularProgress, RadioGroup } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import validator from "validator";
import
{
  Billing,
  Details,
  Elite,
  PCard,
  Payment,
  Purchase,
  PurchaseType,
  Shipping,
  getItemized,
  getSubTotal,
  getTax,
  getTotal,
  setDetails,
  setDiscount,
  setOrder,
} from "../../redux/feature/cartSlice";
import { CPhoneInput } from "../../components/PhoneInput";
import { Currency } from "../../currencies";
import { ColorLabels, Discount } from "../../types";
import { colorToLabel } from "../../utils/features";
import { Discount as DiscountComp } from "../../components/Discount";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../services/firebase";

declare global
{
  interface Window
  {
    pca: any;
    Rewardful: any;
    rewardful: any;
  }
}
function loadStyles(callback?: CallableFunction)
{
  const exists = document.getElementById("stylesheetCAPostID");
  if (exists)
  {
    if (callback)
    { callback(); }
    return;
  }
  const link = document.createElement("link");

  link.id = "stylesheetCAPostID";
  link.rel = "stylesheet";
  link.type = "text/css";
  link.href =
    "https://ws1.postescanada-canadapost.ca/css/addresscomplete-2.30.min.css?key=NK91-HC83-TM34-FJ15";
  document.head.appendChild(link);
  link.onload = () =>
  {
    if (callback)
    { callback(); }
  };
};

function loadScript(callback?: CallableFunction)
{
  const exists = document.getElementById("scriptCAPostID");
  if (exists)
  {
    if (callback)
    { callback(); }
    return;
  }
  const script = document.createElement("script");
  script.async = true;
  script.id = "scriptCAPostID";
  script.src =
    `https://ws1.postescanada-canadapost.ca/js/addresscomplete-2.30.min.js?key=${process.env.REACT_APP_ADDRESS_COMPLETE!}`;
  document.head.appendChild(script);
  script.onload = () =>
  {
    if (callback)
    { callback(); }
  };
};

export function getOrderType(type: PurchaseType)
{
  switch (type)
  {
    case PurchaseType.Black:
      return "BLACK Card"
    case PurchaseType.Elite:
      return "ELITE Card"
    case PurchaseType.ReplacementBlack:
      return "BLACK Card Replacement"
    case PurchaseType.ReplacementElite:
      return "ELITE Card Replacement"
    case PurchaseType.NewMember:
      return "ELITE Card New Member"
    default:
      return '';
  }
}

interface CartProps
{ }

export const Cart = ({ ...props }: CartProps) =>
{
  const navigate = useNavigate();
  const [sameAddress, setSameAddress] = useState(false);
  const [payment, setPayment] = useState("CreditCard");
  const [consent, setConsent] = useState(false);
  const [consentError, setConsentError] = useState("");
  const currency: Currency = useSelector((state: any) => state.currency);
  const cart: Purchase = useSelector((state: any) => state.cart);
  const orderRX = useSelector((state: any) => state.cart.order);
  const detailsRx = useSelector((state: any) => state.cart.details);
  const discountRx = useSelector((state: any) => state.cart.discount);
  const [order, setLocalOrder] = useState({ ...orderRX });
  const minimum = (order as Elite).type === PurchaseType.NewMember ? 1 : 2;
  const [details, setLocalDetails] = useState<Details>({ ...detailsRx });
  const [discount, setLocalDiscount] = useState<Discount>({ ...discountRx });
  const [discountError, setDiscountError] = useState('')
  const [discountLoad, setDiscountLoad] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({
    pcards: Array(order.personalized).fill({
      fullName: "",
    } as PCard),
  });
  const [detailErrors, setDetailErrors] = useState({
    billingDetails: {} as Billing,
    paymentDetails: {} as Payment,
    shippingDetails: {} as Shipping,
  } as Details);
  const dispatch = useDispatch();
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [stylesLoaded, setStylesLoaded] = useState(false);
  const [addressControl, setAddresControl] = useState(null);
  const [localBillingDetails, setLocalBilling] = useState({ country: cart.details.billingDetails.country, stateProvince: cart.details.billingDetails.stateProvince, city: cart.details.billingDetails.city, zip: cart.details.billingDetails.zip, street1: cart.details.billingDetails.street1, street2: cart.details.billingDetails.street2 });
  const [localShippingDetails, setLocalShipping] = useState({ country: cart.details.shippingDetails.country, stateProvince: cart.details.shippingDetails.stateProvince, city: cart.details.shippingDetails.city, zip: cart.details.shippingDetails.zip, street1: cart.details.shippingDetails.street1, street2: cart.details.shippingDetails.street2 });
  const [localBillingCountry, setLocalBillingCountry] = useState({ country: cart.details.billingDetails.country });
  const [localShippingCountry, setLocalShippingCountry] = useState({ country: cart.details.shippingDetails.country });



  useEffect(() =>
  {
    window.scrollTo(0, 0);
    loadScript(() =>
    {
      setScriptLoaded(true);
    });
    loadStyles(() =>
    {
      setStylesLoaded(true);
    });

  }, []);

  useEffect(() =>
  {
    if (scriptLoaded && stylesLoaded && !addressControl)
    {
      const options = {
        key: process.env.REACT_APP_ADDRESS_COMPLETE!
      };
      let mobile = window.innerWidth < 1024;
      const billingFields = [
        {
          element: mobile ? "billing-address-1-mobile" : "billing-address-1",
          field: "Line1",
          mode: window.pca.fieldMode.SEARCH
        },
        {
          element: mobile ? "billing-address-2-mobile" : "billing-address-2",
          field: "Line2",
          mode: window.pca.fieldMode.POPULATE
        },
        {
          element: mobile ? "billing-city-mobile" : "billing-city",
          field: "City",
          mode: window.pca.fieldMode.POPULATE
        },
        {
          element: mobile ? "billing-zip-mobile" : "billing-zip",
          field: "PostalCode",
          mode: window.pca.fieldMode.POPULATE
        },
        {
          element: mobile ? "billing-country-mobile" : "billing-country",
          field: "CountryName",
          mode: window.pca.fieldMode.COUNTRY
        },
        {
          element: mobile ? "billing-state-mobile" : "billing-state",
          field: "ProvinceName",
          mode: window.pca.fieldMode.POPULATE
        },

      ];
      const shippingFields = [
        {
          element: mobile ? "shipping-address-1-mobile" : "shipping-address-1",
          field: "Line1",
          mode: window.pca.fieldMode.SEARCH
        },
        {
          element: mobile ? "shipping-address-2-mobile" : "shipping-address-2",
          field: "Line2",
          mode: window.pca.fieldMode.POPULATE
        },
        {
          element: mobile ? "shipping-city-mobile" : "shipping-city",
          field: "City",
          mode: window.pca.fieldMode.POPULATE
        },
        {
          element: mobile ? "shipping-zip-mobile" : "shipping-zip",
          field: "PostalCode",
          mode: window.pca.fieldMode.POPULATE
        },
        {
          element: mobile ? "shipping-country-mobile" : "shipping-country",
          field: "CountryName",
          mode: window.pca.fieldMode.COUNTRY
        },
        {
          element: mobile ? "shipping-state-mobile" : "shipping-state",
          field: "ProvinceName",
          mode: window.pca.fieldMode.POPULATE
        },

      ];
      const billingControl = new window.pca.Address(billingFields, options);
      billingControl.listen("country", function (country: any)
      {
        if (country)
        {
          setLocalBillingCountry({
            country: country.name,
          });
        }
      });
      billingControl.listen("populate", function (address: any)
      {
        if (address)
        {
          setLocalBilling({
            street1: address.Line1,
            street2: address.Line2,
            city: address.City,
            zip: address.PostalCode,
            stateProvince: address.ProvinceName,
            country: address.CountryName,
          });
        }
      });

      const shippingControl = new window.pca.Address(shippingFields, options);
      shippingControl.listen("country", function (country: any)
      {
        if (country)
        {
          setLocalShippingCountry({
            ...localShippingDetails,
            country: country.name,
          });
        }
      });
      shippingControl.listen("populate", function (address: any)
      {
        if (address)
        {
          setLocalShipping({
            street1: address.Line1,
            street2: address.Line2,
            city: address.City,
            zip: address.PostalCode,
            stateProvince: address.ProvinceName,
            country: address.CountryName,
          });
        }
      });

      setAddresControl(billingControl);
    }
  }, [addressControl, scriptLoaded, stylesLoaded])

  useEffect(() =>
  {
    setLocalBilling({ ...localBillingDetails, country: localBillingCountry.country });
  }, [localBillingCountry])

  useEffect(() =>
  {
    setLocalShipping({ ...localShippingDetails, country: localShippingCountry.country });
  }, [localShippingCountry])

  useEffect(() =>
  {
    setLocalDetails({ ...details, billingDetails: { ...details.billingDetails, ...localBillingDetails }, shippingDetails: { ...details.shippingDetails, ...localShippingDetails } })
  }, [localBillingDetails, localShippingDetails])

  useEffect(() =>
  {
    if (
      order.type !== PurchaseType.Black &&
      order.type !== PurchaseType.Elite &&
      order.type !== PurchaseType.ReplacementBlack &&
      order.type !== PurchaseType.ReplacementElite &&
      order.type !== PurchaseType.NewMember
    )
    {
      navigate(-1);
    } else
    {
      setLoading(false);
    }

    dispatch(setOrder(order));
    dispatch(setDetails(details));
    dispatch(getSubTotal(currency));
    dispatch(setDiscount(discount))
    dispatch(getTax());
    dispatch(getTotal());
    dispatch(getItemized(currency));
  }, [dispatch, order, details, currency, discount]);

  useEffect(() =>
  {
    if (sameAddress)
    {
      setLocalDetails({ ...details, shippingDetails: details.billingDetails })
    }
  }, [details, sameAddress])

  useEffect(() =>
  {
    if (order.personalized + order.nonPersonalized < order.base)
    {
      setLocalOrder({ ...order, nonPersonalized: order.nonPersonalized + 1 });
    } else if (order.personalized + order.nonPersonalized > order.base)
    {
      if (order.personalized > order.nonPersonalized)
      {
        setLocalOrder({
          ...order,
          personalized: order.personalized - 1,
          pCard: order.pCard.filter(
            (e: PCard, i: number) => i !== order.pCard.length - 1
          ),
        });
      } else
      {
        setLocalOrder({ ...order, nonPersonalized: order.nonPersonalized - 1 });
      }
    }
  }, [order.base]);



  function handleSubmit()
  {
    let valid = true;
    if (cart.order.type === PurchaseType.Black)
    {
      valid = validateBlack();
    } else if (cart.order.type === PurchaseType.Elite)
    {
      valid = validateElite();
    }
    else if (cart.order.type === PurchaseType.ReplacementBlack)
    {
      valid = validateBlack();
    }
    //ReplacementElite and NewMember
    else 
    {
      valid = validateElite();
    }
    if (valid)
    {
      navigate("/checkout", {
        state: { cart, order, sameAddress, details },
      }
      );
    }
  }
  function validateBlack()
  {
    let valid = true;
    let newError = "";
    if (!consent)
    {
      valid = false;
      newError = "Agree to proceed with the purchase";
    }
    if (!validateFields())
    {
      valid = false;
    }
    setConsentError(newError);
    return valid;
  }
  function validateFields()
  {
    let valid = true;
    let newError = {
      billingDetails: {} as Billing,
      paymentDetails: {} as Payment,
      shippingDetails: {} as Shipping,
    } as Details;
    if (!details.billingDetails.firstName)
    {
      newError.billingDetails.firstName = "Please provide your first name";
      valid = false;
    }
    if (!details.billingDetails.lastName)
    {
      newError.billingDetails.lastName = "Please provide your last name";
      valid = false;
    }
    if (
      !details.billingDetails.email ||
      !validator.isEmail(details.billingDetails.email)
    )
    {
      newError.billingDetails.email = "Please provide your email";
      valid = false;
    }
    if (!details.billingDetails.street1)
    {
      newError.billingDetails.street1 = "Please provide your address";
      valid = false;
    }

    if (!details.billingDetails.zip || !details.billingDetails.stateProvince || (![
      "Alberta",
      "British Columbia",
      "Manitoba",
      "New Brunswick",
      "Newfoundland and Labrador",
      "Northwest Territories",
      "Nova Scotia",
      "Nunavut",
      "Ontario",
      "Prince Edward Island",
      "Québec",
      "Saskatchewan",
      "Yukon",
    ].includes(details.billingDetails.stateProvince)) && details.billingDetails.country === 'Canada')
    {
      newError.billingDetails.stateProvince = "Please provide a valid state/province";
      valid = false;
    }

    if (!details.billingDetails.country)
    {
      newError.billingDetails.country = "Please provide your country";
      valid = false;
    }
    if (!details.billingDetails.phone || !isPossiblePhoneNumber(details.billingDetails.phone)
    )
    {
      newError.billingDetails.phone = "Please provide a valid phone number";
      valid = false;
    }
    if (
      !details.billingDetails.phone
    )
    {
      newError.billingDetails.phone = "Please provide your phone number";
      valid = false;
    }

    if (!details.billingDetails.city)
    {
      newError.billingDetails.city = "Please provide your city's name";
      valid = false;
    }
    if (!details.billingDetails.zip ||
      (details.billingDetails.country === 'Canada' && !validator.isPostalCode(details.billingDetails.zip, "CA") && sameAddress) ||
      (details.billingDetails.country === 'Canada' && !validator.isPostalCode(details.billingDetails.zip, "CA"))
    )
    {
      newError.billingDetails.zip = "Please provide a valid postal code";
      valid = false;
    }

    if (!sameAddress)
    {
      if (!details.shippingDetails.firstName)
      {
        newError.shippingDetails.firstName = "Please provide your first name";
        valid = false;
      }
      if (!details.shippingDetails.lastName)
      {
        newError.shippingDetails.lastName = "Please provide your last name";
        valid = false;
      }
      if (
        !details.shippingDetails.email ||
        !validator.isEmail(details.shippingDetails.email)
      )
      {
        newError.shippingDetails.email = "Please provide your email";
        valid = false;
      }
      if (!details.shippingDetails.street1)
      {
        newError.shippingDetails.street1 = "Please provide your address";
        valid = false;
      }
      if (!details.shippingDetails.stateProvince || (![
        "Alberta",
        "British Columbia",
        "Manitoba",
        "New Brunswick",
        "Newfoundland and Labrador",
        "Northwest Territories",
        "Nova Scotia",
        "Nunavut",
        "Ontario",
        "Prince Edward Island",
        "Québec",
        "Saskatchewan",
        "Yukon",
      ].includes(details.shippingDetails.stateProvince)) && details.shippingDetails.country === 'Canada')
      {
        newError.shippingDetails.stateProvince = "Please provide a valid state/province";
        valid = false;
      }

      if (!details.shippingDetails.zip ||
        (!validator.isPostalCode(details.shippingDetails.zip, "CA") && details.shippingDetails.country === 'Canada')
      )
      {
        newError.shippingDetails.zip = "Please provide a valid postal code";
        valid = false;
      }
      if (!details.shippingDetails.country)
      {
        newError.shippingDetails.country = "Please provide your country";
        valid = false;
      }
      if (!details.shippingDetails.phone || !isPossiblePhoneNumber(details.shippingDetails.phone)
      )
      {
        newError.shippingDetails.phone = "Please provide a valid phone number";
        valid = false;
      }
      if (!details.shippingDetails.phone)
      {
        newError.shippingDetails.phone = "Please provide your phone number";
        valid = false;
      }
      if (!details.shippingDetails.city)
      {
        newError.shippingDetails.city = "Please provide your city's name";
        valid = false;
      }
    }
    /*
        if (!details.paymentDetails.creditCard || !validator.isCreditCard(details.paymentDetails.creditCard))
        {
          newError.paymentDetails.creditCard = "Please provide a valid credit card number"
          valid = true
        }
        if (!details.paymentDetails.expirationDate || !validator.isDate('01/' + details.paymentDetails.expirationDate, { format: "DD/MM/YY" }))
        {
          newError.paymentDetails.expirationDate = "Please provide a valid expiration date"
          valid = false
        }
        if (!details.paymentDetails.securityCode || details.paymentDetails.securityCode.length < 3)
        {
          newError.paymentDetails.securityCode = "Please provide a valid security code"
          valid = false
        }
    */
    setDetailErrors(newError);
    return valid;
  }
  function validateElite()
  {
    const newError = {
      pcards: Array(order.personalized).fill({
        fullName: "",
      } as PCard),
    };
    let valid = true;
    order.pCard.forEach((e: PCard, i: number) =>
    {
      if (!e.fullName.trim())
      {
        newError.pcards = [
          ...newError.pcards.slice(0, i),
          {
            ...newError.pcards[i],
            fullName: "Please provide a Name for the card",
          } as PCard,
          ...newError.pcards.slice(i + 1),
        ];
        valid = false;
      }
    });
    let newConsentError = "";
    if (!consent)
    {
      valid = false;
      newConsentError = "Agree to proceed with the purchase";
    }
    if (!validateFields())
    {
      valid = false;
    }
    setErrors(newError);
    setConsentError(newConsentError);
    return valid;
  }

  async function checkDiscount(code: string)
  {
    let error = '';
    if (code && code.length > 0)
    {//MUST BE UPPERCASE
      setDiscountLoad(true)
      const q = query(collection(db, "discountCodes"), where('code', '==', code.toUpperCase()))
      const doc = (await getDocs(q)).docs[0];

      if (doc)
      {
        let data = doc.data() as Discount;
        if (data.active)
        {
          setLocalDiscount({ ...data, percentDiscount: data.percentDiscount / 100 } as Discount)
        } else
        {
          error = 'Sorry, the discount code is currently inactive.';
        }
      } else
      {
        error = 'Invalid discount code. Please try again.';
      }
    }
    else
    {
      error = 'Please provide a discount code';
    }
    setDiscountError(error)
    setDiscountLoad(false)
  }

  if (loading)
  {
    return (
      <div className="overflow-hidden flex items-center justify-center h-screen relative w-[100vw] ">
        <div className=" relative">
          <CircularProgress color="inherit" />
        </div>
      </div>
    );
  }
  return (<>
    <div className="overflow-hidden">
      <Navbar />
      <div className="lg:block hidden">
        <div className=" mt-[110px] mb-[61px] px-[81px]">
          <h1 className=" inline-block font-custom font-black lg:text-[64px]/[86px] text-[30px]/[41px] lg:mb-[34px]">
            Cart
          </h1>
          <div className="bg-primary relative lg:w-[150%] w-[125%] h-[37px] z-[-1] lg:mt-[-70px] mt-[-18px] lg:mb-[37px] mb-[28px]" />
        </div>

        <div className="flex lg:flex-row justify-start gap-[58px] px-[80px]">

          <div className="flex flex-col w-[370px]">
            {order.type === PurchaseType.Black ? (
              <>
                <div className="relative w-full  flex items-start justify-start flex-shrink-0 mb-[35px] ">
                  <div className="absolute z-50 top-0 left-0 flex flex-col gap-[5px]  ml-[23px] mt-[20px] ">
                    <div className={"flex flex-row w-full items-start  "}>
                      <div className={(colorToLabel(order.color, currency) === ColorLabels.Silver ? 'silver-gradient' : '') + (colorToLabel(order.color, currency) === ColorLabels.Gold ? 'gold-gradient' : '') + " h-fit lg:max-w-[258px] max-w-[220px] overflow-hidden lg:text-[36px]/[42px] text-[30px]/[30px] custom-div font-custom font-medium  break-words text-white "}>
                        {order.blank ? "" : (order.fullName || order.company) ? order.fullName : "John Doe"}
                      </div>
                    </div>
                    <div className={" flex flex-row w-full  items-start"}>
                      <div className={(colorToLabel(order.color, currency) === ColorLabels.Silver ? 'silver-gradient' : '') + (colorToLabel(order.color, currency) === ColorLabels.Gold ? 'gold-gradient' : '') + " h-fit lg:max-w-[238px] max-w-[205px] overflow-hidden custom-div font-custom font-normal break-words text-white lg:text-[20px]/[30px] text-[16px]/[30px] "}>
                        {order.blank ? "" : (order.fullName || order.company) ? order.company : "LogoPark"}
                      </div>
                    </div>

                  </div>
                  <img className="xl:max-w-[100%] lg:max-w-[75%] h-auto" src={(colorToLabel(order.color, currency) === ColorLabels.Silver ? mock_black_silver : (colorToLabel(order.color, currency) === ColorLabels.Gold ? mock_black_gold : mock_black))} />
                  <img
                    className={
                      (order.logoAdd ? (order.blank ? "hidden" : "block") : 'hidden') + " absolute top-[125px] lg:right-[30px] w-[60px]"
                    }
                    src={(colorToLabel(order.color, currency) === ColorLabels.Silver ? your_logo_here_silver : (colorToLabel(order.color, currency) === ColorLabels.Gold ? your_logo_here_gold : your_logo_here))}
                  />

                </div>
                <h3 className="font-custom font-medium text-text text-[39px]/[53px] mb-[5px] m-0">
                  bcX BLACK
                </h3>
                <div className="flex mb-[7px]">
                  <p className="font-custom font-normal text-[36px]/[49px]">
                    ${cart.subtotal}
                  </p>
                  <p className="lg:hidden font-custom font-light text-[18px]/[25px] flex self-start pt-[5px] pl-[6px]">
                    {" "}
                    {currency}
                  </p>
                  <p className="hidden font-custom font-light text-[18px]/[25px] lg:flex self-start pt-[5px] pl-[6px]">
                    {" "}
                    {currency}
                  </p>
                </div>
                <h3 className="font-custom font-medium tracking-[0.05em] text-text text-[20px]/[32px] mb-[8px] ">
                  Add-Ons
                </h3>
                <div className="flex flex-col ml-[10px] mb-[33px]">
                  <div className="flex flex-row gap-[15px]">
                    <button
                      onClick={() =>
                      {
                        setLocalOrder({ ...order, logoAdd: false });
                      }}
                      className={order.logoAdd ? "" : "opacity-50"}
                    >
                      <img src={minus} />
                    </button>
                    <button
                      onClick={() =>
                      {
                        setLocalOrder({ ...order, logoAdd: true });
                      }}
                      className={order.logoAdd ? "opacity-50" : ""}
                    >
                      <img src={plus} />
                    </button>
                    <div className="font-custom text-text  font-light text-[20px]/[35px] tracking-[0.05em]">
                      Logo +$65
                    </div>
                  </div>
                  <div className="flex flex-row gap-[15px] mb-[34px]">
                    <button
                      onClick={() =>
                      {
                        if (cart.order.extra - 1 >= 0)
                        {
                          setLocalOrder({ ...order, extra: order.extra - 1 });
                        }
                      }}
                      className={cart.order.extra - 1 < 0 ? "opacity-50" : ""}
                    >
                      <img src={minus} />
                    </button>
                    <button
                      onClick={() =>
                      {
                        setLocalOrder({ ...order, extra: order.extra + 1 });
                      }}
                      className=""
                    >
                      <img src={plus} />
                    </button>
                    <div className="font-custom text-text  font-light text-[20px]/[35px] tracking-[0.05em]">
                      {order.extra} Extra Card +$40
                    </div>
                  </div>
                  <div className="font-custom text-text  font-light text-[20px]/[35px] tracking-[0.05em] mb-[10px]">
                    {!order.blank ? "Name: " + order.fullName : ""}
                  </div>
                  <div className="font-custom text-text  font-light text-[20px]/[35px] tracking-[0.05em]">
                    {!order.blank ? "Company: " + order.company : ""}
                  </div>
                </div>
              </>) : <></>}
            {order.type === PurchaseType.Elite ? (
              <>
                <div className="relative flex items-start justify-start flex-shrink-0  lg:mb-[35px] mb-[31px]">
                  <img
                    className={
                      (order.blank ? "hidden" : "block") +
                      " absolute top-[64px] left-[72px] w-[90px] h-auto"
                    }
                    src={(colorToLabel(order.color, currency) === ColorLabels.Silver ? your_logo_here_silver : (colorToLabel(order.color, currency) === ColorLabels.Gold ? your_logo_here_gold : your_logo_here))}
                  />
                  <img
                    className="xl:max-w-[100%] lg:max-w-[75%] h-auto"
                    src={(colorToLabel(order.color, currency) === ColorLabels.Silver ? mock_elite_silver : (colorToLabel(order.color, currency) === ColorLabels.Gold ? mock_elite_gold : mock_elite))}
                  />
                </div>
                <h3 className="font-custom font-medium text-text text-[39px]/[53px] mb-[5px] m-0">
                  bcX ELITE
                </h3>
                <div className="flex mb-[20px] ">
                  <p className="font-custom font-normal text-[36px]/[49px]">
                    ${cart.subtotal}
                  </p>
                  <p className="lg:hidden font-custom font-light text-[18px]/[25px] flex self-start pt-[5px] pl-[6px]">
                    {currency}
                  </p>
                  <p className="hidden font-custom font-light text-[18px]/[25px] lg:flex self-start pt-[5px] pl-[6px]">
                    {currency}
                  </p>
                </div>

                <div className="flex flex-row gap-[15px] ml-[10px]">
                  <button
                    onClick={() =>
                    {
                      if (order.base - 1 >= minimum)
                      {
                        setLocalOrder({ ...order, base: order.base - 1 });
                      }
                    }}
                    className={order.base - 1 < minimum ? "opacity-50" : ""}
                  >
                    <img src={minus} />
                  </button>
                  <button
                    onClick={() =>
                    {
                      setLocalOrder({ ...order, base: order.base + 1 });
                    }}
                    className=""
                  >
                    <img src={plus} />
                  </button>
                  <div className="font-custom text-text  font-light text-[20px]/[35px] tracking-[0.05em]">
                    {order.base} Cards
                  </div>
                </div>
                <div className="mb-[10px]">
                  <h3 className="font-custom font-medium tracking-[0.05em] text-text text-[20px]/[32px] mb-[8px] ">
                    Distribution of Cards
                  </h3>
                  <div className="flex flex-col gap-[8px] ml-[10px]">
                    <div className="flex flex-row items-center gap-[16px] font-custom text-text  font-light text-[20px]/[35px] tracking-[0.05em]">
                      <button
                        onClick={() =>
                        {
                          if (order.nonPersonalized - 1 >= 0)
                          {
                            setErrors({
                              ...errors,
                              pcards: [
                                ...errors.pcards,
                                {
                                  fullName: "",
                                } as PCard,
                              ],
                            });
                            setLocalOrder({
                              ...order,
                              personalized: order.personalized + 1,
                              nonPersonalized: order.nonPersonalized - 1,
                              pCard: [
                                ...order.pCard,
                                {
                                  fullName: "",
                                  orderNumber: "",
                                  qr: "",
                                  specialInstructions: "",
                                },
                              ],
                            });
                          }
                        }}
                        className={
                          order.nonPersonalized - 1 < 0 ? "opacity-50" : ""
                        }
                      >
                        <img src={arrow_button} />
                      </button>
                      {order.personalized} Personalized Cards
                    </div>
                    <div className="flex flex-row items-center gap-[16px] font-custom text-text font-light text-[20px]/[35px] tracking-[0.05em]">
                      <button
                        onClick={() =>
                        {
                          if (order.personalized - 1 >= 0)
                          {
                            setLocalOrder({
                              ...order,
                              nonPersonalized: order.nonPersonalized + 1,
                              personalized: order.personalized - 1,
                              pCard: order.pCard.filter(
                                (e: PCard, i: number) =>
                                  i !== order.pCard.length - 1
                              ),
                            });
                          }
                        }}
                        className={order.personalized - 1 < 0 ? "opacity-50" : ""}
                      >
                        <img className="rotate-180" src={arrow_button} />
                      </button>
                      {order.nonPersonalized} Non-Personalized Cards
                    </div>
                  </div>
                </div>

                <h3 className="font-custom font-medium tracking-[0.05em] text-text text-[20px]/[32px] mb-[8px] ">
                  Add-Ons
                </h3>
                <div className="flex flex-col ml-[10px] mb-[19px]">
                  <div className="flex flex-row gap-[15px] mb-[30px]">
                    <button
                      onClick={() =>
                      {
                        if (order.extra - 1 >= 0)
                        {
                          setLocalOrder({ ...order, extra: order.extra - 1 });
                        }
                      }}
                      className={order.extra - 1 < 0 ? "opacity-50" : ""}
                    >
                      <img src={minus} />
                    </button>
                    <button
                      onClick={() =>
                      {
                        setLocalOrder({ ...order, extra: order.extra + 1 });
                      }}
                      className=""
                    >
                      <img src={plus} />
                    </button>
                    <div className="font-custom text-text  font-light text-[20px]/[35px] tracking-[0.05em]">
                      {" "}
                      {order.extra} Extra Card +$40/per card
                    </div>
                  </div>

                  <div className="font-custom text-text  font-light text-[20px]/[35px] tracking-[0.05em]">
                    {!order.blank ? "Company: " + order.company : ""}
                  </div>
                  <div>
                    {order.pCard.map((e: PCard, i: number) => (
                      <div className="mb-[13px]" key={(i + 1).toString()}>
                        <CAccordion
                          expanded={i === 0}
                          iconangle={["0", "0"]}
                          summary={"Personalized Card #" + (i + 1)}
                          iconcolor="#FBBC05"
                          radius="4px"
                          bgcolor="#fff"
                          txcolor="#000"
                          fontheight="16px"
                          fontsize="14px"
                          fontweight="300"
                          shadow="0px 2px 6px rgba(0, 0, 0, 0.14);"
                        >
                          <CTextField
                            error={errors.pcards[i].fullName}
                            onChange={(a) =>
                            {
                              setLocalOrder({
                                ...order,
                                pCard: [
                                  ...order.pCard.slice(0, i),
                                  { ...e, fullName: a },
                                  ...order.pCard.slice(i + 1),
                                ],
                              });
                            }}
                            value={e.fullName}
                            bordercolor="#FBBC05"
                            classname="mb-[22px]"
                            label="Full Name"
                          />
                        </CAccordion>
                      </div>
                    ))}
                  </div>
                </div></>
            ) : <></>
            }
            {order.type === PurchaseType.ReplacementBlack ? (<>
              <div className="relative w-full  flex items-start justify-start flex-shrink-0 mb-[35px] ">
                <div className="absolute z-50 top-0 left-0 flex flex-col gap-[5px]  ml-[23px] mt-[20px] ">
                  <div className={"flex flex-row w-full items-start  "}>
                    <div className={(colorToLabel(order.color, currency) === ColorLabels.Silver ? 'silver-gradient' : '') + (colorToLabel(order.color, currency) === ColorLabels.Gold ? 'gold-gradient' : '') + " h-fit lg:max-w-[258px] max-w-[220px] overflow-hidden lg:text-[36px]/[42px] text-[30px]/[30px] custom-div font-custom font-medium  break-words text-white "}>
                      {order.blank ? "" : (order.fullName || order.company) ? order.fullName : "John Doe"}
                    </div>
                  </div>
                  <div className={" flex flex-row w-full  items-start"}>
                    <div className={(colorToLabel(order.color, currency) === ColorLabels.Silver ? 'silver-gradient' : '') + (colorToLabel(order.color, currency) === ColorLabels.Gold ? 'gold-gradient' : '') + " h-fit lg:max-w-[238px] max-w-[205px] overflow-hidden custom-div font-custom font-normal break-words text-white lg:text-[20px]/[30px] text-[16px]/[30px] "}>
                      {order.blank ? "" : (order.fullName || order.company) ? order.company : "LogoPark"}
                    </div>
                  </div>

                </div>
                <img className="xl:max-w-[100%] lg:max-w-[75%] h-auto" src={(colorToLabel(order.color, currency) === ColorLabels.Silver ? mock_black_silver : (colorToLabel(order.color, currency) === ColorLabels.Gold ? mock_black_gold : mock_black))} />
                <img
                  className={
                    (order.logoAdd ? (order.blank ? "hidden" : "block") : 'hidden') + " absolute top-[125px] lg:right-[30px] w-[60px]"
                  }
                  src={(colorToLabel(order.color, currency) === ColorLabels.Silver ? your_logo_here_silver : (colorToLabel(order.color, currency) === ColorLabels.Gold ? your_logo_here_gold : your_logo_here))}
                />

              </div>
              <h3 className="font-custom font-medium text-text text-[39px]/[53px] mb-[5px] m-0">
                bcX BLACK Replacement
              </h3>
              <div className="flex mb-[7px]">
                <p className="font-custom font-normal text-[36px]/[49px]">
                  ${cart.subtotal}
                </p>
                <p className="lg:hidden font-custom font-light text-[18px]/[25px] flex self-start pt-[5px] pl-[6px]">
                  {" "}
                  {currency}
                </p>
                <p className="hidden font-custom font-light text-[18px]/[25px] lg:flex self-start pt-[5px] pl-[6px]">
                  {" "}
                  {currency}
                </p>
              </div>

              <div className="flex flex-col ml-[10px] mb-[33px]">
                <div className="font-custom text-text  font-light text-[20px]/[35px] tracking-[0.05em] mb-[10px]">
                  {!order.blank ? "Name: " + order.fullName : ""}
                </div>
                <div className="font-custom text-text  font-light text-[20px]/[35px] tracking-[0.05em]">
                  {!order.blank ? "Company: " + order.company : ""}
                </div>
              </div>
            </>) : <></>
            }
            {order.type === PurchaseType.ReplacementElite ? (<>
              <div className="relative flex items-start justify-start flex-shrink-0  lg:mb-[35px] mb-[31px]">
                <img
                  className={
                    (order.blank ? "hidden" : "block") +
                    " absolute top-[64px] left-[72px] w-[90px] h-auto"
                  }
                  src={(colorToLabel(order.color, currency) === ColorLabels.Silver ? your_logo_here_silver : (colorToLabel(order.color, currency) === ColorLabels.Gold ? your_logo_here_gold : your_logo_here))}
                />
                <img
                  className="xl:max-w-[100%] lg:max-w-[75%] h-auto"
                  src={(colorToLabel(order.color, currency) === ColorLabels.Silver ? mock_elite_silver : (colorToLabel(order.color, currency) === ColorLabels.Gold ? mock_elite_gold : mock_elite))}
                />
              </div>
              <h3 className="font-custom font-medium text-text text-[39px]/[53px] mb-[5px] m-0">
                bcX ELITE Replacement
              </h3>
              <div className="flex mb-[20px] ">
                <p className="font-custom font-normal text-[36px]/[49px]">
                  ${cart.subtotal}
                </p>
                <p className="lg:hidden font-custom font-light text-[18px]/[25px] flex self-start pt-[5px] pl-[6px]">
                  {currency}
                </p>
                <p className="hidden font-custom font-light text-[18px]/[25px] lg:flex self-start pt-[5px] pl-[6px]">
                  {currency}
                </p>
              </div>
              <div className="flex flex-col ml-[10px] mb-[19px]">
                <div className="font-custom text-text  font-light text-[20px]/[35px] tracking-[0.05em]">
                  {!order.blank ? "Company: " + order.company : ""}
                </div>
                <div>
                  {order.pCard.map((e: PCard, i: number) => (
                    <div className="mb-[13px]" key={(i + 1).toString()}>
                      <CAccordion
                        expanded={i === 0}
                        iconangle={["0", "0"]}
                        summary={"Personalized Card #" + (i + 1)}
                        iconcolor="#FBBC05"
                        radius="4px"
                        bgcolor="#fff"
                        txcolor="#000"
                        fontheight="16px"
                        fontsize="14px"
                        fontweight="300"
                        shadow="0px 2px 6px rgba(0, 0, 0, 0.14);"
                      >
                        <CTextField
                          error={errors.pcards[i].fullName}
                          onChange={(a) =>
                          {
                            setLocalOrder({
                              ...order,
                              pCard: [
                                ...order.pCard.slice(0, i),
                                { ...e, fullName: a },
                                ...order.pCard.slice(i + 1),
                              ],
                            });
                          }}
                          value={e.fullName}
                          bordercolor="#FBBC05"
                          classname="mb-[22px]"
                          label="Full Name"
                        />
                      </CAccordion>
                    </div>
                  ))}
                </div>
              </div>
            </>) : <></>
            }
            {order.type === PurchaseType.NewMember ? (<>
              <div className="relative flex items-start justify-start flex-shrink-0  lg:mb-[35px] mb-[31px]">
                <img
                  className={
                    (order.blank ? "hidden" : "block") +
                    " absolute top-[64px] left-[72px] w-[90px] h-auto"
                  }
                  src={(colorToLabel(order.color, currency) === ColorLabels.Silver ? your_logo_here_silver : (colorToLabel(order.color, currency) === ColorLabels.Gold ? your_logo_here_gold : your_logo_here))}
                />
                <img
                  className="xl:max-w-[100%] lg:max-w-[75%] h-auto"
                  src={(colorToLabel(order.color, currency) === ColorLabels.Silver ? mock_elite_silver : (colorToLabel(order.color, currency) === ColorLabels.Gold ? mock_elite_gold : mock_elite))}
                />
              </div>
              <h3 className="font-custom font-medium text-text text-[39px]/[53px] mb-[5px] m-0">
                bcX ELITE<br />New Member
              </h3>
              <div className="flex mb-[20px] ">
                <p className="font-custom font-normal text-[36px]/[49px]">
                  ${cart.subtotal}
                </p>
                <p className="lg:hidden font-custom font-light text-[18px]/[25px] flex self-start pt-[5px] pl-[6px]">
                  {currency}
                </p>
                <p className="hidden font-custom font-light text-[18px]/[25px] lg:flex self-start pt-[5px] pl-[6px]">
                  {currency}
                </p>
              </div>

              <div className="flex flex-row gap-[15px] ml-[10px]">
                <button
                  onClick={() =>
                  {
                    if (order.base - 1 >= minimum)
                    {
                      setLocalOrder({ ...order, base: order.base - 1 });
                    }
                  }}
                  className={order.base - 1 < minimum ? "opacity-50" : ""}
                >
                  <img src={minus} />
                </button>
                <button
                  onClick={() =>
                  {
                    setLocalOrder({ ...order, base: order.base + 1 });
                  }}
                  className=""
                >
                  <img src={plus} />
                </button>
                <div className="font-custom text-text  font-light text-[20px]/[35px] tracking-[0.05em]">
                  {order.base} Cards
                </div>
              </div>
              <div className="mb-[10px]">
                <h3 className="font-custom font-medium tracking-[0.05em] text-text text-[20px]/[32px] mb-[8px] ">
                  Distribution of Cards
                </h3>
                <div className="flex flex-col gap-[8px] ml-[10px]">
                  <div className="flex flex-row items-center gap-[16px] font-custom text-text  font-light text-[20px]/[35px] tracking-[0.05em]">
                    <button
                      onClick={() =>
                      {
                        if (order.nonPersonalized - 1 >= 0)
                        {
                          setErrors({
                            ...errors,
                            pcards: [
                              ...errors.pcards,
                              {
                                fullName: "",
                              } as PCard,
                            ],
                          });
                          setLocalOrder({
                            ...order,
                            personalized: order.personalized + 1,
                            nonPersonalized: order.nonPersonalized - 1,
                            pCard: [
                              ...order.pCard,
                              {
                                fullName: "",
                                orderNumber: "",
                                qr: "",
                                specialInstructions: "",
                              },
                            ],
                          });
                        }
                      }}
                      className={
                        order.nonPersonalized - 1 < 0 ? "opacity-50" : ""
                      }
                    >
                      <img src={arrow_button} />
                    </button>
                    {order.personalized} Personalized Cards
                  </div>
                  <div className="flex flex-row items-center gap-[16px] font-custom text-text font-light text-[20px]/[35px] tracking-[0.05em]">
                    <button
                      onClick={() =>
                      {
                        if (order.personalized - 1 >= 0)
                        {
                          setLocalOrder({
                            ...order,
                            nonPersonalized: order.nonPersonalized + 1,
                            personalized: order.personalized - 1,
                            pCard: order.pCard.filter(
                              (e: PCard, i: number) =>
                                i !== order.pCard.length - 1
                            ),
                          });
                        }
                      }}
                      className={order.personalized - 1 < 0 ? "opacity-50" : ""}
                    >
                      <img className="rotate-180" src={arrow_button} />
                    </button>
                    {order.nonPersonalized} Non-Personalized Cards
                  </div>
                </div>
              </div>


              <div className="flex flex-col ml-[10px] mb-[19px]">
                <div className="font-custom text-text  font-light text-[20px]/[35px] tracking-[0.05em]">
                  {!order.blank ? "Company: " + order.company : ""}
                </div>
                <div>
                  {order.pCard.map((e: PCard, i: number) => (
                    <div className="mb-[13px]" key={(i + 1).toString()}>
                      <CAccordion
                        expanded={i === 0}
                        iconangle={["0", "0"]}
                        summary={"Personalized Card #" + (i + 1)}
                        iconcolor="#FBBC05"
                        radius="4px"
                        bgcolor="#fff"
                        txcolor="#000"
                        fontheight="16px"
                        fontsize="14px"
                        fontweight="300"
                        shadow="0px 2px 6px rgba(0, 0, 0, 0.14);"
                      >
                        <CTextField
                          error={errors.pcards[i].fullName}
                          onChange={(a) =>
                          {
                            setLocalOrder({
                              ...order,
                              pCard: [
                                ...order.pCard.slice(0, i),
                                { ...e, fullName: a },
                                ...order.pCard.slice(i + 1),
                              ],
                            });
                          }}
                          value={e.fullName}
                          bordercolor="#FBBC05"
                          classname="mb-[22px]"
                          label="Full Name"
                        />
                      </CAccordion>
                    </div>
                  ))}
                </div>
              </div>
            </>) : <></>}
            <div className="mb-[26px]">
              <CAccordion
                expanded
                summary='Discount Codes'
                iconangle={["0", "0"]}
                iconcolor="#000"
                radius="4px"
                bgcolor="#fff"
                txcolor="#000"
                fontheight="20px"
                fontsize="16px"
                fontweight="700"
                shadow="0px 2px 1px rgba(0, 0, 0, 0.14);">
                <DiscountComp percent={discount.percentDiscount ?? 0} code={discount.code} onClick={checkDiscount} error={discountError} loading={discountLoad} />
              </CAccordion>
            </div>
            <div className="mb-[36px]">
              <CAccordion
                summary="Billing Summary"
                expanded
                iconangle={["0", "0"]}
                iconcolor="#000"
                radius="4px"
                bgcolor="#fff"
                txcolor="#000"
                fontheight="20px"
                fontsize="16px"
                fontweight="700"
                shadow="0px 2px 1px rgba(0, 0, 0, 0.14);"
              >
                <div className="flex flex-row justify-between font-custom text-[14px]/[20px] font-normal text-text mb-[12px]">
                  <div>Subtotal</div>
                  <div>${cart.subtotal}</div>
                </div>
                {!isNaN(discount.percentDiscount) && <div className="flex flex-row justify-between font-custom text-[14px]/[20px] font-semibold text-discount mb-[12px]">
                  <div>Discount</div>
                  <div>-${cart.discount.totalDiscounted}</div>
                </div>}
                <div className="flex flex-row justify-between font-custom text-[14px]/[20px] font-normal text-text mb-[12px]">
                  <div>Shipping</div>
                  <div>{"$0"}</div>
                </div>
                <div className="flex flex-row justify-between font-custom text-[14px]/[20px] font-normal text-text mb-[29px]">
                  <div>Tax</div>
                  <div>
                    $
                    {cart.tax.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </div>
                </div>
                <div className="border-b-[1px] border-gray-300 mb-[18px]" />
                <div className="flex flex-row justify-between font-custom text-[16px]/[20px] font-bold text-text mb-[20px]">
                  <div>Grand Total</div>
                  <div>${cart.total.toFixed(2)}</div>
                </div>
                <div className="flex flex-col mb-[29px] gap-[5px]">
                  <div className="flex flex-row justify-start ">
                    <CCheckBox
                      checked={consent}
                      id="privacy-terms-black"
                      onCheck={(a: boolean) =>
                      {
                        setConsent(a);
                      }}
                      classname=""
                      label=""
                    />
                    <div className="font-custom text-[14px]/[20px] font-normal text-check ml-[-15px]">
                      Please check to acknowledge our{" "}
                      <Link
                        className="text-blue-500"
                        to="/terms-of-use"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Privacy & Terms Policy
                      </Link>
                    </div>
                  </div>
                  <div className={consentError ? "block" : "hidden"}>
                    <Alert severity="warning">{consentError}</Alert>
                  </div>
                </div>

                <button
                  id='pay-black'
                  className="font-custom font-bold text-button shadow-custom w-full text-[16px]/[20px] text-center hover:bg-secondary bg-primary rounded-[33px]  pt-[12px] pb-[14px]"
                  onClick={() => handleSubmit()}
                >
                  Pay ${cart.total.toFixed(2)}
                </button>
              </CAccordion>
            </div>
            <div className=" flex flex-col items-center justify-center">
              <h4 className="font-custom font-normal text-[34px]/[47px] mb-[21px]">
                Already Have a Card?
              </h4>
              <button
                className="font-custom font-medium text-button shadow-custom text-[20px]/[27px] text-center hover:bg-secondary bg-primary rounded-[33px] px-[111px] pt-[12px] pb-[14px]"
                onClick={() =>
                {
                  navigate("/login");
                }}
              >
                Sign In Here
              </button>
            </div>
          </div>


          <div className="flex flex-col bg-white">
            <div className="shadow-md rounded-[4px] pt-[19px] pb-[58px] px-[21px] mb-[27px]">
              <h4 className="font-custom font-bold text-[16px]/[20px] lg:mb-[34px]">
                Billing Address
              </h4>
              <div className="flex flex-row  gap-[18px] mb-[30px] bg-white">
                <CTextField
                  id="billing-first-name"
                  w="w-[272px]"
                  error={detailErrors.billingDetails.firstName}
                  value={details.billingDetails.firstName}
                  onChange={(a) =>
                  {
                    setLocalDetails({
                      ...details,
                      billingDetails: {
                        ...details.billingDetails,
                        firstName: a,
                      },
                    });
                  }}
                  label="First Name"
                />
                <CTextField
                  w="w-[272px]"
                  id="billing-last-name"
                  error={detailErrors.billingDetails.lastName}
                  value={details.billingDetails.lastName}
                  onChange={(a) =>
                  {
                    setLocalDetails({
                      ...details,
                      billingDetails: {
                        ...details.billingDetails,
                        lastName: a,
                      },
                    });
                  }}
                  label="Last Name"
                />
              </div>
              <div className="mb-[30px]">
                <CTextField
                  error={detailErrors.billingDetails.email}
                  value={details.billingDetails.email}
                  label="Email Address"
                  id="billing-email"
                  onChange={(a) =>
                  {
                    setLocalDetails({
                      ...details,
                      billingDetails: { ...details.billingDetails, email: a },
                    });
                  }}
                />
              </div>
              <div className="mb-[30px]">
                <CTextField
                  label="Country"
                  id='billing-country'
                  classname="w-[272px]"
                  onChange={(a) =>
                  {
                    setLocalDetails({
                      ...details,
                      billingDetails: {
                        ...details.billingDetails,
                        country: a,
                      },
                    });
                  }}
                  value={details.billingDetails.country}
                  error={detailErrors.billingDetails.country}
                />

              </div>
              <div className="mb-[30px]">
                <CTextField
                  error={detailErrors.billingDetails.street1}
                  value={details.billingDetails.street1}
                  label="Address Line 1"
                  id="billing-address-1"
                  onChange={(a) =>
                  {
                    setLocalDetails({
                      ...details,
                      billingDetails: { ...details.billingDetails, street1: a },
                    });
                  }}
                />
              </div>
              <div className="mb-[30px]">
                <CTextField
                  value={details.billingDetails.street2}
                  label="Address Line 2"
                  id="billing-address-2"
                  onChange={(a) =>
                  {
                    setLocalDetails({
                      ...details,
                      billingDetails: { ...details.billingDetails, street2: a },
                    });
                  }}
                />
              </div>
              <div className=" flex flex-row  lg:gap-[18px] mb-[30px]">
                <CTextField
                  label="State/Province"
                  id='billing-state'
                  classname="w-[272px]"
                  onChange={(a) =>
                  {
                    setLocalDetails({
                      ...details,
                      billingDetails: {
                        ...details.billingDetails,
                        stateProvince: a,
                      },
                    });
                  }}
                  value={details.billingDetails.stateProvince}
                  error={detailErrors.billingDetails.stateProvince}
                />
                <CTextField
                  w="w-[272px]"
                  error={detailErrors.billingDetails.city}
                  value={details.billingDetails.city}
                  label="City"
                  id="billing-city"
                  onChange={(a) =>
                  {
                    setLocalDetails({
                      ...details,
                      billingDetails: { ...details.billingDetails, city: a },
                    });
                  }}
                />
              </div>
              <div className="flex flex-row lg:gap-[18px] mb-[30px] ">
                <CTextField
                  error={detailErrors.billingDetails.zip}
                  label="Zip/Postal Code"
                  mask={[/\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/]}
                  id="billing-zip"
                  placeholder=""
                  value={details.billingDetails.zip}
                  w="w-[225px]"
                  onChange={(a) =>
                  {
                    setLocalDetails({
                      ...details,
                      billingDetails: { ...details.billingDetails, zip: a },
                    });
                  }}
                />
                <CPhoneInput
                  name="Phone"
                  w="lg:w-[319px]"
                  error={detailErrors.billingDetails.phone}
                  id={'billing-phone'}
                  value={details.billingDetails.phone}
                  onChange={(a) =>
                  {
                    setLocalDetails({
                      ...details,
                      billingDetails: {
                        ...details.billingDetails,
                        phone: a ? a.toString() : "",
                      },
                    });
                  }}
                />
              </div>
              <div className="flex flex-row">
                <CCheckBox
                  id="same-billing-shipping"
                  onCheck={setSameAddress}
                  classname=""
                  label="My billing and shipping address are the same"
                />
              </div>
              <div className="hidden flex-row lg:gap-[18px]">


              </div>
            </div>
            <div
              hidden={sameAddress}
              className={
                "shadow-md rounded-[4px] pt-[19px] pb-[58px] px-[21px]"
              }
            >
              <h4 className="font-custom font-bold text-[16px]/[20px] lg:mb-[34px]">
                Shipping Address
              </h4>
              <div className="flex flex-row justify-between gap-[18px] mb-[30px]">
                <CTextField
                  error={detailErrors.shippingDetails.firstName}
                  value={details.shippingDetails.firstName}
                  label="First Name"
                  w="w-[272px]"
                  onChange={(a) =>
                  {
                    setLocalDetails({
                      ...details,
                      shippingDetails: {
                        ...details.shippingDetails,
                        firstName: a,
                      },
                    });
                  }}
                />
                <CTextField
                  value={details.shippingDetails.lastName}
                  label="Last Name"
                  w="w-[272px]"
                  error={detailErrors.shippingDetails.lastName}
                  onChange={(a) =>
                  {
                    setLocalDetails({
                      ...details,
                      shippingDetails: {
                        ...details.shippingDetails,
                        lastName: a,
                      },
                    });
                  }}
                />
              </div>
              <div className="mb-[30px]">
                <CTextField
                  value={details.shippingDetails.email}
                  error={detailErrors.shippingDetails.email}
                  label="Email Address"
                  onChange={(a) =>
                  {
                    setLocalDetails({
                      ...details,
                      shippingDetails: { ...details.shippingDetails, email: a },
                    });
                  }}
                />
              </div>
              <div className="mb-[30px]">
                <CTextField
                  label="Country"
                  id='shipping-country'
                  classname="w-[272px]"
                  onChange={(a) =>
                  {
                    setLocalDetails({
                      ...details,
                      shippingDetails: {
                        ...details.shippingDetails,
                        country: a,
                      },
                    });
                  }}
                  value={details.shippingDetails.country}
                  error={detailErrors.shippingDetails.country}
                />
              </div>
              <div className="mb-[30px]">
                <CTextField
                  value={details.shippingDetails.street1}
                  label="Address Line 1"
                  id='shipping-address-1'
                  error={detailErrors.shippingDetails.street1}
                  onChange={(a) =>
                  {
                    setLocalDetails({
                      ...details,
                      shippingDetails: {
                        ...details.shippingDetails,
                        street1: a,
                      },
                    });
                  }}
                />
              </div>
              <div className="mb-[30px]">
                <CTextField
                  value={details.shippingDetails.street2}
                  label="Address Line 2"
                  id='shipping-address-2'
                  onChange={(a) =>
                  {
                    setLocalDetails({
                      ...details,
                      shippingDetails: {
                        ...details.shippingDetails,
                        street2: a,
                      },
                    });
                  }}
                />
              </div>

              <div className=" flex flex-row lg:gap-[18px] mb-[30px]">
                <CTextField
                  value={details.shippingDetails.stateProvince}
                  label="State/Province"
                  id='shipping-state'
                  classname="w-[272px]"
                  error={detailErrors.shippingDetails.stateProvince}
                  onChange={(a) =>
                  {
                    setLocalDetails({
                      ...details,
                      shippingDetails: {
                        ...details.shippingDetails,
                        stateProvince: a,
                      },
                    });
                  }}

                />
                <CTextField
                  label="City"
                  w="lg:w-[272px]"
                  id='shipping-city'
                  value={details.shippingDetails.city}
                  error={detailErrors.shippingDetails.city}
                  onChange={(a) =>
                  {
                    setLocalDetails({
                      ...details,
                      shippingDetails: { ...details.shippingDetails, city: a },
                    });
                  }}
                />
              </div>
              <div className="flex flex-row lg:gap-[18px]  ">
                <CTextField
                  value={details.shippingDetails.zip}
                  error={detailErrors.shippingDetails.zip}
                  label="Zip/Postal Code"
                  mask={[/\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/]}
                  id='shipping-zip'
                  placeholder=""
                  w="lg:w-[225px]"
                  onChange={(a) =>
                  {
                    setLocalDetails({
                      ...details,
                      shippingDetails: { ...details.shippingDetails, zip: a },
                    });
                  }}
                />
                <CPhoneInput
                  name="Phone"
                  w="lg:w-[319px]"
                  error={detailErrors.shippingDetails.phone}
                  value={details.shippingDetails.phone}
                  onChange={(a) =>
                  {
                    setLocalDetails({
                      ...details,
                      shippingDetails: {
                        ...details.shippingDetails,
                        phone: a ? a.toString() : "",
                      },
                    });
                  }}
                />
              </div>
            </div>
            <div
              className={
                " shadow-md rounded-[4px] pt-[19px] pb-[30px] px-[21px] mb-[20px]"
              }
            >
              <h4 className="font-custom font-bold text-[16px]/[20px] lg:mb-[28px]">
                Shipping Method
              </h4>
              <div>
                <RadioGroup style={{ height: "100%" }} value={"Free"}>
                  <div className="flex flex-row h-full justify-between ">
                    <CRadio checked value="Free" label="Free" height="h-full">
                      <div className="flex flex-row justify-between ">
                        <div className="font-custom font-normal text-[14px]/[24px]  text-check ">
                          Canada Post (delivery time 3 - 14 days)
                        </div>
                        <img className=" w-[100px] " src={shipping} />
                      </div>
                    </CRadio>
                  </div>
                </RadioGroup>
              </div>
            </div>

            <div
              className={
                "shadow-md rounded-[4px] pt-[19px] pb-[30px] px-[21px] mb-[20px] hidden"
              }
            >
              <h4 className="font-custom font-bold text-[16px]/[20px] lg:mb-[28px]">
                Payment Method
              </h4>
              <div className="mb-[20px] ">
                <RadioGroup style={{ height: "100%" }} value={payment}>
                  <div className="hidden flex-row h-full justify-between mb-[15px]">
                    <CRadio
                      value="PayPal"
                      label="PayPal"
                      height="h-full"
                      onCheck={setPayment}
                      checked={payment === "PayPal"}
                    >
                      <div className="flex flex-row ">
                        <div className="font-custom font-normal text-[14px]/[20px] text-check w-[330px] mr-[29px]">
                          You will be redirected to the PayPal website after
                          submitting your order
                        </div>
                        <img className="mt-[-5px]" src={paypal} />
                      </div>
                    </CRadio>
                  </div>

                  <div className="flex flex-col h-full justify-between ">
                    <CRadio
                      value="CreditCard"
                      label="Pay with Credit Card"
                      onCheck={setPayment}
                      checked={payment === "CreditCard"}
                      labelwidth="180px"
                      height="h-full"
                      flex="flex-col"
                    >
                      <div className="flex flex-col">
                        <div className="absolute top-0 right-0 mr-[13px] mt-[11px]">
                          <img src={credit_Cards} />
                        </div>
                        <div className="flex flex-row gap-[18px] mb-[30px]">
                          <CTextField
                            textsize="15px"
                            mask="9999 9999 9999 9999 9999"
                            placeholder="_"
                            label="Card number"
                            w="lg:w-[250px] w-[230px]"
                            h="h-[57px]"
                            bgcolor={payment === "PayPal" ? "#fff" : "#E8EFFA"}
                            value={details.paymentDetails.creditCard}
                            error={detailErrors.paymentDetails.creditCard}
                            onChange={(a) =>
                            {
                              setLocalDetails({
                                ...details,
                                paymentDetails: {
                                  ...details.paymentDetails,
                                  creditCard: a,
                                },
                              });
                            }}
                          />
                          <CTextField
                            mask="99/99"
                            type="tel"
                            placeholder="_"
                            label="Expiration Date"
                            w="lg:w-[250px] w-[230px]"
                            bgcolor={payment === "PayPal" ? "#fff" : "#E8EFFA"}
                            value={details.paymentDetails.expirationDate}
                            error={detailErrors.paymentDetails.expirationDate}
                            onChange={(a) =>
                            {
                              setLocalDetails({
                                ...details,
                                paymentDetails: {
                                  ...details.paymentDetails,
                                  expirationDate: a,
                                },
                              });
                            }}
                          />
                        </div>
                        <div className="flex flex-row gap-[18px]">
                          <CTextField
                            mask="9999"
                            label="Card Security Code"
                            w="lg:w-[250px] w-[230px]"
                            type="password"
                            error={detailErrors.paymentDetails.securityCode}
                            bgcolor={payment === "PayPal" ? "#fff" : "#E8EFFA"}
                            value={details.paymentDetails.securityCode}
                            onChange={(a) =>
                            {
                              setLocalDetails({
                                ...details,
                                paymentDetails: {
                                  ...details.paymentDetails,
                                  securityCode: a,
                                },
                              });
                            }}
                          />
                          <div className="hidden self-center font-custom text-[12px]/[20px] font-normal text-check">
                            <a className=" text-blue-500">What is this?</a>
                          </div>
                        </div>
                      </div>
                    </CRadio>
                  </div>
                </RadioGroup>
              </div>
              <div className="flex flex-row items-center gap-[10px]">
                <img src={security} />
                <div className="font-custom font-normal text-gray-400 text-[12px]/[20px]">
                  We protect your payment information using encryption to
                  provide bank-level security.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:hidden block ">
        <div className="flex flex-col px-[9px]">
          <div className="mb-[10px]">
            <CAccordion
              expanded
              summary="Billing Address"
              iconangle={["0", "0"]}
              iconcolor="#000"
              radius="0"
              bgcolor="#fff"
              txcolor="#000"
              fontheight="20px"
              fontsize="14px"
              fontweight="700"
              shadow="0px 2px 6px rgba(0, 0, 0, 0.14);"
            >
              <div className="flex flex-col gap-[26px]">
                <CTextField
                  label="First Name"
                  hint="First Name"
                  value={details.billingDetails.firstName}
                  error={detailErrors.billingDetails.firstName}
                  onChange={(a) =>
                  {
                    setLocalDetails({
                      ...details,
                      billingDetails: {
                        ...details.billingDetails,
                        firstName: a,
                      },
                    });
                  }}
                />
                <CTextField
                  label="Last Name"
                  hint="Last Name"
                  value={details.billingDetails.lastName}
                  error={detailErrors.billingDetails.lastName}
                  onChange={(a) =>
                  {
                    setLocalDetails({
                      ...details,
                      billingDetails: {
                        ...details.billingDetails,
                        lastName: a,
                      },
                    });
                  }}
                />
                <CTextField
                  label="Email Address"
                  value={details.billingDetails.email}
                  error={detailErrors.billingDetails.email}
                  onChange={(a) =>
                  {
                    setLocalDetails({
                      ...details,
                      billingDetails: { ...details.billingDetails, email: a },
                    });
                  }}
                />
                <CTextField
                  label="Country"
                  id='billing-country-mobile'
                  onChange={(a) =>
                  {
                    setLocalDetails({
                      ...details,
                      billingDetails: {
                        ...details.billingDetails,
                        country: a,
                      },
                    });
                  }}
                  value={details.billingDetails.country}
                  error={detailErrors.billingDetails.country}
                />
                <CTextField
                  label="Address Line 1"
                  id='billing-address-1-mobile'
                  value={details.billingDetails.street1}
                  error={detailErrors.billingDetails.street1}
                  onChange={(a) =>
                  {
                    setLocalDetails({
                      ...details,
                      billingDetails: { ...details.billingDetails, street1: a },
                    });
                  }}
                />
                <CTextField
                  label="Address Line 2"
                  id='billing-address-2-mobile'
                  value={details.billingDetails.street2}
                  onChange={(a) =>
                  {
                    setLocalDetails({
                      ...details,
                      billingDetails: { ...details.billingDetails, street2: a },
                    });
                  }}
                />

                <CTextField
                  label="State/Province"
                  id='billing-state-mobile'
                  value={details.billingDetails.stateProvince}
                  error={detailErrors.billingDetails.stateProvince}
                  onChange={(a) =>
                  {
                    setLocalDetails({
                      ...details,
                      billingDetails: {
                        ...details.billingDetails,
                        stateProvince: a,
                      },
                    });
                  }}
                />
                <CTextField
                  label="City"
                  value={details.billingDetails.city}
                  id='billing-city-mobile'
                  error={detailErrors.billingDetails.city}
                  onChange={(a) =>
                  {
                    setLocalDetails({
                      ...details,
                      billingDetails: { ...details.billingDetails, city: a },
                    });
                  }}
                />
                <CTextField
                  mask={[/\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/]}
                  placeholder=""
                  label="Zip/Postal Code"
                  id='billing-zip-mobile'
                  value={details.billingDetails.zip}
                  error={detailErrors.billingDetails.zip}
                  onChange={(a) =>
                  {
                    setLocalDetails({
                      ...details,
                      billingDetails: { ...details.billingDetails, zip: a },
                    });
                  }}
                />
                <div className="">
                  <CPhoneInput
                    name="Phone"
                    w="lg:w-[340px] w-full"
                    value={details.billingDetails.phone}
                    error={detailErrors.billingDetails.phone}
                    onChange={(a) =>
                    {
                      setLocalDetails({
                        ...details,
                        billingDetails: {
                          ...details.billingDetails,
                          phone: a ? a.toString() : "",
                        },
                      });
                    }}
                  />
                </div>
                <CCheckBox
                  onCheck={setSameAddress}
                  fontheight="20px"
                  fontsize="12px"
                  fontweight="400"
                  classname="mt-[-14px] mb-[26px]"
                  label="My billing and shipping address are the same"
                />
                <div className="hidden">
                  <CTextField label="Password" type="password" classname="" />
                  <CTextField
                    classname="mb-[24px]"
                    label="Confirm Password"
                    type="password"
                    onChange={(a) => { }}
                  />
                </div>
              </div>
            </CAccordion>
          </div>
          <div hidden={sameAddress} className="mb-[10px]">
            <CAccordion
              summary="Shipping Address"
              iconangle={["0", "0"]}
              iconcolor="#000"
              radius="0"
              bgcolor="#fff"
              txcolor="#000"
              fontheight="20px"
              fontsize="14px"
              fontweight="700"
              shadow="0px 2px 6px rgba(0, 0, 0, 0.14);"
            >
              <div className="flex flex-col gap-[26px]">
                <CTextField
                  label="First Name"
                  value={details.shippingDetails.firstName}
                  error={detailErrors.shippingDetails.firstName}
                  onChange={(a) =>
                  {
                    setLocalDetails({
                      ...details,
                      shippingDetails: {
                        ...details.shippingDetails,
                        firstName: a,
                      },
                    });
                  }}
                />
                <CTextField
                  label="Last Name"
                  error={detailErrors.shippingDetails.lastName}
                  value={details.shippingDetails.lastName}
                  onChange={(a) =>
                  {
                    setLocalDetails({
                      ...details,
                      shippingDetails: {
                        ...details.shippingDetails,
                        lastName: a,
                      },
                    });
                  }}
                />
                <CTextField
                  label="Email Address"
                  value={details.shippingDetails.email}
                  error={detailErrors.shippingDetails.email}
                  onChange={(a) =>
                  {
                    setLocalDetails({
                      ...details,
                      shippingDetails: { ...details.shippingDetails, email: a },
                    });
                  }}
                />
                <CTextField
                  label="Country"
                  id='shipping-country-mobile'
                  onChange={(a) =>
                  {
                    setLocalDetails({
                      ...details,
                      shippingDetails: {
                        ...details.shippingDetails,
                        country: a,
                      },
                    });
                  }}
                  value={details.shippingDetails.country}
                  error={detailErrors.shippingDetails.country}
                />
                <CTextField
                  label="Address Line 1"
                  id='shipping-address-1-mobile'
                  value={details.shippingDetails.street1}
                  error={detailErrors.shippingDetails.street1}
                  onChange={(a) =>
                  {
                    setLocalDetails({
                      ...details,
                      shippingDetails: {
                        ...details.shippingDetails,
                        street1: a,
                      },
                    });
                  }}
                />
                <CTextField
                  label="Address Line 2"
                  id='shipping-address-2-mobile'
                  value={details.shippingDetails.street2}
                  onChange={(a) =>
                  {
                    setLocalDetails({
                      ...details,
                      shippingDetails: {
                        ...details.shippingDetails,
                        street2: a,
                      },
                    });
                  }}
                />

                <CTextField
                  label="State/Province"
                  id='shipping-state-mobile'
                  value={details.shippingDetails.stateProvince}
                  error={detailErrors.shippingDetails.stateProvince}
                  onChange={(a) =>
                  {
                    setLocalDetails({
                      ...details,
                      shippingDetails: {
                        ...details.shippingDetails,
                        stateProvince: a,
                      },
                    });
                  }}
                />
                <CTextField
                  label="City"
                  id='shipping-city-mobile'
                  value={details.shippingDetails.city}
                  error={detailErrors.shippingDetails.city}
                  onChange={(a) =>
                  {
                    setLocalDetails({
                      ...details,
                      shippingDetails: { ...details.shippingDetails, city: a },
                    });
                  }}
                />
                <CTextField
                  label="Zip/Postal Code"
                  mask={[/\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/, /\S/]}
                  placeholder=""
                  id='shipping-zip-mobile'
                  value={details.shippingDetails.zip}
                  error={detailErrors.shippingDetails.zip}
                  onChange={(a) =>
                  {
                    setLocalDetails({
                      ...details,
                      shippingDetails: { ...details.shippingDetails, zip: a },
                    });
                  }}
                />
                <div className="">
                  <CPhoneInput
                    name="Phone"
                    w="lg:w-[340px] w-full"
                    value={details.shippingDetails.phone}
                    error={detailErrors.shippingDetails.phone}
                    onChange={(a) =>
                    {
                      setLocalDetails({
                        ...details,
                        shippingDetails: {
                          ...details.shippingDetails,
                          phone: a ? a.toString() : "",
                        },
                      });
                    }}
                  />
                </div>
              </div>
            </CAccordion>
          </div>
          <div className="mb-[10px]">
            <CAccordion
              summary="Shipping Method"
              iconangle={["0", "0"]}
              iconcolor="#000"
              radius="0"
              bgcolor="#fff"
              txcolor="#000"
              fontheight="20px"
              fontsize="14px"
              fontweight="700"
              shadow="0px 2px 6px rgba(0, 0, 0, 0.14);"
            >
              <div className="flex flex-col gap-[26px]">
                <RadioGroup value={"Free"}>
                  <div className="flex flex-row justify-between ">
                    <CRadio checked value="Free" label="Free" height="h-full">
                      <div className="flex w-full flex-row justify-between ">
                        <div className="font-custom self-center font-normal text-[14px]/[20px] text-check ml-[-20px] mt-[-10px]">
                          Canada Post (delivery time 3 - 14 days)
                        </div>

                        <img className=" w-[80px]" src={shipping} />
                      </div>
                    </CRadio>
                  </div>
                </RadioGroup>
              </div>
            </CAccordion>
          </div>
          <div className="mb-[10px] hidden">
            <CAccordion
              summary="Payment Method"
              iconangle={["0", "0"]}
              iconcolor="#000"
              radius="0"
              bgcolor="#fff"
              txcolor="#000"
              fontheight="20px"
              fontsize="14px"
              fontweight="700"
              shadow="0px 2px 6px rgba(0, 0, 0, 0.14);"
            >
              <div className="flex flex-col gap-[26px]">
                <RadioGroup value={payment}>
                  <div className="hidden flex-row justify-between mb-[15px]">
                    <CRadio
                      value="PayPal"
                      label="PayPal"
                      height="h-[74px]"
                      fontheight="20px"
                      fontweight="600"
                      fontsize="14px"
                      onCheck={setPayment}
                      checked={payment === "PayPal"}
                    >
                      <div className="flex flex-col">
                        <div className="font-custom font-normal text-[12px]/[20px] text-check ml-[-20px] md:mt-0 mt-[-10px] md:w-[200px] w-[150px]">
                          You will be redirected to the PayPal website after
                          submitting your order
                        </div>

                        <div className="absolute top-0 right-0 md:mr-[-25px] mr-[-30px] md:mt-[11px] mt-[10px]">
                          <img
                            className="self-start md:w-full w-[80%]"
                            src={paypal}
                          />
                        </div>
                      </div>
                    </CRadio>
                  </div>
                  <div className="flex flex-row justify-between ">
                    <CRadio
                      value="CreditCard"
                      label="Pay with Credit Card"
                      fontheight="20px"
                      fontweight="600"
                      fontsize="14px"
                      onCheck={setPayment}
                      checked={payment === "CreditCard"}
                      labelwidth="90px"
                      height="h-full"
                      flex="flex-col"
                    >
                      <div className="flex  flex-col">
                        <div className="absolute top-0 right-0 mr-[-25px] mt-[11px]">
                          <img className="w-[80%] h-auto" src={credit_Cards} />
                        </div>
                        <div className="flex flex-col gap-[18px] mb-[20px]">
                          <CTextField
                            mask="9999 9999 9999 9999 999"
                            placeholder="_"
                            label="Card number"
                            w="235px"
                            textsize="18px"
                            classname="mb-[4px]"
                            error={detailErrors.paymentDetails.creditCard}
                            bgcolor={payment === "PayPal" ? "#fff" : "#E8EFFA"}
                            value={details.paymentDetails.creditCard}
                            onChange={(a) =>
                            {
                              setLocalDetails({
                                ...details,
                                paymentDetails: {
                                  ...details.paymentDetails,
                                  creditCard: a,
                                },
                              });
                            }}
                          />
                          <CTextField
                            mask="99/99"
                            placeholder="_"
                            label="Expiration Date"
                            classname="mb-[4px]"
                            error={detailErrors.paymentDetails.expirationDate}
                            w="235px"
                            bgcolor={payment === "PayPal" ? "#fff" : "#E8EFFA"}
                            value={details.paymentDetails.expirationDate}
                            onChange={(a) =>
                            {
                              setLocalDetails({
                                ...details,
                                paymentDetails: {
                                  ...details.paymentDetails,
                                  expirationDate: a,
                                },
                              });
                            }}
                          />
                        </div>
                        <div className="flex flex-row gap-[18px]">
                          <CTextField
                            mask="9999"
                            label="Card Security Code"
                            w="235px"
                            type="password"
                            bgcolor={payment === "PayPal" ? "#fff" : "#E8EFFA"}
                            value={details.paymentDetails.securityCode}
                            error={detailErrors.paymentDetails.securityCode}
                            onChange={(a) =>
                            {
                              setLocalDetails({
                                ...details,
                                paymentDetails: {
                                  ...details.paymentDetails,
                                  securityCode: a,
                                },
                              });
                            }}
                          />
                          <div className="hidden self-center font-custom text-[12px]/[20px] font-normal text-check">
                            <a className=" text-blue-500">What is this?</a>
                          </div>
                        </div>
                      </div>
                    </CRadio>
                  </div>
                </RadioGroup>
              </div>
            </CAccordion>
          </div>
          <div className="mb-[10px]">
            <CAccordion
              summary="Order Review"
              iconangle={["0", "0"]}
              iconcolor="#000"
              radius="0"
              bgcolor="#fff"
              txcolor="#000"
              fontheight="20px"
              fontsize="14px"
              fontweight="700"
              shadow="0px 2px 6px rgba(0, 0, 0, 0.14);"
            >
              <div className="flex flex-col ">
                {order.type === PurchaseType.Black ? (
                  <div className="flex flex-col w-full h-full">
                    <div className="relative h-[178px] flex items-start justify-start flex-shrink-0 w-[350px]  lg:mb-[35px] mb-[25px]">
                      <div className="absolute z-50 top-0 left-0 flex flex-col lg:gap-[16px] gap-[5px] lg:ml-[43px] ml-[23px] lg:mt-[31px] mt-[18px]">
                        <div className={"flex flex-row w-full   items-start  "}>
                          <div className={(colorToLabel(order.color, currency) === ColorLabels.Silver ? 'silver-gradient' : '') + (colorToLabel(order.color, currency) === ColorLabels.Gold ? 'gold-gradient' : '') + " h-fit lg:max-w-[423px] max-w-[220px] overflow-hidden lg:text-[56px]/[76px] text-[28px]/[38px] custom-div font-custom font-medium  break-words text-white "}>
                            {order.blank ? "" : (order.fullName || order.company) ? order.fullName : "John Doe"}
                          </div>
                        </div>
                        <div className={" flex flex-row w-full   items-start"}>
                          <div className={(colorToLabel(order.color, currency) === ColorLabels.Silver ? 'silver-gradient' : '') + (colorToLabel(order.color, currency) === ColorLabels.Gold ? 'gold-gradient' : '') + " h-fit lg:max-w-[388px] max-w-[195px] overflow-hidden custom-div font-custom font-normal break-words text-white lg:text-[33px]/[45px] text-[16px]/[30px] "}>
                            {order.blank ? "" : (order.fullName || order.company) ? order.company : "LogoPark"}
                          </div>
                        </div>

                      </div>
                      <img
                        className={
                          (order.logoAdd
                            ? "block"
                            : order.blank
                              ? "hidden"
                              : "block") +
                          " z-50 absolute bottom-[10%] left-[68%] w-[48px]"
                        }
                        src={(colorToLabel(order.color, currency) === ColorLabels.Silver ? your_logo_here_silver : (colorToLabel(order.color, currency) === ColorLabels.Gold ? your_logo_here_gold : your_logo_here))}
                      />
                      <img
                        className="absolute z-0 max-w-[89%]"
                        src={(colorToLabel(order.color, currency) === ColorLabels.Silver ? mock_black_silver : (colorToLabel(order.color, currency) === ColorLabels.Gold ? mock_black_gold : mock_black))}
                        alt="preview of the resulting bcx card"
                      />

                    </div>

                    <h3 className="font-custom font-medium text-text text-[39px]/[53px] mb-[5px] m-0">
                      bcX BLACK
                    </h3>
                    <div className="flex mb-[7px]">
                      <p className="font-custom font-normal text-[36px]/[49px]">
                        ${cart.subtotal}
                      </p>
                      <p className="lg:hidden font-custom font-light text-[18px]/[25px] flex self-start pt-[5px] pl-[6px]">
                        {" "}
                        {currency}
                      </p>
                      <p className="hidden font-custom font-light text-[18px]/[25px] lg:flex self-start pt-[5px] pl-[6px]">
                        {" "}
                        {currency}
                      </p>
                    </div>
                    <h3 className="font-custom font-medium tracking-[0.05em] text-text text-[20px]/[32px] mb-[8px] ">
                      Add-Ons
                    </h3>
                    <div className="flex flex-col ml-[10px] mb-[33px]">
                      <div className="flex flex-row gap-[15px]">
                        <button
                          onClick={() =>
                          {
                            setLocalOrder({ ...order, logoAdd: false });
                          }}
                          className={order.logoAdd ? "" : "opacity-50"}
                        >
                          <img src={minus} />
                        </button>
                        <button
                          onClick={() =>
                          {
                            setLocalOrder({ ...order, logoAdd: true });
                          }}
                          className={order.logoAdd ? "opacity-50" : ""}
                        >
                          <img src={plus} />
                        </button>
                        <div className="font-custom text-text  font-light text-[20px]/[35px] tracking-[0.05em]">
                          Logo +$65
                        </div>
                      </div>
                      <div className="flex flex-row gap-[15px] mb-[34px]">
                        <button
                          onClick={() =>
                          {
                            if (order.extra - 1 >= 0)
                            {
                              setLocalOrder({
                                ...order,
                                extra: order.extra - 1,
                              });
                            }
                          }}
                          className={order.extra - 1 < 0 ? "opacity-50" : ""}
                        >
                          <img src={minus} />
                        </button>
                        <button
                          onClick={() =>
                          {
                            setLocalOrder({ ...order, extra: order.extra + 1 });
                          }}
                          className=""
                        >
                          <img src={plus} />
                        </button>
                        <div className="font-custom text-text  font-light text-[20px]/[35px] tracking-[0.05em]">
                          {" "}
                          {order.extra} Extra Card +$40
                        </div>
                      </div>
                      <div className="font-custom text-text  font-light text-[20px]/[35px] tracking-[0.05em] mb-[10px]">
                        {!order.blank ? "Name: " + order.fullName : ""}
                      </div>
                      <div className="font-custom text-text  font-light text-[20px]/[35px] tracking-[0.05em]">
                        {!order.blank ? "Company: " + order.company : ""}
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {order.type === PurchaseType.Elite ? (
                  <div>
                    <div className="relative flex items-start justify-start flex-shrink-0  lg:mb-[35px] mb-[31px]">
                      <img
                        className={
                          (order.blank ? "hidden" : "block") +
                          " absolute top-[32%] left-[67px] w-[75px]"
                        }
                        src={(colorToLabel(order.color, currency) === ColorLabels.Silver ? your_logo_here_silver : (colorToLabel(order.color, currency) === ColorLabels.Gold ? your_logo_here_gold : your_logo_here))}
                      />
                      <img
                        className="xl:max-w-[100%] lg:max-w-[75%] w-[350px] h-auto"
                        src={(colorToLabel(order.color, currency) === ColorLabels.Silver ? mock_elite_silver : (colorToLabel(order.color, currency) === ColorLabels.Gold ? mock_elite_gold : mock_elite))}
                      />
                    </div>
                    <h3 className="font-custom font-medium text-text text-[39px]/[53px] mb-[5px] m-0">
                      bcX ELITE
                    </h3>
                    <div className="flex mb-[20px] ">
                      <p className="font-custom font-normal text-[36px]/[49px]">
                        ${cart.subtotal}
                      </p>
                      <p className="lg:hidden font-custom font-light text-[18px]/[25px] flex self-start pt-[5px] pl-[6px]">
                        {" "}
                        {currency}
                      </p>
                      <p className="hidden font-custom font-light text-[18px]/[25px] lg:flex self-start pt-[5px] pl-[6px]">
                        {" "}
                        {currency}
                      </p>
                    </div>

                    <div className="flex flex-row gap-[15px] ml-[10px]">
                      <button
                        onClick={() =>
                        {
                          if (order.base - 1 >= minimum)
                          {
                            setLocalOrder({ ...order, base: order.base - 1 });
                          }
                        }}
                        className=""
                      >
                        <img src={minus} />
                      </button>
                      <button
                        onClick={() =>
                        {
                          setLocalOrder({ ...order, base: order.base + 1 });
                        }}
                        className=""
                      >
                        <img src={plus} />
                      </button>
                      <div className="font-custom text-text  font-light text-[16px]/[32px] tracking-[0.05em]">
                        {order.base} Cards
                      </div>
                    </div>
                    <div className="mb-[10px]">
                      <h3 className="font-custom font-medium tracking-[0.05em] text-text text-[20px]/[32px] mb-[8px] ">
                        Distribution of Cards
                      </h3>
                      <div className="flex flex-col gap-[8px] ml-[10px]">
                        <div className="flex flex-row items-center gap-[16px] font-custom text-text  font-light text-[16px]/[32px] tracking-[0.05em]">
                          <button
                            onClick={() =>
                            {
                              if (order.nonPersonalized - 1 >= 0)
                              {
                                setErrors({
                                  ...errors,
                                  pcards: [
                                    ...errors.pcards,
                                    {
                                      fullName: "",
                                    } as PCard,
                                  ],
                                });
                                setLocalOrder({
                                  ...order,
                                  personalized: order.personalized + 1,
                                  nonPersonalized: order.nonPersonalized - 1,
                                  pCard: [
                                    ...order.pCard,
                                    {
                                      fullName: "",
                                      orderNumber: "",
                                      qr: "",
                                      specialInstructions: "",
                                    },
                                  ],
                                });
                              }
                            }}
                            className={
                              order.nonPersonalized - 1 < 0 ? "opacity-50" : ""
                            }
                          >
                            <img src={arrow_button} />
                          </button>
                          {order.personalized} Personalized Cards
                        </div>
                        <div className="flex flex-row items-center gap-[16px] font-custom text-text font-light text-[16px]/[32px] tracking-[0.05em]">
                          <button
                            onClick={() =>
                            {
                              if (order.personalized - 1 >= 0)
                              {
                                setLocalOrder({
                                  ...order,
                                  nonPersonalized: order.nonPersonalized + 1,
                                  personalized: order.personalized - 1,
                                  pCard: order.pCard.filter(
                                    (e: PCard, i: number) =>
                                      i !== order.pCard.length - 1
                                  ),
                                });
                              }
                            }}
                            className={
                              order.personalized - 1 < 0 ? "opacity-50" : ""
                            }
                          >
                            <img className="rotate-180" src={arrow_button} />
                          </button>
                          <div className="">
                            {order.nonPersonalized} Non-Personalized Cards
                          </div>
                        </div>
                      </div>
                    </div>

                    <h3 className="font-custom font-medium tracking-[0.05em] text-text text-[20px]/[32px] mb-[8px] ">
                      Add-Ons
                    </h3>
                    <div className="flex flex-col ml-[10px] mb-[19px]">
                      <div className="flex flex-row gap-[15px] mb-[30px]">
                        <button
                          onClick={() =>
                          {
                            if (order.extra - 1 >= 0)
                            {
                              setLocalOrder({
                                ...order,
                                extra: order.extra - 1,
                              });
                            }
                          }}
                          className={order.extra - 1 < 0 ? "opacity-50" : ""}
                        >
                          <img src={minus} />
                        </button>
                        <button
                          onClick={() =>
                          {
                            setLocalOrder({ ...order, extra: order.extra + 1 });
                          }}
                          className=""
                        >
                          <img src={plus} />
                        </button>
                        <div className="font-custom text-text  font-light text-[16px]/[32px] tracking-[0.05em]">
                          {" "}
                          {order.extra} Extra Card +$40
                        </div>
                      </div>

                      <div className="font-custom text-text  font-light text-[20px]/[35px] tracking-[0.05em]">
                        {!order.blank ? "Company: " + order.company : ""}
                      </div>
                      <div>
                        {order.pCard.map((e: PCard, i: number) => (
                          <div className="mb-[13px]" key={(i + 1).toString()}>
                            <CAccordion
                              expanded={i === 0}
                              iconangle={["0", "0"]}
                              summary={"Personalized Card #" + (i + 1)}
                              iconcolor="#FBBC05"
                              radius="4px"
                              bgcolor="#fff"
                              txcolor="#000"
                              fontheight="16px"
                              fontsize="14px"
                              fontweight="300"
                              shadow="0px 2px 6px rgba(0, 0, 0, 0.14);"
                            >
                              <CTextField
                                error={errors.pcards[i].fullName}
                                onChange={(a) =>
                                {
                                  setLocalOrder({
                                    ...order,
                                    pCard: [
                                      ...order.pCard.slice(0, i),
                                      { ...e, fullName: a },
                                      ...order.pCard.slice(i + 1),
                                    ],
                                  });
                                }}
                                value={e.fullName}
                                bordercolor="#FBBC05"
                                classname="mb-[22px]"
                                label="Full Name"
                              />
                            </CAccordion>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {order.type === PurchaseType.ReplacementBlack ? (
                  <div className="flex flex-col w-full h-full">
                    <div className="relative h-[178px] flex items-start justify-start flex-shrink-0 w-[350px]  lg:mb-[35px] mb-[25px]">
                      <div className="absolute z-50 top-0 left-0 flex flex-col lg:gap-[16px] gap-[5px] lg:ml-[43px] ml-[23px] lg:mt-[31px] mt-[18px]">
                        <div className={"flex flex-row w-full   items-start  "}>
                          <div className={(colorToLabel(order.color, currency) === ColorLabels.Silver ? 'silver-gradient' : '') + (colorToLabel(order.color, currency) === ColorLabels.Gold ? 'gold-gradient' : '') + " h-fit lg:max-w-[423px] max-w-[220px] overflow-hidden lg:text-[56px]/[76px] text-[28px]/[38px] custom-div font-custom font-medium  break-words text-white "}>
                            {order.blank ? "" : (order.fullName || order.company) ? order.fullName : "John Doe"}
                          </div>
                        </div>
                        <div className={" flex flex-row w-full   items-start"}>
                          <div className={(colorToLabel(order.color, currency) === ColorLabels.Silver ? 'silver-gradient' : '') + (colorToLabel(order.color, currency) === ColorLabels.Gold ? 'gold-gradient' : '') + " h-fit lg:max-w-[388px] max-w-[195px] overflow-hidden custom-div font-custom font-normal break-words text-white lg:text-[33px]/[45px] text-[16px]/[30px] "}>
                            {order.blank ? "" : (order.fullName || order.company) ? order.company : "LogoPark"}
                          </div>
                        </div>

                      </div>
                      <img
                        className={
                          (order.logoAdd
                            ? "block"
                            : order.blank
                              ? "hidden"
                              : "block") +
                          " z-50 absolute bottom-[10%] left-[68%] w-[48px]"
                        }
                        src={(colorToLabel(order.color, currency) === ColorLabels.Silver ? your_logo_here_silver : (colorToLabel(order.color, currency) === ColorLabels.Gold ? your_logo_here_gold : your_logo_here))}
                      />
                      <img
                        className="absolute z-0 max-w-[89%]"
                        src={(colorToLabel(order.color, currency) === ColorLabels.Silver ? mock_black_silver : (colorToLabel(order.color, currency) === ColorLabels.Gold ? mock_black_gold : mock_black))}
                        alt="preview of the resulting bcx card"
                      />

                    </div>

                    <h3 className="font-custom font-medium text-text text-[39px]/[53px] mb-[5px] m-0">
                      bcX BLACK Replacement
                    </h3>
                    <div className="flex mb-[7px]">
                      <p className="font-custom font-normal text-[36px]/[49px]">
                        ${cart.subtotal}
                      </p>
                      <p className="lg:hidden font-custom font-light text-[18px]/[25px] flex self-start pt-[5px] pl-[6px]">
                        {" "}
                        {currency}
                      </p>
                      <p className="hidden font-custom font-light text-[18px]/[25px] lg:flex self-start pt-[5px] pl-[6px]">
                        {" "}
                        {currency}
                      </p>
                    </div>

                    <div className="flex flex-col ml-[10px] mb-[33px]">
                      <div className="font-custom text-text  font-light text-[20px]/[35px] tracking-[0.05em] mb-[10px]">
                        {!order.blank ? "Name: " + order.fullName : ""}
                      </div>
                      <div className="font-custom text-text  font-light text-[20px]/[35px] tracking-[0.05em]">
                        {!order.blank ? "Company: " + order.company : ""}
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {order.type === PurchaseType.ReplacementElite ? (
                  <div>
                    <div className="relative flex items-start justify-start flex-shrink-0  lg:mb-[35px] mb-[31px]">
                      <img
                        className={
                          (order.blank ? "hidden" : "block") +
                          " absolute top-[32%] left-[67px] w-[75px]"
                        }
                        src={(colorToLabel(order.color, currency) === ColorLabels.Silver ? your_logo_here_silver : (colorToLabel(order.color, currency) === ColorLabels.Gold ? your_logo_here_gold : your_logo_here))}
                      />
                      <img
                        className="xl:max-w-[100%] lg:max-w-[75%] w-[350px] h-auto"
                        src={(colorToLabel(order.color, currency) === ColorLabels.Silver ? mock_elite_silver : (colorToLabel(order.color, currency) === ColorLabels.Gold ? mock_elite_gold : mock_elite))}
                      />
                    </div>
                    <h3 className="font-custom font-medium text-text text-[39px]/[53px] mb-[5px] m-0">
                      bcX ELITE Replacement
                    </h3>
                    <div className="flex mb-[20px] ">
                      <p className="font-custom font-normal text-[36px]/[49px]">
                        ${cart.subtotal}
                      </p>
                      <p className="lg:hidden font-custom font-light text-[18px]/[25px] flex self-start pt-[5px] pl-[6px]">
                        {" "}
                        {currency}
                      </p>
                      <p className="hidden font-custom font-light text-[18px]/[25px] lg:flex self-start pt-[5px] pl-[6px]">
                        {" "}
                        {currency}
                      </p>
                    </div>
                    <div className="flex flex-col ml-[0px] mb-[19px]">
                      <div className="font-custom text-text font-light text-[20px]/[35px] tracking-[0.05em]">
                        {!order.blank ? "Company: " + order.company : ""}
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {order.type === PurchaseType.NewMember ? (
                  <div>
                    <div className="relative flex items-start justify-start flex-shrink-0  lg:mb-[35px] mb-[31px]">
                      <img
                        className={
                          (order.blank ? "hidden" : "block") +
                          " absolute top-[32%] left-[67px] w-[75px]"
                        }
                        src={(colorToLabel(order.color, currency) === ColorLabels.Silver ? your_logo_here_silver : (colorToLabel(order.color, currency) === ColorLabels.Gold ? your_logo_here_gold : your_logo_here))}
                      />
                      <img
                        className="xl:max-w-[100%] lg:max-w-[75%] w-[350px] h-auto"
                        src={(colorToLabel(order.color, currency) === ColorLabels.Silver ? mock_elite_silver : (colorToLabel(order.color, currency) === ColorLabels.Gold ? mock_elite_gold : mock_elite))}
                      />
                    </div>
                    <h3 className="font-custom font-medium text-text text-[39px]/[53px] mb-[5px] m-0">
                      bcX ELITE<br />New Member
                    </h3>
                    <div className="flex mb-[20px] ">
                      <p className="font-custom font-normal text-[36px]/[49px]">
                        ${cart.subtotal}
                      </p>
                      <p className="lg:hidden font-custom font-light text-[18px]/[25px] flex self-start pt-[5px] pl-[6px]">
                        {" "}
                        {currency}
                      </p>
                      <p className="hidden font-custom font-light text-[18px]/[25px] lg:flex self-start pt-[5px] pl-[6px]">
                        {" "}
                        {currency}
                      </p>
                    </div>

                    <div className="flex flex-row gap-[15px] ml-[10px]">
                      <button
                        onClick={() =>
                        {
                          if (order.base - 1 >= minimum)
                          {
                            setLocalOrder({ ...order, base: order.base - 1 });
                          }
                        }}
                        className=""
                      >
                        <img src={minus} />
                      </button>
                      <button
                        onClick={() =>
                        {
                          setLocalOrder({ ...order, base: order.base + 1 });
                        }}
                        className=""
                      >
                        <img src={plus} />
                      </button>
                      <div className="font-custom text-text  font-light text-[16px]/[32px] tracking-[0.05em]">
                        {order.base} Cards
                      </div>
                    </div>
                    <div className="mb-[10px]">
                      <h3 className="font-custom font-medium tracking-[0.05em] text-text text-[20px]/[32px] mb-[8px] ">
                        Distribution of Cards
                      </h3>
                      <div className="flex flex-col gap-[8px] ml-[10px]">
                        <div className="flex flex-row items-center gap-[16px] font-custom text-text  font-light text-[16px]/[32px] tracking-[0.05em]">
                          <button
                            onClick={() =>
                            {
                              if (order.nonPersonalized - 1 >= 0)
                              {
                                setErrors({
                                  ...errors,
                                  pcards: [
                                    ...errors.pcards,
                                    {
                                      fullName: "",
                                    } as PCard,
                                  ],
                                });
                                setLocalOrder({
                                  ...order,
                                  personalized: order.personalized + 1,
                                  nonPersonalized: order.nonPersonalized - 1,
                                  pCard: [
                                    ...order.pCard,
                                    {
                                      fullName: "",
                                      orderNumber: "",
                                      qr: "",
                                      specialInstructions: "",
                                    },
                                  ],
                                });
                              }
                            }}
                            className={
                              order.nonPersonalized - 1 < 0 ? "opacity-50" : ""
                            }
                          >
                            <img src={arrow_button} />
                          </button>
                          {order.personalized} Personalized Cards
                        </div>
                        <div className="flex flex-row items-center gap-[16px] font-custom text-text font-light text-[16px]/[32px] tracking-[0.05em]">
                          <button
                            onClick={() =>
                            {
                              if (order.personalized - 1 >= 0)
                              {
                                setLocalOrder({
                                  ...order,
                                  nonPersonalized: order.nonPersonalized + 1,
                                  personalized: order.personalized - 1,
                                  pCard: order.pCard.filter(
                                    (e: PCard, i: number) =>
                                      i !== order.pCard.length - 1
                                  ),
                                });
                              }
                            }}
                            className={
                              order.personalized - 1 < 0 ? "opacity-50" : ""
                            }
                          >
                            <img className="rotate-180" src={arrow_button} />
                          </button>
                          <div className="">
                            {order.nonPersonalized} Non-Personalized Cards
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="flex flex-col ml-[10px] mb-[19px]">


                      <div className="font-custom text-text  font-light text-[20px]/[35px] tracking-[0.05em]">
                        {!order.blank ? "Company: " + order.company : ""}
                      </div>
                      <div>
                        {order.pCard.map((e: PCard, i: number) => (
                          <div className="mb-[13px]" key={(i + 1).toString()}>
                            <CAccordion
                              expanded={i === 0}
                              iconangle={["0", "0"]}
                              summary={"Personalized Card #" + (i + 1)}
                              iconcolor="#FBBC05"
                              radius="4px"
                              bgcolor="#fff"
                              txcolor="#000"
                              fontheight="16px"
                              fontsize="14px"
                              fontweight="300"
                              shadow="0px 2px 6px rgba(0, 0, 0, 0.14);"
                            >
                              <CTextField
                                error={errors.pcards[i].fullName}
                                onChange={(a) =>
                                {
                                  setLocalOrder({
                                    ...order,
                                    pCard: [
                                      ...order.pCard.slice(0, i),
                                      { ...e, fullName: a },
                                      ...order.pCard.slice(i + 1),
                                    ],
                                  });
                                }}
                                value={e.fullName}
                                bordercolor="#FBBC05"
                                classname="mb-[22px]"
                                label="Full Name"
                              />
                            </CAccordion>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </CAccordion>
          </div>
          <div className="mb-[10px]">
            <CAccordion
              summary="Discount Codes"
              iconangle={["0", "0"]}
              iconcolor="#000"
              radius="0"
              bgcolor="#fff"
              txcolor="#000"
              fontheight="20px"
              fontsize="14px"
              fontweight="700"
              shadow="0px 2px 6px rgba(0, 0, 0, 0.14);">

              <DiscountComp percent={discount.percentDiscount ?? 0} code={discount.code} onClick={checkDiscount} error={discountError} loading={discountLoad} />

            </CAccordion>
          </div>
          <div className="mb-[10px] shadow-custom">
            <div>
              <CAccordion
                summary="Billing Summary"
                expanded
                iconangle={["0", "0"]}
                iconcolor="#000"
                radius="0"
                bgcolor="#fff"
                txcolor="#000"
                fontheight="20px"
                fontsize="14px"
                fontweight="700"
              >
                <div className="flex flex-col">
                  <div className="flex flex-row justify-between font-custom text-[14px]/[20px] font-normal text-text mb-[12px]">
                    <div>Subtotal</div>
                    <div>${cart.subtotal}</div>
                  </div>
                  {!isNaN(discount.percentDiscount) && <div className="flex flex-row justify-between font-custom text-[14px]/[20px] font-semibold text-discount mb-[12px]">
                    <div>Discount</div>
                    <div>-${cart.discount.totalDiscounted}</div>
                  </div>}
                  <div className="flex flex-row justify-between font-custom text-[14px]/[20px] font-normal text-text mb-[12px]">
                    <div>Shipping</div>
                    <div>${"0"}</div>
                  </div>

                  <div className="flex flex-row justify-between font-custom text-[14px]/[20px] font-normal text-text ">
                    <div>Tax</div>
                    <div>
                      $
                      {cart.tax.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </div>
                  </div>
                </div>
              </CAccordion>
            </div>
            <div className="mx-[18px]">
              <div className="border-b-[1px] border-gray-300 mb-[18px]" />
              <div className="flex flex-row justify-between font-custom text-[16px]/[20px] font-bold text-text mb-[26px]">
                <div>Grand Total</div>
                <div>${cart.total}</div>
              </div>
              <div className="flex flex-col mb-[29px] gap-[5px]">
                <div className="flex flex-row justify-start mb-[20px]">
                  <CCheckBox
                    checked={consent}
                    onCheck={(a: boolean) =>
                    {
                      setConsent(a);
                    }}
                    classname=""
                    label=""
                  />
                  <div className="font-custom text-[12px]/[20px] font-normal text-check ml-[-15px] mt-[10px]">
                    Please check to acknowledge our{" "}
                    <Link
                      className="text-blue-500"
                      to="/terms-of-use"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy & Terms Policy
                    </Link>
                  </div>
                </div>
                <div className={consentError ? "block" : "hidden"}>
                  <Alert severity="warning">{consentError}</Alert>
                </div>
              </div>
              <button
                id='pay'
                className="mb-[34px] font-custom font-bold text-button shadow-custom w-full text-[16px]/[20px] text-center hover:bg-secondary bg-primary rounded-[33px]  pt-[12px] pb-[14px]"
                onClick={() =>
                {
                  handleSubmit();
                }}
              >
                Pay ${cart.total}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </>
  );
};

