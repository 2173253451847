import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../components/Navbar";
import square_right from "../../assets/images/login-right-squares.svg";
import CTextField from "../../components/CTextField";
import { useNavigate } from "react-router-dom";
import { auth1, db } from "../../services/firebase";
import
{
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { Card, EliteCompany } from "../../types";
import { getOrderType } from "../Cart";
import { PurchaseType } from "../../redux/feature/cartSlice";
import { CircularProgress } from "@mui/material";
import { cn } from "../../utils/utils";

export const OrderNumberValidation = () =>
{
  const navigate = useNavigate();
  const [orderNumber, setOrderNumber] = useState("");
  const [loading, setLoading] = useState(false)
  const inputElement = useRef<HTMLInputElement>(null);

  useEffect(() =>
  {
    if (inputElement.current)
    {
      inputElement.current.onfocus = () =>
      {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
      };
    }
  });
  async function handleSubmit()
  {
    setLoading(true)
    const cardsQuery = query(
      collection(db, "cards"),
      where("orderNumber", "==", orderNumber)
    );
    const cardQuerySnapshot = await getDocs(cardsQuery);
    let cards = cardQuerySnapshot.docs[0]?.data() as Card;
    if (cards === undefined)
    {
      alert("This order number doesn't exist");
      setLoading(false)
    } else
    {
      const q = query(
        collection(db, "users"),
        where("orderNumber", "==", orderNumber)
      );
      const querySnapshot = await getDocs(q);
      let data = querySnapshot.docs[0]?.data();

      if (data === undefined)
      {
        if (cards.type === getOrderType(PurchaseType.Elite) || cards.type === getOrderType(PurchaseType.NewMember))
        {

          const elite = await getDoc(cards.eliteCompanyRef);

          const eliteCompany = elite.data() as EliteCompany;
          if (
            eliteCompany.adminCards !== undefined &&
            eliteCompany.adminCards.length > 0
          )
          {

            const authUserRef = doc(
              db,
              "users",
              auth1.currentUser?.uid!
            );

            const activeCards = [...eliteCompany.activeCards];

            if (activeCards.indexOf(orderNumber) === -1)
            {
              activeCards.push(orderNumber);
            }


            await updateDoc(elite.ref, {
              activeCards: activeCards
            })

            await updateDoc(authUserRef, {
              orderNumber: orderNumber,
              eliteCompany: elite.ref,
            })
              .then((re) =>
              {
                navigate("/onboarding");
                setLoading(false)
              })
          } else
          {
            const activeCards = [];
            activeCards.push(orderNumber);
            await updateDoc(elite.ref, {
              activeCards: activeCards,
              adminCards: activeCards
            })
            const authUserRef = doc(
              db,
              "users",
              auth1.currentUser?.uid!
            );
            await updateDoc(authUserRef, {
              orderNumber: orderNumber,
              eliteAdmin: true,
              editingDisabled: false,
              eliteCompany: elite.ref,
            })
              .then((re) =>
              {

                navigate("/onboarding");
                setLoading(false)
              })
          }
        } else
        {

          const authUserRef = doc(
            db,
            "users",
            auth1.currentUser?.uid!
          );
          await updateDoc(authUserRef, {
            orderNumber: orderNumber,
          })
            .then((re) =>
            {
              navigate("/onboarding");
              setLoading(false)
            })
        }
      } else
      {
        alert("This order number it's already in use");
        setLoading(false)
      }
    }

  }

  return (
    <div className="">
      <Navbar classname="lg:hidden block mb-[21px]" hidecurr alternate />
      <div className="flex lg:flex-row flex-col justify-between overflow-hidden">
        <div className="lg:flex flex-col  hidden ">
          <img className="w-auto h-full rotate-180" src={square_right} />
        </div>

        <div className="flex flex-col mb-[66px]  ">
          <Navbar classname="lg:block hidden self-center mb-[40px]" alternate />
          <div className="self-center flex flex-col justify-center">
            <h2 className="lg:block hidden font-custom font-medium self-center text-center text-[57px]/[77px] mb-[20px] ">
              Before We Get Started
            </h2>
            <h2 className="lg:hidden block font-custom font-medium self-center text-center text-[30px]/[41px] mb-[34px]">
              Hi! Welcome!
            </h2>

            <h4 className="w-[303px] self-center font-custom font-light text-[18px]/[24px]  lg:tracking-normal tracking-[0.05em] lg:text-left mb-[31px]">
              To sign up you must first purchase a card. Once you complete your
              order, we will provide an order number that you can sign up with
              here.
            </h4>
            <CTextField
              label="Order Number"
              w="w-[354px]"
              disabled={loading}
              innerRef={inputElement}
              classname="self-center mb-[30px]"
              value={orderNumber}
              onChange={(e) =>
              {
                setOrderNumber(e);
              }}
            />
            <button
              className={cn("lg:w-auto w-[343px] relative font-custom font-medium text-white shadow-custom lg:text-[20px]/[27px] text-[16px]/[25px] text-center tracking-[0.05em] hover:bg-inset bg-button rounded-[26px]  mx-[122px] lg:pt-[10px] pt-[12px] lg:pb-[14px] pb-[12px] mb-[41px]",
                { 'hover:bg-button opacity-75': loading })}
              onClick={async () => await handleSubmit()}
              disabled={loading}>
              <div className="flex flex-row items-center justify-center">
                Submit
                <div className={cn("absolute mr-[35px] mt-[5px] right-0", { 'hidden': !loading })}>
                  <CircularProgress color="inherit" size={20} />
                </div>
              </div>
            </button>
            <h2 className="w-[262px] self-center font-custom font-medium text-[30px]/[41px] text-center mb-[31px]">
              Haven't Purchased A Card Yet?
            </h2>
            <button
              className="lg:w-auto w-[343px] font-custom font-medium text-white shadow-custom lg:text-[20px]/[27px] text-[16px]/[25px] text-center tracking-[0.05em] hover:bg-inset bg-button rounded-[26px]  mx-[122px] lg:pt-[10px] pt-[12px] lg:pb-[14px] pb-[12px] mb-[41px]"
              onClick={() =>
              {
                navigate("/shop");
              }}
            >
              Go To Shop
            </button>

            <h2 className=" self-center font-custom  font-medium text-[30px]/[41px] lg:tracking-normal tracking-[0.05em] lg:text-center mb-[31px]">
              Have another Account?
            </h2>

            <button
              className=" w-[343px] self-center font-custom font-medium text-white shadow-custom text-[20px]/[27px] text-center tracking-[0.05em] hover:bg-inset bg-button rounded-[26px]  lg:mx-[122px] lg:pt-[10px] pt-[12px] lg:pb-[14px] pb-[12px]"
              onClick={async () =>
              {
                await auth1.signOut();
                navigate("/login");
              }}
            >
              Login to a different account
            </button>
          </div>
        </div>

        <div className=" lg:flex flex-col hidden  ">
          <img className=" h-full" src={square_right} />
        </div>
      </div>
    </div>
  );
};
