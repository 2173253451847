import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home } from "../pages/Home";
import { Login } from "../pages/Login";
import { Profile } from "../pages/Profile";
import { About } from "../pages/About";
import { AdminView } from "../pages/AdminView";
import { BlackCard } from "../pages/BlackCard";
import { Cart } from "../pages/Cart";
import { ChangePassword } from "../pages/ChangePassword";
import { ConfirmOrder } from "../pages/ConfirmOrder";
import { Contact } from "../pages/Contact";
import { SignUp } from "../pages/SignUp";
import { EditProfile, Role } from "../pages/EditProfile";
import { EliteCard } from "../pages/EliteCard";
import { Onboarding } from "../pages/Onboarding";
import { Onboarding2 } from "../pages/Onboarding2";
import { Onboarding3 } from "../pages/Onboarding3";
import { Onboarding4 } from "../pages/Onboarding4";
import { Onboarding5 } from "../pages/Onboarding5";
import { Shop } from "../pages/Shop";
import { SignUpSuccessfully } from "../pages/SignUpSuccessfully";
import { EmptyCart } from "../pages/EmptyCart";
import { ProfileChangePassword } from "../pages/ProfileChangePassword";
import { SubmitMessage } from "../pages/SubmitMessage";
import { PrivateRoute } from "../components/PrivateRoute";
import { LoggedOutOnly } from "../components/LoggedOutOnly";
import { NotFound } from "../pages/NotFound";
import { TermsOfUse } from "../pages/TermsOfUse";
import { OrderNumberValidation } from "../pages/OrderNumberValidation";
import { CheckOrder } from "../components/CheckOrder";
import { DeleteAccount } from "../pages/DeleteAccount";
import CheckoutPage from "../pages/Checkout";
import { UserNotSetUp } from "../pages/UserNotSetUp";
import { UserNotFound } from "../pages/UserNotFound";
import { ReplacementCard } from "../pages/ReplacementCard";
import { PurchaseType } from "../redux/feature/cartSlice";
import { Onboarding6 } from "../pages/onboarding6";
import Dashboard from "../pages/Dashboard";
import { DashboardCheck } from "../components/DashboardCheck";
import { Tracker } from "../components/Tracker";
import { ContactList } from "../pages/ContactList";
import { Metrics } from "../pages/Metrics";


export const AppRoutes = () =>
{


  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Tracker title='Home' ><Home /></Tracker>} />
        <Route path="/about" element={<Tracker title='About'><About /></Tracker>} />
        <Route path="/adminview" element={<Tracker title='Admin View'><PrivateRoute><AdminView /></PrivateRoute></Tracker>} />
        <Route path="/admin-view" element={<Tracker title='Admin View'><PrivateRoute><AdminView /></PrivateRoute></Tracker>} />
        <Route path="/admineditprofile" element={<Tracker title='Admin Edit Profile'><PrivateRoute><EditProfile type={Role.Elite} /></PrivateRoute></Tracker>} />
        <Route path="/admin-edit-profile" element={<Tracker title='Admin Edit Profile'><PrivateRoute><EditProfile type={Role.Elite} /></PrivateRoute></Tracker>} />
        <Route path="/blackcard" element={<Tracker title='Shop Black Card'><BlackCard /></Tracker>} />
        <Route path="/dashboard" element={<Tracker title='Dashboard'><DashboardCheck><PrivateRoute><Dashboard /></PrivateRoute></DashboardCheck></Tracker>} />
        <Route path="/replacement-blackcard" element={<Tracker title='Shop Replacement Black'><PrivateRoute><ReplacementCard type={PurchaseType.ReplacementBlack} /></PrivateRoute></Tracker>} />
        <Route path="/cart" element={<Tracker title='Cart'><Cart /></Tracker>} />
        <Route path="/emptycart" element={<Tracker title='Empty Cart'><EmptyCart /></Tracker>} />
        <Route path="/empty-cart" element={<Tracker title='Empty Cart'><EmptyCart /></Tracker>} />
        <Route path="/changepassword" element={<Tracker title='Change Password'><ChangePassword /></Tracker>} />
        <Route path="/change-password" element={<Tracker title='Change Password'><ChangePassword /></Tracker>} />
        <Route path="/checkout" element={<Tracker title='Checkout'><CheckoutPage /></Tracker>} />
        <Route path="/profilechangepassword" element={<Tracker title='Profile Change Password'><PrivateRoute><CheckOrder><ProfileChangePassword /></CheckOrder></PrivateRoute></Tracker>} />
        <Route path="/profile-change-password" element={<Tracker title='Profile Change Password'><PrivateRoute><CheckOrder><ProfileChangePassword /></CheckOrder></PrivateRoute></Tracker>} />
        <Route path="/contact-list" element={<Tracker title='Contact List'><PrivateRoute><CheckOrder><ContactList /></CheckOrder> </PrivateRoute></Tracker>} />
        <Route path="/confirmorder" element={<Tracker title='Confirm Order'><ConfirmOrder /></Tracker>} />
        <Route path="/confirm-order" element={<Tracker title='Confirm Order'><ConfirmOrder /></Tracker>} />
        <Route path="/contact" element={<Tracker title='Contact'><Contact /></Tracker>} />
        <Route path="/editprofile" element={<Tracker title='Edit Profile'><PrivateRoute><CheckOrder><EditProfile type={Role.Black} /></CheckOrder></PrivateRoute></Tracker>} />
        <Route path="/edit-profile" element={<Tracker title='Edit Profile'><PrivateRoute><CheckOrder><EditProfile type={Role.Black} /></CheckOrder></PrivateRoute></Tracker>} />
        <Route path="/metrics" element={<Tracker title='Profile Metrics'><PrivateRoute><CheckOrder><Metrics /></CheckOrder></PrivateRoute></Tracker>} />
        <Route path="/elitecard" element={<Tracker title='Shop Elite Card'><EliteCard /></Tracker>} />
        <Route path="/replacement-elitecard" element={<Tracker title='Shop Replacement Elite'><PrivateRoute><ReplacementCard type={PurchaseType.ReplacementElite} /></PrivateRoute></Tracker>} />
        <Route path="/new-member" element={<Tracker title='Shop New Member'><PrivateRoute><ReplacementCard type={PurchaseType.NewMember} /></PrivateRoute></Tracker>} />
        <Route path="/replacement-cart" element={<Tracker title='Replacement Cart'><PrivateRoute><Cart /></PrivateRoute></Tracker>} />
        <Route path="/replacement-checkout" element={<Tracker title='Replacement Checkout'><PrivateRoute><CheckoutPage /></PrivateRoute></Tracker>} />
        <Route path="/login" element={<Tracker title='Login'><LoggedOutOnly><Login /></LoggedOutOnly></Tracker>} />
        <Route path="/onboarding" element={<Tracker title='Onboarding'><PrivateRoute><CheckOrder><Onboarding /></CheckOrder></PrivateRoute></Tracker>} />
        <Route path="/onboarding2" element={<Tracker title='Onboarding 2'><PrivateRoute><CheckOrder><Onboarding2 /></CheckOrder></PrivateRoute></Tracker>} />
        <Route path="/onboarding3" element={<Tracker title='Onboarding 3'><PrivateRoute><CheckOrder><Onboarding3 /></CheckOrder></PrivateRoute></Tracker>} />
        <Route path="/onboarding4" element={<Tracker title='Onboarding 4'><PrivateRoute><CheckOrder><Onboarding4 /></CheckOrder></PrivateRoute></Tracker>} />
        <Route path="/onboarding5" element={<Tracker title='Onboarding 5'><PrivateRoute><CheckOrder><Onboarding5 /></CheckOrder></PrivateRoute></Tracker>} />
        <Route path="/onboarding6" element={<Tracker title='Onboarding 6'><PrivateRoute><CheckOrder><Onboarding6 /></CheckOrder></PrivateRoute></Tracker>} />
        <Route path="/onboarding-2" element={<Tracker title='Onboarding 2'><PrivateRoute><CheckOrder><Onboarding2 /></CheckOrder></PrivateRoute></Tracker>} />
        <Route path="/onboarding-3" element={<Tracker title='Onboarding 3'><PrivateRoute><CheckOrder><Onboarding3 /></CheckOrder></PrivateRoute></Tracker>} />
        <Route path="/onboarding-4" element={<Tracker title='Onboarding 4'><PrivateRoute><CheckOrder><Onboarding4 /></CheckOrder></PrivateRoute></Tracker>} />
        <Route path="/onboarding-5" element={<Tracker title='Onboarding 5'><PrivateRoute><CheckOrder><Onboarding5 /></CheckOrder></PrivateRoute></Tracker>} />
        <Route path="/onboarding-6" element={<Tracker title='Onboarding 6'><PrivateRoute><CheckOrder><Onboarding6 /></CheckOrder></PrivateRoute></Tracker>} />
        <Route path="/ordernumbervalidation" element={<Tracker title='Validate Order Number'><PrivateRoute><OrderNumberValidation /></PrivateRoute></Tracker>} />
        <Route path="/order-number-validation" element={<Tracker title='Validate Order Number'><PrivateRoute><OrderNumberValidation /></PrivateRoute></Tracker>} />
        <Route path="/profile" element={<Tracker title='Profile'><PrivateRoute> <CheckOrder><Profile /></CheckOrder></PrivateRoute></Tracker>} />
        <Route path="/profile/:param" element={<Tracker title='Profile'><Profile /></Tracker>} />
        <Route path="/shop" element={<Tracker title='Shop'><Shop /></Tracker>} />
        <Route path="/signup" element={<Tracker title='Sign Up'><LoggedOutOnly><SignUp /></LoggedOutOnly></Tracker>} />
        <Route path="/signupnew" element={<Tracker title='Sign Up'><LoggedOutOnly><SignUp /></LoggedOutOnly></Tracker>} />
        <Route path="/signup-new" element={<Tracker title='Sign Up'><LoggedOutOnly><SignUp /></LoggedOutOnly></Tracker>} />
        <Route path="/signupsuccessfully" element={<Tracker title='Sign Up Successful'><SignUpSuccessfully /></Tracker>} />
        <Route path="/submitsuccessfully" element={<Tracker title='Contact Email Successful'> <SubmitMessage /></Tracker>} />
        <Route path="/signup-successfully" element={<Tracker title='Sign Up Successful'><SignUpSuccessfully /></Tracker>} />
        <Route path="/submit-successfully" element={<Tracker title='Contact Email Successful'><SubmitMessage /></Tracker>} />
        <Route path="/terms-of-use" element={<Tracker title='Terms Of Use'><TermsOfUse /></Tracker>} />
        <Route path="/privacy-policy" element={<Tracker title='Privacy Policy'><TermsOfUse /></Tracker>} />
        <Route path="/delete-account" element={<Tracker title='Delete Account'><PrivateRoute><DeleteAccount /></PrivateRoute></Tracker>} />
        <Route path="/user-not-set-up" element={<Tracker title='User Not Set Up'><PrivateRoute><CheckOrder><UserNotSetUp /></CheckOrder></PrivateRoute></Tracker>} />
        <Route path="/user-not-found" element={<Tracker title='User Not Found'><UserNotFound /></Tracker>} />
        <Route path="/user-not-found/:param" element={<Tracker title='User Not Found'><UserNotFound /></Tracker>} />
        <Route path="*" element={<Tracker title='Page Not Found'><NotFound /></Tracker>} />
      </Routes>
    </BrowserRouter>
  );
};
