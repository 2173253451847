import { useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { EliteCompany } from "../types";
import { useNavigate } from "react-router-dom";

const functions = getFunctions();
const createUser = httpsCallable(functions, "createUser");

interface ToggleCardProps {
  open?: boolean;
  cardnumber?: number;
  orderNumber?: string;
  eliteCompanyDoc?: EliteCompany;
  cardName?: string;
  callback: Function;
}

export const ToggleCard = ({ ...props }: ToggleCardProps) => {
  const [toggle, setToggle] = useState(props.open);
  const navigate = useNavigate();
  return (
    <div className="flex flex-col rounded-[10px] lg:w-[320px] overflow-clip shadow-custom">
      <div className="flex flex-row lg:h-[38px] h-[43px] ">
        <div className="basis-3/4 bg-inset ">
          <p className="ml-[11px] font-custom font-black text-button text-left text-[16px]/[25px] lg:pt-[6px] pt-[9px] truncate lg:w-[240px] w-[280px]">
            Card {props.cardnumber}{" "}
            {props.cardName ? `(${props.cardName})` : ""}
          </p>
        </div>
        <div className="basis-1/4 flex flex-row justify-center items-center">
          <button
            className={
              
              " w-full h-full flex flex-row justify-center items-center " + ((props.eliteCompanyDoc?.whiteArrows && props.eliteCompanyDoc?.useCustomColors && !toggle) ? " text-white " : " text-button ") + 
              (toggle ? " bg-white text-button " : " bg-primary ") +
              ((!props.eliteCompanyDoc?.useCustomColors && toggle) ? " text-primary " : "") + 
              ((!props.eliteCompanyDoc?.useCustomColors && !toggle) ? " text-white " : "")
            }
            style={{
              backgroundColor: toggle
                ? "#FFFFFF"
                : props.eliteCompanyDoc?.useCustomColors
                ? props.eliteCompanyDoc?.primaryColor
                : "#fbbc05",
            }}
            onClick={() => setToggle(!toggle)}
          >
            <div className={(toggle ? "rotate-0" : "rotate-180") + "  "}>
              <svg
                width="33"
                height="17"
                viewBox="0 0 33 17"
                stroke="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M31 15L19.0631 3.05777C17.6534 1.64741 15.3466 1.64741 13.9369 3.05777L2 15"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="3"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </button>
        </div>
      </div>
      <div
        className={
          (toggle ? "flex" : "hidden") +
          " flex-col gap-[16px] py-[17px] px-[15px]"
        }
      >
        <button
          className={" font-custom font-medium text-black shadow-custom text-[15px]/[25px] tracking-[0.05em] text-center bg-primary rounded-[50px]  lg:pt-[10px] pt-[12px] lg:pb-[10px] pb-[10px] " + ((props.eliteCompanyDoc?.primaryWhite && props.eliteCompanyDoc?.useCustomColors) ? "text-white" : "text-black")}
          style={{
            backgroundColor: props.eliteCompanyDoc?.useCustomColors
              ? props.eliteCompanyDoc?.primaryColor
              : "#fbbc05",
          }}
          onClick={async () => {
            props.callback();
          }}
        >
          Activate
        </button>
        <button
          onClick={() => {
            navigate("/replacement-elitecard");
          }}
          className={
            " font-custom font-medium shadow-custom text-[15px]/[25px] tracking-[0.05em] text-center bg-button rounded-[50px]  lg:pt-[10px] pt-[12px] lg:pb-[10px] pb-[10px] opacity-100 " +
            (props.eliteCompanyDoc?.secondaryWhite ||
            !props.eliteCompanyDoc?.useCustomColors
              ? "text-white"
              : "text-black")
          }
          style={{
            backgroundColor: props.eliteCompanyDoc?.useCustomColors
              ? props.eliteCompanyDoc?.secondaryColor
              : "#252529",
          }}
        >
          Order Replacement Card
        </button>
      </div>
      <div></div>
    </div>
  );
};
