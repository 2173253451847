import { useNavigate } from 'react-router-dom';
import logo from '../assets/images/bcx_logo.svg';
import { useEffect, useState } from 'react';
import { Mode } from './Navbar';
import { DocumentData } from 'firebase/firestore';
import { auth1 } from '../services/firebase';
import { useDispatch, useSelector } from 'react-redux';
import { Black, Purchase, PurchaseType, getSubTotal, setOrder } from '../redux/feature/cartSlice';
import { Currency } from '../currencies';
import { setCurrency } from '../redux/feature/currencySlice';
import { Language, lang } from '../languages';
interface FooterProps
{
    translate?: boolean
}
const Footer = ({ ...props }: FooterProps) =>
{
    const navigate = useNavigate();
    const [user, setUser] = useState('');
    const [mode, setMode] = useState(Mode.Public);
    const [loading, setLoading] = useState(true)
    const cart: Purchase = useSelector((state: any) => state.cart);
    const dispatch = useDispatch();
    const currency: Currency = useSelector((state: any) => state.currency);
    const [localCurrency, setLocalCurrency] = useState<Currency>(currency);
    const globalLang = useSelector((state: any) => state.language);
    const language: Language = props.translate ? globalLang : Language.ENG;


    async function logout()
    {
        dispatch(setOrder({ valid: false } as Black))
        dispatch(getSubTotal(localCurrency))
        await auth1.signOut();
        navigate('/login');
    }

    const getUser = async () =>
    {
        let userDoc: DocumentData | undefined = undefined;
        auth1.onAuthStateChanged(async (user) =>
        {
            if (user)
            {
                setMode(Mode.LoggedIn)
                setLoading(false)
            }
            else
            {
                setMode(Mode.Public)
                setLoading(false)
            }
        });
    };
    function handleCart()
    {
        if (cart.subtotal && cart.subtotal > 0)
        {

            if (cart.order.valid)
            {

                navigate('/cart');
            } else
            {
                cart.order.type === PurchaseType.Black ? navigate('/blackcard') : navigate('/elitecard')
            }
        }
        else
        {
            navigate('/empty-cart')
        }
    }
    useEffect(() =>
    {
        if (loading)
        {
            getUser()
        }
    }, [mode])

    useEffect(() =>
    {
        dispatch(setCurrency(localCurrency))
    }, [dispatch, localCurrency])

    return (
        <footer className="bg-white lg:pb-[47px] pb-[32px] h-full pt-[50px] lg:pl-[81px] lg:pr-[73px]  px-[30px]  m-0 ">
            <div className='flex flex-row items-end lg:gap-0 gap-[27px] h-full lg:w-auto w-full justify-between '>
                <div className="flex lg:flex-grow-0 lg:flex-shrink-0 justify-start items-end lg:w-auto w-full h-full ">
                    <button onClick={() => { navigate('/'); }}>
                        <img src={logo} alt="bcx's Logo" className="max-h-full max-w-full " />
                    </button>
                </div>
                <div className="flex items-center justify-center lg:w-auto w-full mb-[15px]">
                    <div className="flex lg:flex-row flex-col lg:gap-[81px] w-full items-end lg:justify-center justify-end">
                        <button onClick={() => { navigate('/about'); }} className="lg:hidden block px-[10px] text-text  text-center tracking-[0.05em] font-custom font-light text-[16px]/[32px] menu-button ">{lang.about[language]}</button>
                        <button onClick={() => { navigate('/contact'); }} className="px-[10px] text-text  text-center tracking-[0.05em] font-custom font-light text-[16px]/[32px] menu-button ">{lang.contact[language]}</button>
                        <button onClick={() => { navigate('/about'); }} className="lg:block hidden px-[10px] text-text  text-center tracking-[0.05em] font-custom font-light text-[16px]/[32px] menu-button ">{lang.about[language]}</button>
                        <button onClick={() => { navigate('/shop'); }} className="px-[10px] text-text  text-center tracking-[0.05em] font-custom font-light text-[16px]/[32px] menu-button">{lang.shop[language]}</button>
                        <button onClick={() => { handleCart() }} className="px-[10px] text-text  text-center tracking-[0.05em] font-custom font-light text-[16px]/[32px] menu-button">{lang.cart[language]}</button>
                        <button onClick={() => { navigate('/login'); }} className={(mode === Mode.Public ? 'lg:block hidden' : 'hidden') + " px-[10px] text-text  text-center tracking-[0.05em] font-custom font-light text-[16px]/[32px] menu-button"}>Login</button>
                        <button onClick={() => { navigate('/login'); }} className={(mode === Mode.Public ? 'lg:hidden block' : 'hidden') + "  px-[10px] text-text  text-center tracking-[0.05em] font-custom font-light text-[16px]/[32px] menu-button"}>Login/Sign Up</button>

                        <button onClick={() => { navigate('/profile'); }} className={(mode !== Mode.Public ? 'block' : 'hidden') + " px-[10px] text-text  text-center tracking-[0.05em] font-custom font-light text-[16px]/[32px] menu-button"}>{lang.profile[language]}</button>
                        <button onClick={() => { logout() }} className={(mode !== Mode.Public ? 'block' : 'hidden') + " px-[10px] text-text  text-center tracking-[0.05em] font-custom font-light text-[16px]/[32px] menu-button"}>{lang.logout[language]}</button>


                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
