import { Dictionary } from "./types";


export enum Language
{
    ENG = 'EN',
    FRE = 'FR'
}


export const lang: Dictionary<Dictionary<string>> = {
    //metrics
    metrics: { EN: 'Analytics', FR: 'Analytique' },
    //contact card
    dateAdded: { EN: 'Date Added', FR: 'Ajout' },
    contactSaved: { EN: 'Contact Saved', FR: 'Enregistré' },
    viewContactInfo: { EN: 'View Contact Info', FR: 'Voir Info Contact' },
    hideContactInfo: { EN: 'Hide Contact Info', FR: 'Masquer Info Contact' },
    deleteContact: { EN: 'Delete', FR: 'Supprimer' },
    deleteContactConf: { EN: 'Are you sure you want to delete', FR: 'Êtes-vous sûr(e) de vouloir supprimer' },
    asAContact: { EN: 'as a contact?', FR: 'en tant que contact ?' },
    //contact list
    searchParams: { EN: 'Name, Email or Phone', FR: 'Nom, Email ou Téléphone' },
    search: { EN: 'Search', FR: 'Rechercher' },
    noContacts: { EN: "You don't have any contacts yet", FR: "Vous n'avez pas encore de contacts" },
    noMatches: { EN: 'No Matches for your search', FR: 'Aucune correspondance pour votre recherche' },
    loadingContact: { EN: 'Getting Contact Info', FR: 'Obtention des informations de contact' },
    //two way share pop up
    shareYourInfo: { EN: 'Share Your Info!', FR: 'Partagez Vos Informations!' },
    notes: { EN: 'Notes', FR: 'Notes' },
    sendInformation: { EN: 'Send Information', FR: 'Envoyer des informations' },
    cancel: { EN: 'Cancel', FR: 'Annuler' },
    confirm: { EN: 'Close', FR: 'Fermer' },
    firstName: { EN: 'First Name', FR: 'Prénom' },
    confirmClose: { EN: 'You have unsaved changes, are you sure you want to close this menu?', FR: 'Vous avez des modifications non enregistrées, êtes-vous sûr de vouloir fermer ce menu ?' },
    firstNameError: { EN: 'Please provide your first name', FR: 'Veuillez fournir votre prénom' },
    lastNameError: { EN: 'Please provide your last name', FR: 'Veuillez fournir votre nom de famille' },
    neitherError: { EN: 'Please provide at least a phone or an email', FR: 'Veuillez fournir au moins un numéro de téléphone ou une adresse e-mail' },
    //profile
    at: { EN: 'at', FR: 'chez' },
    saveContact: { EN: 'Save Contact', FR: 'Enregistrer Le Contact' },
    twoWayShare: { EN: 'Two Way Share', FR: 'Partage bidirectionnel' },
    whoAmI: { EN: 'Who Am I?', FR: 'Qui suis-je?' },
    getabcxCardToday: { EN: 'Get A businesscardX Card Today', FR: "Obtenez une carte businesscardX" },
    //profile menu
    editAccount: { EN: 'Edit Account', FR: 'Paramètres de votre compte' },
    changePassword: { EN: 'Change Password', FR: 'Changer votre mot de passe' },
    orderReplacementCard: { EN: 'Order A Replacement Card', FR: 'Gérer votre carte' },
    language: { EN: 'Language', FR: 'Langage' },
    about: { EN: 'About', FR: 'À notre sujet' },
    contact: { EN: 'Contact Us', FR: 'Communiquer avec nous' },
    shop: { EN: 'Shop', FR: 'Boutique' },
    logout: { EN: 'Logout', FR: 'Déconnexion' },
    deactivateAccount: { EN: 'Deactivate Account', FR: 'Désactiver votre compte' },
    settings: { EN: 'Settings', FR: 'Paramètres' },
    profile: { EN: 'Profile', FR: 'Profil' },
    contactList: { EN: 'Contact List', FR: 'Liste de contacts' },
    elitePortal: { EN: 'ELITE Portal', FR: 'Portail ÉLITE' },
    //edit profile
    sizeRecommendation: { EN: 'We recommend a size of 400x400 or larger', FR: 'Nous recommandons une taille de 400x400 ou plus' },
    uploadFromPhotos: { EN: 'Upload from Photos', FR: 'Télécharger depuis les photos' },
    customURL: { EN: 'custom URL', FR: 'URL personnalisée' },
    preview: { EN: 'preview', FR: 'Aperçu' },
    name: { EN: 'Name', FR: 'Nom' },
    lastName: { EN: 'Last Name', FR: 'Nom de famille' },
    jobTitle: { EN: 'Job Title', FR: 'Titre du poste' },
    company: { EN: 'Company', FR: 'Entreprise' },
    phone: { EN: 'Phone', FR: 'Téléphone' },
    add: { EN: 'Add', FR: 'Ajouter' },
    phoneNumber: { EN: 'Phone Number', FR: 'Numéro de téléphone' },
    ext: { EN: 'ext.', FR: 'poste' },
    type: { EN: 'Type', FR: 'Type' },
    work: { EN: 'Work', FR: 'Travail' },
    cell: { EN: 'Cell', FR: 'Cell' },
    home: { EN: 'Home', FR: 'Maison' },
    email: { EN: 'Email', FR: 'Courriel' },
    website: { EN: 'Website', FR: 'Site Web' },
    websiteTitle: { EN: 'Title', FR: 'Titre' },
    socials: { EN: 'Socials', FR: 'Réseaux sociaux' },
    social: { EN: 'Social', FR: 'Réseau social' },
    socialMedia: { EN: 'Social Media', FR: 'Médias sociaux' },
    handle: { EN: 'Handle', FR: 'Identifiant' },
    username: { EN: 'Username', FR: "Nom d'utilisateur" },
    bio: { EN: 'Bio', FR: 'Biographie' },
    location: { EN: 'Location', FR: 'Emplacement' },
    city: { EN: 'City', FR: 'Ville' },
    stateProvince: { EN: 'State/Province', FR: 'État/Province' },
    country: { EN: 'Country', FR: 'Pays' },
    addresses: { EN: 'Addresses', FR: 'Adresses' },
    address: { EN: 'Address', FR: 'Adresse' },
    line: { EN: 'Line', FR: 'Ligne' },
    postalCode: { EN: 'Postal Code', FR: 'Code postal' },
    media: { EN: 'Media', FR: 'Médias' },
    showcase: { EN: 'Showcase', FR: 'Vitrine' },
    uploadThumbnail: { EN: 'Upload Thumbnail', FR: 'Télécharger la miniature' },
    message: { EN: 'Message', FR: 'Message' },
    characters: { EN: 'characters', FR: 'caractères' },
    max: { EN: 'max.', FR: 'maximum' },
    goToProfile: { EN: "Go to Profile Page", FR: "Aller à la page de profil" },
    editPermissionError: { EN: "You don't have permissions for edit your profile, please check with your company's admin", FR: "Vous n'avez pas les autorisations pour modifier votre profil, veuillez vérifier auprès de l'administrateur de votre entreprise" },
    //errors
    saveChanges: { EN: 'Save Changes', FR: 'Enregistrer les modifications' },
    unsavedWarning: { EN: 'You have unsaved changes! Are you sure you want to leave this page?', FR: 'Vous avez des changements non enregistrés ! Êtes-vous sûr de vouloir quitter cette page ?' },
    missingInfo: { EN: "Missing Information", FR: "Information manquante" },
    customURLInvalidError: { EN: "The URL can't contain special characters or spaces", FR: "L'URL ne peut pas contenir de caractères spéciaux ou d'espaces" },
    customURLTakenError: { EN: "This URL is taken, try a new one", FR: "Cette URL est déjà utilisée, veuillez en choisir une nouvelle" },
    customURLShortError: { EN: "The URL can't contain special characters or spaces", FR: "L'URL ne peut pas contenir de caractères spéciaux ou d'espaces" },
    countryError: { EN: "Please provide the address's country", FR: "Veuillez indiquer le pays de l'adresse" },
    cityError: { EN: "Please provide the address's city", FR: "Veuillez indiquer la ville de l'adresse" },
    addressError: { EN: "Please provide an address", FR: "Veuillez fournir une adresse" },
    addressNameError: { EN: "Please provide a name for the address", FR: "Veuillez fournir un nom pour l'adresse" },
    websiteError: { EN: "Please provide a website URL", FR: "Veuillez fournir une URL de site web" },
    emailError: { EN: "Please provide an email address", FR: "Veuillez fournir une adresse e-mail" },
    numberTypeError: { EN: "Please provide a number type", FR: "Veuillez indiquer un type de numéro" },
    phoneNumberError: { EN: "Please provide a valid phone number", FR: "Veuillez fournir un numéro de téléphone valide" },
    mediaNameError: { EN: "Please provide a Name for the Showcase", FR: "Veuillez fournir un nom pour la vitrine" },
    snapchatError: { EN: "Please provide your Snapchat Username", FR: "Veuillez fournir votre nom d'utilisateur Snapchat" },
    whatsappError: { EN: "Please provide your WhatsApp URL", FR: "Veuillez fournir votre URL WhatsApp" },
    linkedinError: { EN: "Please provide your LinkedIn URL", FR: "Veuillez fournir votre URL LinkedIn" },
    instagramError: { EN: "Please provide your Instagram URL", FR: "Veuillez fournir votre URL Instagram" },
    tiktokError: { EN: "Please provide your TikTok URL", FR: "Veuillez fournir votre URL TikTok" },
    facebookError: { EN: "Please provide your Facebook URL", FR: "Veuillez fournir votre URL Facebook" },
    twitterError: { EN: "Please provide your X URL", FR: "Veuillez fournir votre URL X" },
    emptyError: { EN: "Please pick an option", FR: "Veuillez choisir une option" },
    //navbar
    cart: { EN: 'Cart', FR: 'Panier' },
    login: { EN: 'Login', FR: 'Se connecter' },

}

