
import React from 'react'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import { useNavigate } from 'react-router-dom';

export const SubmitMessage = () =>
{
    const navigate = useNavigate();
    return (
        <div className='overflow-hidden'>
            <Navbar />
            <div className='lg:mx-[80px] mx-[25px] mb-[100px] lg:mt-[110px] mt-[74px]'>
                <h1 className=' inline-block font-custom font-black lg:text-[64px]/[86px] text-[30px]/[41px] lg:mb-[34px] lg:w-auto w-[340px]'>
                    Success! Your message has been sent.
                </h1>
                <div className='bg-primary relative lg:w-[150%] w-[125%]  h-[37px] z-[-1]   lg:mt-[-70px] mt-[-18px] lg:mb-[37px] mb-[24px]' />
                <div>
                    <h3 className=' inline-block font-custom font-light lg:text-[20px]/[32px] text-[14px]/[25px] lg:tracking-normal tracking-[0.05em] lg:mb-[28px] mb-[32px] w-[338px]'>
                        Thank you for contacting us! We have received your message and will respond shortly.
                    </h3>
                    <div className='flex lg:justify-start justify-start items-center z-10'>
                        <button className=' font-custom font-medium text-white shadow-custom lg:text-[19px]/[32px] text-[16px]/[25px] text-center tracking-[0.05em] bg-button hover:bg-inset rounded-[33px] lg:w-auto w-[343px] lg:px-[71px] px-0 lg:pt-[10px] pt-[12px] lg:pb-[12px] pb-[14px]' onClick={() => { navigate('/shop'); }}>Go to Shop</button>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
