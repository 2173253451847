import { useNavigate } from "react-router-dom";
import { auth1 } from "../services/firebase";
import { useAuthState } from "react-firebase-hooks/auth";

function PrivateRoute({ children }: any)
{
  const [user, loading] = useAuthState(auth1);
  const navigate = useNavigate()

  if (loading)
  {
    return <></>;
  }
  if (!user)
  {
    navigate('/login')
    return
  } else
  {
    return children;
  }

}

export { PrivateRoute };
