import { DocumentReference, collection, deleteDoc, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import bcx_icon from "../../assets/images/bcx-icon.svg";
import menu from "../../assets/images/hamburger.svg";
import menu_black from "../../assets/images/hamburger-black.svg";
import { auth1, db } from "../../services/firebase";
import { EliteCompany, Metric, User } from "../../types";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Language, lang } from "../../languages";
import { setLanguage } from "../../redux/feature/languageSlice";
import { CircularProgress } from "@mui/material";
import ig from '../../assets/images/instagram-logo.svg'
import fb from '../../assets/images/facebook-logo.svg'
import wp from '../../assets/images/whatsapp-logo.svg'
import tk from '../../assets/images/tiktok.svg'
import tw from '../../assets/images/twitter-x-logo.svg'
import li from '../../assets/images/linkedin-logo.svg'
import sc from '../../assets/images/snapchat.svg'
import { ProfileMenu } from "../../components/ProfileMenu";
export const Metrics = () =>
{
    const navigate = useNavigate();
    const [menuToggle, setMenuToggle] = useState(true);
    const [user, setUser] = useState<User>({} as User);
    const [metrics, setMetrics] = useState<Metric>();
    const [eliteCompanyDoc, setEliteCompanyDoc] = useState<EliteCompany>();
    const [loading, setLoading] = useState(true);
    const language: Language = useSelector((state: any) => state.language);
    const [languageSelection, setLanguageSelection] = useState<Language | null>(null);
    const dispatch = useDispatch();

    async function getUser(
        callbackFunction: Function,
        eliteCallback: Function
    )
    {
        auth1.onAuthStateChanged(async (user) =>
        {
            if (user)
            {
                const authUserRef = doc(db, "users", auth1.currentUser?.uid!);
                let userDoc = ((await getDoc(authUserRef)).data()) as User;
                const q = query(
                    collection(db, "metrics"),
                    where("orderNumber", "==", userDoc.orderNumber))
                const querySnapshot = await getDocs(q);
                if (querySnapshot.docs.length > 0)
                {
                    setMetrics(querySnapshot.docs[0].data() as Metric);
                } else
                {
                    setMetrics({} as Metric)
                }
                const userTyped = userDoc as User;
                if (userTyped.eliteCompany !== undefined)
                {
                    await eliteCallback(userTyped.eliteCompany);
                }
                await callbackFunction(userDoc as User);
            }
        });
    }
    const setStateUser = (user: User) =>
    {
        setUser(user);
        setLanguageSelection(user.language ?? Language.ENG);
        setLoading(false);
    };

    const setStateElite = async (data: DocumentReference) =>
    {
        const eliteCompanyDocs = (await getDoc(data)).data();
        const eliteCompany = eliteCompanyDocs as EliteCompany;
        setEliteCompanyDoc(eliteCompany);
        setLoading(false);
    };
    async function updateLanguage(lang: Language)
    {
        const userRef = doc(db, "users", auth1.currentUser?.uid!);
        const userDoc = (await getDoc(userRef)).data() as User;
        if (userDoc.orderNumber === user.orderNumber)
        {
            await updateDoc(userRef!, { language: lang });
        }
    }

    useEffect(() =>
    {
        window.scrollTo(0, 0);
        const getData = async () =>
        {
            await getUser(
                setStateUser,
                setStateElite
            );
        };
        getData();
    }, []);



    useEffect(() =>
    {
        if (languageSelection)
        {
            dispatch(setLanguage(languageSelection!));
            updateLanguage(languageSelection!);
        }
    }, [dispatch, languageSelection])

    if (loading)
    {
        return (
            <div className="overflow-hidden flex items-center justify-center h-screen relative w-[100vw] ">
                <div className=" relative">
                    <CircularProgress color="inherit" />
                </div>
            </div>
        );
    }

    return <div className="flex flex-col">
        <div className="z-0 relative flex flex-col items-center w-full  bg-offwhite ">
            <header className=" z-[5]  top-0 lg:w-[393px] w-full flex flex-col bg-offwhite ">
                <div className="flex flex-row items-center justify-center bg-text w-full "
                    style={{
                        backgroundColor: eliteCompanyDoc?.useCustomColors
                            ? "#FFFFFF"
                            : "#161617",
                    }}
                >
                    <div className="flex-1"></div>
                    <img
                        className="mt-[46px] mb-[18px]  cursor-pointer max-w-[150px] max-h-[50px] object-cover"
                        src={
                            eliteCompanyDoc?.useCustomColors
                                ? eliteCompanyDoc?.logo[0]
                                : bcx_icon
                        }
                        onClick={() =>
                        {
                            navigate("/");
                        }}
                    />


                    <div className="flex-1">
                        <button
                            className={" block ml-auto self-start mt-[58px] lg:mr-[38px] mr-[29px] mb-[21px]"
                            }
                            onClick={() =>
                            {
                                setMenuToggle(!menuToggle);
                            }}
                        >
                            <img
                                alt="menu"
                                src={eliteCompanyDoc?.useCustomColors ? menu_black : menu}
                            />
                        </button>


                    </div>
                </div>
                <h3 className="w-full font-custom font-medium text-[30px] text-center mt-[20px] bg-white">{lang.metrics[language]}</h3>
            </header>
        </div>

        <div className="flex flex-col items-center mt-[35px] w-[390px] self-center font-custom">
            <div className="flex flex-col gap-[16px] text-[16px]/[18px] w-full border rounded-xl shadow-custom bg-white px-[18px] py-[36px]">
                <div className="flex flex-row justify-between items-center ">
                    <div>Number Of Profile Visits:</div>
                    <div className="font-bold text-[20px]/[22px]">{metrics?.visits ?? 0}</div>
                </div>
                <div className="bg-slate-300 h-[1px] w-[13%] -mt-[8px] -mb-[8px]" />
                <div className="flex flex-row justify-between items-center">
                    <div>Number Of Contacts Saved:</div>
                    <div className="font-bold text-[20px]/[22px]">{metrics?.contactsSaved ?? 0}</div>
                </div>
                <div className="bg-slate-300 h-[1px] w-[13%] -mt-[8px] -mb-[8px]" />
                <div className="flex flex-row justify-between items-center">
                    <div>Leads Created:</div>
                    <div className="font-bold text-[20px]/[22px]">{metrics?.leadsCreated ?? 0}</div>
                </div>
                <div className="bg-slate-300 h-[1px] w-[13%] -mt-[8px] -mb-[8px]" />
                <div className="flex flex-row justify-between items-center">
                    <div>Phone Calls:</div>
                    <div className="font-bold text-[20px]/[22px]">{metrics?.phoneCalls ?? 0}</div>
                </div>
                <div className="bg-slate-300 h-[1px] w-[13%] -mt-[8px] -mb-[8px]" />
                <div className="flex flex-row justify-between items-center">
                    <div>Website Visits:</div>
                    <div className="font-bold text-[20px]/[22px]">{metrics?.websiteVisits ?? 0}</div>
                </div>
                <div className="bg-slate-300 h-[1px] w-[13%] -mt-[8px] -mb-[8px]" />
                <div className="flex flex-row justify-between items-center">
                    <div>Email Taps:</div>
                    <div className="font-bold text-[20px]/[22px]">{metrics?.emailTaps ?? 0}</div>
                </div>
                <div className="bg-slate-300 h-[1px] w-[13%] -mt-[8px] -mb-[8px]" />
                <div className="flex flex-row justify-between items-center">
                    <div>Address Lookups:</div>
                    <div className="font-bold text-[20px]/[22px]">{metrics?.addressLookups ?? 0}</div>
                </div>
                <div className="bg-slate-300 h-[1px] w-[13%] -mt-[8px] -mb-[8px]" />
                <div className="flex flex-row justify-between items-center">
                    <div>Media Showcase Lookups:</div>
                    <div className="font-bold text-[20px]/[22px]">{metrics?.mediaLookups ?? 0}</div>
                </div>
            </div>
        </div>

        <div className="flex flex-col items-center mt-[35px] mb-[20px] w-[393px] self-center font-custom">
            <div className="flex flex-col w-full border rounded-xl shadow-custom bg-white py-[23px] px-[18px]">
                <div className="mb-[12px]">Social Taps:</div>
                <div className="flex flex-row justify-between">
                    <div className="flex flex-col items-center gap-[12px]">
                        <img className="w-[45px] h-[45px]" src={ig} alt="Instagram Logo"></img>
                        <div className="font-medium text-[20px]/[22px]">{metrics?.ig ?? 0}</div>
                    </div>
                    <div className="flex flex-col items-center gap-[12px]">
                        <img className="w-[45px] h-[45px]" src={fb} alt="Facebook Logo"></img>
                        <div className="font-medium text-[20px]/[22px]">{metrics?.fb ?? 0}</div>
                    </div>
                    <div className="flex flex-col items-center gap-[12px]">
                        <img className="w-[45px] h-[45px]" src={tw} alt="X Logo"></img>
                        <div className="font-medium text-[20px]/[22px]">{metrics?.tw ?? 0}</div>
                    </div>
                    <div className="flex flex-col items-center gap-[12px]">
                        <img className="w-[45px] h-[45px]" src={li} alt="LinkedIn Logo"></img>
                        <div className="font-medium text-[20px]/[22px]">{metrics?.li ?? 0}</div>
                    </div>
                    <div className="flex flex-col items-center gap-[12px]">
                        <img className="w-[45px] h-[45px]" src={tk} alt="TikTok Logo"></img>
                        <div className="font-medium text-[20px]/[22px]">{metrics?.tk ?? 0}</div>
                    </div>
                    <div className="flex flex-col items-center gap-[12px]">
                        <img className="w-[45px] h-[45px]" src={sc} alt="Snapchat Logo"></img>
                        <div className="font-medium text-[20px]/[22px]">{metrics?.sc ?? 0}</div>
                    </div>
                    <div className="flex flex-col items-center gap-[12px]">
                        <img className="w-[45px] h-[45px]" src={wp} alt="WhatsApp Logo"></img>
                        <div className="font-medium text-[20px]/[22px]">{metrics?.wp ?? 0}</div>
                    </div>
                </div>
            </div>
        </div>

        <ProfileMenu
            language={language}
            navigate={navigate}
            open={menuToggle}
            onOpen={() => { setMenuToggle(!menuToggle) }}
            setLanguageSelection={setLanguageSelection}
            user={user}
        />



    </div>
}
