import React, { useState } from 'react'
import Navbar from '../../components/Navbar'
import square_left from "../../assets/images/forgotpwd-left-squares.svg";
import square_right from "../../assets/images/forgotpwd-right-squares.svg";
import CTextField from '../../components/CTextField'
import { useNavigate } from 'react-router-dom';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth1 } from '../../services/firebase';
import validator from 'validator';

export const ChangePassword = () =>
{
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  async function handleSubmit()
  {
    let valid = true
    let error = ''
    if (!validator.isEmail(email))
    {
      valid = false
      error = 'Please provide a valid email'
    }
    if (!email)
    {
      valid = false
      error = 'Please confirm your email'
    }

    if (valid)
    {

      await sendPasswordResetEmail(auth1, email).then(async (a) =>
      {
        await auth1.signOut().then(() =>
        {
          navigate("/login");
        });
      }).catch((a) =>
      {
        if (a.message === 'Firebase: Error (auth/user-not-found).')
        {
          error = "The email you provided doesn't match with our info.";
        }
      });
    }
    setError(error);
  }


  return (
    <div className="">
      <Navbar classname="lg:hidden block mb-[35px]" hidecurr alternate />
      <div className="lg:relative flex lg:flex-row flex-col justify-between">
        <div className="lg:block hidden h-full justify-start z-40">
          <img className=" max-h-[95%] h-auto" src={square_left} />
        </div>
        <div className="flex flex-col lg:absolute lg:left-[50%] lg:translate-x-[-50%] pb-[66px]">
          <Navbar classname="lg:block hidden self-center mb-[85px] " alternate />
          <div className="self-center flex flex-col justify-center lg:mx-0 mx-[25px]">
            <h2 className=" font-custom font-medium self-center text-center lg:text-[57px]/[77px] text-[30px]/[41px] mb-[47px] ">
              Want to Change Your Password?
            </h2>
            <h4 className=" w-[306px] font-custom font-light self-center lg:text-left text-center lg:text-[20px]/[32px]  tracking-[0.05em] lg:mb-[32px] mb-[55px] ">
              Please insert your email and check your inbox.
            </h4>
            <CTextField error={error} label='Email' w='lg:w-[354px] w-[347px] ' classname='mb-[41px] self-center ' type='email' value={email} onChange={(e) => { setEmail(e) }} />
            <button className='w-[333px] self-center font-custom font-medium text-button shadow-custom text-[20px]/[27px] text-center tracking-[0.05em] hover:bg-secondary bg-primary rounded-[26px] lg:mb-[21px] mb-[0px]  lg:pt-[10px] pt-[12px] lg:pb-[14px] pb-[12px]' onClick={() =>
            {
              handleSubmit()
            }}>
              Go To Login
            </button>

          </div>
        </div>
        <div className=" lg:block hidden h-full justify-end">
          <img className=" max-h-[100%] h-auto" src={square_right} />
        </div>

      </div>

    </div>
  )
}
