import React from 'react'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import { useNavigate } from 'react-router-dom';
import CAccordion from '../../components/CAccordion';
export const TermsOfUse = () =>
{
    const navigate = useNavigate();
    return (
        <div className='overflow-hidden'>
            <Navbar />
            <div className='lg:mx-[80px] mx-[25px] mb-[100px] lg:mt-[110px] mt-[74px]'>
                <h1 className=' inline-block font-custom font-black lg:text-[64px]/[86px] text-[30px]/[41px] lg:mb-[34px] lg:w-auto w-[340px]'>
                    Terms and Privacy
                </h1>
                <div className='bg-primary relative lg:w-[150%] w-[125%]  h-[37px] z-[-1]   lg:mt-[-70px] mt-[-18px] lg:mb-[37px] mb-[24px]' />
                <div className='mb-[20px]'>
                    <CAccordion
                        iconangle={['0', '0']}
                        summary='Terms of Use'
                        iconcolor="#000"
                        radius="4px"
                        bgcolor="#fff"
                        txcolor="#000"

                        shadow="0px 2px 6px rgba(0, 0, 0, 0.14);"
                    >
                        <div className='mt-[-5px] pt-[10px] border-t-[1px] border-button'>
                            <h3 className=' inline-block font-custom font-light lg:text-[20px]/[32px] text-[14px]/[25px] lg:tracking-normal tracking-[0.05em] lg:mb-[28px] mb-[32px] w-[338px]'>
                                LAST UPDATED: 2023-04-12
                            </h3>
                            <div className='font-custom'>
                                <h2 className='font-custom font-medium'>1. OVERVIEW</h2>
                                These Terms of Use form a legally binding contract between LOGOPARK INC. ("we" or "us") and the people and companies (“Users” or “you”) that access and use our website located at www.logopark.com or any related website or mobile platform controlled by us (collectively, the "Website"). These Terms of Use apply to all services provided through our Website and any other software application we operate, and all content, services, and products available at or through the Website.<br /><br />
                                It is very important that you read and fully understand these Terms of Use before using the Website. By agreeing to these Terms of Use and using the Website, you are entering into a legally binding contract which will impact your rights.<br /><br />
                                By accessing any part of the Website, you agree to be bound by these Terms of Use. You are also confirming that you have read and fully understood these Terms of Use. If you do not agree to or understand all of these Terms of Use, then you may not access the Website or use any services available on the Website.<br /><br />
                                These Terms of Use should be read in conjunction with the Privacy Policy and any other rules, guidelines, or policies posted on the Website.<br /><br />
                                <h2 className='font-custom font-medium'>2. SERVICES AVAILABLE ON THE WEBSITE</h2>
                                Without restriction, we generally offer the following services through the Website:<br /><br />
                                We offer logo design, branding, and web design services.<br /><br />
                                The services we offer are subject to change over time. By using the Website, you are confirming that you have determined that the services are appropriate for your needs. We do not guarantee that these services meet your needs or that they are suitable for your specific purposes.<br /><br />
                                <h2 className='font-custom font-medium'>3. OWNERSHIP OF YOUR FILES</h2>
                                When you choose to upload documents or data (including any images or text), LOGOPARK INC. does not review or pre-screen the contents of electronic data uploaded or posted to the Website (“Content”) and LOGOPARK INC. claims no intellectual property rights with respect to the Content.<br /><br />
                                However, by posting, uploading, inputting or submitting any Content whatsoever to the Website, you are granting LOGOPARK INC. an irrevocable, royalty free licence while the Content remains uploaded to the Website, to use the Content for any purpose related to the use and promotion of its business and the Website, including the right to copy, distribute, edit, and publicly display such content with or without attribution.<br /><br />
                                <h2 className='font-custom font-medium'>4. TERMINATION</h2>
                                You may cancel these Terms of Use at any time by discontinuing your use of the Website.<br /><br />
                                <h2 className='font-custom font-medium'>5. LIMITATION OF LIABILITY</h2>
                                Except in a case where we are in violation of these Terms of Use, we will not be held liable for, and you hereby fully waive the right to claim for, any loss, injury, claim, liability or damages of any kind resulting in any way from use of the Website.<br /><br />
                                Your use of the Website is at your sole risk. The Website is provided on an “as is” and “as available” basis without any warranty or condition, express, implied or statutory. We do not warrant that your use of the Website will be uninterrupted, secure or error-free.<br /><br />
                                In no event will we have any liability to you or any third party for any lost profits or revenues or for any indirect, special, incidental, consequential, or punitive damages however caused, whether in contract, tort, or otherwise, and whether or not you or the third party have been advised of the possibility of such damages. In the event the foregoing paragraph, or any part thereof, is void under applicable law, this paragraph, or such part thereof, shall be inapplicable.<br /><br />
                                <h2 className='font-custom font-medium'>6. INDEMNIFICATION</h2>
                                You agree to indemnify and hold harmless LOGOPARK INC., including our officers, directors, shareholders, employees and agents, from and against any and all claims and expenses, including legal fees and disbursements, which are made against us and arise out of your use of the Website, including but not limited to your violation of any term of these Terms of Use or any other policy posted on the Website.<br /><br />
                                <h2 className='font-custom font-medium'>7. SECURITY BREACHES</h2>
                                In order to protect your security, it is your sole responsibility to ensure that all usernames and passwords used to access the Website are kept secure and confidential.<br /><br />
                                You must immediately notify us of any unauthorized use of your account, including the unauthorized use of your password, or any other breach of security.<br /><br />
                                We will investigate any breach of security on the Website that we determine in our sole discretion to be serious in nature, but we will not be held responsible or liable in any manner for breaches of security or any unauthorized access to your account however arising.<br /><br />
                                <h2 className='font-custom font-medium'>8. WARRANTIES AND REPRESENTATIONS</h2>
                                We hereby disclaim all warranties of any kind, whether express, implied, or statutory, including but not limited to implied warranties as to merchantability or fitness for a particular purpose as they relate to the Website.<br /><br />
                                <h2 className='font-custom font-medium'>9. COMPLIANCE WITH LAWS</h2>
                                You represent and warrant that:<br />
                                <ol className='list-decimal space-y-4 ml-[22px]'>
                                    <li>You have the authority to bind yourself to these Terms of Use;</li>
                                    <li>Your use of the Website will be solely for purposes that are permitted by these Terms of Use;</li>
                                    <li>Your use of the Website will not infringe or misappropriate the confidentiality or intellectual property rights of any User or third party; and</li>
                                    <li>Your use of the Website will comply with all local, provincial and federal laws, rules and regulations, and with all policies posted on the Website.</li>
                                </ol><br />
                                You must only use the Website for your own lawful purposes, in accordance with these Terms of Use and any notice, policy or condition posted on the Website. You may use the Website on behalf of others or in order to provide services to others but if you do so you must ensure that you are authorized to do so and that all persons for whom or to whom services are provided comply with and accept all these Terms of Use.<br /><br />
                                <h2 className='font-custom font-medium'>10. AGE RESTRICTIONS</h2>
                                Users under the age of 18 are permitted to access and use the Website, but are prohibited from submitting any personal information or other information that may be used to identify them. Users under the age of 13 are only permitted to access and use the Website while under the direct supervision of a parent or guardian.<br /><br />
                                <h2 className='font-custom font-medium'>11. GOVERNING LAW AND DISPUTE RESOLUTION</h2>
                                These Terms of Use and any access to or use of the Website shall be governed by, and construed in accordance with, the laws in force in the Province of Ontario.<br /><br />
                                If any claim, dispute or controversy occurs between LOGOPARK INC. and a User relating to the interpretation or implementation of any of the provisions of these Terms of Use, such dispute shall be resolved by private, confidential and binding arbitration. Such arbitration shall be conducted by a single arbitrator. The arbitrator shall be appointed by agreement of the parties or, in the absence of an agreement, such arbitrator shall be appointed by a judge upon the application of either the User or LOGOPARK INC. Arbitration shall be held in the Province of Ontario, unless otherwise agreed by the parties. The arbitration procedure to be followed shall be agreed by the parties or, in absence of an agreement, determined by the arbitrator. The arbitration shall proceed in accordance with the provisions of the Arbitration Act, 1991, SO 1991, c 17. Subject to any right of appeal, the decision arrived at by the arbitrator shall be final and binding. Judgment upon the award rendered by the arbitrator may be entered in any court having jurisdiction.<br /><br />
                                <h2 className='font-custom font-medium'>12. WAIVER OF CLASS ACTION</h2>
                                By using the Website, you agree to resolve any claim or dispute arising between you and us on an individual basis, rather than addressing such claim or dispute as part of a group or class. You hereby waive any right you may have to commence or participate in any class action lawsuit commenced against LOGOPARK INC. or its affiliates related to any claim, dispute or controversy arising from your use of the Website. Where applicable, you hereby agree to opt out of any class proceeding against LOGOPARK INC. otherwise commenced.<br /><br />
                                The above waiver shall not apply to claims or disputes arising under consumer protection legislation or any other claim or dispute where a waiver of class action lawsuits is unenforceable at law.<br /><br />
                                <h2 className='font-custom font-medium'>13. GENERAL TERMS</h2>
                                <h2 className='font-custom '>a. AMENDING THESE TERMS OF USE</h2>
                                These Terms of Use may be updated and amended from time to time. We reserve the right to change these Terms of Use at any time, and any amended Terms of Use are effective upon posting to the Website. We will make efforts to communicate any changes to these Terms of Use we deem material, in our sole discretion, via email or notifications on the Website. Your continued use of the Website will be deemed to be immediate and unconditional acceptance of any amended Terms of Use, whether or not we deemed the amendments to be material.<br /><br />
                                <h2 className='font-custom '> b. ASSIGNMENT</h2>
                                We may assign or delegate these Terms of Use, in whole or in part, to any person or entity at any time with or without your consent and without prior notice to you. Users may not assign or delegate any rights or obligations under these Terms of Use, without our prior written consent, and any unauthorized assignment and delegation is void.<br /><br />
                                <h2 className='font-custom'>c. NO WAIVER</h2>

                                No waiver of a provision, right or remedy of this Agreement shall operate as a waiver of any other provision, right or remedy or the same provision, right or remedy on a future occasion.<br /><br />
                                <h2 className='font-custom'> d. NO AGENCY</h2>

                                The parties to these Terms of Use are independent contractors and are not partners or agents. LOGOPARK INC. has no fiduciary obligations or professional obligations whatsoever to you arising from these Terms of Use or your use of the Website.<br /><br />
                                <h2 className='font-custom '>e. SEVERABILITY</h2>

                                In the event that any provision or part of this Agreement is found to be void or invalid by a court of law, the remaining provisions, or parts thereof, shall be and remain in full force and effect.<br /><br />
                                <h2 className='font-custom '>f. ENTIRE AGREEMENT</h2>

                                These Terms of Use, in conjunction with all policies and guidelines available on the Website (including but not limited to any Privacy Policy), incorporated by reference, constitute the entire agreement between you and LOGOPARK INC. and supersede all prior communications, agreements and understandings, written or oral, with respect to the subject matter of these Terms of Use.<br /><br />
                                <h2 className='font-custom font-medium'> 14. QUESTIONS ON THESE TERMS OF USE</h2>

                                We welcome you to contact us with any questions on these Terms of Use. You can send your questions regarding these Terms of Use to the following email address:<br /><br />
                                contact@logopark.com<br /><br />
                                <h2 className='font-custom font-medium'>15. CONFIRMATION OF AGREEMENT TO TERMS OF USE</h2>

                                BY PROCEEDING TO USE THE WEBSITE, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD AND AGREE TO BE BOUND BY THESE TERMS OF USE AND ANY POLICIES AND NOTICES POSTED ON THE WEBSITE.<br /><br />
                            </div>

                        </div>
                    </CAccordion>
                </div>
                <div>
                    <CAccordion
                        iconangle={['0', '0']}
                        summary='Privacy Policy'
                        iconcolor="#000"
                        radius="4px"
                        bgcolor="#fff"
                        txcolor="#000"
                        shadow="0px 2px 6px rgba(0, 0, 0, 0.14);"
                    >
                        <div className='mt-[-5px] pt-[10px] border-t-[1px] border-button'>
                            <h3 className=' inline-block font-custom font-light lg:text-[20px]/[32px] text-[14px]/[25px] lg:tracking-normal tracking-[0.05em] lg:mb-[28px] mb-[32px] w-[338px]'>
                                LAST UPDATED: 2023-03-11
                            </h3>
                            <div className='font-custom'>
                                <h2 className='font-custom font-medium'>1. OVERVIEW</h2>
                                At LOGOPARK INC. (the “Company”, “we” or “us”), we use our website and
                                other internet-based tools for the following purpose:<br /><br />
                                We sell digital business cards that allow users to share their information through
                                NFC and/or QR technology.<br /><br />
                                We are required to collect and retain information about the people and
                                companies that access and use the website (“Users” or “you”). This Policy
                                applies to our privacy practices on all communications between the Company
                                and Users, including but not limited to communication via:<br /><br />
                                <ol className='list-decimal space-y-4 ml-[22px]'>
                                    <li>the Company website (including the website hosted at
                                        www.businesscardx.com and any other web domain used by the Company to
                                        communicate with users now or in the future);</li>
                                    <li>any mobile, computer, or tablet-based application currently offered by
                                        Company or developed in the future by the Company; and</li>
                                    <li>all other services provided by the Company, as described on the Website
                                        (Items 1 to 3 are collectively referred to as the "Website").</li>
                                </ol><br />



                                This Privacy Policy (“Policy”) sets out how we use, protect, collect and preserve
                                information submitted by Users, including User information gathered by our
                                technology. This Policy should be read in conjunction with any Terms of Use or
                                policies available on the Website.<br /><br />
                                By accessing or submitting any information through the Website, you are
                                agreeing to the terms of this Policy. You are prohibited from accessing or using
                                the Website unless you fully understand and agree to this Policy and all Terms
                                of Use available for review on the Website.<br /><br />

                                If you have any questions about this Policy, please contact us by sending an
                                email with your questions to contact@businesscardx.com before accessing or
                                entering any information on the Website.<br /><br />
                                <h2 className='font-custom font-medium'>2. INFORMATION ABOUT USERS WE COLLECT AND STORE</h2>

                                When you choose to create an account on the Website, we will collect and retain
                                all personal identifying information submitted to us.<br /><br />
                                We will take steps to protect the information submitted to us by Users and we
                                will not disclose any personal identifying information without the consent of
                                Users, unless otherwise set out in this Policy.<br /><br />
                                Personal identifying information may include but is not necessarily limited to:<br /><br />
                                full name, email address, phone number, location, social media links, etc.<br /><br />
                                We will not use personal identifying information to contact you for any reason
                                unrelated to your use of the Website or for commercial reasons unrelated to the
                                business of the Company. Except with your consent or where required by law,
                                we will not provide personal identifying information to any third party.<br /><br />
                                <h2 className='font-custom font-medium'>3. UPLOADING PERSONAL INFORMATION</h2>
                                In order to facilitate their interactions on the Website, Users are able, where
                                they deem appropriate, to upload information including personal identifying
                                information. Users should only upload information to the extent that such
                                information enhances their experience of using the Website.<br /><br />
                                <h2 className='font-custom font-medium'>4. ADDITIONAL DATA COLLECTED</h2>
                                To learn about your experience as a User and to make periodic improvements to
                                the Website and our business, we may collect and retain certain information. We
                                will automatically record information about your activity on the Website. This
                                may include your Internet Protocol (IP) address, operating system, geolocation
                                information, locale preferences, identification numbers associated with your
                                computers and mobile devices, your mobile carrier, date and time stamps
                                associated with transactions, system configuration information, metadata, and
                                other electronic interactions with the Company and the Website.<br /><br />
                                <h2 className='font-custom font-medium'>5. COOKIES</h2>
                                To facilitate the collection of information and to enhance the experience of
                                Users of the Website, we use cookies which may be stored on Users’ computers
                                and/or devices so that we can recognize Users when they return. You may set
                                your browser to notify you when you receive a cookie or to not accept certain
                                cookies. However, changing such settings may result in you not being able to
                                use the Website, either in its entirety or certain features.<br /><br />
                                Using cookies, you may have the option to allow us to save your user ID and
                                login password for future logins to the Website.<br /><br />
                                Cookies that are stored on your computer and/or device after visiting the
                                Website may be recognized for advertising purposes and to show you targeted
                                advertisements after you visit the Website.<br /><br />
                                For more information on the cookies we use or to opt-out, please contact us at
                                contact@businesscardx.com.<br /><br />
                                <h2 className='font-custom font-medium'>6. REMARKETING</h2>
                                We may engage in "remarketing" activities using third-party services, which
                                may include but are not limited to Google Ads, Google Analytics, and Facebook.
                                By using the Website, you consent to allow us to provide information to thirdparty service parties who may present you with advertisements about the
                                Company after you visit the Website. We will provide third-party providers of
                                remarketing services with the minimum amount of information required to
                                facilitate remarketing, and in no circumstances will Company provide any
                                personal identifying information to third-parties for the purpose of remarketing.<br /><br />
                                <h2 className='font-custom font-medium'>7. PERMITTED USES OF DATA</h2>

                                We use data collected from the Website for various purposes, including to:
                                provide the Website to you and accompanying customer support; notify you
                                about updates and changes to the Website; prevent and address technical issues
                                with the Website; monitor usage of the Website.<br /><br />
                                Without restriction, we may use any information you submit through the
                                Website for any purpose we deem appropriate so long as it is anonymous and
                                stripped of any and all personal identifying information.<br /><br />
                                We may use the data collected from the Website, devices, browsers, and any
                                other sources, for its own purposes, both related to and unrelated to improving
                                the Website.<br /><br />
                                <h2 className='font-custom font-medium'>8. LEGAL BASIS FOR PROCESSING PERSONAL IDENTIFYING INFORMATION</h2>
                                We may process your personal identifying information for a number of reasons,
                                including:<br />
                                <ul className='list-disc space-y-4 ml-[22px]'>
                                    <li>performing a contract with you relating to the Website;</li>
                                    <li>you have provided us consent to do so;</li>
                                    <li>fulfilling a legal obligation;</li>
                                    <li>fulfilling our legitimate interests, which are not overridden by your rights;</li>
                                    <li>for payment processing purposes.</li>
                                </ul><br />

                                <h2 className='font-custom font-medium'>9. ACCESSING, CORRECTING & DELETING YOUR PERSONAL INFORMATION</h2>
                                If you wish to access or amend your personal information or to request that we
                                permanently delete your personal information from our servers, please send an
                                email to contact@businesscardx.com. We will ensure your personal information
                                is accessible, amended and/or deleted in a reasonable time after receiving that
                                request. Deleting such information may require us to terminate any account you
                                may have created in its entirety and prevent you from using the Website.<br /><br />

                                So long as your account remains active, we will preserve your personal
                                information in accordance with the Policy.<br /><br />
                                You may decline to share certain personal information with us, in which case
                                you may not be able to use the Website, either in its entirety or certain features.<br /><br />
                                If you have not accessed your account for a total of 3 years, we may terminate
                                your account, without notice, along with any personal information retained by
                                us.<br /><br />
                                When deleting your information, whether by request or due to inactivity, we will
                                use standard electronic means to remove your personal information from our
                                files.<br /><br />
                                We will also retain any and all information that we are required to retain under
                                any applicable laws for the full duration of time required under those laws.<br /><br />
                                <h2 className='font-custom font-medium'>10. KEEPING YOUR DATA SECURE</h2>
                                We will store your information in electronic format on industry-standard servers
                                that we have selected. Except where required to do so by law, we will not store
                                your personal information in any physical or paper-based format.<br /><br />
                                The servers we select to store information engage security software and
                                generally accepted standards to protect personal information. The software
                                used by these servers is intended to prevent unauthorized access or improper
                                use.<br /><br />
                                However, no security system is perfect and there is always a risk of
                                unauthorized parties accessing your personal information. Therefore, we make
                                no guarantees or representations as to the technical or legal compliance of the
                                servers we use. We cannot guarantee the complete protection and security of
                                your personal information.<br /><br />
                                Our servers are located exclusively in Canada.<br /><br />
                                <h2 className='font-custom font-medium'>11. SECURITY BREACHES</h2>

                                In the event the personal information of a User is disclosed as the result of any
                                breach of security of the Website or its servers, regardless of who is at fault, we
                                will take steps to advise all known affected Users within a reasonable
                                timeframe of learning of the breach.<br /><br />
                                <h2 className='font-custom font-medium'>12. THIRD-PARTY APPLICATIONS</h2>
                                To provide an enhanced experience to Users, including to track conversions and
                                analytics, we may engage services and features controlled by third-parties. We
                                cannot control or dictate how any third-party application you use in conjunction
                                with the Website, now or at any point in the future, will treat any personal
                                information you transmit through their servers when using the Website. We
                                advise you to contact these parties directly to review their privacy policies.<br /><br />
                                Without limitation, the Company uses or may use the following third-party
                                applications and services in conjunction with the Website:<br /><br />
                                Google Analytics<br /><br />
                                By using any of these third-party application to interact with the Website, we
                                will not be given access to your login credentials, user name, password,
                                payment information, or any information you may provide directly to these
                                applications.<br /><br />
                                <h2 className='font-custom font-medium'>13. USE OF WEBSITE BY MINORS</h2>
                                With the following restrictions, the Website may be accessed in certain
                                circumstances by persons under the age of 18.<br /><br />
                                We take the following precautions to protect the safety and information of
                                persons under the age of 18:<br /><br />
                                Persons under the age of 13 are prohibited from using the Website without the
                                express permission and direct supervision of a parent or guardian. Persons
                                under the age of 13 are prohibited from submitting any personal identifying
                                information through the Website. The Company will immediately delete any
                                personal identifying information submitted in violation of this Policy.<br /><br />
                                <h2 className='font-custom font-medium'>14. DISCLOSURE OF PERSONAL INFORMATION</h2>
                                In certain situations, we may be required to disclose personal data in response
                                to lawful requests by public authorities, including to meet national security or
                                law enforcement requirements.<br /><br />
                                We may disclose your personal information to third parties:<br /><br />
                                <ol className='list-decimal space-y-4 ml-[22px]'>
                                    <li>In the event that we sell or buy any business or assets, in which case we
                                        may disclose your personal data to the prospective seller or buyer of such
                                        business or assets.</li>
                                    <li>If the Company or its assets are acquired by a third party, in which case
                                        personal data about Users may be one of the transferred assets.</li>
                                    <li>If we are under a duty to disclose or share your personal data in order to
                                        comply with any legal obligation, or in order to enforce or apply our Terms
                                        of Use; or to protect the rights, property, or safety of the Company, our
                                        Users, or others. This includes exchanging information with other
                                        companies and organizations for the purposes of fraud protection and credit
                                        risk reduction.</li>
                                </ol><br />

                                <h2 className='font-custom font-medium'>15. USER REFERRAL SERVICE</h2>
                                You can choose to provide us with the names and email addresses of individuals
                                or organizations that you feel would be interested in learning more about our
                                products and services. When you choose to engage this service, we may send a
                                preliminary email to each address provided to us, inviting the contact to visit
                                our website. We continue to store the contact details in order to track the
                                success of our referral service.<br /><br />
                                <h2 className='font-custom font-medium'>16. AMENDING THIS POLICY</h2>

                                This Policy may be updated and amended from time to time. We reserve the
                                right to change this Policy at any time, and any amended Policy is effective
                                upon posting to the Website. We will make efforts to communicate any changes
                                to this Policy we deem material, in our sole discretion, via email or notifications
                                on the Website. Your continued use of the Website will be deemed acceptance of
                                any amended Policy.<br /><br />
                                <h2 className='font-custom font-medium'>17. CONTACT US</h2>
                                We encourage you to send us questions and inquiries on this Policy and the
                                steps we take to keep your personal information secure. Please send us an
                                email: contact@businesscardx.com<br /><br />
                                We will respond to all inquiries on the Policy within 7 days.
                            </div>

                        </div>
                    </CAccordion>
                </div>
            </div>
            <Footer />
        </div>
    )
}