import { useEffect, useState } from "react";
import { User } from "../types";
import CTextField from "./CTextField";
import { SocialDropDown } from "./SocialDropDown";
import fb from "../assets/images/facebook-logo.svg";
import ig from "../assets/images/instagram-logo.svg";
import lin from "../assets/images/linkedin-logo.svg";
import x from "../assets/images/twitter-x-logo.svg";
import tk from "../assets/images/tiktok.svg";
import whapp from "../assets/images/whatsapp-logo.svg";
import snap from "../assets/images/snapchat.svg";
import { Language, lang } from "../languages";

export function getSocialIcon(arg: string)
{
  switch (arg)
  {
    case "Instagram":
      return ig;
    case "Facebook":
      return fb;
    case "X":
      return x;
    case "TikTok":
      return tk;
    case "LinkedIn":
      return lin;
    case "WhatsApp":
      return whapp;
    case "Snapchat":
      return snap;
    default:
      return "";
  }
}

interface SocialsDynamicProps
{
  items: string[];
  error: User;
  user: User;
  empty: string;
  itemsSetter: (a: string[]) => void;
  userSetter: (a: User) => void;
  color: string;
  textColor: string;
  language: Language;
}

export const SocialsDynamic = ({ ...props }: SocialsDynamicProps) =>
{
  const [items, setItems] = useState(props.items);
  const socialOpts: string[] = [
    "Instagram",
    "Facebook",
    "X",
    "TikTok",
    "WhatsApp",
    "LinkedIn",
    "Snapchat",
  ];

  function removeSocial(platform: string)
  {
    switch (platform)
    {
      case "Instagram":
        props.userSetter({ ...props.user, instagram: "no" });
        break;
      case "Facebook":
        props.userSetter({ ...props.user, facebook: "no" });
        break;
      case "X":
        props.userSetter({ ...props.user, twitter: "no" });
        break;
      case "TikTok":
        props.userSetter({ ...props.user, tiktok: "no" });
        break;
      case "LinkedIn":
        props.userSetter({ ...props.user, linkedin: "no" });
        break;
      case "WhatsApp":
        props.userSetter({ ...props.user, whatsapp: "no" });
        break;
      case "Snapchat":
        props.userSetter({ ...props.user, snapchat: "no" });
        break;
      default:
        return "";
    }
  }
  function addSocial()
  {
    if (items.length < socialOpts.length)
    {
      setItems([...items, ""]);
    }
  }
  function updateSocial(platform: string, value: string)
  {
    switch (platform)
    {
      case "Instagram":
        props.userSetter({ ...props.user, instagram: value });
        break;
      case "Facebook":
        props.userSetter({ ...props.user, facebook: value });
        break;
      case "X":
        props.userSetter({ ...props.user, twitter: value });
        break;
      case "TikTok":
        props.userSetter({ ...props.user, tiktok: value });
        break;
      case "LinkedIn":
        props.userSetter({ ...props.user, linkedin: value });
        break;
      case "WhatsApp":
        props.userSetter({ ...props.user, whatsapp: value });
        break;
      case "Snapchat":
        props.userSetter({ ...props.user, snapchat: value });
        break;
      default:
        return "";
    }
  }
  function getSocial(platform: string)
  {
    switch (platform)
    {
      case "Instagram":
        return props.user.instagram === "no" ? "" : props.user.instagram;
      case "Facebook":
        return props.user.facebook === "no" ? "" : props.user.facebook;
      case "X":
        return props.user.twitter === "no" ? "" : props.user.twitter;
      case "TikTok":
        return props.user.tiktok === "no" ? "" : props.user.tiktok;
      case "LinkedIn":
        return props.user.linkedin === "no" ? "" : props.user.linkedin;
      case "WhatsApp":
        return props.user.whatsapp === "no" ? "" : props.user.whatsapp;
      case "Snapchat":
        return props.user.snapchat === "no" ? "" : props.user.snapchat;
      default:
        return "";
    }
  }
  function getSocialError(platform: string)
  {
    switch (platform)
    {
      case "Instagram":
        return props.error.instagram;
      case "Facebook":
        return props.error.facebook;
      case "Twitter":
        return props.error.twitter;
      case "TikTok":
        return props.error.tiktok;
      case "LinkedIn":
        return props.error.linkedin;
      case "WhatsApp":
        return props.error.whatsapp;
      case "Snapchat":
        return props.error.snapchat;
      case "":
        return props.empty;
      default:
        return "";
    }
  }
  function deleteSocial(index: number)
  {
    setItems([...items.slice(0, index), ...items.slice(index + 1)]);
  }

  useEffect(() =>
  {
    props.itemsSetter(items);
  }, [items]);

  return (
    <>
      <h3 className="font-custom font-medium self-start text-[30px]/[41px] text-button mb-[20px] ">
        {lang.socials[props.language]}
      </h3>
      {props.items.map((e, i) =>
      {
        return (
          <div key={e} className="flex flex-col gap-[15px] lg:w-[393px] w-[344px]">
            <div className="relative flex flex-row justify-between items-start w-full">
              <SocialDropDown
                key={e}
                value={e}
                onChange={(a) =>
                {
                  if (a)
                  {
                    removeSocial(e);
                    setItems([...items.slice(0, i), a, ...items.slice(i + 1)]);
                  }
                }}
                hint={lang.socialMedia[props.language]}
                label={lang.socialMedia[props.language]}
                items={[
                  ...socialOpts
                    .filter((e, i) => !items.includes(e))
                    .map((e, i) =>
                    {
                      return { class: "", image: getSocialIcon(e), text: e };
                    }),
                ]}
              />
              <button
                key={"social_remove_" + e}
                className="absolute z-40 right-0 top-0 rotate-45 text-red-500 p-[3px] font-custom font-normal text-[25px]/[20px]"
                onClick={() =>
                {
                  removeSocial(e);
                  deleteSocial(i);
                }}
              >
                +
              </button>
            </div>
            <div className="flex flex-row ">
              {e !== "Instagram" && e !== "Snapchat" ? (
                <CTextField
                  error={getSocialError(e)}
                  key={"social_" + e}
                  value={getSocial(e)}
                  label={(e ? e + " " : "") + "URL"}
                  hint={(e ? e + " " : "") + "URL"}
                  classname="mb-[22px] flex-grow"
                  onChange={(arg) =>
                  {
                    updateSocial(e, arg);
                  }}
                />
              ) : (
                <CTextField
                  error={getSocialError(e)}
                  key={"social_" + e}
                  mask={[
                    "@",
                    /\S/,
                    /\S/,
                    /\S/,
                    /\S/,
                    /\S/,
                    /\S/,
                    /\S/,
                    /\S/,
                    /\S/,
                    /\S/,
                    /\S/,
                    /\S/,
                    /\S/,
                    /\S/,
                    /\S/,
                    /\S/,
                    /\S/,
                    /\S/,
                    /\S/,
                    /\S/,
                    /\S/,
                    /\S/,
                    /\S/,
                    /\S/,
                    /\S/,
                    /\S/,
                    /\S/,
                    /\S/,
                    /\S/,
                    /\S/,
                  ]}
                  value={getSocial(e)}
                  label={
                    e === "Snapchat"
                      ? `Snapchat ${lang.username[props.language]}`
                      : (e ? e + " " : "") + lang.handle[props.language]
                  }
                  hint={
                    e === "Snapchat"
                      ? `Snapchat ${lang.username[props.language]}`
                      : (e ? e + " " : "") + lang.handle[props.language]
                  }
                  classname="mb-[22px] flex-grow"
                  onChange={(arg) =>
                  {
                    updateSocial(e, arg);
                  }}
                />
              )}
            </div>
          </div>
        );
      })}
      <button
        onClick={() =>
        {
          addSocial();
        }}
        className={"lg:w-[393px] w-[344px] font-custom font-medium shadow-custom text-[14px]/[25px] text-center hover:bg-secondary bg-primary rounded-[26px]  lg:pt-[10px] pt-[12px] lg:pb-[10px] pb-[12px] " + props.textColor}
        style={{
          backgroundColor: props.color,
        }}
      >
        + {`${lang.add[props.language]} ${lang.social[props.language]}`}
      </button>
    </>
  );
};
