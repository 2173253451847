/* eslint-disable no-useless-concat */
import { useRef, useState } from "react";
import { Alert } from "@mui/material";
import arrow from '../assets/images/arrow.svg';
import { useOutsideAlerter } from "./SocialDropDown";
import Incrementer from "./Incrementer";

interface CardSelectorProps
{
    classname?: string,
    items: { orderNumber: string, fullName: string, onChange: (a: number) => void, value: number, class?: string }[],
    value?: string,
    label?: string,
    required?: boolean,
    hint?: string
    error?: string
    errorClass?: string
    onChange: CallableFunction & ((arg: string) => void),
}

export const CardSelector = ({ ...props }: CardSelectorProps) =>
{
    const [open, setOpen] = useState(false);
    const select = useRef<HTMLDivElement>(null);
    useOutsideAlerter(select, () => { setOpen(false) });

    const handleOpen = () =>
    {

        setOpen(!open);
    };
    const handleKey = (event: any) =>
    {

        if (event.key === 'Enter')
        {
            handleOpen();
        }
    };
    return <div ref={select} className="w-full ">
        <div className={props.classname ?? '' + ' flex flex-row flex-grow '}>
            <div onKeyDown={handleKey} tabIndex={0} className={" flex-grow flex flex-col justify-start font-custom font-light tracking-[0.05em]  border-button border-[2px] " + (open ? ' border-b-transparent shadow-none rounded-t-[3px]' : ' rounded-[3px] shadow-custom  ')}>
                <input type="select" className="hidden" onChange={() => { }} value={props.value} />
                <div className="relative">
                    {props.label ?
                        <div className="absolute top-0 left-0 ml-[21px] mt-[-10px] px-[4px] text-[10px]/[19px] bg-white">
                            {(props.label) + (props.required ? ' *' : '')}
                        </div> : ''
                    }
                </div>
                <div onClick={handleOpen} className={(props.value === '' ? 'block' : 'hidden') + " flex justify-between font-light w-full text-[20px]/[32px] px-[24px] pt-[11px] pb-[10px] select-none cursor-pointer "}>
                    {props.hint}
                    <img alt='arrow' className={(open ? "rotate-180 duration-75" : 'rotate-0 duration-75') + ' w-[16px] h-[7px] self-center'} src={arrow} />
                </div>
                <div onClick={handleOpen} className={(props.value === '' ? 'hidden' : 'block') + " flex justify-between font-light  text-[20px]/[32px] px-[24px] pt-[11px] pb-[10px] select-none cursor-pointer pointer-events-auto"}>
                    <div className="flex flex-row gap-[22px]">
                        {props.value}
                    </div>
                    <img alt='arrow' className={(open ? "rotate-180 duration-75" : 'rotate-0 duration-75') + ' w-[16px] h-[7px] self-center'} src={arrow} />
                </div>

                <div className="relative">
                    <div className={(open ? 'block' : 'hidden') + " absolute z-[51] mt-[-2px] border-black border-b-[2px] w-full opacity-25"} />
                    <div className={"absolute inset-[-1.6px] absolute-offset z-50  "}>
                        <div onClick={() => { }} className={open ? ' max-h-[200px] flex flex-col overflow-y-auto social-dropdown-scrollbar border-t-0 border-button border-[2px] shadow-custom rounded-b-[3px] ' : 'hidden '}>
                            {props.items.map((element, i) =>
                            {
                                return <div key={i} className=" basis-1/5 z-50 text-[16px]/[32px] bg-white">
                                    <div className=" border-slate-500 border-[1px] opacity-25 " />
                                    <div className="flex flex-row justify-between items-center w-full ">
                                        <div className="flex flex-col py-[10px] px-[24px] font-light">
                                            <div className={element.class + " text-[14px]/[25px]  cursor-pointer pointer-events-none"} onClick={(a) => { /*props.onChange(a.currentTarget.innerText);*/ }}>{element.orderNumber}</div>
                                            <div className="text-[10px]/[10px]">
                                                {element.fullName}
                                            </div>
                                        </div>
                                        <div className="pr-[31px]">
                                            <Incrementer textArea='lg:w-[40px] w-[40px]' buttonSize="w-[17px]" w="lg:w-[99px] w-[99px]" h="lg:h-[26px] h-[26px]" textSize='text-[12px]/[normal]' value={element.value} minimum={0} onDecrement={() => { element.onChange(element.value - 1); }} onIncrement={() => { element.onChange(element.value + 1); }} />
                                        </div>
                                    </div>
                                </div>
                            })}</div>
                    </div>
                </div>
            </div>
        </div>
        <div className={(props.error ? 'block ' : 'hidden ') + (props.errorClass ?? ' mt-[-28px]') + ' rounded-[6px] overflow-clip'}>
            <Alert style={{ fontFamily: 'Satoshi-Variable', fontWeight: '300', fontSize: '15px', lineHeight: '20px' }} severity="warning">{props.error}</Alert>
        </div>
        <div className={(open ? 'block' : 'hidden') + " pointer-events-none fixed top-0 left-0 w-screen h-screen "}>

        </div>
    </div >
}