import React, { useEffect, useState } from "react";
import { Billing, Card, CardObject, Discount, Order as OrderType, Shipping } from "../types";
import { collection, doc, getDocs, getFirestore, query, where } from "firebase/firestore";
import { Attachments, Email } from "../types";
import
{
  HBool,
  Receipt,
  getCurrentTimeInEST,
  getEmailSubject,
  getOrderSummary,
  getReceipt,
  orderDescriptionToType,
  orderToItemized,
  orderToPurchase,
} from "../utils/features";
import generateQRCode from "../utils/qr_code_generator";
import { PurchaseType } from "../redux/feature/cartSlice";
import { CircularProgress } from "@mui/material";
import { db } from "../services/firebase";

interface OrderProps
{
  order: OrderType;
}

export const Order: React.FC<OrderProps> = ({ order }) =>
{
  const [showDetails, setShowDetails] = useState(false);
  const [billing, setBilling] = useState<Billing | null>(order.billing!);
  const [shipping, setShipping] = useState<Shipping | null>(order.shipping!);
  const [loading, setLoading] = useState(false);
  const [card, setCard] = useState<Card[]>([]);
  const [hasLoaded, setHasLoaded] = useState(false);

  async function sendEmail(mail: Email)
  {
    try
    {
      fetch(process.env.REACT_APP_SENDMAIL_URL!, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(mail),
      }).then((response) =>
      {
        if (response.status === 200)
        {

        }
      });
    } catch (error)
    {
      console.error(error);
    }
  }

  async function sendReceipt()
  {
    const logo = order.uploadLogoLater ? true : (order.logo.filter((e) => { return e.length > 0 }).length >= 1)
    await sendEmail({
      dest: order.buyerEmail,
      fullName: "businesscardX",
      message: getReceipt({
        orderNumber: card[0].orderNumber,
        company: order.company,
        logo: logo ? HBool.Yes : HBool.No,
        address: `${shipping?.streetAddress}, ${shipping?.city}, ${shipping?.stateProvince}, ${shipping?.zipCode}, ${shipping?.country}`,
        date: getCurrentTimeInEST('YYYY-MM-DD ** h:mmA', order.date.toDate()).replace('**', 'at'),
        email: shipping?.emailAddress,
        phone: shipping?.phone,
        shippingMethod: order.shippingMethod,
        tax: order.tax,
        items: orderToItemized(order, order.currency),
        total: order.grandTotal,
        color: order.color,
        currency: order.currency,
        discount: { affiliateName: order.discountAffiliate, code: order.discountCode, totalDiscounted: order.discountAmount } as Discount
      } as Receipt),
      sender: "", // Put the sender email here
      subject: getEmailSubject(orderDescriptionToType(order.type) as PurchaseType),
    });
    alert(`Receipt sent to ${order.buyerEmail}`)
  }

  const sendSummary = async () =>
  {
    let allAttachments: Attachments[] = [];
    for (let i = 0; i < order.cards.length; i++)
    {
      const qrcode = generateQRCode(
        process.env.REACT_APP_PROFILE_URL! + order.cards[i]
      );
      const newAttachment: Attachments = {
        filename: `${order.cards[i]}.png`,
        content: qrcode.split('data:image/png;base64,')[1],
        encoding: "base64"
      }
      allAttachments.push(newAttachment)
    }
    await sendEmail({
      dest: process.env.REACT_APP_EMAIL!,
      fullName: "businesscardX",
      message: getOrderSummary(
        orderToPurchase({ ...order, billing: billing, shipping: shipping } as OrderType, card, order.currency),
        order.currency,
        card.map((e, i) => { return { name: e.name, orderNumber: e.orderNumber, personalized: e.personalized } as CardObject }), order.date.toDate()),
      sender: process.env.REACT_APP_EMAIL!,
      subject: "New Order Arrived",
      attachments: allAttachments
    });
    alert(`Summary sent to ${process.env.REACT_APP_EMAIL!}`)
  };

  async function getDetails()
  {
    const billing = ((await getDocs(collection(doc(db, "orders", order.id), "billing"))).docs[0]?.data()) as Billing;
    const shipping = ((await getDocs(collection(doc(db, "orders", order.id), "billing"))).docs[0]?.data()) as Shipping;
    const cards = await Promise.all(order.cards.map(async (card, idx) =>
    {
      let orderNumber = orderDescriptionToType(order.type) === PurchaseType.ReplacementElite ? (card as {
        extra: number;
        orderNumber: string;
      }).orderNumber : card
      const cardRef = query(
        collection(db, "cards"),
        where("orderNumber", "==", orderNumber));
      const cardSnapshot = await getDocs(cardRef);
      return cardSnapshot.docs[0]?.data() as Card
    }))

    setBilling(billing)
    setShipping(shipping)
    setCard(cards)
    setLoading(false)
    setHasLoaded(true)

  }

  function handleShowDetails()
  {
    setShowDetails(!showDetails)
    if (!hasLoaded)
    {
      setLoading(true);
      getDetails()
    }
  }

  return (
    <div className="border border-gray-200 rounded p-4 ">
      <h2 className="text-xl font-bold mb-2">
        Order:{" "}
        {orderDescriptionToType(order.type) === PurchaseType.ReplacementElite ? ((order.cards as { orderNumber: string, extra: number }[])[0].orderNumber) : (order.cards as string[])[0]}
      </h2>
      <h2 className="text-xl font-bold mb-2">
        Email:{" "}
        {typeof order.date !== "string" && order.date !== undefined
          ? `${order.buyerEmail} - ${order.date.toDate().toLocaleDateString()}`
          : order.buyerEmail}
      </h2>

      <button
        className="text-blue-500 underline"
        onClick={() => handleShowDetails()}
      >
        {showDetails ? "Hide Details" : "Show Details"}
      </button>
      {
        loading && (
          <div className="w-full flex items-center justify-center">
            <CircularProgress color="inherit" />
          </div>)
      }
      {(showDetails && !loading) && (
        <div className="mt-4">
          <div className="flex lg:flex-row flex-col gap-[30px]">
            <div>
              <h3 className="text-lg font-bold mb-2">Order Details:</h3>
              <p>Cards: {orderDescriptionToType(order.type) === PurchaseType.ReplacementElite ? (order.cards as { extra: number, orderNumber: string }[]).map((r) => { return r.orderNumber }).join(', ') : order.cards.join(", ")}</p>
              <p>Type: {order.type}</p>
              <p>Company: {order.company}</p>
              <p>Blank: {String(order.blank)}</p>
              <p>Upload Logo Later: {String(order.uploadLogoLater)}</p>
              <p>Logos:</p>
              {order.logo.map((e: string) =>
              {
                if (e.length > 0)
                {
                  return <a href={e}>
                    <img
                      className="w-[70px] h-auto"
                      src={e}
                    ></img>
                  </a>
                } else { return <></> }
              })}
              <p>Color: {order.color}</p>
              <p>Extra Cards: {order.extraCards}</p>
              <p>Currency: ${order.currency}</p>
              <p>Grand Total: ${order.grandTotal}</p>
              <p>Tax: ${order.tax}</p>
              {order.discountCode ? (<>
                <p>Affiliate: {order.discountAffiliate}</p>
                <p>Discount Code: {order.discountCode}</p>
                <p>Discount: ${order.discountAmount}</p></>) : <></>
              }
              <p>
                Date:{" "}
                {typeof order.date === "string" || order.date === undefined
                  ? order.date
                  : order.date.toDate().toLocaleDateString()}
              </p>
              <p>Shipping Method: {order.shippingMethod}</p>
              <p>Buyer Email: {order.buyerEmail}</p>


              {orderDescriptionToType(order.type) !== PurchaseType.Black && orderDescriptionToType(order.type) !== PurchaseType.ReplacementBlack ?
                <p>
                  Personalized Cards:{" "}
                  {Array.isArray(order.personalizedCards)
                    ? order.personalizedCards.join(", ")
                    : order.personalizedCards}
                </p> : <></>}
              {orderDescriptionToType(order.type) === PurchaseType.ReplacementElite ? (
                <>Copies:{" "}
                  <ul>
                    {(order.cards as { extra: number, orderNumber: string }[]).map((e, i: number) => { return <li className="mb-[5px]">{e.orderNumber} - X {e.extra}</li> })}
                  </ul></>) : <></>}

              <h3 className="text-lg font-bold mb-2">Billing Details:</h3>
              {billing && (
                <div>
                  <p>First Name: {billing.firstName}</p>
                  <p>Last Name: {billing.lastName}</p>
                  <p>Email Address: {billing.emailAddress}</p>
                  <p>Street Address: {billing.streetAddress}</p>
                  <p>State/Province: {billing.stateProvince}</p>
                  <p>City: {billing.city}</p>
                  <p>Zip Code: {billing.zipCode}</p>
                  <p>Phone: {billing.phone}</p>
                  <p>Country: {billing.country}</p>
                </div>
              )}

              <h3 className="text-lg font-bold mb-2">Shipping Details:</h3>
              {shipping && (
                <div>
                  <p>First Name: {shipping.firstName}</p>
                  <p>Last Name: {shipping.lastName}</p>
                  <p>Email Address: {shipping.emailAddress}</p>
                  <p>Street Address: {shipping.streetAddress}</p>
                  <p>State/Province: {shipping.stateProvince}</p>
                  <p>City: {shipping.city}</p>
                  <p>Zip Code: {shipping.zipCode}</p>
                  <p>Phone: {shipping.phone}</p>
                  <p>Country: {shipping.country}</p>
                </div>
              )}



              <div className='flex flex-row gap-[15px]'>
                <button onClick={async () =>
                {
                  sendReceipt();
                }} className='mt-[20px] bg-primary p-[10px] text-custom rounded-[10px]'>
                  Send Receipt
                </button>
                <button onClick={async () =>
                {
                  sendSummary();
                }} className='mt-[20px] bg-primary p-[10px] text-custom rounded-[10px]'>
                  Send Summary
                </button>
              </div>

            </div>
            <div className="flex flex-row flex-wrap">
              {card.map((e, i) =>
              {
                if (e)
                {
                  return (
                    <div className="flex flex-col">
                      <div className="m-[5px] rounded-[4px] border-[1px] p-[5px] border-button ">

                        <div><b>Order: </b>{e.orderNumber}</div>
                        <div><b>Name: </b>{e.name}</div>
                        <div><b>Company: </b>{e.companyName}</div>
                        <div><b>Type: </b>{e.type}</div>
                        <div><b>Color: </b>{e.color}</div>
                        <div><b>Blank: </b>{String(e.blank)}</div>
                        <div><b>Personalized: </b>{String(e.personalized)}</div>
                        <div><b>Logo: </b></div>
                        <div className="flex flex-row">
                          {e.logo && e.logo[0] && <img className="w-[117px] h-[117px] object-cover" src={e.logo[0]} />}
                          {e.logo && e.logo[1] && <img className="w-[117px] h-[117px] object-cover" src={e.logo[1]} />}
                          {e.logo && e.logo[2] && <img className="w-[117px] h-[117px] object-cover" src={e.logo[2]} />}
                        </div>
                        <div><b>QR: </b></div>
                        <a download={`${e.orderNumber}.png`} href={e.qrCode}>
                          <div className="w-[200px]" ><img src={e.qrCode} /></div>
                        </a>
                      </div>
                    </div>)
                }
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
