import
{
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useReducer,
  useState,
} from "react";
import { User } from "firebase/auth";

type AuthActions =
  | { type: "SIGN_IN"; payload: { user: User } }
  | { type: "SIGN_OUT" };

type AuthState =
  | {
    state: "SIGNED_IN";
    currentUser: User;
  }
  | {
    state: "SIGNED_OUT";
  }
  | {
    state: "UNKNOWN";
  };

type Phone = {
  number: string;
  ext: string;
  type: string;
};
type Location = {
  city: string;
  state_province: string;
  country: string;
};

type Social = {
  url: string;
  platform: string;
};
type Address = {
  name: string;
  line1: string;
  line2: string;
  city: string;
  state_province: string;
  country: string;
  zip: string;
};
type Media = {
  img: File | null;
  title: string;
  url: string;
  message: string;
};

const AuthReducer = (state: AuthState, action: AuthActions): AuthState =>
{
  switch (action.type)
  {
    case "SIGN_IN":
      return {
        state: "SIGNED_IN",
        currentUser: action.payload.user,
      };
    case "SIGN_OUT":
      return {
        state: "SIGNED_OUT",
      };
  }
};

type AuthContextProps = {
  state: AuthState;
  dispatch: (value: AuthActions) => void;
};

export const AuthContext = createContext<AuthContextProps>({
  state: { state: "UNKNOWN" },
  dispatch: (val) => { },
});

const AuthProvider = ({ children }: { children: ReactNode }) =>
{
  const [state, dispatch] = useReducer(AuthReducer, { state: "UNKNOWN" });

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuthState = () =>
{
  const { state } = useContext(AuthContext);
  return {
    state,
  };
};

const useSignIn = () =>
{
  const { dispatch } = useContext(AuthContext);
  return {
    signIn: (user: User) =>
    {
      dispatch({ type: "SIGN_IN", payload: { user } });
    },
  };
};

const useSignOut = () =>
{
  const { dispatch } = useContext(AuthContext);
  return {
    signOut: () =>
    {
      dispatch({ type: "SIGN_OUT" });
    },
  };
};

export type OnboardingUser = {
  photo: string | undefined;
  name: string | undefined;
  lastName: string | undefined;
  email: string[] | undefined;
  bio: string | undefined;
  website: string[] | undefined;
  phone: Phone[] | undefined;
  jobTitle: string | undefined;
  company: string | undefined;
  industry: string | undefined;
  city: string | undefined;
  stateProvince: string | undefined;
  country: string | undefined;
  linkedin: string | undefined;
  facebook: string | undefined;
  instagram: string | undefined;
  twitter: string | undefined,
  whatsapp: string | undefined;
  tiktok: string | undefined;
  snapchat: string | undefined;
  finishedCreating: boolean;
  customUrl: string | undefined;
};

export interface OnboardingUserContextInterface
{
  user: OnboardingUser;
  setUser: Dispatch<SetStateAction<OnboardingUser>>;
}

const defaultState = {
  user: {
    photo: "",
    name: "",
    lastName: "",
    email: [],
    bio: "",
    website: [],
    phone: [],
    jobTitle: "",
    company: "",
    industry: "",
    city: "",
    stateProvince: "",
    country: "",
    linkedin: "",
    facebook: "",
    instagram: "",
    whatsapp: "",
    twitter: "",
    tiktok: "",
    snapchat: "",
    customUrl: '',
    finishedCreating: false,
  },
  setUser: (user: OnboardingUser) => { },
} as OnboardingUserContextInterface;

export const UserContext = createContext(defaultState);

type UserProvideProps = {
  children: ReactNode;
};

export default function OnboardingUserProvider({ children }: UserProvideProps)
{
  const [user, setUser] = useState<OnboardingUser>({
    photo: "",
    name: "",
    lastName: "",
    email: [],
    bio: "",
    website: [],
    phone: [{ number: '', ext: '', type: '' }],
    jobTitle: "",
    company: "",
    industry: "",
    city: "",
    stateProvince: "",
    country: "",
    linkedin: "",
    facebook: "",
    twitter: "",
    instagram: "",
    whatsapp: "",
    tiktok: "",
    snapchat: "",
    finishedCreating: false,
    customUrl: ''
  });

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
}

export
{
  useAuthState,
  useSignIn,
  useSignOut,
  AuthProvider,
  OnboardingUserProvider,
};
