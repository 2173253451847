import React, { useState } from "react";
import
{
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";
import { auth1, db, useAuth } from "../services/firebase";
import facebook from "../assets/images/facebook.svg";
import google from "../assets/images/google.svg";
import apple from "../assets/images/apple.svg";
import
{
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";

const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

interface LoginProps
{
  classname?: string;
}

export const FacebookLogin = ({ ...props }: LoginProps) =>
{
  const auth = useAuth();
  const handleFacebookLogin = async () =>
  {
    try
    {
      const result = await signInWithPopup(auth, facebookProvider);
      // Login successful, perform any additional actions
      const user = result.user;

    } catch (error)
    {

    }
  };

  return (
    <div className={props.classname}>
      <button onClick={handleFacebookLogin}>
        <img src={facebook} />
      </button>
    </div>
  );
};

export const handleLogin = async (email: string, password: string) =>
{
  try
  {
    await signInWithEmailAndPassword(auth1, email, password);
    return "success";
    // Login successful, perform any additional actions
  } catch (error: any)
  {

    return error;
  }
};

export const Login = () =>
{
  const auth = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleLogin = async () =>
  {
    try
    {
      await signInWithEmailAndPassword(auth, email, password);
      // Login successful, perform any additional actions
    } catch (error: any)
    {
      setError(error.message);
    }
  };

  return (
    <div>
      <h2>Login</h2>
      {error && <p>{error}</p>}
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={handleLogin}>Login</button>
    </div>
  );
};

export const SignUp = () =>
{
  const auth = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  return (
    <div>
      <h2>Sign Up</h2>
      {error && <p>{error}</p>}
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <input
        type="password"
        placeholder="Confirm Password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
      />
      <button onClick={() => { }}>Sign Up</button>
    </div>
  );
};

export const handleSignUp = async (
  email: string,
  password: string,
  confirmPassword: string
) =>
{
  if (password !== confirmPassword)
  {
    //alert("Passwords don't match");
    return "Passwords don't match";
  }
  try
  {
    await createUserWithEmailAndPassword(auth1, email, password).then(
      async (result) =>
      {
        const emailArray = [email];
        const ref = doc(db, "users", result.user.uid);
        const docRef = await setDoc(ref, {
          orderNumber: "",
          finishedCreating: false,
          email: emailArray,
        })
          .then((re) =>
          {
            return re;
          })
          .catch((e) =>
          {
            return e.message;
          });
      }
    );
    // Sign-up successful, perform any additional actions
  } catch (error: any)
  {
    return error.message;
  }
};

export const Apple = ({ ...props }: LoginProps) =>
{
  return (
    <div className={props.classname}>
      <button disabled>
        <img src={apple} />
      </button>
    </div>
  );
};

export const GoogleSignIn = ({ ...props }: LoginProps) =>
{
  const auth = useAuth();
  const signInGoogle = async () =>
  {
    signInWithPopup(auth, googleProvider)
      .then(async (result) =>
      {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential?.accessToken;
        const user = result.user;
        const ref = doc(db, "users", result.user.uid);
        const document = await getDoc(ref);
        if (document.exists())
        {
          await updateDoc(ref, { googleSignIn: true })

        } else
        {
          await setDoc(ref, {
            googleSignIn: true,
            email: [user.email],
          })
        }
      })
      .catch((error) =>
      {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        const credential = GoogleAuthProvider.credentialFromError(error);
      });
  };
  return (
    <button onClick={signInGoogle} className="flex items-center justify-between font-custom text-button w-[333px] shadow-custom  lg:text-[20px] text-[16px]  text-center tracking-[0.05em] hover:bg-secondary bg-white rounded-[26px]  lg:mx-[10px] lg:pt-[10px] pt-[12px] lg:pb-[14px] pb-[12px] px-[20px]">
      <img src={google} width={30} height={30} />
      <div> Continue with Google</div>
      <div></div>
    </button>
  );
};
