import { useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../CheckoutForm";
import stripePromise from "../../services/stripe";

interface PaymentProps
{
  onStripeFinishLoading: Function,
  onPaymentComplete: () => Promise<void>,
  amount: number,
  currency: string,
  email: string,
  referral?: string,
}

function PaymentScreen({ ...props }: PaymentProps)
{
  const [clientSecret, setClientSecret] = useState("");
  const useAmount = props.amount;
  const useCurrency = props.currency;
  const useEmail = props.email;
  const useReferral = props.referral
  useEffect(() =>
  {

    fetch(
      process.env.REACT_APP_STRIPE_URL!,
      {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ amount: useAmount, currency: useCurrency, email: useEmail, referral: useReferral }),
      }
    ).then(async (r) =>
    {
      //
      const { clientSecret } = await r.json();
      setClientSecret(clientSecret);
      //
    });


  }, []);

  return (
    <>
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm onPaymentComplete={props.onPaymentComplete} totalAmount={props.amount} onStripeFinishLoading={props.onStripeFinishLoading} />
        </Elements>
      )}
    </>
  );
}

export default PaymentScreen;