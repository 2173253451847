import { useEffect, useState } from "react";

interface SplashProps
{
    imageUrl: string;
    duration: number;
    nextComponent: JSX.Element;
}


const Splash: React.FC<SplashProps> = ({
    imageUrl,
    duration,
    nextComponent,
}) =>
{
    const [showImage, setShowImage] = useState<boolean>(true);

    useEffect(() =>
    {
        const timer = setTimeout(() =>
        {
            setShowImage(false);
        }, duration);

        return () => clearTimeout(timer);
    }, [duration]);
    //TODO Create theme for the colors
    return (
        <div className='min-h-[100hv] w-full m-0 p-0'>
            {showImage &&
                <div className='absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 '>
                    <img className='max-w-full max-h-full w-[275px] h-auto' alt='businesscardX logo' src={imageUrl} />
                </div>}
            {!showImage && nextComponent}
        </div>
    );
};
export default Splash;