import
{
  GoogleSignIn,
  handleLogin,
} from "../../auth/auth";
import Navbar from "../../components/Navbar";
import square_left from "../../assets/images/login-left-squares.svg";
import square_right from "../../assets/images/login-right-squares.svg";
import logo from "../../assets/images/bcx_logo.svg";
import CTextField from "../../components/CTextField";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Alert } from "@mui/material";

export const Login = () =>
{
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({ email: '', password: '' });
  const navigate = useNavigate();
  async function handleSubmit()
  {
    let valid = true
    let newErrors = { email: '', password: '' }
    if (!email)
    {
      valid = false;
      newErrors.email = 'Please provide an email'
    }
    if (!password)
    {
      valid = false;
      newErrors.email = 'Please provide your password'
    }
    if (valid)
    {
      await handleLogin(email, password).then((response) =>
      {
        if (response === "success")
        {
          navigate("/profile");
        } else
        {

          if (response.message === 'Firebase: Error (auth/wrong-password).')
          {
            newErrors.password = 'Invalid credentials. Please double-check them and try again'
          } else if (response.message === 'Firebase: Error (auth/user-not-found).')
          {
            newErrors.password = 'User Not Found. Please check the email address provided and try again.'
          } else if (response.code === 'auth/too-many-requests')
          {
            newErrors.password = 'Too Many Requests. Please wait a few minutes and try again.'
          }
        }
      });
    }
    setErrors(newErrors);
  }
  return (
    <div className="">
      <Navbar classname="lg:hidden block mb-[21px]" hidecurr alternate />
      <div className="flex lg:flex-row flex-col justify-between ">
        <div className="lg:flex flex-col  hidden ">
          <img className="w-auto h-full" src={square_left} alt="" />
        </div>

        <div className="flex flex-col mb-[66px] lg:ml-[-146px]">
          <Navbar classname="lg:block hidden self-center mb-[40px]" alternate />
          <div className="self-center flex flex-col justify-center ">
            <h2 className="font-custom font-medium self-center text-center text-[57px]/[77px] lg:mb-[20px] mb-[18px]">
              Welcome!
            </h2>
            <div className="lg:hidden block border-primary border-[2px] w-[85px] self-center mb-[36px]" />

            <div className="lg:hidden block self-center mb-[31px]">
              <img className="w-[144px]" src={logo} alt="businesscardX logo" />
            </div>

            <h4 className="font-custom font-light lg:text-[20px]/[32px] text-[14px]/[25px] lg:tracking-normal tracking-[0.05em] text-center mb-[31px]">
              Sign In With Either
            </h4>
            <div className="flex flex-row self-center justify-center gap-[24px]">

              <GoogleSignIn classname="self-center" />

            </div>
            <div className="border-primary border-[1px] w-[85px] self-center my-[36px]" />
            <div className="flex flex-col mb-[30px] self-center">
              <CTextField
                value={email}
                onChange={(a) =>
                {
                  setEmail(a);
                }}
                error={errors.email}
                label="Email"
                w="w-[354px]"
                classname="mb-[39px]"
              />

              <CTextField
                label="Password"
                w="w-[354px]"
                type="password"
                value={password}
                onChange={(a) =>
                {
                  setPassword(a);
                }}
                classname="lg:mb-[11px] mb-[21px]"
              />
              <div className="lg:block hidden font-custom font-light text-secondary text-[10px]/[14px] underline mb-[10px]">
                <Link to="/change-password">Forgot Password</Link>
              </div>

              <div className={(errors.password ? 'block' : 'hidden') + ' w-[354px] mb-[24px] rounded-[6px] overflow-clip'}>
                <Alert style={{ fontFamily: 'Satoshi-Variable', fontWeight: '300', fontSize: '15px', lineHeight: '20px' }} severity="warning">{errors.password}</Alert>
              </div>

              <button
                className="font-custom font-medium text-button shadow-custom  lg:text-[20px]/[27px] text-[16px]/[25px]  text-center tracking-[0.05em] hover:bg-secondary bg-primary rounded-[26px]  lg:mx-[10px] lg:mb-0 mb-[28px] lg:pt-[10px] pt-[12px] lg:pb-[14px] pb-[12px]"
                onClick={() => { handleSubmit() }}
              >
                Login
              </button>
              <div className="lg:hidden block self-center font-custom font-light text-secondary text-[18px]/[25px] underline">
                <Link to="/change-password">Forgot Password</Link>
              </div>
            </div>

            <h4 className="font-custom font-light lg:text-[20px]/[32px] text-[14px]/[25px] lg:tracking-normal tracking-[0.05em] self-center lg:text-left text-center lg:mb-[34px] mb-[22px] lg:w-[303px] w-[229px]">
              If you don't have an account, please sign up here.
            </h4>
            <button
              className="font-custom font-medium text-white shadow-custom  lg:text-[20px]/[27px] text-[16px]/[25px]  text-center tracking-[0.05em] hover:bg-inset bg-button rounded-[26px]  mx-[10px] lg:pt-[10px] pt-[12px] lg:pb-[14px] pb-[12px]"
              onClick={() =>
              {
                navigate("/signup");
              }}
            >
              Sign Up
            </button>
          </div>
        </div>
        <div className=" lg:flex flex-col hidden  ">
          <img className=" h-full" src={square_right} alt="" />
        </div>
      </div>
    </div>
  );
};
