import { MouseEventHandler, useState } from "react";
import incre from '../assets/images/increment.svg';
import decre from '../assets/images/decrement.svg';




interface IncrementerProps
{
    onIncrement: CallableFunction,
    onDecrement: CallableFunction,
    minimum: number,
    maximum?: number,
    classname?: string,
    value: number
    w?: string
    h?: string
    textSize?: string
    buttonSize?: string
    textArea?: string
}

const Incrementer = ({ ...props }: IncrementerProps) =>
{
    props.maximum = props.maximum ?? Infinity;

    const onIncrement = () =>
    {


        if (props.value + 1 > props.maximum!)
            return
        props.onIncrement();

    };
    const onDecrement = () =>
    {

        if (props.value - 1 < props.minimum)
            return
        props.onDecrement();

    };

    return <div className={props.classname}>
        <div className={(props.w ?? 'w-[201px]') + ' ' + (props.h ?? ' h-[52px]') + " flex flex-row rounded-[26px] items-center justify-center bg-white border-text border-[2px]"}>
            <button className={(props.buttonSize ?? 'w-[30px]') + ' h-[50px] flex flex-col justify-center items-center ' + (props.value === props.minimum ? 'opacity-50' : '')} onClick={onDecrement}>
                <img src={decre} alt="" />
            </button>
            <div className={(props.textSize ?? 'text-[20px]/[27px]') + ' ' + (props.textArea ?? 'w-[75px]') + " font-custom font-medium text-center"}>{props.value}</div>
            <button className={(props.buttonSize ?? 'w-[30px]') + " h-[50px] flex flex-col justify-center items-center " + (props.value === props.maximum ? 'opacity-50' : '')} onClick={onIncrement}>
                <img src={incre} alt="" />
            </button>
        </div>
    </div>;
}

export default Incrementer;
