import React, { useContext, useEffect, useState } from 'react'
import Navbar from '../../components/Navbar'
import square_left from "../../assets/images/onboarding4-left-squares.svg";
import square_right from "../../assets/images/onboarding4-right-squares.svg";
import CTextField from '../../components/CTextField'
import { Navigate, useNavigate } from 'react-router-dom';
import { OnboardingUser, UserContext } from '../../components/contexts/UserContext';
export const Onboarding4 = () =>
{
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const [errors, setErrors] = useState({ city: '', stateProvince: '', country: '' });

  function handleSubmit()
  {
    const newError = { city: '', stateProvince: '', country: '', }

    let valid = true;
    if (!user.city?.trim())
    {
      newError.city = "Please provide your city's name"
      valid = false;
    }
    if (!user.stateProvince?.trim())
    {
      newError.stateProvince = "Please provide your State/Province's name"
      valid = false;
    }
    if (!user.country?.trim())
    {
      newError.country = "Please provide your country's name"
      valid = false;
    }

    setErrors(newError);
    if (valid)
    {
      navigate('/onboarding-5');
    }
  }
  return (
    <div className="">
      <Navbar classname="lg:hidden block mb-[24px]" hidecurr alternate />
      <div className="lg:relative flex lg:flex-row flex-col justify-between">
        <div className="lg:block hidden h-full justify-start z-50 pointer-events-none">
          <img className=" max-h-[95%] h-auto" src={square_left} />
        </div>
        <div className="flex flex-col lg:absolute lg:left-[50%] lg:translate-x-[-50%]  pb-[66px]">
          <Navbar classname="lg:block hidden self-start mb-[40px] " alternate />
          <div className="self-center flex flex-col justify-center lg:mx-0 mx-[25px]">
            <h2 className="lg:block hidden w-[457px] font-custom font-medium self-center text-center text-[57px]/[77px]  mb-[38px] ">
              Tell Your Network Where You Are
            </h2>

            <h2 className="lg:hidden block w-[329px] font-custom font-medium self-center text-center text-[30px]/[41px]  lg:mb-[27px] mb-[22px]">
              Find Professionals Nearby
            </h2>

            <h4 className="w-[280px] font-custom font-light lg:self-center lg:text-left lg:text-[20px]/[32px] text-[14px]/[25px] tracking-[0.05em] lg:mb-[38px] mb-[31px] ">
              You can always change this info later.
            </h4>
            <div className='relative self-center'>
              <CTextField error={errors.city} value={user.city} onChange={(a) => { setUser({ ...user, city: a }) }} label='City' w='lg:w-[354px] w-[347px]' classname='mb-[36px]' />
              <div className='absolute top-0 right-0 mt-[-25px] mr-[-10px] text-[25px] font-semibold text-primary'>
                *
              </div>
            </div>
            <div className='relative self-center'>

              <CTextField error={errors.stateProvince} value={user.stateProvince} onChange={(a) => { setUser({ ...user, stateProvince: a }) }} label='State/Province' w='lg:w-[354px] w-[347px]' classname='mb-[36px]' />
              <div className='absolute top-0 right-0 mt-[-25px] mr-[-10px] text-[25px] font-semibold text-primary'>
                *
              </div>
            </div>
            <div className='relative self-center'>
              <CTextField error={errors.country} value={user.country} onChange={(a) => { setUser({ ...user, country: a }) }} label='Country' w='lg:w-[354px] w-[347px]' classname='lg:mb-[61px] mb-[159px]' />
              <div className='absolute top-0 right-0 mt-[-25px] mr-[-10px] text-[25px] font-semibold text-primary'>
                *
              </div>
            </div>

            <div className='flex flex-row gap-[29px] self-center items-center'>

              <button className='w-[98px] mb-[12px] font-custom font-medium text-white shadow-custom text-[20px]/[27px] text-center tracking-[0.05em] hover:bg-inset bg-black rounded-[26px]  lg:pt-[10px] pt-[12px] lg:pb-[14px] pb-[12px]' onClick={() =>
              {
                navigate('/onboarding-3')
              }}>
                Back
              </button>
              <div className='flex flex-row gap-[10px] self-end mt-[90px] '>
                <div className='w-[9px] h-[9px] bg-slate-400 rounded-full' />
                <div className='w-[9px] h-[9px] bg-slate-400 rounded-full' />
                <div className='w-[9px] h-[9px] bg-slate-400 rounded-full' />
                <div className='w-[9px] h-[9px] bg-primary rounded-full' />
                <div className='w-[9px] h-[9px] bg-slate-400 rounded-full' />
                <div className='w-[9px] h-[9px] bg-slate-400 rounded-full' />
              </div>
              <button className='w-[98px] mb-[12px] font-custom font-medium hover:bg-secondary hover:text-white text-button shadow-custom text-[20px]/[27px] text-center tracking-[0.05em] bg-primary rounded-[26px]   mr-0  lg:pt-[10px] pt-[12px] lg:pb-[14px] pb-[12px]' onClick={() =>
              {
                handleSubmit();
              }}>
                Next
              </button>
            </div>


          </div>
        </div>
        <div className=" lg:block hidden h-full justify-end z-50 pointer-events-none">
          <img className=" max-h-[100%] h-auto" src={square_right} />
        </div>

      </div>

    </div>
  )
}
