import * as React from 'react';
import { makeStyles, styled, withStyles } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps, } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { ExpandMore, } from '@mui/icons-material';

interface CAccordionProps
{
    summary: React.ReactNode
    children?: React.ReactNode
    iconcolor?: string
    bgcolor?: string,
    txcolor?: string,
    fontsize?: string,
    fontheight?: string,
    fontweight?: string,
    radius?: string,
    shadow?: string,
    bordercolor?: string,
    iconangle: Array<string>,
    expanded?: boolean,
    height?: string,
    disabled?: boolean

}

const Accordion = styled(MuiAccordion, { shouldForwardProp: (prop) => prop !== 'props' })<{ props: CAccordionProps }>
    (({ props }) => `
    font-family: 'Satoshi-Variable';
    font-weight: ${props.fontweight ?? '300'};
    font-size: ${props.fontsize ?? '24px'};
    line-height: ${props.fontheight ?? '32px'};
    height: ${props.height ?? '100%'};
    disabled:${true};
    display: 'flex';
    color: ${props.txcolor ?? '#ffff'};;
    background-color: ${props.bgcolor ?? '#161617'};
    flex-direction: column;
    border-radius:${props.radius ?? '0'};
    border: 0;
    border-bottom: 0;
    box-shadow:${props.shadow ?? '0'};
    & .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root
    {
    color: ${props.iconcolor ?? '#ffff'};
    transition-property: transform;
    transition-duration: 0.3s;
    }
`);

const AccordionSummary = styled(MuiAccordionSummary)``;

const AccordionDetails = styled(MuiAccordionDetails)`
    font-size: 20px;
    line-height: 27px;
    font-weight: 300px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-direction: row;
    justify-content: flex-start;
    border-radius: 0px;
    border: 0px;
    border-top: 0px;
`;
const Arrow = styled(ExpandMore)`
`;


const CAccordion = ({ ...props }: CAccordionProps) =>
{
    const [expanded, setExpanded] = React.useState<boolean>(props.expanded ?? false);
    const handleAccordionChange = (event: React.ChangeEvent<{}>, isExpanded: boolean) =>
    {   
        if(!props.disabled){
            setExpanded(isExpanded);
        }
    };

    return <Accordion props={props} disableGutters={true} elevation={0} square expanded={expanded} onChange={handleAccordionChange} >
        <AccordionSummary expandIcon={<Arrow />} sx={{
            '& .MuiSvgIcon-root': {
                transform: expanded ? 'rotate(' + props.iconangle![1] + 'deg)' : 'rotate(' + props.iconangle![0] + 'deg)'
            }
        }}>
            {props.summary}
        </AccordionSummary>
        <AccordionDetails>
            {props.children}
        </AccordionDetails>
    </Accordion>
};
export default CAccordion;



